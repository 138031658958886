import React, { Component } from 'react';
import { connect } from 'react-redux';
import InputAdornment from '@material-ui/core/InputAdornment';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import TextField from '@material-ui/core/TextField';
import MoreInfo from '../MoreInfo/MoreInfo';
import SharedCheckBox from '../../shared/Checkbox/SharedCheckBox';
import { setDisplayOffersServices, setSelectedOffer, setExpandedOffer } from '../../Redux/ActionCreator';
import CommentsBox from './CommentsBox';
import Offers from '../MoreInfo/Offers';
import { renderCurrencyValue, renderPrice } from '../../shared/Utils/PriceLocalization';

import * as consts from '../../shared/Constants/ServiceConstants';

class NeedSomethingElse extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isHidden: true,
            searchValue: '',
            isSearching: false,
        };
        this.toggleHidden = this.toggleHidden.bind(this);
        this.props.setDisplayOffersServices(this.props.vAdditionalOffers);
    }

    /**
     * @desc This function resets the isSearching state variable so that the error message
     *  "no results found" will not display if all the items searched are checked
     */
    resetIsSearching = () => {
        this.setState({ isSearching: false });
    };

    /**
     * @desc This function updates the display offers and services with what the user is searching
     *  for in the search box
     * @param {*} e - The event that user creates by typing in the search box.
     */
    onTextChange = (e) => {
        this.setState({ isSearching: true });

        this.setState({ searchValue: e.target.value });
        const { value } = e.target;

        // If the user clears the search, bring back all services
        if (value === '') {
            this.props.setDisplayOffersServices(this.props.vAdditionalOffers);
            return;
        }
        // Find any matches in the service list according to what the user is typing and add
        // to suggestions
        const regex = new RegExp(`${value}`, 'i');
        const serviceFilteredList = this.props.vAdditionalOffers.filter((it) =>
            this.filterSearchedServices(it, value, regex)
        );

        this.props.setDisplayOffersServices(serviceFilteredList);
    };

    handleChange = (panel) => () => {
        if (this.props.expandedOffer !== panel) {
            this.props.setExpandedOffer(panel);
        } else {
            this.props.setExpandedOffer(false);
        }
    };

    toggleHidden() {
        this.setState({
            isHidden: !this.state.isHidden,
        });
    }

    /**
     * @desc This function returns all the services that match what the user searches
     *  to the name or additional words array
     * @param {*} it - A single item that is being matched
     * @param {*} value - The string the user is searching
     * @param {*} regex - The regex that is being used to determine if it is a match
     */
    filterSearchedServices(it, value, regex) {
        let flag;

        if (value === '') {
            flag = false;
        }
        if (regex.test(it.name)) {
            flag = true;
        }
        if (value.length >= 3) {
            for (let i = 0; i < it.offers.length; i++) {
                if (regex.test(it.offers[i])) {
                    flag = true;
                }
            }
        }

        return flag;
    }

    render() {
        return (
            <div className="need-something-else-container">
                {this.state.isHidden && (
                    <NeedSomething clicked={this.toggleHidden} translations={this.props.vTranslations} />
                )}
                {!this.state.isHidden && (
                    <ServiceOffers
                        clearSearchValue={this.clearSearchValue}
                        displayOffersServices={this.props.vAdditionalOffers}
                        expandedOffer={this.props.expandedOffer}
                        isSearching={this.state.isSearching}
                        onTextChange={this.onTextChange}
                        resetIsSearching={this.resetIsSearching}
                        searchValue={this.state.searchValue}
                        setSelectedOffer={this.props.setSelectedOffer}
                        toggleAccordion={this.handleChange}
                        translations={this.props.vTranslations}
                    />
                )}
                {!this.state.isHidden && (
                    <ShowLess clicked={this.toggleHidden} translations={this.props.vTranslations} />
                )}
            </div>
        );
    }
}

function displayMoreInfo(desc) {
    return !desc.offers.length && (desc.disclaimer || desc.itemDescription);
}

const ShowLess = (props) => (
    <div id="btnShowLess" className="need-something-else-toggle link" onClick={props.clicked}>
        {props.translations.SHOW_LESS}
    </div>
);

const NeedSomething = (props) => (
    <div
        id="btnAdditionalServices"
        className="stat-text-link need-something-else-toggle link"
        data-dtm="service:need-something-else"
        onClick={props.clicked}
    >
        {props.translations.NEED_SOMETHING_ELSE}
    </div>
);

const ServiceOffers = (props) => (
    <>
        <div className="services-title">
            <div className="services-title-text headline2">{props.translations.ADD_SERVTEXT}</div>
            <div className="services-title-border headline-border" />
        </div>
        <TextField
            className="stat-input-field search-input"
            error={
                props.displayOffersServices.filter((x) => x.display && !x.selected).length === 0 &&
                props.searchValue.length > 0 &&
                props.isSearching
            }
            helperText={props.translations.NO_RESULTS}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <div className="icon search" />
                    </InputAdornment>
                ),
            }}
            inputProps={{
                maxLength: '30',
            }}
            onChange={() => props.onTextChange(event)}
            onClick={props.clearSearchValue}
            placeholder={props.translations.SEARCH_OFFSERV}
            value={props.searchValue}
            variant="filled"
            data-dtm="service needs"
        />
        <div className="service-table table" id="services" responsive="sm">
            {props.displayOffersServices.map((desc, index) => {
                if (!desc.display) {
                    return;
                }
                return (
                    <Accordion
                        elevation={0}
                        expanded={props.expandedOffer === desc.serviceFEID}
                        key={desc.serviceFEID}
                        onChange={props.toggleAccordion(desc.serviceFEID)}
                        square
                    >
                        <AccordionSummary
                            aria-label="Info" // need translation
                            aria-controls="service-item-content"
                            className="service-item-content"
                            id="service-item-content"
                        >
                            <div
                                className="stat-checkbox service-item"
                                data-dtm="service needs"
                                onClick={(event) => event.stopPropagation()}
                                onFocus={(event) => event.stopPropagation()}
                            >
                                <div className="service-item-row">
                                    {desc.selectedFE}
                                    <div className="service-cell desc-title">
                                        <div className="desc-title-line1">{desc.name}</div>
                                        {desc.offers.length === 0 ? null : desc.selectedOffer ? (
                                            <div className="selected-offer">{desc.selectedOffer}</div>
                                        ) : null}
                                        {desc.serviceType === consts.DEALERSHIP_ITEM_SERVICE ? (
                                            <div className="desc-subtitle body2">
                                                {props.translations.DEALER_SPECIAL}
                                            </div>
                                        ) : null}
                                    </div>
                                    {desc.displayPrice && !desc.displayPrice[0].tagLine
                                        ? renderCurrencyValue(desc.displayPrice[0])
                                        : ''}

                                    <SharedCheckBox
                                        checked={desc.selected}
                                        onClick={props.resetIsSearching}
                                        id={`chk${desc.serviceFEID}`}
                                        onChange={() => {
                                            props.setSelectedOffer({
                                                id: desc.serviceFEID,
                                                checked: !desc.selected,
                                                currentSearch: props.searchValue,
                                            });
                                        }}
                                    />
                                </div>
                                <div className="service-item-row">
                                    {desc.displayPrice && desc.displayPrice[0].tagLine
                                        ? renderPrice(desc.displayPrice[0])
                                        : ''}
                                </div>
                                <div className="service-item-row">
                                    {props.displayOffersServices[index].offers.length ? (
                                        <Offers
                                            categoryDescription={desc.categoryDescription}
                                            itemDescription={desc.itemDescription}
                                            name={desc.name}
                                            offers={desc.offers}
                                            selected={desc.selected}
                                            serviceCategoryCode={desc.serviceCategoryCode}
                                            serviceFEID={desc.serviceFEID}
                                        />
                                    ) : (
                                        displayMoreInfo(desc) && (
                                            <button
                                                className="service-item-details-link link stat-text-link"
                                                data-dtm={`service:need-something-else:${desc.name}`}
                                                onClick={props.toggleAccordion(desc.serviceFEID)}
                                                type="button"
                                            >
                                                {props.expandedOffer !== desc.serviceFEID
                                                    ? props.translations.DETAILS
                                                    : ' '}
                                            </button>
                                        )
                                    )}
                                </div>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className="service-details">
                                {!desc.serviceType === consts.DEALER_CONFIG_ITEM_SERVICE || displayMoreInfo(desc) ? (
                                    <MoreInfo
                                        backdrop="static"
                                        disclaimer={desc.disclaimer}
                                        inspect={desc.inspect}
                                        itemDescription={desc.itemDescription}
                                        keyboard={false}
                                        name={desc.name}
                                        price={desc.price}
                                        priceDetails={desc.priceDetails}
                                        priceDisclaimer={desc.priceDisclaimer}
                                        remedyDescription={desc.remedyDescription}
                                        replace={desc.replace}
                                        safetyDescription={desc.safetyDescription}
                                        selected={desc.selected}
                                        serviceCategoryCode={desc.serviceCategoryCode}
                                        serviceFEID={desc.serviceFEID}
                                        serviceType={desc.serviceType}
                                        subtext={desc.subtext}
                                        offerSubTitle={desc.offerSubTitle}
                                    />
                                ) : null}
                            </div>
                        </AccordionDetails>
                    </Accordion>
                );
            })}
        </div>
        <CommentsBox initialText={`${props.translations.ADDITIONAL_COMMENTS}...`} label="" />
    </>
);

const mapStateToProps = (state) => ({
    expandedOffer: state.ossAppGlobals.expandedOffer,
    vAdditionalOffers: state.ossAppGlobals.servicesArrayList
        ? state.ossAppGlobals.servicesArrayList.filter((x) => !x.requiredOrCommonService)
        : null,
    vTranslations: state.countryOptionsReducer.translations,
});

export default connect(mapStateToProps, {
    setDisplayOffersServices,
    setExpandedOffer,
    setSelectedOffer,
})(NeedSomethingElse);
