import React from 'react';
import { connect } from 'react-redux';
import SharedModal from '../../../shared/Modal/SharedModal';

class TermsAndConditionsPopup extends React.Component {
    render() {
        const termsAndConditionsContent = (
            <div className="terms-and-condition-content">
                <h2>{this.props.translations.MOBILE_ALERT_MODAL_TITLE}</h2>
                <div
                    dangerouslySetInnerHTML={{
                        __html: this.props.translations.MOBILE_ALERT_MODAL_BODY,
                    }}
                />
                <div
                    dangerouslySetInnerHTML={{
                        __html: this.props.translations.MOBILE_ALERT_MODAL_BODY_TWO,
                    }}
                />
                <div
                    dangerouslySetInnerHTML={{
                        __html: this.props.translations.MOBILE_ALERT_MODAL_BODY_THREE,
                    }}
                />
            </div>
        );

        return (
            <div>
                <SharedModal
                    content={termsAndConditionsContent}
                    handleClose={this.props.closeTACPopup}
                    headerClose={
                        this.props.translations.CLOSE
                            ? this.props.translations.CLOSE.charAt(0).toUpperCase() +
                              this.props.translations.CLOSE.slice(1).toLowerCase()
                            : ''
                    }
                    show={this.props.showTACPopup}
                    simpleModal
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    translations: state.countryOptionsReducer.translations,
});

export default connect(mapStateToProps)(TermsAndConditionsPopup);
