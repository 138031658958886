import AppRoutes from '../RoutingFlow/AppRoutes';
import { browserHistory } from '../../components/App/App';
import { v1Services, v2Services } from "../BackendCalls/BackendCallsConstants";

export const setupTitle = () => {
    const compName = window.location.pathname.split('/')[2];
    let title = 'LABEL_OSS_UI';
    for (let i = 0; i < AppRoutes.length; i++) {
        const routePath = AppRoutes[i].path.split('/')[1];
        if (compName === routePath) {
            title = AppRoutes[i].title;
            break;
        }
    }
};

export const detectRuntimeEnvironment = () => {
    return process.env.REACT_APP_OSS_API_BASE_URL;
};

// React Router
export const reactRoute = (path) => (path !== '/' ? `/newui${path}` : path);

export const reactRoutefromProxy = (path) => {
    console.log(`The path is${path}`);
    return `/oss_redesign/ui-modules_redesign${path}`;
};
// React Router if you need to use onClick or onChange Event
export const browserHistoryPush = (path) => browserHistory.push(`/newui${path}`);

// Redirect to the Error Page
export const errorPageURL = () => browserHistoryPush('/Error');

export const linkToHomeURL = () => {
    const homeURL = '/OSSLandingPage';
    return homeURL;
};

export const adobeAnalytics = (page) => {
    const analytics = document.createElement('script');
    analytics.async = true;
    analytics.innerHTML = `_satellite.track("${page}")`;
    console.log(`Adobe Analytics - Determined Page   :${analytics.innerHTML}`);
    return analytics;
    // document.body.appendChild(analytics)
};

// Function that returns a string with the first letter capitalized
export const capitalizeFirstLetter = (string) => (string && string[0].toUpperCase() + string.slice(1)) || '';

// Function that checks country options and based on value for DEALER_LOCATOR_VERSION, sets the version of ther services call used
export function dealerSearchSetVersion(value) {
    if (value === "1"){
        return v1Services;
    } else if (value === "2"){
        return v2Services;
    } else {
        return v1Services;
    }
}


export const googleAddressParser = (addressComponent) => {
    if(addressComponent){
        let components = {};
        addressComponent.map((value) => {
            value.types && value.types.map((value2) => {
                components[value2] = value.long_name;
                if (value2==='country')
                    components.country_id = value.short_name;
                if (value2==='administrative_area_level_1')
                    components.state_code = value.short_name;
            })
        })
        return components
    }
}
