import React from 'react';
import { connect } from 'react-redux';

class ErrorPage extends React.Component {

    render() {
        const serviceDeptPhoneNumber = this.props.dealershipSummary?.departments.filter((item)=>item.name==='SERVICE')[0].phoneNumber;
        const formatPhoneNUmber = (input)=>{
                input = input.replace(/\D/g,'');
                let size = input.length;
                if (size>0) {input="("+input}
                if (size>3) {input=input.slice(0,4)+") "+input.slice(4,11)}
                if (size>6) {input=input.slice(0,9)+"-" +input.slice(9)}
               return input
            };

        return (
            <div className="error-page-container">
                <div className="error-message-header bold">
                    <div className="error-icon icon alert-caution-red" />
                    <div className="error-message-sorry headline3">
                        {this.props.vTranslations.ERROR_DISP
                            ? this.props.vTranslations.ERROR_DISP
                            : "We're sorry, an error has occured"}
                    </div>
                </div>
                {
                  this.props.showOnTracError ? `An unexpected error has occurred while attempting to schedule your appointment. Please try again later, or contact your dealer at ${formatPhoneNUmber(serviceDeptPhoneNumber)}.`
                      : <div className="error-message-try-again">
                      {this.props.vTranslations.TRY_AGAIN
                          ? this.props.vTranslations.TRY_AGAIN
                          : 'Please try again later or contact your dealer to schedule an appointment'}
                  </div>
                }

            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    vTranslations: state.countryOptionsReducer.translations,
    showOnTracError:state.appointmentReducer.showOnTracError && state.appointmentReducer.onTracTries === 'second',
    dealershipSummary: state.prefillReducer.dealerSummaryObject.dealershipSummary
});

export default connect(mapStateToProps)(ErrorPage);
