import {Accordion} from "react-bootstrap";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Maintenances from "../../../shared/ServicesLineItemWrapper/Maintenances";
import React from "react";
import SharedCheckBox from "../../../shared/Checkbox/SharedCheckBox";
import DisplayRecalls from "../../../shared/ServicesLineItemWrapper/DisplayRecalls";
import ServicesOffers from "../../../shared/ServicesLineItemWrapper/ServicesOffers";
import LineItemDetails from "../../../shared/ServicesLineItemWrapper/LineItemDetails";




const RecommendedServiceList = (props)=>{
     const services = props.item;

    return (<div>

        {
            services.map((item)=>{
                return <div className="service-table service-list-two" style={{borderBottom:'1px solid #dbdddf'}}>
                    <Accordion
                        elevation={0}
                        square
                    >
                        <AccordionSummary
                            aria-label="Info" // need translation
                            aria-controls="service-item-content"
                            className="service-item-content"
                            id="service-item-content"
                        >
                            <div
                                className="stat-checkbox service-item"
                                data-dtm="service needs"
                                onClick={(event) => event.stopPropagation()}
                                onFocus={(event) => event.stopPropagation()}
                                style={{display:'block'}}
                            >
                                <div className="service-item-row">
                                    <div className="service-item-cell title-container">
                                        <div className="service-item-offer">

                                            {
                                                item.lineItemTypePlus === 'PEGA_RECALL' ? <DisplayRecalls name={item.name}/>:(<div className="non-recall-items">
                                                    {item.name}
                                                </div>)
                                            }
                                           </div>
                                    </div>
                                    <div className="service-item-cell service-item-offer">
                                       {/* {item.price.price && item.price[0].tagLine
                                            ? renderCurrencyValue(item.price[0])
                                            : ''}*/}
                                    </div>

                                    <SharedCheckBox
                                        checked={item.isSelected}
                                        id={item.uniqueId}
                                        onChange={() => {
                                            console.log("Check box")
                                        }}
                                    />
                                </div>
                                <div className="service-item-row">
                                    {/*{displayPrice && displayPrice[0].tagLine ? renderPrice(displayPrice[0]) : ''}*/}
                                </div>
                                <div className="service-item-row">
                                    {
                                        item.maintenances.length  > 0 && <Maintenances maintenances={item.maintenances}/>
                                    }
                                    {
                                        item.offers.length > 0 && <ServicesOffers servicesItem={item}/>
                                    }
                                    {
                                        <LineItemDetails lineItem={item}/>
                                    }
                                </div>
                            </div>
                        </AccordionSummary>
                    </Accordion>
                </div>
            })
        }
    </div>)
}

export default RecommendedServiceList