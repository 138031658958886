// This object is used for linking between pages according to the current "flow"
// The current flows are: Authenticated, EditAppointment, Brand, TireFinder, and OSSLocator
// New flows may be added later
let RoutingFlow;

RoutingFlow = {
    Authenticated: {
        EntryParam: {
            Back: '',
            Forward: 'SERVICES_LINK',
        },
        OssLandingPage: {
            // OssLandingPage == Services page
            Back: '',
            Forward: 'DEALERSHIP_LOCATOR_LINK',
        },
        Locator: {
            Back: 'SERVICES_LINK',
            Forward: 'APPOINTMENT_LINK',
        },
        Appointment: {
            Back: 'DEALERSHIP_LOCATOR_LINK',
            Forward: 'REVIEW_LINK',
        },
        Review: {
            Back: 'APPOINTMENT_LINK',
            Forward: 'CONFIRMATION_LINK',
        },
        Confirmation: {
            Back: '',
            Forward: '',
        },
    },
    AuthenticatedOCPlus: {
        EntryParam: {
            Back: '',
            Forward: 'VEHICLE_LINK',
        },
        Vehicle: {
            Back: '',
            Forward: 'SERVICES_LINK',
        },
        OssLandingPage: {
            // OssLandingPage == Services page
            Back: 'VEHICLE_LINK',
            Forward: 'DEALERSHIP_LOCATOR_LINK',
        },
        Locator: {
            Back: 'SERVICES_LINK',
            Forward: 'APPOINTMENT_LINK',
        },
        Appointment: {
            Back: 'DEALERSHIP_LOCATOR_LINK',
            Forward: 'REVIEW_LINK',
        },
        Review: {
            Back: 'APPOINTMENT_LINK',
            Forward: 'CONFIRMATION_LINK',
        },
        Confirmation: {
            Back: '',
            Forward: '',
        },
    },
    EditAppointment: {
        EntryParam: {
            Back: '',
            Forward: 'CONFIRMATION_LINK',
        },
        Appointment: {
            Back: '',
            Forward: 'REVIEW_LINK',
        },
        Review: {
            Back: 'APPOINTMENT_LINK',
            Forward: 'CONFIRMATION_LINK',
        },
        Confirmation: {
            Back: '',
            Forward: 'APPOINTMENT_LINK',
        },
    },
    Brand: {
        EntryParam: {
            Back: '',
            Forward: 'VEHICLE_LINK',
        },
        Locator: {
            Back: '',
            Forward: 'VEHICLE_LINK',
        },
        Vehicle: {
            Back: 'DEALERSHIP_LOCATOR_LINK',
            Forward: 'SERVICES_LINK',
        },
        OssLandingPage: {
            Back: 'VEHICLE_LINK',
            Forward: 'APPOINTMENT_LINK',
        },
        Appointment: {
            Back: 'SERVICES_LINK',
            Forward: 'REVIEW_LINK',
        },
        Review: {
            Back: 'APPOINTMENT_LINK',
            Forward: 'CONFIRMATION_LINK',
        },
        Confirmation: {
            Back: '',
            Forward: '',
        },
    },
    TireFinder: {
        EntryParam: {
            Back: '',
            Forward: 'SERVICES_LINK',
        },
        OssLandingPage: {
            Back: '',
            Forward: 'APPOINTMENT_LINK',
        },
        Appointment: {
            Back: 'SERVICES_LINK',
            Forward: 'REVIEW_LINK',
        },
        Review: {
            Back: 'APPOINTMENT_LINK',
            Forward: 'CONFIRMATION_LINK',
        },
        Confirmation: {
            Back: '',
            Forward: '',
        },
    },
    DealerUS: {
        EntryParam: {
            Back: '',
            Forward: 'LANDING_LINK',
        },
        Landing: {
            Back: '',
            Forward: 'VEHICLE_LINK',
        },
        Vehicle: {
            Back: 'LANDING_LINK',
            Forward: 'SERVICES_LINK',
        },
        OssLandingPage: {
            Back: 'VEHICLE_LINK',
            Forward: 'APPOINTMENT_LINK',
        },
        Appointment: {
            Back: 'SERVICES_LINK',
            Forward: 'REVIEW_LINK',
        },
        Review: {
            Back: 'APPOINTMENT_LINK',
            Forward: 'CONFIRMATION_LINK',
        },
        Confirmation: {
            Back: '',
            Forward: '',
        },
    },
    Dealer: {
        EntryParam: {
            Back: '',
            Forward: 'VEHICLE_LINK',
        },
        Vehicle: {
            Back: '',
            Forward: 'SERVICES_LINK',
        },
        OssLandingPage: {
            Back: 'VEHICLE_LINK',
            Forward: 'APPOINTMENT_LINK',
        },
        Appointment: {
            Back: 'SERVICES_LINK',
            Forward: 'REVIEW_LINK',
        },
        Review: {
            Back: 'APPOINTMENT_LINK',
            Forward: 'CONFIRMATION_LINK',
        },
        Confirmation: {
            Back: '',
            Forward: '',
        },
    },
    OssLocator: {
        EntryParam: {
            Back: '',
            Forward: 'DEALERSHIP_LOCATOR_LINK',
        },
        Locator: {
            Back: '',
            Forward: 'VEHICLE_LINK',
        },
        Vehicle: {
            Back: 'DEALERSHIP_LOCATOR_LINK',
            Forward: 'SERVICES_LINK',
        },
        OssLandingPage: {
            Back: 'VEHICLE_LINK',
            Forward: 'APPOINTMENT_LINK',
        },
        Appointment: {
            Back: 'SERVICES_LINK',
            Forward: 'REVIEW_LINK',
        },
        Review: {
            Back: 'APPOINTMENT_LINK',
            Forward: 'CONFIRMATION_LINK',
        },
        Confirmation: {
            Back: '',
            Forward: '',
        },
    },
};
// Call this to return a key to lookup the page link in the Translations file
// example: findNextFlow(Authenticated, Review, Forward) returns 'CONFIRMATION_LINK'
export default function findNextFlow(flow, page, direction) {
    if (flow === undefined) {
        flow = 'Authenticated';
    }
    return RoutingFlow[flow][page][direction];
}
