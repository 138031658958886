import { connect } from 'react-redux';
import axios from 'axios';
import React from 'react';
import {setCancelError, setDeleteAppointmentFlag, setSpinner} from '../../Redux/ActionCreator';
import SharedModal from '../../shared/Modal/SharedModal';
import { formatDateLong } from '../../shared/Utils/TimeLocalization';
import store from "../../Redux/Store";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Grid, Grow} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

class CancelAppointmentPopup extends React.Component {
    handleDelete = () => {
        store.dispatch(setSpinner(true));
        if (this.props.cancelError) store.dispatch(setCancelError(false));
        const deleteUrl = `${process.env.REACT_APP_OSS_API_BASE_URL}/oss/oss/api/v4.0/${this.props.vCountry}/dealerships/${this.props.bac}/appointment/${this.props.recordLocator}`;

        axios.post(deleteUrl, this.props.recordLocator).then((res) => {
            store.dispatch(setSpinner(false));
            if (res.status === 200) {
                this.props.setDeleteAppointmentFlag(true);
                this.props.closeCancelAppointmentPopup();
            } else {
                store.dispatch(setCancelError(true));
            }
        },
        (error) => {
            store.dispatch(setSpinner(false));
            store.dispatch(setCancelError(true));
        });
    };

    render() {
        const formatDate = formatDateLong(this.props.selectedDate);
        let { cancelError, vTranslations } = this.props;

        const cancelAppointmentPopupContent = (
            <div className="cancel-appointment-content">
                {cancelError ?
                    <Grid item xs={12}>
                        <Grow in={cancelError}>
                            <Alert className="login-error" icon={<i className="icon alert-caution-red" />} severity="error">
                                {vTranslations.CONFIRM_CANCEL}
                            </Alert>
                        </Grow>
                    </Grid> : null }
                {this.props.showSpinner ? <CircularProgress/> :
                    <div>
                        <div className="cancel-appointment-date headline3">{formatDate}</div>
                        <div className="cancel-appointment-text-container">
                            <div className="icon alert-caution-red"/>
                            <div className="cancel-appointment-text">{this.props.vTranslations.CONFIRM_CANCEL}</div>
                        </div>
                        <div className="cancel-appointment-button-container">
                            <input
                                onClick={this.handleDelete}
                                type="submit"
                                value={this.props.vTranslations.CANCEL_APPOINTMENT}
                                className="cancel-appointment-button button primary-button"
                            />
                            <input
                                onClick={this.props.closeCancelAppointmentPopup}
                                type="submit"
                                value={this.props.vTranslations.KEEP_APPOINTMENT}
                                className="keep-appointment-button button secondary-button"
                            />
                        </div>
                    </div>
                }
            </div>
        );

        return (
            <SharedModal
                content={cancelAppointmentPopupContent}
                handleClose={this.props.closeCancelAppointmentPopup}
                show={this.props.showCancelAppointmentPopup}
                simpleModal
                backdrop="static"
                keyboard={false}
                headerClose={this.props.vTranslations.CLOSE}
            />
        );
    }
}

const mapStateToProps = (state) => ({
    selectedDate: state.appointmentReducer.selectedDate,
    deleteAppointmentFlag: state.confirmationReducer.deleteAppointmentFlag,
    bac: state.locReducer.selectedDealer.bac,
    recordLocator: state.reviewReducer.recordLocator,
    vCountry: state.prefillReducer.country,
    vTranslations: state.countryOptionsReducer.translations,
    showSpinner: state.ossAppGlobals.showSpinner,
    cancelError: state.confirmationReducer.cancelError,
});

export default connect(mapStateToProps, { setDeleteAppointmentFlag })(CancelAppointmentPopup);
