import dayjs from 'dayjs';
import store from '../../../Redux/Store';
import { localizeCurrencyValueSimple } from '../PriceLocalization';
import { yes, no, notApplicable } from '../../Constants/SharedConstants';
import { DealerFlowUs, AuthenticatedFlow, AuthenticatedOCPlus } from '../../RoutingFlow/FlowComponentsConstants';
import {
    MOBILE_SERVICE,
    PREMIUM_CONCIERGE,
    PREMIUM_CONCIERGE_WITH_LOANER
} from "../../../components/Appointment/AppointmentConstants";

let mobileValue;
let emailValue;
const advancedFormat = require('dayjs/plugin/advancedFormat');
const appointmentConstants = require('../../../components/Appointment/AppointmentConstants');
dayjs.extend(advancedFormat);

export default function appointmentObject(display) {
    const localStore = store.getState();
    let appointmentDate = localStore.appointmentReducer.selectedDate;
    let appointmentTime = localStore.appointmentReducer.selectedDate;
    let nationalId;
    let { year } = localStore.prefillReducer;
    let { make } = localStore.prefillReducer;
    let { model } = localStore.prefillReducer;
    const isAuthFlow = localStore.prefillReducer.flowSource;
    let mileage = localStore.vehicleReducer.selectedMileage
        ? localStore.vehicleReducer.selectedMileage
        : localStore.prefillReducer.miles;
    let recordLocator;
    let id;
    let licensePlate;
    let customerId;
    let vehicleId;
    let dmsAppointmentId;
    // Field to indicate dealer/users enrollment status and if they want to use their points
    let useMyRewardsIndicator = notApplicable;

    if (appointmentDate) {
        appointmentDate = dayjs(localStore.appointmentReducer.selectedDate).format('YYYY-MM-DD');
        appointmentTime = dayjs(localStore.appointmentReducer.selectedDate).format('HH:mm');
    }

    const mobileMode = localStore.prefillReducer.view === 'm';

    if (localStore.appointmentReducer.isBusinessCustomer) {
        nationalId = localStore.appointmentReducer.businessDni;
    } else {
        nationalId = localStore.appointmentReducer.dni;
    }

    // Check if vehicle reducer is populated
    // (i.e. Are we in an unauth flow that uses the vehicle page?)
    if (
        localStore.vehicleReducer.selectedYear &&
        localStore.vehicleReducer.selectedMake &&
        localStore.vehicleReducer.selectedModel
    ) {
        year = localStore.vehicleReducer.selectedYear;
        make = localStore.vehicleReducer.selectedMake;
        model = localStore.vehicleReducer.selectedModel;
    }

    if (localStore.vehicleReducer.selectedMileage) {
        mileage = localStore.vehicleReducer.selectedMileage;
    }

    if (localStore.vehicleReducer.selectedLicense) {
        licensePlate =
            localStore.vehicleReducer.selectedLicense !== '' ? localStore.vehicleReducer.selectedLicense : null;
    } else {
        licensePlate = localStore.prefillReducer.licensePlate !== '' ? localStore.prefillReducer.licensePlate : null;
    }

    if (localStore.prefillReducer.editAppointmentObject.length !== 0) {
        if (localStore.prefillReducer.editAppointmentObject.dmsAppointmentId) {
            dmsAppointmentId = localStore.prefillReducer.editAppointmentObject.dmsAppointmentId;
        }

        if (localStore.confirmationReducer.newAppointmentFlag) {
            id = null;
        } else {
            id = localStore.prefillReducer.editAppointmentObject.id;
        }

        customerId = localStore.prefillReducer.editAppointmentObject.customer.id;
        vehicleId = localStore.prefillReducer.editAppointmentObject.vehicle.id;
    }

    if (localStore.reviewReducer.recordLocator) {
        if (localStore.confirmationReducer.newAppointmentFlag) {
            recordLocator = null;
        } else {
            recordLocator = localStore.reviewReducer.recordLocator;
        }
    } else {
        recordLocator = localStore.prefillReducer.editAppointmentRecordLocator;
    }

    if (localStore.countryOptionsReducer.countryOptions.GET_LOYALTY && localStore.myRewardsReducer.usePoints) {
        useMyRewardsIndicator = yes;
    } else if (
        localStore.countryOptionsReducer.countryOptions.GET_LOYALTY &&
        !localStore.myRewardsReducer.usePoints &&
        !localStore.myRewardsReducer.softEnroll &&
        (localStore.myRewardsReducer.active || localStore.myRewardsReducer.active === '')
    ) {
        useMyRewardsIndicator = no;
    }


    const getTransportationCode = ()=>{
        let transportationCode = localStore.appointmentReducer.transportation
            ? localStore.appointmentReducer.transportation.code
            : localStore.prefillReducer.editAppointmentObject.transportationOption;

        if(((localStore.prefillReducer !== AuthenticatedFlow)
            && (transportationCode === MOBILE_SERVICE
                || transportationCode === PREMIUM_CONCIERGE_WITH_LOANER
                || transportationCode === PREMIUM_CONCIERGE)
            && (!localStore.appointmentReducer.pickupAddress))){
            console.log(`${transportationCode} must required the pick up location, changing the transportation to empty object since pick up address is not available`)
            transportationCode =  ""
        }
        return transportationCode
    }

    return {
        appointmentDate,
        appointmentSource: 'DEALER', // TODO: Where do we get this?
        appointmentTime: { start: appointmentTime },
        advisor: { id: localStore.appointmentReducer.advisor.id },
        bac: localStore.prefillReducer.dealerSummaryObject.dealershipSummary.bac,
        brand: null, // TODO: How to populate?
        countryCode: localStore.prefillReducer.country,
        useMyRewardsIndicator,
        gpmsProfileId: localStore.prefillReducer.gpmsProfileId ? localStore.prefillReducer.gpmsProfileId : null,
        customer: {
            address:
                localStore.prefillReducer.street === ''
                    ? null
                    : {
                          addressLine1: localStore.prefillReducer.street,
                          cityName: localStore.prefillReducer.city,
                          countrySubentity: localStore.prefillReducer.regionCode,
                          countrySubentityCode: `${localStore.prefillReducer.country}-${localStore.prefillReducer.regionCode}`,
                          postalZone: localStore.prefillReducer.postalCode,
                      },
            businessName: localStore.appointmentReducer.businessName,
            communications: combineCommunications(),
            firstName: localStore.appointmentReducer.customerFirstName,
            id: customerId,
            isBusiness: localStore.appointmentReducer.isBusinessCustomer,
            lastName: localStore.appointmentReducer.customerLastName,
            gcin: localStore.prefillReducer.gcin
                ? localStore.prefillReducer.gcin
                : localStore.myGarageReducer.gcin
                ? localStore.myGarageReducer.gcin
                : null,
            // All marketing is false for US
            marketingOptOut: localStore.appointmentReducer.marketingOptOutFlag,
            marketingOptOutEmail: localStore.appointmentReducer.marketingOptOutFlag,
            marketingOptOutPCM: localStore.appointmentReducer.marketingOptOutFlag,
            marketingOptOutPhone: localStore.appointmentReducer.marketingOptOutFlag,
            marketingOptOutPostalMail: localStore.appointmentReducer.marketingOptOutFlag,
            marketingOptOutSms: localStore.appointmentReducer.marketingOptOutFlag,
            nationalId,
        },
        // These are the comments left on the Review Page
        customerComments: localStore.reviewReducer.comments,
        specialInstructions: localStore.appointmentReducer.conciergeComments,
        // Language and country
        customerLocale: localStore.prefillReducer.locale,
        dmsAppointmentId,
        dropoffLocation:
            localStore.appointmentReducer.transportation.code === appointmentConstants.PREMIUM_CONCIERGE ||
            localStore.appointmentReducer.transportation.code === appointmentConstants.PREMIUM_CONCIERGE_WITH_LOANER ||
            localStore.appointmentReducer.transportation.code === appointmentConstants.CADILLAC_PREMIUM_CONCIERGE
                ? localStore.appointmentReducer.dropoffAddress
                : null,
        // Unselected Recall Items
        fieldActions: mapUnSelectedRecallsToPost(),
        id, // This needs to be set to null when a new appt is created
        lineItems: combineServicesAndOffers(display),
        mobileModeSubmission: mobileMode,
        origin: localStore.prefillReducer.ultimateOrigin
            ? localStore.prefillReducer.ultimateOrigin
            : localStore.prefillReducer.ultimateOrigin,
        pickUpTime: null,
        pickupLocation:
            localStore.appointmentReducer.transportation.code === appointmentConstants.PREMIUM_CONCIERGE ||
            localStore.appointmentReducer.transportation.code === appointmentConstants.PREMIUM_CONCIERGE_WITH_LOANER ||
            localStore.appointmentReducer.transportation.code === appointmentConstants.CADILLAC_PREMIUM_CONCIERGE
                ? localStore.appointmentReducer.pickupAddress
                : null,
        recordLocator,
        serviceLane: localStore.appointmentReducer.serviceLane,
        // Comments left on services page
        serviceNotes: localStore.ossAppGlobals.miscService,
        serviceAddresses: localStore.appointmentReducer.transportation.code === appointmentConstants.MOBILE_SERVICE ?
                 localStore.mobileServiceReducer.serviceAddresses
                : localStore.appointmentReducer.transportation.code === appointmentConstants.PREMIUM_CONCIERGE ||
            localStore.appointmentReducer.transportation.code === appointmentConstants.PREMIUM_CONCIERGE_WITH_LOANER ||
            localStore.appointmentReducer.transportation.code === appointmentConstants.CADILLAC_PREMIUM_CONCIERGE
                ? localStore.appointmentReducer.serviceAddresses
                : null,
        serviceCommunications: localStore.appointmentReducer.transportation.code === appointmentConstants.MOBILE_SERVICE
            ?  localStore.mobileServiceReducer.serviceCommunications :
            localStore.appointmentReducer.transportation.code === appointmentConstants.PREMIUM_CONCIERGE ||
            localStore.appointmentReducer.transportation.code === appointmentConstants.PREMIUM_CONCIERGE_WITH_LOANER ||
            localStore.appointmentReducer.transportation.code === appointmentConstants.CADILLAC_PREMIUM_CONCIERGE
                ? localStore.appointmentReducer.serviceCommunications
                : null,
        transportationOption: getTransportationCode(),
        vehicle: {
            id: vehicleId,
            licensePlate,
            mileage,
            registrationId: localStore.vehicleReducer.selectedRegistration
                ? localStore.vehicleReducer.selectedRegistration
                : localStore.prefillReducer.registration,
            vin:
                isAuthFlow === AuthenticatedFlow ||
                isAuthFlow === AuthenticatedOCPlus ||
                (isAuthFlow === DealerFlowUs && localStore.prefillReducer.vin)
                    ? localStore.prefillReducer.vin
                    : null,
            vehicle: {
                year,
                make: { make },
                model: { model },
                plus: {
                    plus: localStore.vehicleReducer.selectedMotor
                        ? localStore.vehicleReducer.selectedMotor
                        : localStore.prefillReducer.engine,
                },
            },
        },
    };
}

function setPriceForLineItems(displayPriceObject, index) {
    let priceField = '';
    let space = '';
    let tagLineField = '';
    if (
        displayPriceObject !== null &&
        displayPriceObject !== undefined &&
        displayPriceObject.length > index &&
        displayPriceObject[index] !== null
    ) {
        if (displayPriceObject[index].price !== null) {
            priceField = localizeCurrencyValueSimple(displayPriceObject[index].price);
            space = ' ';
        }

        if (displayPriceObject[index].tagLine !== null) {
            tagLineField = displayPriceObject[index].tagLine;
        }
    }

    return priceField + space + tagLineField;
}

function setCustomerEmail() {
    const localStore = store.getState();

    emailValue = localStore.appointmentReducer.customerEmail;

    return {
        type: 'EMAIL',
        value: emailValue,
    };
}

function setCustomerPhone() {
    const localStore = store.getState();

    mobileValue = localStore.appointmentReducer.customerPhoneNumber;

    return {
        type: 'PHONE',
        value: mobileValue,
    };
}

function setSMS() {
    const localStore = store.getState();
    const { optInFlag } = localStore.appointmentReducer;
    const mobileValue = localStore.appointmentReducer.customerPhoneNumber;

    if (optInFlag === true) {
        return {
            type: 'CELL_PHONE',
            value: mobileValue,
        };
    }
    return null;
}

function mapSelectedServicesToPost() {
    const localStore = store.getState();
    const services = localStore.ossAppGlobals.servicesArrayList;
    let lineItems = [];

    if (services) {
        services
            .filter((x) => x.selected)
            .forEach((service) => {
                if (service.offers === null || service.offers.filter((x) => x.selected).length === 0) {
                    const lineItem = {};
                    lineItem.code = service.code;
                    lineItem.price = setPriceForLineItems(service.displayPrice, 0);
                    lineItem.secondaryPrice = setPriceForLineItems(service.displayPrice, 1);
                    lineItem.description = service.itemDescription;
                    lineItem.name = service.name;
                    lineItem.globalLaborCode = service.globalLaborCode;
                    lineItem.namePrefix = service.namePrefix;
                    lineItem.type = service.type;
                    lineItem.typePlus = service.typePlus;
                    lineItem.parentServiceName = '';
                    lineItem.couponCode = service.couponCode;
                    lineItems.push(lineItem);
                } else {
                    service.offers
                        .filter((x) => x.selected)
                        .forEach((offer) => {
                            const lineItem = {};
                            lineItem.code = offer.code;
                            lineItem.description = offer.offerSubTitle;
                            lineItem.name = offer.name;
                            lineItem.namePrefix = offer.namePrefix;
                            lineItem.price = setPriceForLineItems(offer.displayPrice, 0);
                            lineItem.secondaryPrice = setPriceForLineItems(offer.displayPrice, 1);
                            lineItem.type = offer.type;
                            lineItem.typePlus = offer.typePlus;
                            lineItem.globalLaborCode = offer.globalLaborCode;
                            lineItem.couponCode = offer.couponCode;
                            lineItem.parentServiceName = service.name;
                            lineItems.push(lineItem);
                        });
                }
            });
    }

    return lineItems.map((itemsArray) => {
        const {
            code,
            description,
            name,
            globalLaborCode,
            namePrefix,
            secondaryPrice,
            type,
            typePlus,
            price,
            parentServiceName,
            couponCode,
        } = itemsArray;
        return (lineItems = {
            code,
            description,
            name,
            globalLaborCode,
            namePrefix,
            secondaryPrice,
            type,
            typePlus,
            price,
            parentServiceName,
            couponCode,
        });
    });
}

function mapUnSelectedRecallsToPost() {
    const localStore = store.getState();
    const services = localStore.ossAppGlobals.servicesArrayList;
    let recalls = [];

    if (services) {
        services
            .filter((x) => x.selected === false && (x.typePlus === 'PEGA_RECALL' || x.typePlus === 'RECALL'))
            .forEach((service) => {
                const lineItem = {};
                lineItem.code = service.code;
                lineItem.description = service.name;
                lineItem.type = 'RECALL';
                recalls.push(lineItem);
            });
    }

    return recalls.map((itemsArray) => {
        const { code, description, type } = itemsArray;
        return (recalls = {
            code,
            description,
            type,
        });
    });
}

function mapTireFinderServicesToPost() {
    const localStore = store.getState();
    const lineItems = [];

    if (localStore.ossAppGlobals.tiresString) {
        const tireFinderServices = localStore.ossAppGlobals.tiresString.split('ALINE');
        for (let i = 0; i < tireFinderServices.length; i++) {
            const lineItem = {};
            lineItem.code = 'MISC';
            lineItem.name = tireFinderServices[i];
            lineItem.type = 'MISC';
            //lineItems.tf = true;
            lineItems.push(lineItem);
        }
    }
    return lineItems;
}

function combineCommunications() {
    const communications = [];
    communications.push(setCustomerEmail());
    communications.push(setCustomerPhone());
    if (setSMS() !== null) {
        communications.push(setSMS());
    }
    return communications;
}

function combineServicesAndOffers(display) {
    const localStore = store.getState();
    const { editAppointmentObject } = localStore.prefillReducer;

    // If the user has not come from the service page, use the lineItems object
    if (
        editAppointmentObject &&
        editAppointmentObject.lineItems &&
        editAppointmentObject.lineItems.length > 0 &&
        !localStore.confirmationReducer.newAppointmentFlag
    ) {
        return editAppointmentObject.lineItems;
    }
    let servicesAndOffers;
    if (!display) {
        servicesAndOffers = [...mapSelectedServicesToPost(), ...mapTireFinderServicesToPost()];
    } else {
        servicesAndOffers = mapSelectedServicesToPost();
    }
    return servicesAndOffers;
}
