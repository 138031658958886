import { takeEvery, takeLatest } from 'redux-saga/effects';

// Import Permission
/* import { LOAD_PERMISSION_ASYNC } from "../shared/Permission/PermissionRedux/PermissionActionTypes"; */
/* import * as permissionWorker from '../shared/Permission/PermissionRedux/PermissionWorker'; */

export default function* RootSaga() {
    // watcher goes here
    /*   yield takeEvery(LOAD_PERMISSION_ASYNC,permissionWorker.permissionWorker); */
}
