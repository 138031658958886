/* eslint-disable no-else-return */

import store from '../../Redux/Store';
import { AuthenticatedFlow } from '../RoutingFlow/FlowComponentsConstants';
import {SET_ANALYTICS_PAGE_NAME} from "../../Redux/ActionTypes";



export const setPageNameForAnalytics = (name, value)=>{
    const setSessionStorage = ()=>{
        return {
            type:SET_ANALYTICS_PAGE_NAME,
            payload:value
        }
    }
    console.log("set Page Name",value);
    store.dispatch(setSessionStorage());
};

export const getPageNameForAnalytics = (name)=>{

    const localStore = store.getState();
    const { analyticsPageName } = localStore.headReducer;
    console.log("Analytics Page Name",name,analyticsPageName);
    return analyticsPageName
};

export const resetPageNameForAnalytics = (name)=>{
    console.log("Reset Page Name",name);
    const value = ''
    const setSessionStorage = ()=>{
        return {
            type:SET_ANALYTICS_PAGE_NAME,
            value
        }
    }
    store.dispatch(setSessionStorage());
};

const AnalyticsService = () => {
    const analyticsFactory = {};

    const localStore = store.getState();
    const { origin } = localStore.prefillReducer;

    const { locale } = localStore.countryOptionsReducer;
    let { country } = localStore.prefillReducer;
    country = country.toLowerCase();

    if (origin.indexOf('CFUI') > -1) {
        country = '';
    }

    let language = locale.split('-')[0].toLowerCase();
    if (origin.indexOf('CFUI') > -1) {
        language = '';
    }

    const dealershipBrands = localStore.prefillReducer.dealerSummaryObject.dealershipSummary.brands;

    analyticsFactory.origin = getOrigin();
    analyticsFactory.currentPage = null;
    analyticsFactory.loggingEnabled = getLoggingEnabled();

    // get BAC and displayMode 'on-demand' for the case where we are using dealer locate
    // and the BAC or displayMode could change
    function getBac() {
        let bac = '';
        if (localStore.prefillReducer.dealerSummaryObject.dealershipSummary.bac) {
            bac = localStore.prefillReducer.dealerSummaryObject.dealershipSummary.bac;
        }
        return bac;
    }

    function getOrigin() {
        const { ultimateOriginFromServer } = localStore.prefillReducer;

        if (ultimateOriginFromServer) {
            return ultimateOriginFromServer;
        } else if (origin.indexOf('CFUI') > -1) {
            return 'CFUI';
        } else {
            return 'N/A';
        }

        // else if (
        //     apptObject.getAppointment() &&
        //     apptObject.getAppointment().origin
        // ) {
        //     return apptObject.getAppointment().origin;
        // } else {
        //     return 'N/A';
        // }
    }

    function getOriginPretty() {

        if (origin.indexOf('CHEV') > -1) {
            return 'Chevrolet';
        } else if (origin.indexOf('CAD') > -1) {
            return 'Cadillac';
        } else if (origin.indexOf('GMC') > -1) {
            return 'GMC';
        } else if (origin.indexOf('BUICK') > -1) {
            return 'Buick';
        } else if (origin.indexOf('TIREFIND') > -1) {
            return 'Tire Finder';
        } else if (origin.indexOf('DealerWebsite') > -1) {
            return 'Dealer Website';
        } else if (origin.indexOf('HOLD') > -1) {
            return 'Holden';
        } else if (origin.indexOf('OWNCTR') > -1) {
            return 'Owner Center';
        } else if (origin.indexOf('MCS') > -1) {
            return 'My Certified Service';
        } else if (origin.indexOf('CFUI') > -1) {
            return 'cfui';
        } else {
            return 'dealer';
        }
    }

    function getLoggingEnabled() {
        // Only log for certain origins
        if (localStore.prefillReducer.brandsiteOrigin) {
            return true;
        }

        return false;
    }

    function getBrandOrSellingSource() {
        let siteBrand = '';
        const { sellingSource } = localStore.prefillReducer;
        const { makeCode } = localStore.prefillReducer;
        const { brand } = localStore.prefillReducer;

        if (analyticsFactory.origin === 'TIREFIND') {
            siteBrand = sellingSource !== '' ? sellingSource : 'My Certified Service';
        } else if (analyticsFactory.origin != null && analyticsFactory.origin !== '') {
            siteBrand = getOriginPretty();
        } else if (makeCode !== null && makeCode !== '') {
            siteBrand = makeCode;
        } else if (sellingSource !== null && sellingSource !== '') {
            siteBrand = sellingSource;
        } else if (brand !== null && brand !== '') {
            siteBrand = brand;
        } else if (dealershipBrands && dealershipBrands !== null && dealershipBrands.length === 1) {
            // eslint-disable-next-line prefer-destructuring
            siteBrand = dealershipBrands[0];
        }

        return siteBrand.toLowerCase();
    }

    function getBrandShortHand(brand) {
        switch (brand.toUpperCase()) {
            case 'CHEVROLET':
            case 'CHEVY':
                return 'ch';
            case 'CADILLAC':
            case 'CAD':
                return 'ca';
            case 'GMC':
                return 'gmc';
            case 'BUICK':
                return 'bu';
            case 'HOLD':
            case 'HOLDEN':
                return 'ho';
            case 'TIRE FINDER':
                return 'tf';
            case 'DEALER WEBSITE':
                return 'dealer';
            case 'OWNER CENTER':
                return 'oc';
            case 'MY CERTIFIED SERVICE':
                return 'mcs';
            default:
                return 'cfui';
        }
    }

    function getRegion() {
        let region;
        if (origin.indexOf('CFUI') > -1) {
            region = '';
        } else {
            switch (country) {
                case 'us':
                case 'mx':
                    region = 'na';
                    break;
                case 'br':
                case 'ar':
                    region = 'sa';
                    break;
                case 'au':
                case 'nz':
                    region = 'oc';
                    break;
                default:
                    region = 'na';
                    break;
            }
        }
        return region;
    }

    function getTierAndOrigin() {
        if (getOrigin() === 'TIREFIND') {
            return ':t1:owners:certified-service:tirefind:oss:';
        } else if (getOrigin() === 'CFUI') {
            return ':owners:certified-service:oss:';
        } else {
            return `:${getRegion()}:${country}:${language}:t1:owners:certified-service:oss:`;
        }
    }

    function getPageName(pageName, brand) {
        return `${getBrandShortHand(
            brand
        )}${getTierAndOrigin()}${pageName.toLowerCase()}`;
    }

    function getSeoPageName(pageName) {
        return `${getOriginPretty()} | Schedule Appointment | ${pageName}`;
    }

    function getSiteName(brand) {
        if (brand === null) {
            return 'gmwp';
        } else {
            return 'quantum';
        }
    }

    function getDevice() {
        const PHONE_MAX = 768;
        const TABLET_MAX = 992;
        let renderedExperience = "";
        let width = window.innerWidth;
        if (width <= PHONE_MAX) {
            renderedExperience = "Mobile";
        }
        else if (width <= TABLET_MAX) {
            renderedExperience = "Tablet";
        }
        else {
            renderedExperience = "Desktop";
        }

        return renderedExperience;
    }

    function getZipCode() {
        if (localStore.prefillReducer.postalCode) {
            return localStore.prefillReducer.postalCode;
        } else {
            return '';
        }
    }

    function getAuthenticated() {
        return localStore.prefillReducer.gpmsProfileId ? 'Authenticated':'Guest'
    }
    function getAppointmentType() {
      if (localStore.prefillReducer.dealerSummaryObject.dealershipSummary.deploymentMode === 'REQUEST_FORM'){
          return 'REQUEST_FORM';
      }
    return 'submit appointment';
    }

    function getDealerInfo(pageName) {
        const dealerInfo = {
            bac: '',
            dealerName: '',
            dealerID: '',
        };

        if (pageName === 'App Submitted') {
            dealerInfo.bac = localStore.prefillReducer.dealerSummaryObject.dealershipSummary.bac;
            dealerInfo.dealerName = localStore.prefillReducer.dealerSummaryObject.dealershipSummary.name;
            dealerInfo.dealerID = '';
        }

        return dealerInfo;
    }
    function getVehicleInfo() {
        const vehicleInfo = {};
        vehicleInfo.vehicleMake = localStore.prefillReducer.make
            ? localStore.prefillReducer.make
            : localStore.vehicleReducer.selectedMake;
        vehicleInfo.modelName = localStore.prefillReducer.model
            ? localStore.prefillReducer.model
            : localStore.vehicleReducer.selectedModel;
        vehicleInfo.modelYear = localStore.prefillReducer.year
            ? localStore.prefillReducer.year
            : localStore.vehicleReducer.selectedYear;
        vehicleInfo.vin = localStore.prefillReducer.vin ? localStore.prefillReducer.vin : '';
        vehicleInfo.bodyStyle = '';
        vehicleInfo.trimLevel = '';
        vehicleInfo.vehicleType = '';
        return vehicleInfo;
    }

    function getFormName(pageName) {
        if (pageName === 'Confirm') {
            return 'confirm your appointment';
        } else if (pageName === 'feedback') {
            return pageName;
        } else {
            return '';
        }
    }

    analyticsFactory.trackError = function (pageName, errorMessage) {
        trackPageAndEvent(pageName, errorMessage);
    };

    analyticsFactory.trackPage = function (pageName) {
        trackPageAndEvent(pageName, null);
    };

    analyticsFactory.trackAppointmentSubmissionPageAndEvent = function (pageName) {
        trackPageAndEvent(pageName);
    };

    function trackPageAndEvent(pageName, errorMessage) {
        const brand = getBrandOrSellingSource();
        analyticsFactory.currentPage = pageName;
        const completePageName = getPageName(pageName, brand);
        const formName = getFormName(pageName);

        const dealerInfo = getDealerInfo(pageName);
        const vehicleInfo = getVehicleInfo();

        if (analyticsFactory.loggingEnabled) {
            const seoPageName = getSeoPageName(pageName);
            const region = getRegion();

            window.digitalData = {
                pageInfo: {
                    pageName: completePageName,
                    url: window.location !== window.parent.location ? document.referrer : document.location.href,
                    seoStrategyPageName: seoPageName,
                    pageType: pageName,
                    languageSelected: language,
                    siteName: `${getSiteName()}_${country}`,
                    brand,
                    country,
                    region,
                    renderedExperience: getDevice(),
                    viewport: `${window.innerWidth}x${window.innerHeight}`,
                    orientation: window.innerWidth > window.innerHeight ? 'Landscape' : 'Portrait',
                    dealerBac: getBac(),
                    dynOrigin: localStore.prefillReducer.ultimateOrigin,
                },
                formInfo: {
                    formName,
                    errorMessage: errorMessage !== null ? errorMessage : '',
                    leadName: '',
                    appointmentType:getAppointmentType()
                },
                visitorProfile: {
                    zipCode: getZipCode(),
                    userProfileStateAuthentication: getAuthenticated(),
                },
                toolsInfo: {
                    dealerBac: dealerInfo.bac,
                    dealerName: dealerInfo.dealerName,
                    dealerID: dealerInfo.ID,
                },
                vehicleInfo: {
                    vehicleType: vehicleInfo.vehicleType,
                    vehicleMake: vehicleInfo.vehicleMake,
                    modelName: vehicleInfo.modelName,
                    modelYear: vehicleInfo.modelYear,
                    bodyStyle: vehicleInfo.bodyStyle,
                    trimLevel: vehicleInfo.trimLevel,
                    vin: vehicleInfo.vin,
                },
            };
        }
    }
    return analyticsFactory;
};

export default AnalyticsService;
