import {
    SET_COMMENTS,
    SET_IMAGE,
    SET_RECORD_LOCATOR,
    SET_IMAGE_SEARCH_BY_LICENSE_IMAGE1,
    SET_IMAGE_SEARCH_BY_LICENSE_IMAGE2,
} from './ActionTypes';

const initialState = {
    comments: '',
    vehicleImage: '',
    vehicleImage1: '',
    vehicleImage2: '',
    recordLocator: '',
};

const reviewReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_COMMENTS: {
            return { ...state, comments: action.payload };
        }
        case SET_IMAGE: {
            return { ...state, vehicleImage: action.payload };
        }
        case SET_IMAGE_SEARCH_BY_LICENSE_IMAGE1: {
            return { ...state, vehicleImage1: action.payload };
        }
        case SET_IMAGE_SEARCH_BY_LICENSE_IMAGE2: {
            return { ...state, vehicleImage2: action.payload };
        }
        case SET_RECORD_LOCATOR: {
            return { ...state, recordLocator: action.payload };
        }
        default:
            return state;
    }
};

export default reviewReducer;
