import React from 'react';
import { connect } from 'react-redux';

const flowConstants = require('../../shared/RoutingFlow/FlowComponentsConstants');
const constants = require('../../shared/Constants/SharedConstants');

class Stepper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    getStepContents() {
        // Determine flow source
        let stepperContents = [];
        const translations = this.props.vTranslations;
        switch (this.props.flowSource) {
            case flowConstants.AuthenticatedFlow: {
                if (this.props.modifyFlag !== true) {
                    stepperContents = [
                        [translations.SERVICES, 0, constants.TITLE_SERVICES],
                        [translations.DEALERSHIP, 1, constants.DEALERSHIP_LOC],
                        [translations.APPOINTMENT, 2, constants.APPOINTMENT],
                        [translations.REVIEW, 3, constants.REVIEW],
                    ];
                } else {
                    // Edit Appointment Stepper for Authenticated Flow
                    stepperContents = [
                        [translations.EDIT, 0, constants.APPOINTMENT],
                        [translations.REVIEW, 1, constants.REVIEW],
                    ];
                }
                break;
            }
            case flowConstants.AuthenticatedOCPlus: {
                if (this.props.modifyFlag !== true) {
                    stepperContents = [
                        [translations.VEHICLE, 0, constants.SELECT_VEHICLE],
                        [translations.SERVICES, 1, constants.TITLE_SERVICES],
                        [translations.DEALERSHIP, 2, constants.DEALERSHIP_LOC],
                        [translations.APPOINTMENT, 3, constants.APPOINTMENT],
                        [translations.REVIEW, 4, constants.REVIEW],
                    ];
                } else {
                    // Edit Appointment Stepper for Authenticated Flow
                    stepperContents = [
                        [translations.EDIT, 0, constants.APPOINTMENT],
                        [translations.REVIEW, 1, constants.REVIEW],
                    ];
                }
                break;
            }
            case flowConstants.BrandFlow: {
                stepperContents = [
                    [translations.DEALERSHIP, 0, constants.DEALERSHIP_LOC],
                    [translations.VEHICLE, 1, constants.SELECT_VEHICLE],
                    [translations.SERVICES, 2, constants.TITLE_SERVICES],
                    [translations.APPOINTMENT, 3, constants.APPOINTMENT],
                    [translations.REVIEW, 4, constants.REVIEW],
                ];
                break;
            }
            case flowConstants.DealerFlow:
            case flowConstants.DealerFlowUs: {
                stepperContents = [
                    [translations.VEHICLE, 0, constants.SELECT_VEHICLE],
                    [translations.SERVICES, 1, constants.TITLE_SERVICES],
                    [translations.APPOINTMENT, 2, constants.APPOINTMENT],
                    [translations.REVIEW, 3, constants.REVIEW],
                ];
                break;
            }
            case flowConstants.EditAppointmentFlow: {
                stepperContents = [
                    [translations.EDIT, 0, constants.APPOINTMENT],
                    [translations.REVIEW, 1, constants.REVIEW],
                ];
                break;
            }
            case flowConstants.OssLocatorFlow: {
                stepperContents = [
                    [translations.DEALERSHIP, 0, constants.DEALERSHIP_LOC],
                    [translations.VEHICLE, 1, constants.SELECT_VEHICLE],
                    [translations.SERVICES, 2, constants.TITLE_SERVICES],
                    [translations.APPOINTMENT, 3, constants.APPOINTMENT],
                    [translations.REVIEW, 4, constants.REVIEW],
                ];
                break;
            }
            case flowConstants.TireFinderFlow: {
                stepperContents = [
                    [translations.SERVICES, 0, constants.TITLE_SERVICES],
                    [translations.APPOINTMENT, 1, constants.APPOINTMENT],
                    [translations.REVIEW, 2, constants.REVIEW],
                ];
                break;
            }
            default: {
                stepperContents = [];
            }
        }
        return stepperContents;
    }

    renderStepperContents() {
        const stepperContents = this.getStepContents();
        const { headerNav } = this.props;
        const stepPast = 'step past';
        const stepCurrent = 'step current';
        const stepFuture = 'step future disabled';
        const connectorPast = 'border past';
        const connectorFuture = 'border future';
        let activeStep;
        let stepClassName;
        let circleContents;

        for (let i = 0; i < stepperContents.length; i++) {
            if (headerNav === stepperContents[i][2]) {
                activeStep = stepperContents[i][1];
            }
        }

        return stepperContents.map((step, index) => {
            if (index < activeStep) {
                stepClassName = stepPast;
                circleContents = <i className="icon checkmark" />;
            } else if (index === activeStep) {
                stepClassName = stepCurrent;
                circleContents = <div className="hide-on-mobile">{activeStep + 1}</div>;
            } else {
                stepClassName = stepFuture;
                circleContents = <div className="hide-on-mobile">{index + 1}</div>;
            }

            return (
                <div className="stepper-container" key={`step${index}`}>
                    <div className="stepper-contents">
                        {index < stepperContents.length ? (
                            // eslint-disable-next-line max-len
                            <div className={index <= activeStep ? connectorPast : connectorFuture} />
                        ) : null}
                        <div className={stepClassName}>{circleContents}</div>
                        {index < stepperContents.length ? (
                            // eslint-disable-next-line max-len
                            <div className={index < activeStep ? connectorPast : connectorFuture} />
                        ) : null}
                    </div>
                    <div className="stepper-page-title hide-on-mobile">{stepperContents[index][0]}</div>
                </div>
            );
        });
    }

    render() {
        return <div className="stepper">{this.renderStepperContents()}</div>;
    }
}

const mapStateToProps = (state) => ({
    flowSource: state.prefillReducer.flowSource,
    headerNav: state.headReducer.headerNavigation,
    modifyFlag: state.appointmentReducer.modifyFlag,
    vTranslations: state.countryOptionsReducer.translations,
});

export default connect(mapStateToProps)(Stepper);
