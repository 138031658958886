import axios from 'axios';
import dayjs from 'dayjs';
import { dealerSearchSetVersion, detectRuntimeEnvironment } from '../Utils/Utils';
import store from '../../Redux/Store';
import {
    extractAdvisorTransportOptions,
    parseAvailableResData,
    parseDatesResData,
    setShowBusinessName,
    ppdFirstAvail,
} from '../../components/Appointment/AppointmentHelpers';
import editAppointmentHelper from '../../Redux/EditAppointmentHelper';
import appointmentObject from '../Utils/POST/AppointmentObject';
import myRewardsObject from '../Utils/POST/MyRewardsObject';
import { processDealerships } from '../../components/Locator/getDealerships';
import {
    clearSelectedDealer,
    hideUseLocationButtonFalse,
    setAppGlobals,
    searchVehicleByLicensePlateResp,
    setAvailFlagFalse,
    setCountryOptions,
    setDealer,
    setDealerSummary,
    setDeleteAppointmentFlag,
    setLocale,
    setRenderFlagFalse,
    setRenderFlagTrue,
    setRewardsPoints,
    setSearchFlag,
    setShowCancelButtonsFlag,
    setSpinner,
    setVehicleImage,
    setVehicleImage_searchByLicensePlate1,
    setVehicleImage_searchByLicensePlate2,
    setVehicleMakes,
    setVehicleModels,
    setVehiclePlus,
    setVehicleYears,
    showListAndMapFalse,
    showLocationIconFalse,
    showSearchBoxErrorFalse,
    showSearchBoxErrorTrue,
    setNetworkErrorFlgServices,
    setEditFlagAnalytics,
    setLoadingFlag,
    setLoyaltyDetails,
    setFirstAvailDateEmpty,
    setShowTimesFlag,
    setTimeIndex,
    softEnroll,
    getLoyaltyError,
    enrollMyRewardsError,
    showEnrollSpinner,
    setMyRewardsPointsIndicator,
    setGarageData,
    setGarageError,
    setGarageLoading,
    setProfileId,
    setSessionActive,
    checkSession,
    setCustomerFirstName,
    setCustomerLastName,
    setCustomerEmail,
    setCustomerPhoneNumber,
    setMyGarageError,
    setMyAccountLogin,
    setVendorErrorMessage,
    setOnTracError,
    setEvCertified,
    setCancelError,
    setCity,
    setPostalCode,
    setRegionCode,
    setEmail,
    setFirstName,
    setLastName,
    setStreet,
    setSession,
    setVinEligibility,
    setNoTimeSlotAvailableError,
    setOptionCallLoading,
    setVanError,
    setOnTracErrorOnSubmission, setNewServiceList,
} from '../../Redux/ActionCreator';
import { notApplicable } from '../Constants/SharedConstants';
import AnalyticsService from '../Utils/analytics.service';
import {batch} from "react-redux";
import {GET_DEALER_CONFIG_QUERY, GET_SERVICE_WITHOUT_VIN_QUERY, SERVICE_QUERY_WITH_VIN} from "../GraphQL/ServiceQuery";

// Use the Shared Data Objects Constants
const constants = require('./BackendCallsConstants');
const flowComponentConstatnts = require('../RoutingFlow/FlowComponentsConstants');
const localizationConstants = require('../Constants/LocalizationConstants');
const appointmentConstants = require('../../components/Appointment/AppointmentConstants');

// Object for destructuring imported constants
const {
    dealerSearchName,
    formatJSON,
    initialBAC,
    dealerGeoSearch,
    latitude,
    longitude,
    locale,
    count,
    loyalty,
    member,
    noMembersFound,
    enroll,
    iamLogin,
    gpmsProfile,
    cookieConst,
} = constants;

const { OssLocatorFlow, BrandFlow, AuthenticatedFlow, AuthenticatedOCPlus, DealerFlow } = flowComponentConstatnts;

let analyticsElement = document.querySelector("meta[name='analytics-id']");
let analyticsId = analyticsElement ? analyticsElement.getAttribute('content') : '';
if (analyticsId) {
    axios.defaults.headers.common['X-Correlation-Id'] = analyticsId;
}

export function getCountryOptions(country, pLocale, flow) {
    // Detect if we are running on local dev Machine via param from NodeJS Express Server
    let url = detectRuntimeEnvironment() + constants.v1Services + country + constants.countryOptions;

    // Do we need to add the locale to parameters
    if (pLocale) {
        url = `${url}?${constants.locale}${pLocale}`;
    }

    axios.get(url).then((res) => {
        if (Object.keys(res.data).length > 0) {
            store.dispatch(setCountryOptions(res.data, flow));
            // Setup the locale in Prefill
            if (pLocale !== localizationConstants.frCA) {
                store.dispatch(setLocale(res.data.defaultLocale.tag));
            }

            store.dispatch(setMyAccountLogin(res.data.countryOptions));
        }
    });
}

export function getSummaryBAC(country, bac) {
    const localStore = store.getState();
    const vLocale = localStore.prefillReducer.locale;

    // Detect if we are running on local dev Machine via param from NodeJS Express Server
    const url = `${
        detectRuntimeEnvironment() + constants.v1Services + country + constants.dealership + bac + constants.summary
    }?${locale}${vLocale}`;

    axios.get(url).then((res) => {
        if (Object.keys(res.data).length > 0) {
            store.dispatch(setDealerSummary(res.data));
        }

        // This block of code sets the selected dealer for the DealerFlow and BrandFlow, this is because these flows skip over
        // the DL page initially. Only execute this block of code if the selected dealer is initial
        if (!{}.hasOwnProperty.call(localStore.locReducer.selectedDealer, 'bac')) {
            if (
                localStore.prefillReducer.flowSource === DealerFlow ||
                localStore.prefillReducer.flowSource === BrandFlow
            ) {
                const { dealershipSummary } = res.data;
                const dealer = {
                    address: {},
                    amenities: [],
                    bac: '',
                    departments: [],
                    latitude: '',
                    longitude: '',
                    name: '',
                    url: '',
                };

                dealer.address = dealershipSummary.address;
                dealer.amenities = dealershipSummary.amenities;
                dealer.bac = dealershipSummary.bac;
                dealer.departments = dealershipSummary.departments;
                dealer.latitude = dealershipSummary.locator.latitude;
                dealer.longitude = dealershipSummary.locator.longitude;
                dealer.name = dealershipSummary.name;
                dealer.url = dealershipSummary.url;

                store.dispatch(setDealer(dealer));
            }
        }
    });
}

export function editAppointmentAxios(AppointmentRecordLocator) {
    const localStore = store.getState();
    store.dispatch(setEditFlagAnalytics('CANCEL'));

    if (localStore.reviewReducer.recordLocator) {
        AppointmentRecordLocator = localStore.reviewReducer.recordLocator;
    } else {
        AppointmentRecordLocator = localStore.prefillReducer.editAppointmentRecordLocator;
    }

    const url = `${process.env.REACT_APP_APPOINTMENTS_BASE_URL}${constants.api}/${constants.appointments}/${localStore.prefillReducer.bac}/${AppointmentRecordLocator}`;

    axios.get(url).then((res) => {
        if (Object.keys(res.data).length > 0) {
            editAppointmentHelper(res.data);
        }
    });
}

export function getVehicleYears(country) {
    const url = detectRuntimeEnvironment() + constants.v1Services + country + constants.vehicleYears;

    axios.get(url).then((res) => {
        if (Object.keys(res.data).length > 0) {
            store.dispatch(setVehicleYears(res.data));
        }
    });
}

export function getVehicleMakes(country, year) {
    const url = `${detectRuntimeEnvironment() + constants.v1Services + country + constants.vehicleYears}/${year}${
        constants.makes
    }`;

    axios.get(url).then((res) => {
        if (Object.keys(res.data).length > 0) {
            store.dispatch(setVehicleMakes(res.data));
        }
    });
}

export function getVehicleModels(country, year, make) {
    const url = `${detectRuntimeEnvironment() + constants.v1Services + country + constants.vehicleYears}/${year}${
        constants.makes
    }/${make}${constants.models}`;

    axios.get(url).then((res) => {
        if (Object.keys(res.data).length > 0) {
            store.dispatch(setVehicleModels(res.data.model));
        }
    });
}

export function getVehiclePlus(country, year, make, model) {
    const url = `${detectRuntimeEnvironment() + constants.v1Services + country + constants.vehicleYears}/${year}${
        constants.makes
    }/${make}${constants.models}/${model}${constants.plus}`;

    axios.get(url).then((res) => {
        if (Object.keys(res.data).length > 0) {
            store.dispatch(setVehiclePlus(res.data.plus));
        }
    });
}

export function dealersNameSearch(country, lang, name, postalCode, cityName, bac) {
    let localStore = store.getState();
    let urlFlag;
    const vLocale = localStore.prefillReducer.locale;

    // For Quantum and Regular Brand flow, on a new search clear out the Selected Dealer in Redux
    if (
        localStore.prefillReducer.flowSource === BrandFlow || // Quantum Flow
        (localStore.prefillReducer.flowSource === OssLocatorFlow && localStore.prefillReducer.branchDealer === false)
    ) {
        // Regular Brand Flow
        store.dispatch(clearSelectedDealer());
        localStore = store.getState();
    }

    if (
        localStore.prefillReducer.flowSource === AuthenticatedFlow ||
        localStore.prefillReducer.flowSource === AuthenticatedOCPlus
    ) {
        // AUTHENTICATED FLOW
        urlFlag = true;
    } else if (
        (localStore.prefillReducer.flowSource === OssLocatorFlow && localStore.prefillReducer.branchDealer === false) ||
        localStore.prefillReducer.flowSource === BrandFlow
    ) {
        // REGULAR BRAND FLOW || QUANTUM FLOW
        urlFlag = false;
        store.dispatch(setAvailFlagFalse());
    }

    const url = urlFlag
        ? `${
              detectRuntimeEnvironment() +
              dealerSearchSetVersion(localStore.countryOptionsReducer.countryOptions.DEALER_LOCATOR_VERSION) +
              country +
              dealerSearchName +
              name
          }?${locale}${vLocale}&${initialBAC}${bac}${formatJSON}`
        : `${
              detectRuntimeEnvironment() +
              dealerSearchSetVersion(localStore.countryOptionsReducer.countryOptions.DEALER_LOCATOR_VERSION) +
              country +
              dealerSearchName +
              name
          }?${locale}${vLocale}${formatJSON}`;

    store.dispatch(setRenderFlagFalse());
    store.dispatch(setSearchFlag());
    // Process With No Appointment Object
    if (
        (localStore.prefillReducer.flowSource === OssLocatorFlow && localStore.prefillReducer.branchDealer === false) ||
        localStore.locReducer.disableAppointmentObj
    ) {
        axios.post(url, {}).then((res) => {
            if (res.data.dealerships.length > 0) {
                // Good Search -> Process normally for non-authenticated name search
                store.dispatch(showSearchBoxErrorFalse());
                processDealerships(res.data, true);
            } else {
                // Bad Search -> Handle bad search depending on type of error
                if (localStore.locReducer.dealerships.length > 0) {
                    // Bad search after dealerships loaded on page -> Show previous results and error in search bar
                    store.dispatch(setRenderFlagTrue());
                    store.dispatch(showSearchBoxErrorTrue());
                    store.dispatch(showSearchBoxErrorTrue());
                } else if (localStore.locReducer.dealerships.length === 0) {
                    // Bad initial search -> Reset DOM and show error in search bar
                    store.dispatch(setRenderFlagFalse());
                    store.dispatch(showLocationIconFalse());
                    store.dispatch(hideUseLocationButtonFalse());
                    store.dispatch(showListAndMapFalse());
                    store.dispatch(showSearchBoxErrorTrue());
                }
            }
        });
    } else {
        // Process With an Appointment Object for authenticated name search
        const apptObject = appointmentObject();
        axios.post(url, apptObject).then((res) => {
            // Good Search -> Process normally
            if (res.data.dealerships.length > 0) {
                if (
                    res.data.dealerships.length === 1 &&
                    (localStore.prefillReducer.flowSource === flowComponentConstatnts.AuthenticatedFlow ||
                        localStore.prefillReducer.flowSource === flowComponentConstatnts.AuthenticatedOCPlus)
                ) {
                    // Bad search after dealerships loaded on page -> Show previous results and error in search bar
                    store.dispatch(setRenderFlagTrue());
                    store.dispatch(showSearchBoxErrorTrue());
                    store.dispatch(showSearchBoxErrorTrue());
                } else {
                    // Good Search -> Process normally for non-authenticated name search
                    store.dispatch(showSearchBoxErrorFalse());
                    processDealerships(res.data, true);
                }
            } else {
                if (localStore.locReducer.dealerships.length > 0) {
                    // Bad search after dealerships loaded on page -> Show previous results and error in search bar
                    store.dispatch(setRenderFlagTrue());
                    store.dispatch(showSearchBoxErrorTrue());
                    store.dispatch(showSearchBoxErrorTrue());
                } else if (localStore.locReducer.dealerships.length === 0) {
                    // Bad initial search -> Reset DOM and show error in search bar
                    store.dispatch(setRenderFlagFalse());
                    store.dispatch(showLocationIconFalse());
                    store.dispatch(hideUseLocationButtonFalse());
                    store.dispatch(showListAndMapFalse());
                    store.dispatch(showSearchBoxErrorTrue());
                }
                store.dispatch(setSearchFlag());
            }
        });
    }
}

export function getDealersByRegion() {
    const localStore = store.getState();
    const vLocale = localStore.prefillReducer.locale;
    const { bac } = localStore.prefillReducer.dealerSummaryObject.dealershipSummary;
    const lat = localStore.prefillReducer.dealerSummaryObject.dealershipSummary.locator.latitude;
    const lng = localStore.prefillReducer.dealerSummaryObject.dealershipSummary.locator.longitude;
    const { country } = localStore.prefillReducer.dealerSummaryObject.dealershipSummary.address;

    const url =
        `${
            detectRuntimeEnvironment() +
            dealerSearchSetVersion(localStore.countryOptionsReducer.countryOptions.DEALER_LOCATOR_VERSION) +
            country +
            dealerGeoSearch +
            latitude +
            lat +
            longitude +
            lng
        }&${count}26` + `&${locale}${vLocale}&${initialBAC}${bac}`;
    regionCall(url, localStore.locReducer.disableAppointmentObj);
}

function regionCall(url, disableAppointmentObj) {
    if (disableAppointmentObj) {
        axios.post(url, {}).then((res) => {
            if (res.data) {
                processDealerships(res.data, false);
            }
        });
    } else {
        const apptObject = appointmentObject();
        axios.post(url, apptObject).then((res) => {
            if (res.data) {
                processDealerships(res.data, false);
            }
        });
    }
}

export function getDealersByCords(places, position, country, bac) {
    let localStore = store.getState();
    let lat;
    let lng;
    let urlFlag;
    const vLocale = localStore.prefillReducer.locale;

    // For Quantum and Regular Brand flow, on a new search clear out the Selected Dealer in Redux
    if (
        localStore.prefillReducer.flowSource === BrandFlow || // Quantum Flow
        (localStore.prefillReducer.flowSource === OssLocatorFlow && localStore.prefillReducer.branchDealer === false)
    ) {
        // Regular Brand Flow
        store.dispatch(clearSelectedDealer());
        localStore = store.getState();
    }

    if (position === false) {
        lat = places.geometry.location.lat();
        lng = places.geometry.location.lng();
    } else if (places === false) {
        lat = position.coords.latitude;
        lng = position.coords.longitude;
    }

    if (
        localStore.prefillReducer.flowSource === AuthenticatedFlow ||
        localStore.prefillReducer.flowSource === AuthenticatedOCPlus
    ) {
        // AUTHENTICATED FLOW
        urlFlag = true;
    } else if (
        (localStore.prefillReducer.flowSource === OssLocatorFlow && localStore.prefillReducer.branchDealer === false) ||
        localStore.prefillReducer.flowSource === BrandFlow
    ) {
        // REGULAR BRAND FLOW || QUANTUM FLOW
        urlFlag = false;
        store.dispatch(setAvailFlagFalse());
    }

    const url = urlFlag
        ? `${
              detectRuntimeEnvironment() +
              dealerSearchSetVersion(localStore.countryOptionsReducer.countryOptions.DEALER_LOCATOR_VERSION) +
              country +
              dealerGeoSearch +
              latitude +
              lat +
              longitude +
              lng
          }&${count}26` + `&${locale}${vLocale}&${initialBAC}${bac}`
        : `${
              detectRuntimeEnvironment() +
              dealerSearchSetVersion(localStore.countryOptionsReducer.countryOptions.DEALER_LOCATOR_VERSION) +
              country +
              dealerGeoSearch +
              latitude +
              lat +
              longitude +
              lng
          }&${locale}${vLocale}&${count}26`;

    // Process Regular Brand Flow With No Appointment Object
    if (
        (localStore.prefillReducer.flowSource === OssLocatorFlow && localStore.prefillReducer.branchDealer === false) ||
        localStore.locReducer.disableAppointmentObj
    ) {
        axios.post(url, {}).then((res) => {
            if (res.data) {
                processDealerships(res.data, false);
            }
        });
    } else {
        const apptObject = appointmentObject();
        axios.post(url, apptObject).then((res) => {
            if (res.data) {
                processDealerships(res.data, false);
            }
        });
    }
}

export function getAppointments(startDate) {
    store.dispatch(setSpinner(true));

    const apptObject = appointmentObject();
    const customParseFormat = require('dayjs/plugin/customParseFormat');
    const localStore = store.getState();

    dayjs.extend(customParseFormat);
    const date = dayjs(startDate).format('YYYY-MM-DD');
    const url =
        `${
            detectRuntimeEnvironment() +
            constants.v1Services +
            localStore.prefillReducer.country +
            constants.dealership +
            localStore.prefillReducer.dealerSummaryObject.dealershipSummary.bac
        }/available` +
        `?startDate=${date}&days=${localStore.appointmentReducer.numDaysToDisplay}${
            localStore.prefillReducer.editAppointmentRecordLocator
                ? `&recLoc=${localStore.prefillReducer.editAppointmentRecordLocator}`
                : ''
        }`;

    axios
        .post(url, apptObject)
        .then(
            (res) => {
                parseDatesResData(res.data);
                store.dispatch(setSpinner(false));
                res.data.vendorErrorMessage && apptObject.transportationOption === appointmentConstants.LOANER_CAR
                    ? store.dispatch(setFirstAvailDateEmpty(true))
                    : null;
            },
            (error) => {
                store.dispatch(setAvailabilityErrorFlag('true'));
                store.dispatch(setDate('null'));
            }
        )
        .catch((error) => {
            console.log('error', error);
            store.dispatch(setAvailabilityErrorFlag('true'));
            store.dispatch(setDate('null'));
        });
}

export function getAdvisorsTransportOptions() {
    store.dispatch(setOptionCallLoading('true'))
    const apptObject = appointmentObject();
    const localStore = store.getState();
    const url = `${
        detectRuntimeEnvironment() +
        constants.v4Services +
        localStore.prefillReducer.country +
        constants.dealerships +
        localStore.prefillReducer.dealerSummaryObject.dealershipSummary.bac
    }/schedule/options`;

    axios
        .post(url, apptObject)
        .then((res) => {
            extractAdvisorTransportOptions(res.data);
        })
        .catch((error) => {
            console.log('error', error);
        }).finally(()=>{
            store.dispatch(setOptionCallLoading('false'))
             }
            );
}

export function getNextAvailable(bac) {
    const apptObject = appointmentObject();
    const localStore = store.getState();
    const url = `${
        detectRuntimeEnvironment() +
        constants.v1Services +
        localStore.prefillReducer.country +
        constants.dealership +
        bac
    }/available/next`;
    axios
        .post(url, apptObject)
        .then(
            (res) => {
                parseAvailableResData(res.data,true);
            },
            (error) => {
                store.dispatch(setAvailabilityErrorFlag('true'));
            }
        )
        .catch((error) => {
            console.log('error', error);
            store.dispatch(setAvailabilityErrorFlag('true'));
        });
}

export const getNextAvailableDateAndTime  = (bac)=>{
    const apptObject = appointmentObject();
    const localStore = store.getState();
    const url = `${
        detectRuntimeEnvironment() +
        constants.v1Services +
        localStore.prefillReducer.country +
        constants.dealership +
        bac
    }/available/next`;
    return axios.post(url,apptObject);
}

export const getPPDLAvailability = (startDate) => {
    const apptObject = appointmentObject();
    const localStore = store.getState();
    const date = startDate !== null ? dayjs(startDate).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD');
    const url =
        `${
            detectRuntimeEnvironment() +
            constants.v1Services +
            localStore.prefillReducer.country +
            constants.dealership +
            localStore.prefillReducer.dealerSummaryObject.dealershipSummary.bac
        }/available` +
        `?availabilityGuid=${localStore.appointmentReducer.availabilityGuid}&startDate=${date}&days=${localStore.appointmentReducer.numDaysToDisplay}${
            localStore.prefillReducer.editAppointmentRecordLocator
                ? `&recLoc=${localStore.prefillReducer.editAppointmentRecordLocator}`
                : ''
        }`;
    return axios.post(url, apptObject);
}

export function getPPDAvailability(bac, closeConciergeForm, startDate, ppd) {
    const apptObject = appointmentObject();
    const localStore = store.getState();
    const date = startDate !== null ? dayjs(startDate).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD');
    const url =
        `${
            detectRuntimeEnvironment() +
            constants.v1Services +
            localStore.prefillReducer.country +
            constants.dealership +
            localStore.prefillReducer.dealerSummaryObject.dealershipSummary.bac
        }/available` +
        `?availabilityGuid=${localStore.appointmentReducer.availabilityGuid}&startDate=${date}&days=${localStore.appointmentReducer.numDaysToDisplay}${
            localStore.prefillReducer.editAppointmentRecordLocator
                ? `&recLoc=${localStore.prefillReducer.editAppointmentRecordLocator}`
                : ''
        }`;
    store.dispatch(setLoadingFlag('true'));
    store.dispatch(setOnTracError('false'));
    axios.post(url, apptObject).then(
        (res) => {
            if (res.data.vendorErrorMessage === null) {
                if (ppd === 'ppdFirstAvail') {
                    store.dispatch(setFirstAvailDateEmpty(true));
                    ppdFirstAvail(res.data);
                } else {
                    parseDatesResData(res.data);
                }
               closeConciergeForm ? closeConciergeForm() : null;
            } else {
               // parseDatesResData(res.data);
                if (res.data.vendorErrorMessage.onTracError) {
                    store.dispatch(setOnTracError('true'));
                    store.dispatch(setVendorErrorMessage(res.data.vendorErrorMessage.errorMessage));
                }
                else {
                    parseDatesResData(res.data);

                }
            }
        },
        (error) => {
            console.log(error);
        }
    ).finally(()=>{
         store.dispatch(setLoadingFlag('false'));
        });
}

export function setModifyEmpty() {
    const apptObject = appointmentObject();
    const localStore = store.getState();
    store.dispatch(setEditFlagAnalytics('EDIT'));
    let AppointmentRecordLocator;

    if (localStore.reviewReducer.recordLocator) {
        AppointmentRecordLocator = localStore.reviewReducer.recordLocator;
    } else {
        AppointmentRecordLocator = localStore.prefillReducer.editAppointmentRecordLocator;
    }

    const url = `${process.env.REACT_APP_APPOINTMENTS_BASE_URL}${constants.api}/${constants.appointments}/${localStore.prefillReducer.dealerSummaryObject.dealershipSummary.bac}/${AppointmentRecordLocator}/time`;

    axios.post(url, apptObject).then(
        (response) => {},
        (error) => {}
    );
}

export async function submitAppointment() {
    store.dispatch(setSpinner(true));

    const localStore = store.getState();
    const apptObject = appointmentObject();


    if (!localStore.prefillReducer.vin) {
        delete apptObject.vehicle.vin;
    }

    let headers = {};
    if (localStore.sessionReducer.session) {
        headers['X-OSS-SESSION'] = localStore.sessionReducer.session;
    }

    const url = `${process.env.REACT_APP_APPOINTMENTS_BASE_URL}${constants.api}/${constants.appointments}/${localStore.prefillReducer.dealerSummaryObject.dealershipSummary.bac}?availabilityGuid=${localStore.appointmentReducer.availabilityGuid}`;

    try {
        const response = await axios.post(url, apptObject, { headers: headers,validateStatus:()=> true });
        if(response.status === 200 && response.data){

            store.dispatch(setRecordLocator(response.data.recordLocator));
            store.dispatch(setSpinner(false));
            store.dispatch(setEditFlagAnalytics('SUBMIT'));
            if (localStore.appointmentReducer.transportation.code === appointmentConstants.PREMIUM_CONCIERGE) {
                store.dispatch(setFirstAvailDateEmpty(true));
            }
        }
        else if(response.status === 500 && response?.data.message.includes('Unexpected error occurred while attempting to communicate with Ontrac')){
            console.error("On Trac Error Occurred while trying to submit an Appointment", response.data);
            batch(()=>{
                store.dispatch(setOnTracErrorOnSubmission({
                    showOnTracError:true,
                    onTracTries: "second"
                }));
                store.dispatch(setNetworkErrorFlg(true));
            })
        }
        else if( response.status === 422 &&  response?.data.message.includes('no porter available at')){
            const onTracTries = localStore.appointmentReducer.onTracTries;
            const showOnTracError = true;
            const payload = {
                showOnTracError,
                onTracTries: onTracTries === "first" ? "second" : "first",
            };

            store.dispatch(setOnTracErrorOnSubmission(payload));

            if (onTracTries === "first") {
                console.error("On Trac Error Occurred while trying to submit an Appointment", response.data);
                store.dispatch(setNetworkErrorFlg(true));
            }
        }
        else if (response.data.message?.includes("Unable to assign advisor to appointment: Date/time not available.")){
            console.error("No Time Slot Error",response.data)
            store.dispatch(setNoTimeSlotAvailableError('true'));
        }
        else if (response.data.message?.includes("Unable to assign van to appointment:")){
            console.error("Unable to assign van error: ",response.data)
            store.dispatch(setVanError('true'));
        }
        else {
            console.error('Error Occur while trying to submit an Appointment',response);
            store.dispatch(setNetworkErrorFlg('true'));
        }

    }
    catch (error){
        console.error('Error Occur while trying to submit an Appointment',error);
        store.dispatch(setNetworkErrorFlg('true'));
    }
}

export function submitFeedback(rating, reason) {
    const localStore = store.getState();
    const feedback = {
        rating: rating,
        reasons: reason !== '' ? [reason] : null,
    };
    const url = `${process.env.REACT_APP_APPOINTMENTS_BASE_URL}${constants.api}/${constants.appointments}/${localStore.prefillReducer.dealerSummaryObject.dealershipSummary.bac}/${localStore.reviewReducer.recordLocator}${constants.feedback}`;

    axios.post(url, feedback).then((error) => {});
}

export function vehicleRenderer() {
    const localStore = store.getState();

    // API PROD URL
    const url =
        detectRuntimeEnvironment() +
        constants.v4Services +
        localStore.prefillReducer.country +
        constants.vehicleImage +
        localStore.prefillReducer.vin;

    axios.get(url).then((res) => {
        if (Object.keys(res.data).length > 0) {
            store.dispatch(setVehicleImage(res.data));
        }
    });
}

/*
 * Objective: Call this function and an image is returned based on the vin provided (Search by License Plate flow)
 *
 */
export function vehicleRenderer_searchByLicensePlateImage1(vin) {
    const localStore = store.getState();

    const url =
        detectRuntimeEnvironment() +
        constants.v4Services +
        localStore.prefillReducer.country +
        constants.vehicleImage +
        vin;

    axios.get(url).then((res) => {
        if (Object.keys(res.data).length > 0) {
            store.dispatch(setVehicleImage_searchByLicensePlate1(res.data));
        }
    });
}

export function vehicleRenderer_searchByLicensePlateImage2(vin) {
    const localStore = store.getState();

    const url =
        detectRuntimeEnvironment() +
        constants.v4Services +
        localStore.prefillReducer.country +
        constants.vehicleImage +
        vin;

    axios.get(url).then((res) => {
        if (Object.keys(res.data).length > 0) {
            store.dispatch(setVehicleImage_searchByLicensePlate2(res.data));
        }
    });
}

export function handleDelete() {
    AnalyticsService().trackAppointmentSubmissionPageAndEvent('app cancelled');
    const localStore = store.getState();
    let AppointmentRecordLocator = '';

    store.dispatch(setSpinner(true));
    // Check if user is trying to cancel again, hide message while cancel is processing
    if (localStore.confirmationReducer.cancelError) store.dispatch(setCancelError(false));

    if (localStore.reviewReducer.recordLocator) {
        AppointmentRecordLocator = localStore.reviewReducer.recordLocator;
    } else {
        AppointmentRecordLocator = localStore.prefillReducer.editAppointmentRecordLocator;
    }

    const deleteUrl = `${process.env.REACT_APP_APPOINTMENTS_BASE_URL}${constants.api}/${constants.appointments}/${localStore.prefillReducer.dealerSummaryObject.dealershipSummary.bac}/${AppointmentRecordLocator}/cancel`;

    axios.get(deleteUrl).then(
        (res) => {
            store.dispatch(setSpinner(false));
            if (res.status === 200) {
                store.dispatch(setDeleteAppointmentFlag(true));
                store.dispatch(setShowCancelButtonsFlag(false));
            } else {
                store.dispatch(setCancelError(true));
            }
        },
        (error) => {
            store.dispatch(setSpinner(false));
            store.dispatch(setCancelError(true));
        }
    );
}

function setRecordLocator(record) {
    return {
        type: 'SET_RECORD_LOCATOR',
        payload: record,
    };
}

function setNetworkErrorFlg(flag) {
    return {
        type: 'SET_NETWORK_ERROR_FLG',
        payload: flag,
    };
}

export function setAvailabilityErrorFlag(showError) {
    return {
        type: 'SET_AVAIL_ERROR_FLG',
        payload: showError,
    };
}

function setDate(date) {
    return {
        type: 'SET_APPT_DATE',
        payload: date,
    };
}

export function getRewardsPoints(gcin, bac) {
    const url = `${detectRuntimeEnvironment()}/oss/oss/loyalty/points?gcin=${gcin}&bac=${bac}`;
    axios.get(url).then((res) => {
        if (res.data > 0) {
            store.dispatch(setRewardsPoints(res.data));
        }
    });
}

export function getCalendarInvite(country, bac, reclocator) {
    const url = `${
        detectRuntimeEnvironment() +
        constants.v4Services +
        country +
        constants.dealerships +
        bac +
        constants.appointment +
        reclocator
    }/calendar`;
    axios.get(url).then((res) => {
        const icsMSG = res.data;

        const blob = new Blob([icsMSG], { type: 'text/calendar;charset=utf-8' });
        const filename = 'OSSServiceAppt.ics';

        if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, filename);
        } else {
            const elem = window.document.createElement('a');
            elem.href = window.URL.createObjectURL(blob);
            elem.download = filename;
            document.body.appendChild(elem);
            elem.click();
            document.body.removeChild(elem);
        }
    });
}

export function getAppGlobals(country, vin, bac, year, make, model, locale, previousBac, plus, mileage, units, record) {
    const localStore = store.getState();
    store.dispatch(setSpinner(true));

    let plateParam = '';
    if (
        localStore.vehicleReducer.searchByLicensePlateView &&
        localStore.vehicleReducer.searchByLicensePlateRespReceived
    ) {
        plateParam = constants.plate;
    }

    vin = localStore.vehicleReducer.searchByLicensePlate_selectedVin
        ? localStore.vehicleReducer.searchByLicensePlate_selectedVin
        : localStore.prefillReducer.flowSource === 'Authenticated' ||
          localStore.prefillReducer.flowSource === 'DealerUS' ||
          localStore.prefillReducer.flowSource == 'AuthenticatedOCPlus'
        ? localStore.prefillReducer.vin
        : '';

    const precheck_dealerConfig = localStore.countryOptionsReducer.countryOptions.PRECHECK_COMMON_SERVICE_UNAUTH;
    const precheck_maintenanceData = localStore.countryOptionsReducer.countryOptions.PRECHECK_MAINTENANCE_DATA;
    const use_ev_certified = localStore.countryOptionsReducer.countryOptions.EV_DEALER_LOCATOR;

    if (
        use_ev_certified === 'true' &&
        localStore.prefillReducer.flowSource !== 'Authenticated' &&
        year &&
        make &&
        model
    ) {
        getEVCertified(bac, year, make, model, locale);
    }

    const url = `${
        detectRuntimeEnvironment() +
        constants.v1Services +
        country +
        constants.dealership +
        bac +
        (localStore.reviewReducer.recordLocator || localStore.prefillReducer.editAppointmentRecordLocator
            ? constants.selectedServices
            : constants.services) +
        constants.vin +
        (vin || '') +
        constants.year +
        year +
        constants.make +
        make +
        constants.model +
        model +
        constants.pluss +
        encodeURIComponent(plus) +
        constants.mileage +
        (mileage || '0') +
        plateParam +
        constants.units +
        units
    }&${constants.locale}${locale}${constants.format}${constants.json}${constants.record}${record}`;

    let headers = {};
    if (localStore.sessionReducer.session) {
        headers['X-OSS-SESSION'] = localStore.sessionReducer.session;
    }

    axios
        .get(url, {headers: headers})
        .then(
            (res) => {
                if (Object.keys(res.data).length > 0) {
                    store.dispatch(
                        setAppGlobals({
                            data: res.data,
                            bac,
                            country,
                            previousBac,
                            mileage,
                            record,
                            precheck_dealerConfig,
                            precheck_maintenanceData,
                        })
                    );
                }
                store.dispatch(setSpinner(false));
            },
            (error) => {
                store.dispatch(setNetworkErrorFlgServices(true));
            }
        )
        .catch((error) => {
            console.log('error', error);
            store.dispatch(setNetworkErrorFlgServices(true));
        });
}
export function searchByLicensePlateViewApiCall(state, licensePlate) {
    const state_trimmed = state.substring(0, 2); // Trim state

    const url = `${
        detectRuntimeEnvironment() + constants.v1Services + constants.plateToVin + state_trimmed
    }/${licensePlate}`;

    const myErrorData = [
        {
            vehicleSeqNo: 1,
            vin: 'Error',
            stateOfRegistration: '',
            modelYear: '',
            makeCode: '',
            modelDesc: '',
            plateTypeCode: '',
            inputLicensePlate: '',
            vinPattern: '',
        },
    ];

    // Real API call
    axios
        .get(url)
        .then(
            (res) => {
                if (res.data.length > 0) {
                    store.dispatch(searchVehicleByLicensePlateResp({ data: res.data }));

                    // let's get the vehicle images now
                    if (res.data.length === 1) {
                        vehicleRenderer_searchByLicensePlateImage1(res.data[0].vin);
                    }
                    if (res.data.length === 2) {
                        vehicleRenderer_searchByLicensePlateImage1(res.data[0].vin);
                        vehicleRenderer_searchByLicensePlateImage2(res.data[1].vin);
                    }
                } else {
                    store.dispatch(searchVehicleByLicensePlateResp({ data: myErrorData }));
                }
            },
            (error) => {
                store.dispatch(searchVehicleByLicensePlateResp({ data: myErrorData }));
            }
        )
        .catch((error) => {
            console.log('error', error);
        });
}

/**
 * Finds if business name field should be shown on appt pg
 * @param {*} bac
 */
export function getShowBusinessNameField(bac) {
    const url =
        detectRuntimeEnvironment() + constants.oss + constants.dealership + bac + constants.showBusinessNameField;

    axios
        .get(url)
        .then((res) => {
            setShowBusinessName(res.data);
        })
        .catch((error) => {
            console.log('error', error);
        });
}

/**
 * Gets My Rewards member details - takes bac and gpmsProfileId and returns my rewards details for dealer and user
 * @param {*} bac
 * @param {*} gpmsProfileId
 */
export function getMyRewardsDetails(bac, gpmsProfileId) {
    const url = process.env.REACT_APP_LOYALTY_API_BASE_URL + loyalty + member;
    const localStore = store.getState();
    const loyaltyObject = { customerIdentifier: gpmsProfileId, bac: bac };

    axios
        .post(url, loyaltyObject)
        .then((res) => {
            if (localStore.prefillReducer.flowSource === AuthenticatedFlow || loyaltyObject.customerIdentifier) {
                store.dispatch(setLoyaltyDetails(res.data));
            }
        })
        .catch((error) => {
            if (error.response) {
                const { status, data } = error.response;
                if (status === 404 && data.message?.includes(noMembersFound)) {
                    // prompt user to enroll
                    store.dispatch(softEnroll(true));
                    store.dispatch(setMyRewardsPointsIndicator(notApplicable));
                } else {
                    // hide loyalty
                    store.dispatch(getLoyaltyError(true));
                }
            } else {
                // hide loyalty
                store.dispatch(getLoyaltyError(true));
            }
        });
}

/**
 * Enrolls users into the MyRewards program
 */
export function enrollMyRewards() {
    const url = process.env.REACT_APP_LOYALTY_API_BASE_URL + loyalty + enroll;
    const rewardsObject = myRewardsObject();

    store.dispatch(showEnrollSpinner(true));

    axios
        .post(url, rewardsObject)
        .then((res) => {
            store.dispatch(showEnrollSpinner(false));
            store.dispatch(enrollMyRewardsError(false));
        })
        .catch((error) => {
            store.dispatch(showEnrollSpinner(false));
            store.dispatch(enrollMyRewardsError(true));
        });
}

/**
 * Handles user login with IAM - takes user name and password encoded in base64 and returns token
 * @param {*} username
 * @param {*} password
 * @param {*} locale
 */
export function loginIAM(username, password, locale) {
    const url = process.env.REACT_APP_IAM_BASE_URL + iamLogin;
    const headers = {
        Authorization: `Basic ${btoa(username + ':' + password)}`,
        Username: username,
        Locale: locale,
    };

    store.dispatch(setGarageLoading(true));

    axios
        .post(url, {}, { headers })
        .then((res) => {
            store.dispatch(setSession(res.data.id));
            loginGPMS(res.data.id);
        })
        .catch((error) => {
            store.dispatch(setGarageLoading(false));
            if (error.response) {
                store.dispatch(setGarageError(error.response.data));
            }
        });
}

/**
 * Handles user login with GPMS - takes token from IAM and returns cookie
 * @param {*} token
 */
export function loginGPMS(id, callback) {
    const url = process.env.REACT_APP_GPMS_BASE_URL + gpmsProfile;
    const headers = {
        'X-OSS-SESSION': id,
    };
    axios
        .post(url, {}, { headers: headers })
        .then((res) => {
            store.dispatch(setGarageData(res.data));
            store.dispatch(setCustomerFirstName(res.data.firstName));
            store.dispatch(setCustomerLastName(res.data.lastName));
            store.dispatch(setCustomerEmail(res.data.email));
            store.dispatch(setCustomerPhoneNumber(res.data.phoneNumber));
            store.dispatch(setGarageLoading(false));
            store.dispatch(setProfileId(res.data.profileId));
            store.dispatch(setFirstName(res.data.firstName));
            store.dispatch(setLastName(res.data.lastName));
            store.dispatch(setCity(res.data.city));
            store.dispatch(setPostalCode(res.data.postalCode));
            store.dispatch(setStreet(res.data.addressLineOne));
            store.dispatch(setRegionCode(res.data.state));
            store.dispatch(setEmail(res.data.email));
            if(callback){
                callback(res.data.vehicles);
            }
        })
        .catch((error) => {
            store.dispatch(setGarageLoading(false));
            store.dispatch(setMyGarageError(true));
            if(callback){
                callback(null);
            }
        });
}

export function cookieCheck() {
    const localStore = store.getState();
    if (localStore.sessionReducer.session) {
        store.dispatch(setSessionActive(true));
        store.dispatch(checkSession(false));
    } else {
        store.dispatch(setSessionActive(false));
        store.dispatch(checkSession(false));
    }
}

export function getEVCertified(bac, year, make, model, vlocale) {
    const url =
        detectRuntimeEnvironment() +
        constants.v1Services +
        constants.dealerships.substring(1) +
        bac +
        constants.serviceCertified +
        constants.year.substring(1) +
        year +
        constants.make +
        make +
        constants.model +
        model +
        '&' +
        constants.locale +
        vlocale;

    axios
        .get(url)
        .then((res) => {
            store.dispatch(setEvCertified(res.data));
        })
        .catch((error) => {
            console.log(error);
        });
}


export const checkVinEligibility =  (vinNumber)=>{
    return axios.get(`${process.env.REACT_APP_MOBILE_SERVICE_PLUS_API_URL}api/v1/eligibility/vin/${vinNumber}`);
}

export const getLaunchDarklyId = ()=> {
   return axios.get(`${process.env.REACT_APP_LAUNCH_DARKLY_API_URL}api/launch-darkly-id`)
}

export const getListOfServices2 = (country, vin, bac, year, make, model, locale, previousBac, plus, mileage) => {
    const localStore = store.getState();
    store.dispatch(setSpinner(true));

    let plateParam = '';
    if (
        localStore.vehicleReducer.searchByLicensePlateView &&
        localStore.vehicleReducer.searchByLicensePlateRespReceived
    ) {
        plateParam = constants.plate;
    }

    vin = localStore.vehicleReducer.searchByLicensePlate_selectedVin
        ? localStore.vehicleReducer.searchByLicensePlate_selectedVin
        : localStore.prefillReducer.flowSource === 'Authenticated' ||
        localStore.prefillReducer.flowSource === 'DealerUS' ||
        localStore.prefillReducer.flowSource === 'AuthenticatedOCPlus'
            ? localStore.prefillReducer.vin
            : '';

    const precheck_dealerConfig = localStore.countryOptionsReducer.countryOptions.PRECHECK_COMMON_SERVICE_UNAUTH;
    const precheck_maintenanceData = localStore.countryOptionsReducer.countryOptions.PRECHECK_MAINTENANCE_DATA;
    const use_ev_certified = localStore.countryOptionsReducer.countryOptions.EV_DEALER_LOCATOR;

    if (
        use_ev_certified === 'true' &&
        localStore.prefillReducer.flowSource !== 'Authenticated' &&
        year &&
        make &&
        model
    ) {
        getEVCertified(bac, year, make, model, locale);
    }

    let headers = {};
    if (localStore.sessionReducer.session) {
        headers['X-OSS-SESSION'] = localStore.sessionReducer.session;
    }






    const query = vin ? SERVICE_QUERY_WITH_VIN(bac, vin, locale, mileage) : GET_SERVICE_WITHOUT_VIN_QUERY(bac, locale);


    const url =  process.env.REACT_APP_SERVICE_2_BASE_URL + "graphql";
    axios.post(url, { query })
        .then(response => {
            if (response && response.data) {
                const responseData = response?.data?.data;
                const recommendedItems = responseData?.getAllLineItems?.recommended;
                const additionalItems = responseData?.getAllLineItems?.additional;
                const dealerAdditionalServices = responseData?.getDealerConfigEnabledServicesAndOffers;

                const initializeSelection = (item,name) => {
                    item.isSelected = false;
                    item.uniqueId = `${name}-${Math.random().toString(16).slice(2)}`;

                    if (item.offers) {
                        item.offers.forEach(offer => {
                            offer.isSelected = false;
                            offer.uniqueId = `${name}-offer-${Math.random().toString(16).slice(2)}`;
                        });
                    }
                }

                if(recommendedItems || additionalItems || dealerAdditionalServices){
                    if(dealerAdditionalServices && dealerAdditionalServices.length > 0 ){
                        dealerAdditionalServices.forEach(item => {
                            initializeSelection(item,'dealerAdditionalServices');
                        });

                    }
                    if(recommendedItems && recommendedItems.length > 0){
                        recommendedItems.forEach(item => {
                            initializeSelection(item, "recommended");
                        });
                    }
                    if(additionalItems && additionalItems.length > 0){
                        additionalItems.forEach(item => {
                            initializeSelection(item,"additional");
                        });
                    }
                    const payload = {
                        recommendedService:recommendedItems || "",
                        additionalService:additionalItems || dealerAdditionalServices
                    }
                    store.dispatch(setNewServiceList(payload))
                }
                else {
                    console.error('Error:', response);
                    store.dispatch(setNetworkErrorFlgServices(true));
                }
            }
        })
        .catch(error => {
            console.error('Error:', error);
           store.dispatch(setNetworkErrorFlgServices(true));
        })
        .finally(() => {
            store.dispatch(setSpinner(false));
        });


}


