import React from 'react';

// Input: liked: bool
// Output: onClick event -> toggle the like change and update the database

// Stateless Functional Component declaration of a component

const PreferredDealerButton = (props) => {
    let notPreferredClass = '';

    if (!props.preferred) {
        notPreferredClass = '-unselected';
    }

    return (
        <div className="preferred-dealer-button">
            <i className={`preferred-icon icon star${notPreferredClass}`} />
            <div className="preferred-label">{props.text}</div>
        </div>
    );
};

export default PreferredDealerButton;
