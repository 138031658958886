import {
    SET_VEHICLE_DATA,
    SET_VEHICLE_MAKES,
    SET_VEHICLE_MODELS,
    SET_VEHICLE_PLUS,
    SET_VEHICLE_YEARS,
    SET_VEHICLE_SEARCH_BY_LICENSE_RESP,
    SEARCH_BY_LICENSE_RESP_BACK_BUTTON_CLICKED,
} from './ActionTypes';

import { stringToTitleCase } from '../components/Appointment/AppointmentHelpers';

const initialState = {
    yearsList: [],
    makesList: [],
    modelsList: [],
    plusList: [],
    selectedYear: '',
    selectedMake: '',
    selectedModel: '',
    selectedMileage: '',
    selectedLicense: '',
    selectedMotor: '',
    selectedRegistration: '',
    booleanVal: true,
    searchByLicensePlateSelectedState: '',
    searchByLicensePlateEnteredValue: '',
    searchByLicensePlateView: false,
    searchByLicensePlateRespReceived: false,
    searchByLicensePlateRespError: false,
    searchByLicensePlate_selectedMake: '',
    searchByLicensePlate_selectedModel: '',
    searchByLicensePlate_selectedYear: '',
    searchByLicensePlate_selectedVin: '',
};

const VehicleReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_VEHICLE_YEARS: {
            return { ...state, yearsList: setVehicleYears(action) };
        }
        case SET_VEHICLE_MAKES: {
            return { ...state, makesList: setVehicleMakes(action) };
        }
        case SET_VEHICLE_MODELS: {
            return { ...state, modelsList: setVehicleModels(action) };
        }
        case SET_VEHICLE_PLUS: {
            return { ...state, plusList: setVehiclePlus(action) };
        }
        case SET_VEHICLE_DATA: {
            setVehicleData(state, action);
            return { ...state };
        }
        case SET_VEHICLE_SEARCH_BY_LICENSE_RESP: {
            setVehicleDataSearchByLicensePlate(state, action);
            return { ...state };
        }
        case SEARCH_BY_LICENSE_RESP_BACK_BUTTON_CLICKED: {
            // When on search by license plate results page, this code is called if back button is clicked
            // We flip this flag to show previous vehicle page of search by license where you enter in the values.
            return { ...state, searchByLicensePlateRespReceived: false };
        }
        default:
            return state;
    }
};

const setVehicleYears = (action) => {
    let yearsList;
    if (Object.keys(action.payload).length > 0) {
        yearsList = action.payload.year.sort((a, b) => (a.year > b.year ? 1 : -1));
    }
    return yearsList;
};

const setVehicleMakes = (action) => {
    let makesList;
    if (Object.keys(action.payload).length > 0) {
        makesList = action.payload.make.sort((a, b) => (a.make > b.make ? 1 : -1));
    }
    return makesList;
};

const setVehicleModels = (action) => {
    let modelsList;
    if (Object.keys(action.payload).length > 0) {
        modelsList = action.payload.sort((a, b) => (a.toUpperCase() > b.toUpperCase() ? 1 : -1));
    }
    return modelsList;
};

const setVehiclePlus = (action) => {
    let plusList;
    if (Object.keys(action.payload).length > 0) {
        plusList = action.payload;
    }
    return plusList;
};

const setVehicleData = (state, action) => {
    if (Object.keys(action.payload).length > 0) {
        state[action.payload.key] = action.payload.value;
        state.booleanVal = !state.booleanVal;
    }
    return state;
};

const setVehicleDataSearchByLicensePlate = (state, action) => {
    if (Object.keys(action.payload).length > 0) {
        if (action.payload.data[0].vin === 'Error') {
            state.searchByLicensePlateResp = action.payload.data;
            state.searchByLicensePlateRespError = true;
            state.searchByLicensePlateRespReceived = false; // set it back to true, setting to false to see no console errors
        } else {
            state.searchByLicensePlateRespError = false;
            state.searchByLicensePlateResp = action.payload.data;
            state.searchByLicensePlateRespReceived = true;
        }
    }

    // Initialize vehicle selection here when only 1 vehicle returned
    if (!state.searchByLicensePlateRespError && state.searchByLicensePlateResp.length === 1) {
        state.searchByLicensePlate_selectedMake = stringToTitleCase(state.searchByLicensePlateResp[0].makeCode);
        state.searchByLicensePlate_selectedModel = stringToTitleCase(state.searchByLicensePlateResp[0].modelDesc);
        state.searchByLicensePlate_selectedYear = state.searchByLicensePlateResp[0].modelYear;
        state.searchByLicensePlate_selectedVin = state.searchByLicensePlateResp[0].vin;

        // Initialize these variables too since they are used on services page
        state.selectedMake = stringToTitleCase(state.searchByLicensePlateResp[0].makeCode);
        state.selectedModel = stringToTitleCase(state.searchByLicensePlateResp[0].modelDesc);
        state.selectedYear = state.searchByLicensePlateResp[0].modelYear;
    }

    return state;
};

export default VehicleReducer;
