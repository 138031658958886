import {
    SET_GARAGE_DATA,
    SET_GARAGE_ERROR,
    RESET_GARAGE_ERROR,
    SET_GARAGE_LOADING,
    SET_SESSION_ACTIVE,
    CHECK_SESSION,
    SET_GARAGE_INDEX,
    SET_VEHICLE_SELECTED,
    SET_MY_GARAGE_ERROR,
    SET_ATTEMPTED_LOGIN
} from './ActionTypes';

const initialState = {
    checkSessionFlag: false,
    email: '',
    firstName: '',
    lastName: '',
    loading: false,
    message: '',
    phoneNumber: '',
    status: '',
    loginStatus: false,
    vehicles: [],
    selectedIndex: 0,
    vehicleSelected: 0,
    garageFailed: false,
    profileId: '',
    gcin: '',
    attemptedLogin: false,
};

const MyGarageReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_GARAGE_DATA: {
            let { email, firstName, lastName, phoneNumber, vehicles, profileId, gcin } = action.payload;
            return {
                ...state,
                email,
                firstName,
                lastName,
                phoneNumber,
                loginStatus: true,
                vehicles,
                profileId,
                gcin,
            };
        }
        case SET_GARAGE_ERROR: {
            let { status, message } = action.payload;

            return {
                ...state,
                message,
                status,
                loginStatus: false,
            };
        }
        case RESET_GARAGE_ERROR: {
            return {
                ...state,
                message: initialState.message,
                status: initialState.status,
                loginStatus: false,
                garageFailed: false,
            };
        }
        case SET_GARAGE_LOADING: {
            let loading = action.payload;

            return {
                ...state,
                loading,
            };
        }
        case SET_SESSION_ACTIVE: {
            let loginStatus = action.payload;

            return {
                ...state,
                loginStatus,
            };
        }
        case CHECK_SESSION: {
            let checkSessionFlag = action.payload;

            return {
                ...state,
                checkSessionFlag,
            };
        }
        case SET_GARAGE_INDEX: {
            return {
                ...state,
                selectedIndex: action.payload,
            };
        }
        case SET_VEHICLE_SELECTED: {
            return {
                ...state,
                vehicleSelected: action.payload,
            };
        }
        case SET_MY_GARAGE_ERROR: {
            return {
                ...state,
                garageFailed: action.payload,
            };
        }
        case SET_ATTEMPTED_LOGIN: {
            return {
                ...state,
                attemptedLogin: action.payload,
            };
        }
        default:
            return state;
    }
};

export default MyGarageReducer;
