import store from '../../../Redux/Store';
import {setBrandText} from '../../../components/MyRewards/MyRewards';

export default function myRewardsObject() {
    const localStore = store.getState();

    let translations = localStore.countryOptionsReducer.translations;
    let preferredBrand = localStore.myRewardsReducer.preferredBrand;
    let themeMake = localStore.prefillReducer.themeMake;
    let make = localStore.prefillReducer.make;
    let brands = localStore.prefillReducer.dealerSummaryObject.dealershipSummary.brands;
    let addressLine1 = localStore.prefillReducer.street;
    let city = localStore.prefillReducer.city;
    let postalCode = localStore.prefillReducer.postalCode;
    let state = localStore.prefillReducer.regionCode;
    let countryCode = 'USA';
    let email = localStore.prefillReducer.email;
    let enrollBrand = setBrandText(translations, preferredBrand, themeMake, make, brands);
    enrollBrand = enrollBrand.substring(enrollBrand.indexOf(' ') + 1, enrollBrand.lastIndexOf(' '));
    let firstName = localStore.prefillReducer.firstName;
    let lastName = localStore.prefillReducer.lastName;
    let gpmsProfileId = localStore.prefillReducer.gpmsProfileId;

    return {
        addressLine1,
        city,
        countryCode,
        email,
        enrollBrand,
        firstName,
        gpmsProfileId,
        lastName,
        postalCode,
        state,
    };
}
