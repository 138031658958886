import store from '../../Redux/Store';
import { getDealersByCords } from '../../shared/BackendCalls/SharedBackendGets';
import {
    setHideDistanceTrue,
    setHideDistanceFalse,
    clearSelectedDealer,
    disableShowMore,
} from '../../Redux/ActionCreator';

const constants = require('../../shared/RoutingFlow/FlowComponentsConstants');

export function getGeoLocation() {
    // Ask user for access to current location
    if (navigator.geolocation) {
        // grab local state to check disbaleShowMore, reset to false if true to re-enable ShowMore
        const localStore = store.getState();
        if (localStore.locReducer.disableShowMore === true) {
            store.dispatch(enableShowMore());
        }
        navigator.geolocation.getCurrentPosition(geoSuccess, geoError);
    }
}

function geoSuccess(position) {
    const localStore = store.getState();
    const { country } = localStore.prefillReducer;
    const { bac } = localStore.prefillReducer;

    // Set coordinates in the state
    store.dispatch(setCoordinates(position));
    // Update DOM UI depending on the flowSource
    store.dispatch(setRenderFlagFalse());
    store.dispatch(setSearchFlag());
    if (
        localStore.prefillReducer.flowSource === constants.OssLocatorFlow &&
        localStore.prefillReducer.branchDealer === false
    ) {
        store.dispatch(showLocationIcon());
        store.dispatch(hideUseLocationButton());
        store.dispatch(showListAndMap());
    }

    // Use coordinates set in state to getDealers
    getDealersByCords(false, position, country, bac);
}

function geoError() {
    // Take no action, If user denies access to location they will need to perform a different type of search
}

export function processDealerships(data, nameSearch) {
    let localStore = store.getState();
    let i;
    let prevDealer;

    if (
        localStore.locReducer.currentLatLng.lat === 0 &&
        localStore.locReducer.currentLatLng.lng === 0 &&
        localStore.prefillReducer.flowSource === constants.OssLocatorFlow &&
        localStore.prefillReducer.branchDealer === false
    ) {
        store.dispatch(setHideDistanceTrue());
    } else {
        store.dispatch(setHideDistanceFalse());
    }

    // Loop through dealer to process the data
    for (i in data.dealerships) {
        // For Authenticated and Quantum flows, search is based on preferred/quantum dealer coordinates.
        // Distance will show 0.0 mi/km - set as error so this is hidden
        if (data.dealerships[i].miles === '0.0' && data.dealerships[i].kilometers === '0.0') {
            data.dealerships[i].miles = localStore.countryOptionsReducer.translations.ERROR_CALCULATING_DISTANCE;
            data.dealerships[i].kilometers = localStore.countryOptionsReducer.translations.ERROR_CALCULATING_DISTANCE;
        }

        // Check for overlapping dealership coordinates.
        // When found, offset one of the coordinates slightly so the markers do not render directly on top of each other
        if (Object.prototype.hasOwnProperty.call(data.dealerships, i)) {
            if (!prevDealer) {
                // first loop set prevDealer to zero index of dealerships array
                prevDealer = data.dealerships[i];
            } else if (prevDealer) {
                // each consecutive loop pass, check prevDealer against current index
                if (
                    prevDealer.latitude === data.dealerships[i].latitude &&
                    prevDealer.longitude === data.dealerships[i].longitude
                ) {
                    const newLat = Number(data.dealerships[i].latitude) + (Math.random() - 0.5) / 1500;
                    const newLng = Number(data.dealerships[i].longitude) + (Math.random() - 0.5) / 1500;
                    data.dealerships[i].offsetLatitude = newLat.toString();
                    data.dealerships[i].offsetLongitude = newLng.toString();
                } else {
                    prevDealer = data.dealerships[i];
                }
            }
        }

        // Find preferred Dealer and assign property of preferred dealer
        if (data.dealerships[i].bac === localStore.prefillReducer.bac) {
            data.dealerships[i].preferred = true;
            // Move Preferred/Quantum dealership to the 0 index of the array
            if (i !== '0') {
                const temp = data.dealerships[0];
                data.dealerships[0] = data.dealerships[i];
                data.dealerships[i] = temp;
            }
        }
    }

    if (data.dealerships.length === 0) store.dispatch(disableShowMore());

    store.dispatch(clearSelectedDealer());
    store.dispatch(setDealers(data.dealerships, nameSearch));
    localStore = store.getState();
    if (localStore.locReducer.selectedDealer) {
        const el = document.getElementById(localStore.locReducer.selectedDealer.bac);

        if (el) el.scrollIntoView({ behavior: 'smooth' });
    }
}

function showLocationIcon() {
    return {
        type: 'SHOW_LOCATION_ICON',
    };
}

function hideUseLocationButton() {
    return {
        type: 'HIDE_USE_LOCATION_BUTTON',
    };
}

function showListAndMap() {
    return {
        type: 'SHOW_LIST_AND_MAP',
    };
}

function setRenderFlagFalse() {
    return {
        type: 'SET_RENDER_FLAG_FALSE',
    };
}

function setSearchFlag() {
    return {
        type: 'SET_SEARCH_FLAG',
    };
}

function enableShowMore() {
    return {
        type: 'ENABLE_SHOW_MORE',
    };
}

function setCoordinates(position) {
    return {
        type: 'SET_CURRENT_LAT_LNG',
        payload: position,
    };
}

function setDealers(dealerships, nameSearch) {
    return {
        type: 'SET_DEALERS',
        payload: dealerships,
        nameSearch,
    };
}
