export const PREFILL = 'PREF';
export const EDTCOM = 'EDTCOM';
export const SUMMBAC = 'SUMMBAC';
export const COUNTRY = 'COUNTRY';
export const COUNTRY_EDTCOM = 'COUNTRY_EDTCOM';
export const COMP = 'COMP';
export const STOP = 'STOP';
export const BU = 'BU';
export const CA = 'CA';
export const CH = 'CH';
export const GM = 'GM';
export const MB = 'MB';

export const Buick = 'Buick';
export const Cadillac = 'Cadillac';
export const Chevrolet = 'Chevrolet';
export const GMC = 'GMC';

export const CONFIRMATION_LINK = '/Confirmation';
export const REVIEW_LINK = '/SubmitAppointment';
export const VEHICLE = '/Vehicle';

export const APPOINTMENT = 'APPOINTMENT';
export const CONFIRMATION = 'CONFIRMATION';
export const DEALERSHIP_LOC = 'DEALERSHIP_LOC';
export const REVIEW = 'REVIEW';
export const SELECT_VEHICLE = 'SELECT_VEHICLE';
export const TITLE_SERVICES = 'TITLE_SERVICES';
export const SIGN_IN = 'SIGN_IN';
export const LANDING = 'LANDING';

export const b_buick = 'buick';
export const b_cadillac = 'cadillac';
export const b_chevrolet = 'chevrolet';
export const b_gmc = 'gmc';

export const Silver = 'Silver';
export const Gold = 'Gold';
export const Platinum = 'Platinum';
export const Crossbrand = 'GM';

export const myGMRewardsLink = 'https://www.mygmrewards.com/';

export const myChevroletRewardsLink = 'https://www.chevrolet.com/rewards';
export const myBuickRewardsLink = 'https://www.buick.com/rewards';
export const myGMCRewardsLink = 'https://www.gmc.com/rewards';
export const myCadillacRewardsLink = 'https://www.cadillac.com/rewards';
export const ExperienceRewardsLink = 'https://experience.gm.com/rewards';

export const PRIVACY_POLICY_LINK = 'https://www.gm.com/privacy-statement.html?evar25=gm_myrewards_footer';

export const yes = 'Y';
export const no = 'N';
export const notApplicable = 'Not Applicable';

export const terms = 'terms';
