import {makeStyles} from "@material-ui/core";

const sharedBasicModalStyle = makeStyles((theme) => ({
    model: {
        top: '50%',
        left: '50%',
        position: 'absolute',
        maxWidth: '400px',
        transform: 'translate(-50%, -50%)',
        padding: '16px 32px 24px',
        backgroundColor: 'rgb(255, 255, 255)',

        [theme.breakpoints.down('sm')]:{
            overflowY:"scroll",
            maxHeight:'600px'
        },

        '& .primary-button, .secondary-button':{
            minWidth:'100%'

        },
        '& h2':{
            fontSize:'1.5rem'
        },
        '& .secondary-button':{
            marginTop:'1rem'
        },

       '& .MuiListItem-gutters':{
            listStyleType: 'disc',
            display: 'list-item',
            left:'26px'
        }


    },
    addScroller:{
        [theme.breakpoints.up('md')]:{
            overflowY:"scroll",
            maxHeight:'calc(100vh - 70px)'
        }
    }

}))

export default sharedBasicModalStyle;