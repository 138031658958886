import React, { Component } from 'react';
import {connect} from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import $ from 'jquery';
import {
    addTireFinderService,
    addTireFinderServiceString,
    setExpandedOffer,
    setPrefillMileage,
    setSelectedOffer,
    setVehicleData,
    setSelectedService,
} from '../../Redux/ActionCreator';
import SharedCheckBox from '../../shared/Checkbox/SharedCheckBox';
import MoreInfo from '../MoreInfo/MoreInfo';
import NeedSomethingElse from '../NeedSomethingElse/NeedSomethingElse';
import OdometerPopup from './OdometerPopup';
import Offers from '../MoreInfo/Offers';
import {getAppGlobals} from '../../shared/BackendCalls/SharedBackendGets';
import { renderCurrencyValue, renderPrice } from '../../shared/Utils/PriceLocalization';
import appointmentObject from '../../shared/Utils/POST/AppointmentObject';
import * as consts from '../../shared/Constants/ServiceConstants';
import AnalyticsService from '../../shared/Utils/analytics.service';
import { adobeAnalytics } from '../../shared/Utils/Utils.js';
import ServiceAcknowledge from "../MobileService/ServiceAcknowledge";
import MobileServiceUnenrolledDealerModal from "../MobileService/MobileServiceUnenrolledDealerModal";
import ServiceAcknowledgementWrapper from "../MobileService/ServiceAcknowledgementWrapper";

const localizationConstants = require('../../shared/Constants/LocalizationConstants');

const constants = require('../../shared/RoutingFlow/FlowComponentsConstants');

class LegacyServices extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showOdometerPopup: false,
            mileage: this.props.vMiles,
            tiresString: '',
            tiresArray: [],
        };

        if (this.props.vFlowSource === constants.TireFinderFlow) {
            const urlParams = new URLSearchParams(this.props.vUrlParamString);
            this.state.tiresString = decodeURI(urlParams.get('service:lineitems'));

            this.props.addTireFinderServiceString(this.state.tiresString);
            this.processTireFinderUrlParams(this.state.tiresString);
        }

        window.scrollTo(0, 0);
    }

    componentDidMount() {
        const analyticsService = AnalyticsService();
        analyticsService.trackPage('LegacyServices');
        if (AnalyticsService().loggingEnabled) {
            // Append the Analytics Object to the DOM
            $('body').append(adobeAnalytics('form-step'));
            console.log('Analytics Added for Page to DOM: LegacyServices using JQuery');
        }
    }

    submitOdometerValue(mileage) {
        this.setState({ mileage });
        this.toggleOdometerPopup();
        const apptObject = appointmentObject();

        this.getOssGlobals(
            this.props.vVin,
            apptObject.recordLocator ? apptObject.bac : this.props.vBac,
            mileage,
            this.props.vCountry
        );
        this.props.setPrefillMileage(mileage);
        if (this.props.flowSource !== 'Authenticated' || this.props.flowSource !== 'AuthenticatedOCPlus') {
            this.props.setVehicleData({
                key: 'selectedMileage',
                value: mileage,
            });
        }
        this.renderTableData();
    }

    processTireFinderUrlParams(serviceLineItems) {
        // Remove if tire finder ever adds delimiters
        // const serviceLineItemsWithDelimiters = this.addTireFinderDelimiter(serviceLineItems);

        const tiresArray = serviceLineItems.split('ALINE');
        tiresArray.forEach(function (tire, index) {
            const tireArray = tire.replace(')', '').split(' (');

            const tireTitle = tireArray[0];
            const tireDescription = tireArray[1];

            this[index] = { name: tireTitle, description: tireDescription };
        }, tiresArray);

        this.state.tiresArray = tiresArray;
        this.props.addTireFinderService(tiresArray);
    }

    showDeploymentMode() {
        let deploymentMode;

        if (this.props.vDeploymentMode === 'STANDALONE') {
            deploymentMode = this.props.vTranslations.STANDALONE_VIEW_INDICATOR;
        } else if (this.props.vDeploymentMode === 'DMS') {
            deploymentMode = this.props.vTranslations.DMS_INTEGRATED_VIEW_INDICATOR;
        } else if (this.props.vDeploymentMode === 'REQUEST_FORM') {
            deploymentMode = this.props.vTranslations.REQUEST_FORM_VIEW_INDICATOR;
        } else {
            deploymentMode = '';
        }

        return deploymentMode;
    }

    handleChange = (panel) => () => {
        if (this.props.expandedOffer !== panel) {
            this.props.setExpandedOffer(panel);
        } else {
            this.props.setExpandedOffer(false);
        }
    };

    toggleOdometerPopup() {
        this.setState({
            showOdometerPopup: !this.state.showOdometerPopup,
        });
        window.scrollTo(0, 0);
    }

    getOssGlobals(vin, bac, mileage, country) {
        if (bac) {
            getAppGlobals(
                country,
                vin,
                bac,
                this.props.vYear,
                this.props.vMake,
                this.props.vModel,
                this.props.vLocale,
                '',
                this.props.vPlus,
                mileage,
                this.props.vUnits,
                this.props.record
            );
        } else {
            this.props.history.push({
                pathname: '/Error',
            });
        }
    }

    renderTireFinderData() {
        return this.state.tiresArray.map((tire) => (
            <div className="service-item">
                <div className="service-item-row">
                    <div className="service-item-cell title-container">
                        <div className="non-recall-items bold">{tire.name}</div>
                        <div>{tire.description}</div>
                    </div>
                    <SharedCheckBox checked id="chkTireFinder" disabled />
                </div>
            </div>
        ));
    }

    displayAccordionIcon(index, serviceType) {
        // TFS Bug UAT - 1990843
        return !this.props.vmergedServices[index].offers.length;
    }

    hasMaintenanceItems(code, items) {
        let temp = [];

        if (items) {
            temp = items.filter((offerCode) => offerCode.code === code);
        }

        return temp.length;
    }

    /**
     * @desc Convert a string to title case
     * @param {*} string
     * @return string
     */
    formatRecallString(string) {
        const DASH_STR = ' - ';
        let formattedString = '';

        if (string) {
            const tempStr = string.replace('Recall - ', '');
            const tempArray = tempStr.split(DASH_STR);

            tempArray.forEach((str, index) => {
                str = str.charAt(0).toUpperCase() + str.substr(1).toLowerCase();
                formattedString += str;

                if (index < tempArray.length - 1) {
                    formattedString += DASH_STR;
                }
            });
        } else {
            formattedString = '';
        }

        return formattedString;
    }

    renderTableData() {
        if (this.props.vmergedServices) {
            return this.props.vmergedServices.map((row, index) => {
                const {
                    categoryDescription,
                    code,
                    disclaimer,
                    displayPrice,
                    inspect,
                    itemDescription,
                    name,
                    offers,
                    price,
                    priceDetails,
                    priceDisclaimer,
                    recommended,
                    remedyDescription,
                    replace,
                    safetyDescription,
                    selected,
                    selectedOffer,
                    serviceCategoryCode,
                    serviceFEID,
                    serviceType,
                    type,
                } = row; // destructuring
                return (
                    <Accordion
                        elevation={0}
                        expanded={this.props.expandedOffer === serviceFEID}
                        key={serviceFEID}
                        onChange={this.handleChange(serviceFEID)}
                        square
                    >
                        <AccordionSummary
                            aria-label="Info" // need translation
                            aria-controls="service-item-content"
                            className="service-item-content"
                            id="service-item-content"
                        >
                            <div
                                className="stat-checkbox service-item"
                                data-dtm="service needs"
                                onClick={(event) => event.stopPropagation()}
                                onFocus={(event) => event.stopPropagation()}
                            >
                                <div className="service-item-row">
                                    <div className="service-item-cell title-container">
                                        <div className="service-item-offer">
                                            {serviceType === consts.PRIORITY_SERVICE || serviceType === consts.IVH ? (
                                                <div className="recall">
                                                    <div className="recall-header">
                                                        <i className="recall-header-icon icon alert-caution-red" />
                                                        <div className="recall-header-label">
                                                            {this.props.vTranslations.COMMONSERVICE_RECALL}
                                                        </div>
                                                    </div>
                                                    <div className="recall-title">{this.formatRecallString(name)}</div>
                                                </div>
                                            ) : (
                                                <div className="non-recall-items">
                                                    {name
                                                        .replace(
                                                            'Maintenance Schedule',
                                                            this.props.vTranslations.MAINTENANCE_SCHED
                                                        )
                                                        .replace('Miles', this.props.vTranslations.MILES)}
                                                </div>
                                            )}
                                            {this.props.vmergedServices[index].offers.length > 0 ? (
                                                selectedOffer ? (
                                                    <div className="selected-offer">{selectedOffer}</div>
                                                ) : (
                                                    ''
                                                )
                                            ) : null}
                                            {serviceType === consts.MAINTENANCE_SERVICE && recommended ? (
                                                <div className="recommended-subtitle">
                                                    <div className="recommended-icon icon star" />
                                                    <div className="recommended-subtitle-label">
                                                        {this.props.vTranslations.RECOMMENDED}
                                                    </div>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="service-item-cell service-item-offer">
                                        {displayPrice && !displayPrice[0].tagLine
                                            ? renderCurrencyValue(displayPrice[0])
                                            : ''}
                                    </div>

                                    <SharedCheckBox
                                        checked={selected}
                                        id={`chk${serviceFEID}`}
                                        onChange={() => {
                                            // Make a Call to update the state
                                            serviceType === 'MaintenanceItems'
                                                ? this.props.setSelectedService({
                                                      id: serviceFEID,
                                                      checked: !selected,
                                                      currentSearch: false,
                                                  })
                                                : this.props.setSelectedOffer({
                                                      id: serviceFEID,
                                                      checked: !selected,
                                                      currentSearch: false,
                                                  });
                                        }}
                                    />
                                </div>
                                <div className="service-item-row">
                                    {displayPrice && displayPrice[0].tagLine ? renderPrice(displayPrice[0]) : ''}
                                </div>
                                <div className="service-item-row">
                                    {this.props.vmergedServices[index].offers.length ? (
                                        <Offers
                                            index={index}
                                            categoryDescription={categoryDescription}
                                            itemDescription={itemDescription}
                                            name={name}
                                            offers={offers}
                                            selected={selected}
                                            serviceCategoryCode={serviceCategoryCode}
                                            serviceFEID={serviceFEID}
                                        />
                                    ) : (
                                        this.displayAccordionIcon(index, serviceType) &&
                                        (disclaimer ||
                                        itemDescription ||
                                        this.hasMaintenanceItems(code, this.props.maintenanceItems) ? (
                                            <button
                                                className="service-item-details-link link"
                                                data-dtm={`service:${name}`}
                                                onClick={this.handleChange(serviceFEID)}
                                                type="button"
                                            >
                                                {this.props.expandedOffer !== serviceFEID
                                                    ? this.props.vTranslations.DETAILS
                                                    : ' '}
                                            </button>
                                        ) : null)
                                    )}
                                </div>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className="offer-details bold">
                                {serviceType !== consts.DEALER_CONFIG_ITEM_SERVICE ||
                                (!offers.length && (disclaimer || itemDescription)) ? (
                                    <MoreInfo
                                        backdrop="static"
                                        code={code}
                                        disclaimer={disclaimer}
                                        displayPrice={displayPrice}
                                        inspect={inspect}
                                        itemDescription={itemDescription}
                                        keyboard={false}
                                        name={name}
                                        price={price}
                                        priceDetails={priceDetails}
                                        priceDisclaimer={priceDisclaimer}
                                        remedyDescription={remedyDescription}
                                        replace={replace}
                                        safetyDescription={safetyDescription}
                                        selected={selected}
                                        serviceCategoryCode={serviceCategoryCode}
                                        serviceFEID={serviceFEID}
                                        serviceType={serviceType}
                                    />
                                ) : null}
                            </div>
                        </AccordionDetails>
                    </Accordion>
                );
            });
        }
    }

    render() {
        let PRIVACY_STATEMENT_LINK = (
            <a
                href={this.props.vTranslations.PRIVACY_STATEMENT_LINK}
                target="_blank"
            >{` ${this.props.vTranslations.VEHICLE_PRIVACY_STATEMENT}`}</a>
        );

        const origin = this.props.origin;
        const tirefinderServices = (
            <div>
                <div className="services-title headline2">{this.props.vTranslations.REQUESTED_SERVICES}</div>
                <div className="headline-border" />
                <div className="service-table">{this.renderTireFinderData()}</div>
            </div>
        );

        const div = (
            <>
                {this.props.vshowSpinner ? (
                    <div className="pega-loading-container">
                        <h3 className="pega-loading-text">{this.props.vTranslations.PEGA_LOADING}</h3>
                        <CircularProgress />
                    </div>
                ) : (<>
                    <div className="services-container">
                        <div className="change-dealer-message">
                            {this.props.vPreviousBac && this.props.vPreviousBac !== this.props.vBac ? (
                                <div>
                                    {this.props.vTranslations.DEALER_CHANGE_MSG}
                                    <Link
                                        className={'change-dealer-link'}
                                        to={{
                                            pathname: this.props.vTranslations.DEALERSHIP_LOCATOR_LINK,
                                        }}
                                    >
                                        {this.props.vTranslations.CHOOSE_ANOTHER_DEALER}
                                    </Link>
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                        {this.props.vFlowSource === constants.TireFinderFlow && this.state.tiresString && (
                            <div>{tirefinderServices}</div>
                        )}
                        <div className="services-title headline2">
                            {this.props.vCountry === 'US'
                                ? this.props.vPegaResponse !== null
                                    ? this.props.vTranslations.RECOMMENDED_SERVICES_TITLE
                                    : this.props.vTranslations.COMMON_SERVICE_TITLE
                                : this.props.vCountryOptions.USE_COMMON_SERVICE === 'true' && !this.props.vPegaReturned
                                ? this.props.vTranslations.COMMON_SERVICE_TITLE
                                : this.props.vTranslations.RECOMMENDED_SERVICES_TITLE}
                        </div>
                        <div className="headline-border" />
                        <div className="services-subtitle">
                            {this.props.vTranslations.BASED_ON_YOUR} {this.props.vbooleanVal} {this.props.vYear}{' '}
                            {this.props.vMake} {this.props.vModel} {this.props.vTranslations.WITH}{' '}
                            {this.props.vPegaResponse ? this.props.vPegaResponse.odoReading : this.props.vMiles}{' '}
                            {this.props.vTranslations.L_MILES}.
                            {!this.state.showOdometerPopup ? (
                                <button
                                    id="btnEditMileage"
                                    data-dtm="recommended service"
                                    className="stat-text-link mileage-value link"
                                    onClick={this.toggleOdometerPopup.bind(this)}
                                    type="button"
                                >
                                    {this.props.vTranslations.EDIT_MILEAGE}
                                </button>
                            ) : (
                                <OdometerPopup
                                    mileage={this.state.mileage}
                                    closeOdometerPopup={this.toggleOdometerPopup.bind(this)}
                                    showOdometerPopup={this.state.showOdometerPopup}
                                    submitOdometerValue={this.submitOdometerValue.bind(this)}
                                />
                            )}
                        </div>
                        <div className="service-table">{this.renderTableData()}</div>
                        <NeedSomethingElse />
                        <div className="deployment-mode desc">{this.showDeploymentMode()}</div>
                        <div className="privacy-statement-for-service">
                            {origin === 'DLRTIRE' && this.props.vCountry !== localizationConstants.AR && (
                                <>
                                    {this.props.vTranslations.GM_PRIVACY_STATEMENT} {PRIVACY_STATEMENT_LINK}
                                </>
                            )}
                            {origin === 'DLRTIRE' && this.props.vCountry === localizationConstants.AR && (
                                <>
                                    {this.props.vTranslations.AR_GM_PRIVACY_STATEMENT_ONE} {PRIVACY_STATEMENT_LINK}{' '}
                                    {this.props.vTranslations.AR_GM_PRIVACY_STATEMENT_TWO}
                                </>
                            )}
                        </div>
                    </div>
                   <ServiceAcknowledgementWrapper/>
                    </>
                )}
            </>
        );
        return div;
    }
}

const mapStateToProps = (state) => ({
    expandedOffer: state.ossAppGlobals.expandedOffer,
    maintenanceItems: state.ossAppGlobals.maintenanceItems,
    record: state.reviewReducer.recordLocator
        ? state.reviewReducer.recordLocator
        : state.prefillReducer.editAppointmentRecordLocator,
    vBac: state.ossAppGlobals.bac,
    vbooleanVal: state.ossAppGlobals.booleanVal,
    vCountry: state.prefillReducer.country,
    vCountryOptions: state.countryOptionsReducer.countryOptions,
    vFlowSource: state.prefillReducer.flowSource,
    vLocale: state.prefillReducer.locale,
    vMake: state.vehicleReducer.selectedMake ? state.vehicleReducer.selectedMake : state.prefillReducer.make,
    vmergedServices: state.ossAppGlobals.servicesArrayList
        ? state.ossAppGlobals.servicesArrayList.filter((x) => x.requiredOrCommonService)
        : null,
    vMiles:
        state.prefillReducer.flowSource === 'Authenticated' || state.prefillReducer.flowSource === 'AuthenticatedOCPlus'
            ? state.prefillReducer.miles
                ? state.prefillReducer.miles
                : '0'
            : state.vehicleReducer.selectedMileage
            ? state.vehicleReducer.selectedMileage
            : '0',
    vModel: state.vehicleReducer.selectedModel ? state.vehicleReducer.selectedModel : state.prefillReducer.model,
    vPegaReturned: state.ossAppGlobals.pegaReturned,
    vPlus: state.vehicleReducer.selectedMotor,
    vPreviousBac: state.ossAppGlobals.previousBac,
    vDeploymentMode: state.prefillReducer.dealerSummaryObject.dealershipSummary.deploymentMode,
    vshowSpinner: state.ossAppGlobals.showSpinner,
    vTranslations: state.countryOptionsReducer.translations,
    vUnits: state.prefillReducer.units,
    vUrlParamString: state.prefillReducer.urlParamString,
    vVin: state.prefillReducer.vin,
    vYear: state.vehicleReducer.selectedYear ? state.vehicleReducer.selectedYear : state.prefillReducer.year,
    vPegaResponse: state.ossAppGlobals.pega_response,
    origin: state.prefillReducer.origin

});

export default connect(mapStateToProps, {
    addTireFinderService,
    addTireFinderServiceString,
    setExpandedOffer,
    setPrefillMileage,
    setSelectedOffer,
    setVehicleData,
    setSelectedService,
})(LegacyServices);
withRouter(LegacyServices);
