import * as React from "react";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import store from "../../Redux/Store";
import {setNoTimeSlotAvailableError} from "../../Redux/ActionCreator";
import SharedBasicModal from "../../shared/Modal/SharedBasicModal";



const NoTimeSlotErrorModal = ()=>{
     let history = useHistory();
     const {translations} = useSelector((state) => state.countryOptionsReducer);
     const {showTimeSlotErrorModel} = useSelector((state) => state.appointmentReducer);


    const handleClick=()=>{
        history.push('/Appointment');
        store.dispatch(setNoTimeSlotAvailableError('false'));

        }


    return <>
        <SharedBasicModal show={showTimeSlotErrorModel}
                          hideCloseIcon={true}
                          title={translations.APP_UNAVAILABLE_TITLE || 'APP_UNAVAILABLE_TITLE'}
                          bodyAsText={translations.APP_UNAVAILABLE_PARA_1 || 'APP_UNAVAILABLE_PARA_1'}
                          primaryButton={translations.APP_UNAVAILABLE_BUTTON || 'APP_UNAVAILABLE_BUTTON'}
                          handleClick={handleClick}/>
        </>
}

export default NoTimeSlotErrorModal