import { SET_COUNTRY_OPTIONS } from './ActionTypes';
import * as flowConstants from '../shared/RoutingFlow/FlowComponentsConstants';

const initialState = {
    countryOptions: '',
    translations: '',
    actioncom: '',
    locale:'',
    language: '',
};

const sharedConstants = require('../shared/Constants/SharedConstants');

const CountryOptionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_COUNTRY_OPTIONS: {
            if (Object.keys(action.payload).length > 0) {
                return {
                    ...state,
                    countryOptions: action.payload.countryOptions,
                    translations: action.payload.translations.translations,
                    locale: action.payload.defaultLocale.tag,
                    language: action.payload.defaultLocale.language,
                    actioncom:
                        action.flow !== flowConstants.EditAppointmentFlow
                            ? sharedConstants.COUNTRY
                            : sharedConstants.COUNTRY_EDTCOM,
                };
            } else {
                return state;
            }
        }
        default:
            return state;
    }
};

export default CountryOptionsReducer;
