const constants = require('./FlowComponentsConstants');

export default function validateFlow(state) {
    let errorMessage = '';
    switch (state.flowSource) {
        case constants.AuthenticatedFlow: {
            // Needed: VIN, BAC, Country are required
            if (!state.vin) {
                errorMessage += 'VIN ';
            }
            if (!state.bac) {
                errorMessage += 'BAC ';
            }
            if (!state.country) {
                errorMessage += 'Country';
            }

            break;
        }

        case constants.EditAppointmentFlow: {
            // Needed: Extracted OSS Record Locator Needed, BAC
            if (!state.editAppointmentRecordLocator) {
                errorMessage += 'Record Locator ';
            }
            if (!state.bac) {
                errorMessage += 'BAC ';
            }
            break;
        }

        case constants.BrandFlow: {
            // Needed: BAC, Country
            if (!state.bac) {
                errorMessage += 'BAC ';
            }
            if (!state.country) {
                errorMessage += 'Country ';
            }
            break;
        }

        case constants.DealerFlow: {
            // Needed: Country
            if (!state.country) {
                errorMessage += 'Country ';
            }
            break;
        }

        case constants.OssLocatorFlow: {
            // Needed: Country, in Branch Scenario BAC will be needed
            if (!state.country) {
                errorMessage += 'Country ';
            }
            break;
        }

        case constants.TireFinderFlow: {
            // Needed:Country, BAC, ServiceLineItems, Origin, Vehicle Make, Vehicle Year, Vehicle Model
            if (!state.country) {
                errorMessage += 'Country ';
            }
            if (!state.bac) {
                errorMessage += 'BAC ';
            }
            if (!state.serviceLineItems) {
                errorMessage += 'Service Line Items ';
            }
            if (!state.origin) {
                errorMessage += 'Origin ';
            }
            if (!state.make) {
                errorMessage += 'Make ';
            }
            if (!state.year) {
                errorMessage += 'Year ';
            }
            if (!state.model) {
                errorMessage += 'Model ';
            }

            break;
        }
    }
    if (errorMessage) {
        errorMessage = `The following URL Parameters Are Missing for flow:${state.flowSource} Parameters:${errorMessage}`;
    }
    return errorMessage;
}
