import React, { Component } from 'react';
import { connect } from 'react-redux';
import NameSearchBox from './NameSearchBox';
import SearchBox from './SearchBox';

class SearchBoxToggle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchFlag: true,
        };
        this.toggleSearchBox = this.toggleSearchBox.bind(this);
        this.renderSearchBox = this.renderSearchBox.bind(this);
    }

    toggleSearchBox() {
        if (this.state.searchFlag) {
            this.setState({ searchFlag: false });
        } else {
            this.setState({ searchFlag: true });
        }
    }

    renderSearchBox() {
        return (
            <>
                <NameSearchBox hide={this.state.searchFlag} />
                <SearchBox
                    hide={!this.state.searchFlag}
                    autocomplete={this.autocomplete}
                    showError={this.props.showError}
                    selectedPlace={this.props.selectedPlace}
                />
            </>
        );
    }

    render() {
        const { searchFlag } = this.state;

        return (
            <div>
                <div className="locator-radio-group">
                    <div className={`locator-radio-button radio-button ${searchFlag === true ? 'checked' : ''}`}>
                        <input
                            type="radio"
                            value="Default"
                            checked={searchFlag === true}
                            className="stat-radio default-radio-button"
                            onChange={this.toggleSearchBox}
                            data-dtm="search a dealer"
                        />
                        <label className="radio-button-label">{this.props.vTranslations.CITY_STATE_ZIP_SEARCH}</label>
                    </div>
                    <div className={`locator-radio-button radio-button ${searchFlag === false ? 'checked' : ''}`}>
                        <input
                            type="radio"
                            value="dealerSearch"
                            checked={searchFlag === false}
                            className="default-radio-button"
                            onChange={this.toggleSearchBox}
                        />
                        <label className="radio-button-label">{this.props.vTranslations.NAME_SEARCH}</label>
                    </div>
                </div>
                {this.renderSearchBox()}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    vTranslations: state.countryOptionsReducer.translations,
});

export default connect(mapStateToProps)(SearchBoxToggle);
