import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { stringToTitleCase, formatPhoneNum } from '../Appointment/AppointmentHelpers';
import { formatCustomerPhoneNumber } from '../Review/SubmitAppointmentHelpers';

const constants = require('../../shared/Constants/SharedConstants');
const localizationConstants = require('../../shared/Constants/LocalizationConstants');
const appointmentConstants = require('../Appointment/AppointmentConstants');
const flowConstants = require('../../shared/RoutingFlow/FlowComponentsConstants');

class AppointmentDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            addressLink: '',
            androidApp: 'geo:0,0?&z=15&mrt=yp&t=m&q=',
            appleMap: 'http://maps.apple.com/?q=',
            googleMap: 'http://maps.google.com/maps?&z=15&mrt=yp&t=m&q=',
            mapTarget: '_blank',
            platform: navigator.platform,
        };
    }

    routeToMap() {
        const selectedAddress = `${this.props.selectedDealer.address.addressLine1}+${this.props.selectedDealer.address.cityName}`;

        if (navigator.platform === 'iPhone' || navigator.platform === 'iPad' || navigator.platform === 'iPod') {
            this.state.addressLink = this.state.appleMap + selectedAddress;
        } else if (/android/i.test(navigator.userAgent)) {
            // When on Android , open the app/maps via geo call
            this.state.addressLink = encodeURI(this.state.androidApp + selectedAddress);
        } else {
            this.state.addressLink = this.state.googleMap + selectedAddress;
        }
        return this.state.addressLink;
    }

    routeToMapTarget() {
        if (/android/i.test(navigator.userAgent)) {
            // When on Android , open the app the maps via geo
            this.state.mapTarget = '_system';
        }
        return this.state.mapTarget;
    }

    returnPostalZone() {
        if (this.props.selectedDealer.address.postalZone) {
            if (this.props.vCountry === localizationConstants.US) {
                return this.props.selectedDealer.address.postalZone.slice(0, 5);
            }
            return this.props.selectedDealer.address.postalZone;
        }
        return null;
    }

    getPhoneNum() {
        const { departments } = this.props.selectedDealer;
        let phone = '';

        departments.forEach((department) => {
            if (department.name === 'SERVICE') {
                if (department.phoneNumber) {
                    phone = department.phoneNumber;
                } else {
                    phone = '';
                }
            }
        });

        return phone;
    }

    displayCustomMessage() {
        let message;
        const { customMessage } = this.props.selectedTransportOption.transportationCustomMessage;

        if (this.props.selectedTransportOption) {
            if (customMessage) {
                message = customMessage;
            } else {
                message = this.props.translations[this.props.selectedTransportOption.disclaimer];
            }
            return message;
        }
        return null;
    }

    formatCityZipSpacing() {
        if (this.props.selectedDealer.address.postalZone) {
            return ', ';
        }
        return '';
    }

    formatSubentityZipSpacing() {
        if (this.props.selectedDealer.address.countrySubentity) {
            return ', ';
        }
        return '';
    }

    renderAdvisor() {
        let advisorText;
        advisorText = (
            <div><b>{this.props.translations.ADVISOR + ":"}</b> {this.props.selectedAdvisor.name}</div>
        );
        return advisorText;
    }

    renderMobileServiceAddress() {
        let mobileServiceAddressText;
        const serviceAddress = this.props.serviceAddresses && this.props.serviceAddresses.length > 0 && this.props.serviceAddresses.filter((item)=>item.type ==="SERVICE")[0]
        mobileServiceAddressText = (
            serviceAddress ? <div>
                <div>{serviceAddress.addressLine1}</div>
                <div>{serviceAddress.cityName + ", " + serviceAddress.countrySubentity + " " + serviceAddress.postalZone}</div>
                <div className="review-confirm-instructions-phone">{this.props.mobileServicePhone}</div>
            </div> : null
        );

        return mobileServiceAddressText;

    }

    renderAddress(address) {
        let addressText;

        addressText = (<div>
                <div>{address.addressLine1}</div>
                <div>{address.cityName + ", " + address.countrySubentity + " " + address.postalZone}</div>
            </div>
        );
        return addressText;
    }

    renderPickupInstructions() {
        let instructions;

        if (!this.props.pickupAddress) {
            instructions = (
                <div className="review-confirm-customer-info-hidden">
                    {this.props.translations.CUSTOMER_INFO_PRIVACY}
                </div>
            );
        } else {

            const pickupAddress = this.props.serviceAddresses && this.props.serviceAddresses.length > 0 && this.props.serviceAddresses.filter((item)=>item.type ==="PICK_UP")[0]
            const dropoffAddress = this.props.serviceAddresses && this.props.serviceAddresses.length > 0 && this.props.serviceAddresses.filter((item)=>item.type ==="DROP_OFF")[0]

            instructions = (
                <div className="review-confirm-instructions">
                    <div className="review-confirm-instructions-pickup">
                        {this.props.addressIsSame
                            ? <div><b>{this.props.translations.LOCATION_COLON}</b></div>

                            : <div><b>{this.props.translations.PICKUP_LOCATION}</b></div>
                            }

                    </div>
                    <div>{pickupAddress ? this.renderAddress(pickupAddress) : this.props.pickupAddress}</div>
                    {!this.props.addressIsSame ? (
                        <div className="review-confirm-instructions-dropoff">
                            <div><b>{this.props.translations.DROPOFF_LOCATION}</b></div>
                            <div>{dropoffAddress ? this.renderAddress(dropoffAddress) : this.props.dropoffAddress}</div>
                        </div>
                    ) : null}
                    <div className="review-confirm-instructions-phone">
                        <div>
                            {this.props.validFlag === true
                                ? formatCustomerPhoneNumber(this.props.conciergePhoneNumber, this.props.vCountry)
                                : null}
                        </div>
                    </div>
                    {this.props.specialInstructions ? (
                        <div className="review-confirm-special-instructions">
                            <div><b>{this.props.translations.SPECIAL_INSTRUCTIONS_COLON}</b></div>
                            <div>{this.props.specialInstructions}</div>
                        </div>
                    ) : null}
                </div>
            );
        }

        return instructions;
    }

    render() {
        if (this.props.selectedDealer) {
            return (
                <div className="review-confirm-appointment-details paper">
                    <div className="review-confirm-appointment-details-title-container">
                        <div className="review-confirm-appointment-details-title headline3">
                            {this.props.translations.APP_DET}
                        </div>
                        {this.props.headerNavigation === constants.REVIEW ? (
                            <div className="review-confirm-appointment-details-edit hide-on-mobile">
                                <Link id="btnApptEdit" className="appointment-details-edit-link" to={{ pathname: '/Appointment' }}>
                                    {this.props.translations.EDIT}
                                </Link>
                            </div>
                        ) : null}
                    </div>

                    <div>{this.props.selectedAdvisor?.name ? this.renderAdvisor() : null}</div>


                    <div className="review-confirm-appointment-details-transport-option-selected bold">
                        {this.props.translations[this.props.selectedTransportOption.code]}
                    </div>

                    {this.props.selectedTransportOption ? (
                        <div className="review-confirm-appointment-transport">

                            <div className="review-confirm-appointment-dealer-disclaimer desc">
                                {
                                this.props.selectedTransportOption.code === appointmentConstants.MOBILE_SERVICE
                                    ? this.renderMobileServiceAddress()
                                    : null}
                                {
                                    this.props.selectedTransportOption.code === appointmentConstants.PREMIUM_CONCIERGE ||
                                    this.props.selectedTransportOption.code === appointmentConstants.PREMIUM_CONCIERGE_WITH_LOANER ||
                                    this.props.selectedTransportOption.code === appointmentConstants.CADILLAC_PREMIUM_CONCIERGE ?
                                        this.renderPickupInstructions() : null
                                }
                            </div>
                        </div>
                    ) : null}

                    <div className="review-service-details-container">
                        {this.props.selectedTransportOption ? (
                            <div className="service-details bold">
                                {this.props.selectedTransportOption.code === appointmentConstants.MOBILE_SERVICE
                                    ? this.props.translations.SERVICE + " " + this.props.translations.DETAILS : null}
                            </div>

                        ) : null}


                        <div className="service-details-technician">
                            {this.props.selectedTransportOption.code === appointmentConstants.MOBILE_SERVICE
                                ? this.props.translations.TECHNICIAN_COLON || "TECHNICIAN_COLON"
                                : null}
                        </div>
                        <div className="service-details-technician">
                            {this.props.selectedTransportOption.code === appointmentConstants.MOBILE_SERVICE
                                ? this.props.translations.TECHNICIAN_TEXT || "TECHNICIAN_TEXT"
                                : null}
                        </div>
                    </div>



                    <div className="review-confirm-appointment-details-dealer-info">
                        <div className="dealer-name">{this.props.selectedDealer.name}</div>
                        <div className="dealer-street-address">
                            {stringToTitleCase(this.props.selectedDealer.address.addressLine1)}
                        </div>
                        <div className="dealer-city-state-zip">
                            {stringToTitleCase(this.props.selectedDealer.address.cityName)}
                            {this.formatCityZipSpacing()}
                            {this.props.selectedDealer.address.countrySubentity}
                            {this.formatSubentityZipSpacing()}
                            {this.returnPostalZone()}
                        </div>
                        <a id="btnCallDealer" className="dealer-phone bold" href={`tel:${this.getPhoneNum()}`}>
                            {formatPhoneNum(this.getPhoneNum(), this.props.vCountry)}
                        </a>
                        <div className="review-confirm-appointment-details-map-button">
                            <a id="btnViewMap" href={this.routeToMap()} target={this.routeToMapTarget()}>
                                {this.props.translations.VIEW_MAP}
                            </a>
                        </div>
                    </div>



                    <div className="review-confirm-appointment-details-columns">
                        {this.props.headerNavigation === constants.REVIEW ? (
                            <div className="review-confirm-appointment-details-edit hide-on-desktop">
                                <Link className="appointment-details-edit-link" to={{ pathname: '/Appointment' }}>
                                    {this.props.translations.EDIT}
                                </Link>
                            </div>
                        ) : null}
                    </div>
                </div>
            );
        }
        return null;
    }
}

const mapStateToProps = (state) => ({
    addressIsSame: state.appointmentReducer.conciergeAddressIsSame,
    conciergePhoneNumber: state.appointmentReducer.conciergePhoneNumber,
    dropoffAddress: state.appointmentReducer.dropoffAddress,
    flowSource: state.prefillReducer.flowSource,
    headerNavigation: state.headReducer.headerNavigation,
    mobileView: state.prefillReducer.view,
    modifyFlag: state.appointmentReducer.modifyFlag,
    pickupAddress: state.appointmentReducer.pickupAddress,
    selectedAdvisor: state.appointmentReducer.advisor,
    selectedDate: state.appointmentReducer.selectedDate,
    selectedDealer: state.prefillReducer.dealerSummaryObject.dealershipSummary,
    selectedTransportOption: state.appointmentReducer.transportation,
    specialInstructions: state.appointmentReducer.conciergeComments,
    translations: state.countryOptionsReducer.translations,
    transportationOptions: state.appointmentReducer.transportationOptions,
    vCountry: state.prefillReducer.country,
    validFlag: state.appointmentReducer.validFlag,
    serviceAddresses: state.appointmentReducer.prevAppointmentObject && state.appointmentReducer.prevAppointmentObject.serviceAddresses,
    mobileServicePhone: state.mobileServiceReducer.mobileService?.mobileServicePhoneNumber
});

export default connect(mapStateToProps)(AppointmentDetails);
withRouter(AppointmentDetails);
