import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { addAdditionalComments } from '../../Redux/ActionCreator';

const constants = require('../../shared/Constants/SharedConstants');

class AdditionalComments extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: '',
            lengthRemaining: 300,
            maxLength: 300,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleOnFocus = this.handleOnFocus.bind(this);
        this.handleOnBlur = this.handleOnBlur.bind(this);
    }

    componentDidMount() {
        const { startingText } = this.props;
        let dropOffAddress;
        const MAX_LENGTH = 300;

        if (this.props.headerNavigation === constants.REVIEW) {
            if (this.props.reviewComments) {
                this.setState({
                    lengthRemaining: MAX_LENGTH - this.props.reviewComments.length,
                    value: this.props.reviewComments,
                });
            } else {
                this.setState({ lengthRemaining: MAX_LENGTH - startingText.length, value: startingText });
            }
        } else {
            this.setState({ lengthRemaining: MAX_LENGTH, value: this.props.reviewComments });
        }
    }

    handleChange(event) {
        const comments = event.target.value;
        event.target.value.length === 0
            ? this.setState({ value: '', lengthRemaining: this.state.maxLength })
            : this.setState({
                  value: comments,
                  lengthRemaining: this.state.maxLength - event.target.value.length,
              });
        this.props.addAdditionalComments(comments.trim());
    }

    handleOnFocus() {
        this.state.value === this.props.startingText
            ? this.setState({ value: '', lengthRemaining: this.state.maxLength })
            : null;
    }

    handleOnBlur(event) {
        const comments = event.target.value.replace(/[&\/\\#+()~%'"*?<>{}]/g, '').trim();
        event.target.value.length === 0
            ? this.setState({
                  value: this.props.startingText,
                  lengthRemaining: this.state.maxLength,
              })
            : this.setState({
                  value: comments,
                  lengthRemaining: this.state.maxLength - comments.length,
              });
        this.props.addAdditionalComments(comments);
    }

    render() {
        return (
            <form className="review-confirm-comments" onSubmit={this.handleSubmit}>
                {this.props.headerNavigation === constants.REVIEW ? (
                    <label className="review-label">
                        {this.props.label}
                        <textarea
                            id="txtAdditionalComments"
                            className="review-comments-box"
                            placeholder={this.state.value}
                            maxLength={this.state.maxLength}
                            type="text"
                            value={this.state.value}
                            onChange={this.handleChange}
                            onFocus={this.handleOnFocus}
                            onBlur={this.handleOnBlur}
                            data-dtm="contact information"
                        />
                        <div className="review-length-remaining">
                            {!this.props.reviewComments ? this.state.maxLength : this.state.lengthRemaining}{' '}
                            {this.props.vTranslations.CHAR_REM}
                        </div>
                    </label>
                ) : (
                    <div className="review-comments">{this.props.reviewComments}</div>
                )}
            </form>
        );
    }
}

const mapStateToProps = (state) => ({
    dropOffAddress: state.appointmentReducer.dropoffAddress,
    headerNavigation: state.headReducer.headerNavigation,
    pickUpAddress: state.appointmentReducer.pickupAddress,
    reviewComments: state.reviewReducer.comments,
    selectedTransportation: state.appointmentReducer.transportation.code,
    vTranslations: state.countryOptionsReducer.translations,
});

export default connect(mapStateToProps, { addAdditionalComments })(AdditionalComments);
withRouter(AdditionalComments);
