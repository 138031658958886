import React from 'react';
import { connect } from 'react-redux';
import SharedModal from '../../../shared/Modal/SharedModal';

class PrivacyPolicyPopup extends React.Component {
    render() {
        const privacyPolicyContent = (
            <div className="privacy-policy-content">
                <h2>{this.props.translations.PRIVACY_POLICY}</h2>
                <div
                    dangerouslySetInnerHTML={{
                        __html: this.props.translations.PRIVACY_STATEMENT,
                    }}
                />
            </div>
        );
        return (
            <div>
                <SharedModal
                    content={privacyPolicyContent}
                    handleClose={this.props.closePrivacyPopup}
                    show={this.props.showPrivacyPopup}
                    simpleModal
                />
            </div>
        );
    }
}
const mapStateToProps = (state) => ({
    translations: state.countryOptionsReducer.translations,
});

export default connect(mapStateToProps)(PrivacyPolicyPopup);
