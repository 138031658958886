import React from 'react';
import { connect } from 'react-redux';
import { setHideContactInfoFlag, setValidFlag } from '../../../Redux/ActionCreator';
import { EditAppointmentFlow } from '../../../shared/RoutingFlow/FlowComponentsConstants';
import Customer from './Customer';
import OptIn from './OptIn';

class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hideContactInfo: this.props.hideContactInfoFlag,
            isEditAppointmentFlow: this.props.flowSource === EditAppointmentFlow,
        };
    }

    setHideContactInfo = () => {
        this.setState({ hideContactInfo: false });
    };

    render() {
        return (
            <div className="appointment-contact-info-container">
                <div className="appointment-header headline2">
                    {this.state.isEditAppointmentFlow
                        ? this.props.translations.MODIFY_CONTACT_INFORMATION
                        : this.props.translations.CONTACT_INFORMATION}
                </div>
                <div className="appointment-headline-border headline-border" />
                <div className="appointment-contact-content">
                    {!this.state.hideContactInfo ? (
                        <>
                            <Customer customerPhoneNumber={this.props.customerPhoneNumber} />
                            <OptIn />
                        </>
                    ) : (
                        <div className="contact-info-hidden-container">
                            <div className="contact-info-hidden-statement">
                                {this.props.translations.CUSTOMER_INFO_PRIVACY}
                            </div>
                            <button
                                className="contact-info-hidden-edit-link link"
                                onClick={this.setHideContactInfo}
                                type="button"
                            >
                                {this.props.translations.EDIT_INFO}
                            </button>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    flowSource: state.prefillReducer.flowSource,
    hideContactInfoFlag: state.appointmentReducer.hideContactInfoFlag,
    translations: state.countryOptionsReducer.translations,
    validFlag: state.appointmentReducer.validFlag,
});

export default connect(mapStateToProps, {
    setHideContactInfoFlag,
    setValidFlag,
})(Contact);
