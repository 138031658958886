import EntryParams from '../../components/Util/EntryParams';
import OSSLandingPage from '../../components/Services/LegacyServices';
import Locator from '../../components/Locator/Locator';
import Appointment from '../../components/Appointment/Appointment';
import Confirmation from '../../components/Confirmation/Confirmation';
import SubmitAppointment from '../../components/Review/Review';
import DevelopEntryParams from '../../components/Util/DeveloperEntryParams';
import ErrorPage from '../../components/ErrorPage/ErrorPage';
import Vehicle from '../../components/Vehicle/Vehicle';
import Landing from '../../components/Login/Landing';
import SignIn from '../../components/Login/SignIn';

const routes = [
    {
        path: '/',
        component: EntryParams,
        title: 'ENTRY_PARAMS_START',
    },

    {
        path: '/Vehicle',
        component: Vehicle,
        title: 'VEHICLE',
    },

    {
        path: '/DevelopEntryParams',
        component: DevelopEntryParams,
        title: 'ENTRY_PARAMS_START_DEVELOP',
    },

    {
        path: '/index.ossUI.html',
        component: EntryParams,
        title: 'ENTRY_PARAMS_START',
    },

    {
        path: '/oss/o/d/*',
        component: EntryParams,
        title: 'ENTRY_PARAMS_START',
    },

    {
        path: '/OssLandingPage',
        component: OSSLandingPage,
        title: 'LABEL_TITLE_OSS_LANDING_PAGE',
    },

    {
        path: '/Locator',
        component: Locator,
        title: 'LABEL_TITLE_LOCATOR',
    },

    {
        path: '/Appointment',
        component: Appointment,
        title: 'LABEL_TITLE_APPOINTMENT',
    },

    {
        path: '/SubmitAppointment',
        component: SubmitAppointment,
        title: 'LABEL_TITLE_SUBMIT',
    },

    {
        path: '/Confirmation',
        component: Confirmation,
        title: 'LABEL_TITLE_CONFIRMATION',
    },

    {
        path: '/Landing',
        component: Landing,
        title: 'LABEL_TITLE_LANDING',
    },

    {
        path: '/SignIn',
        component: SignIn,
        title: 'LABEL_TITILE_SIGNIN',
    },

    {
        path: '/Error',
        component: ErrorPage,
        title: 'LABEL_GENERAL_ERROR',
    },
];

export default routes;
