import React from 'react';
import { connect } from 'react-redux';
import {
    populateSelectedServiceOffer,
    restoreSelectedServiceOffer,
    setChooseOneServiceOffer,
} from '../../Redux/ActionCreator';
import SharedModal from '../../shared/Modal/SharedModal';
import SharedRadioButton from '../../shared/RadioButton/SharedRadioButton';
import { renderCurrencyValue, renderPrice } from '../../shared/Utils/PriceLocalization';

class Offers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openSimpleModal: false,
            selected: false,
            selectedOffer: {},
        };

        this.handleOpenSimpleModal = this.handleOpenSimpleModal.bind(this);
        this.handleCloseSimpleModal = this.handleCloseSimpleModal.bind(this);
    }

    handleOpenSimpleModal = (event) => {
        this.setState({ openSimpleModal: true });
        event.stopPropagation();
        window.scrollTo(0, 0);
    };

    handleCloseSimpleModal = (event) => {
        this.props.restoreSelectedServiceOffer(this.props.serviceFEID);
        this.setState({ openSimpleModal: false });
        event.stopPropagation();
    };

    handleCloseOnApply() {
        this.setState({ openSimpleModal: false });
    }

    render() {
        let radioButton = [];

        const clickAction = () => {
            this.props.populateSelectedServiceOffer(this.state.selectedOffer);
            this.handleCloseOnApply();
        };

        const selectOilChangeOffer = (desc) => (e) => {
            const selectedOffer = {
                checked: e.target.checked,
                item: e.target.value,
                serviceID: this.props.serviceFEID,
                offerID: desc.id ? desc.id : desc.serviceFEID,
            };
            this.setState({ selectedOffer });

            this.props.setChooseOneServiceOffer(selectedOffer);

            this.setState({
                selected: true,
            });
        };

        const buttonSimpleModal = (
            <button
                id={"offersLink" + this.props.index}
                className="stat-button-link offer-modal-link link"
                onClick={this.handleOpenSimpleModal}
                onFocus={(event) => event.stopPropagation()}
                type="button"
                data-dtm={`service:${this.props.name}`}
            >
                {this.props.translations.OFFERS}
            </button>
        );

        const contentSimple = (
            <div className="offer-container">
                <div className="offer-headline">
                    <div className="offer-headline-text headline2">
                        {this.props.name}
                    </div>
                    <div className="headline-border" />
                </div>
                <div className="offer-table table" id="Offers">
                    {this.props.offers.map((desc, index) => {
                        radioButton = [
                            {
                                checked: desc.selectedDisplay,
                                className: 'form-check-input',
                                disabled: false,
                                handleClick: selectOilChangeOffer(desc),
                                handleOnChange: selectOilChangeOffer(desc),
                                id: "offers" + index,
                                label: desc.name,
                                value: desc.name,
                            },
                        ];
                        return (
                            <div className="offer-item paper" key={desc.couponCode}>
                                <div className="radio-button-label">
                                    <SharedRadioButton
                                        className="stat-radio offer-radio-button bold"
                                        radioButtonProps={radioButton}
                                    />
                                </div>
                                {desc.subtext ? (
                                    <div className="offer-subtitle">{desc.subtext}</div>
                                ) : (
                                    <div className="offer-subtitle">{desc.subtitle}</div>
                                )}
                                {desc.displayPrice && desc.displayPrice[0] ? renderPrice(desc.displayPrice[0]) : ''}
                                <div className="offer-description">{desc.description}</div>
                                <div className="offer-disclaimer">{desc.disclaimer}</div>
                            </div>
                        );
                    })}
                </div>
                <button
                    id="btnUpdateOffer"
                    className="stat-button-link add-offer-btn button primary-button"
                    disabled={!this.state.selected}
                    onClick={clickAction}
                    type="button"
                    data-dtm="service:offers"
                >
                    {this.props.translations.UPDATE}
                </button>
            </div>
        );

        return (
            <SharedModal
                backdrop="static"
                buttonToOpen={buttonSimpleModal}
                className="offers-modal"
                content={contentSimple}
                handleClose={this.handleCloseSimpleModal}
                keyboard={false}
                show={this.state.openSimpleModal}
                simpleModal
            />
        );
    }
}

const mapStateToProps = (state) => ({
    translations: state.countryOptionsReducer.translations,
});

export default connect(mapStateToProps, {
    populateSelectedServiceOffer,
    restoreSelectedServiceOffer,
    setChooseOneServiceOffer,
})(Offers);
