import React, { Component } from 'react';
import { connect } from 'react-redux';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import { dealersNameSearch } from '../../shared/BackendCalls/SharedBackendGets';
import { getGeoLocation } from './getDealerships';

const constants = require('../../shared/RoutingFlow/FlowComponentsConstants');

class NameSearchBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchValue: '',
            placeholderValue: this.props.vTranslations.DEALERSHIP_NAME,
        };
        this.handleChange = this.handleChange.bind(this);
        this.searchDealersByName = this.searchDealersByName.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
    }

    /**
     * @desc This function updates the state with the user's input after each key stroke
     */
    handleChange(event) {
        this.clearSearchValue();
        this.setState({ searchValue: event.target.value });
    }

    handleKeyPress(event) {
        if (event.key === 'Enter') this.searchDealersByName();
    }

    /**
     * @desc This function clears the placeholderValue back to empty string once the user has started typing
     */
    clearSearchValue() {
        this.setState({ placeholderValue: '' });
    }

    /**
     * @desc This function puts together the parameters for the dealer name search and fires it off
     */
    searchDealersByName = () => {
        const { country } = this.props;
        const lang = this.props.locale;
        let name;
        const { postalCode } = this.props;
        const { cityName } = this.props;
        const { bac } = this.props;

        if (this.state.searchValue === '') {
            this.props.showSearchBoxErrorTrue();
        } else {
            this.props.showSearchBoxErrorFalse();
            if (this.props.flowSource === constants.OssLocatorFlow) {
                this.props.showLocationIconAction();
                this.props.hideUseLocationButton();
                this.props.showListAndMap();
            }
            this.props.disableShowMore();
            name = this.state.searchValue;
            dealersNameSearch(country, lang, name, postalCode, cityName, bac);
        }
    };

    render() {
        return (
            <div>
                <div data-standalone-searchbox="">
                    <TextField
                        className={`stat-input-field dl-search-input${this.props.hide ? ' hide' : ''}`}
                        error={this.props.showSearchBoxError}
                        helperText={this.props.vTranslations.LOCATE_DEALER_NO_RESULTS}
                        InputProps={{
                            endAdornment: (
                                <>
                                    {this.props.showSearchBoxError ? (
                                        <InputAdornment position="end">
                                            <div className="icon error" />
                                        </InputAdornment>
                                    ) : null}
                                    {this.props.showLocationIcon ||
                                    this.props.flowSource !== constants.OssLocatorFlow ? (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => {
                                                    getGeoLocation();
                                                }}
                                            >
                                                <div className="icon location-airplane" />
                                            </IconButton>
                                        </InputAdornment>
                                    ) : null}
                                </>
                            ),
                            startAdornment: (
                                <InputAdornment position="start">
                                    <IconButton onClick={() => this.searchDealersByName()}>
                                        <div className="icon search" />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        inputProps={{
                            maxLength: '100',
                        }}
                        value={this.state.searchValue}
                        onChange={this.handleChange}
                        onKeyPress={this.handleKeyPress}
                        placeholder={this.state.placeholderValue}
                        type="text"
                        variant="filled"
                        data-dtm="search a dealer"
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    bac: state.prefillReducer.bac,
    postalCode: state.prefillReducer.postalCode,
    cityName: state.prefillReducer.city,
    locale: state.prefillReducer.locale,
    country: state.prefillReducer.country,
    vTranslations: state.countryOptionsReducer.translations,
    flowSource: state.prefillReducer.flowSource,
    hideUseLocationButton: state.locReducer.hideUseLocationButton,
    showListAndMap: state.locReducer.showListAndMap,
    showLocationIcon: state.locReducer.showLocationIcon,
    showSearchBoxError: state.locReducer.showSearchBoxError,
});

const mapDispatchToProps = (dispatch) => ({
    setRenderFlagFalse: () =>
        dispatch({
            type: 'SET_RENDER_FLAG_FALSE',
        }),
    setSearchFlag: () =>
        dispatch({
            type: 'SET_SEARCH_FLAG',
        }),
    disableShowMore: (nameSearch) =>
        dispatch({
            type: 'DISABLE_SHOW_MORE',
            payload: nameSearch,
        }),
    hideUseLocationButton: () =>
        dispatch({
            type: 'HIDE_USE_LOCATION_BUTTON',
        }),
    showListAndMap: () =>
        dispatch({
            type: 'SHOW_LIST_AND_MAP',
        }),
    showLocationIconAction: () =>
        dispatch({
            type: 'SHOW_LOCATION_ICON_TRUE',
        }),
    showSearchBoxErrorTrue: () =>
        dispatch({
            type: 'SHOW_SEARCH_BOX_ERROR_TRUE',
        }),

    showSearchBoxErrorFalse: () =>
        dispatch({
            type: 'SHOW_SEARCH_BOX_ERROR_FALSE',
        }),
});

export default connect(mapStateToProps, mapDispatchToProps)(NameSearchBox);
