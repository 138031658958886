import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
    setAppConfig,
    setAppGlobals,
    setCallCount,
    setPrefill,
    setPrefillHeader,
    setSpinner,
    setTranslations,
    setBrandsiteOrigin,
    setVehicleData,
} from '../../Redux/ActionCreator';
import {
    editAppointmentAxios,
    getAdvisorsTransportOptions,
    getAppGlobals,
    getCountryOptions,
    getSummaryBAC, getVehicleMakes, getVehicleModels, getVehiclePlus,
} from '../../shared/BackendCalls/SharedBackendGets';
import findNextFlow from '../../shared/RoutingFlow/RoutingFlow';
import { setupDateLocalization } from '../../shared/Utils/TimeLocalization';
import { detectRuntimeEnvironment } from '../../shared/Utils/Utils';
import { CSSRenderer } from '../../shared/Utils/CSSRenderer';

// Use the Shared Data Objects Constants
const constants = require('../../shared/RoutingFlow/FlowComponentsConstants');
const sharedConstants = require('../../shared/Constants/SharedConstants');
const localizationConstants = require('../../shared/Constants/LocalizationConstants');

class EntryParams extends Component {
    state = {
        showSpinner: true,
        actioncom: '',
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const { search } = location;

        let searchLocation = search;

        if (window.locationurl) {
            searchLocation = '?' + window.locationurl;
        }

        const parsedQuery = queryString.parse(searchLocation);

        location.queryArray = parsedQuery;

        if (detectRuntimeEnvironment()) {
            if (parsedQuery.developerEntry && this.onNiceList(window.location.hostname)) {
                this.props.history.push({
                    pathname: '/DevelopEntryParams',
                });
            } else {
                this.state.actioncom = sharedConstants.PREFILL;
                this.props.setPrefill(location);
                this.props.setAppConfig(location);
            }
        } else {
            this.props.history.push({
                pathname: '/Error',
            });
        }

        setupDateLocalization();
    }

    onNiceList = (hostname) => {
        let niceList = process.env.REACT_APP_NICE_LIST.split(',');
        let hostnameIndex = hostname.indexOf('.');
        let hostnameSubstring = hostname.substring(hostnameIndex + 1, hostname.length);

        return _.includes(niceList, hostnameSubstring);
    };

    redirectUsertoUnAuth(urlParams, bac, country) {
        window.location.href = `/oss/o/d/${country}/${bac}${urlParams}&LessClickFail=X`;
    }

    getOssGlobals() {
        this.state.actioncom = sharedConstants.STOP;
        // We only need to pre-run the call for Authenticated Flow and TireFinder
        if (
            this.props.vFlowSource === constants.AuthenticatedFlow ||
            this.props.vFlowSource === constants.TireFinderFlow
        ) {
            if (
                this.props.vBac &&
                this.props.vCountry &&
                (this.props.vVin || (this.props.vYear && this.props.vMake && this.props.vModel))
            ) {
                getAppGlobals(
                    this.props.vCountry,
                    this.props.vVin,
                    this.props.vBac,
                    this.props.vYear,
                    this.props.vMake,
                    this.props.vModel,
                    this.props.vLocale,
                    '',
                    this.props.vPlus,
                    this.props.vMileage,
                    this.props.vUnits,
                    this.props.record
                );

                this.handleGoToLandingPage();
            } else {
                // Send the user to next page
                this.handleGoToLandingPage();
            }
        } else if( this.props.vFlowSource === constants.AuthenticatedOCPlus){

            let Make  = this.props.vMake;
            let Model = this.props.vModel;

            if(this.props.vCountry === 'BR'){
                Make  = this.props.vMake.toUpperCase();
                Model = this.props.vModel.toUpperCase();
            }

            //Check to see if this AuthenticatedOCPlus flow prepopulate data
                this.props.setVehicleData({
                    key: 'selectedYear',
                    value: this.props.vYear,
                });
                this.props.setVehicleData({
                    key: 'selectedMake',
                    value: Make,
                });
                this.props.setVehicleData({
                    key: 'selectedModel',
                    value: Model,
                });
                this.props.setVehicleData({
                    key: 'selectedMotor',
                    value: this.props.vPlus,
                });

            getVehicleMakes(this.props.vCountry, this.props.vYear);
            getVehicleModels(
                this.props.vCountry,
                this.props.vYear,
                Make
            );
            getVehiclePlus(
                this.props.vCountry,
                this.props.vYear,
                Make,
                Model
            );

            this.handleGoToLandingPage();
        } else {
            // Send the user to next page
            this.handleGoToLandingPage();
        }
    }

    componentDidUpdate() {
        if (this.state.actioncom !== sharedConstants.STOP) {
            if (
                this.props.vActioncom === this.state.actioncom ||
                this.props.VCountryActioncom === this.state.actioncom
            ) {
                this.state.actioncom = '';

                if (this.props.vActioncom === sharedConstants.PREFILL && !this.props.VCountryActioncom) {
                    this.props.setPrefillHeader(this.props.vPrefill);
                    if (this.props.vFlowSource === constants.EditAppointmentFlow) {
                        this.state.actioncom = sharedConstants.SUMMBAC;
                        getSummaryBAC(this.props.vCountry, this.props.vBac);
                    } else {
                        setupDateLocalization();
                        this.state.actioncom = sharedConstants.COUNTRY;

                        let locale = this.props.vLocale;
                        if (this.props.vLangcd === localizationConstants.frCA) {
                            locale = this.props.vLangcd;
                        }
                        getCountryOptions(this.props.vCountry, locale, this.props.vFlowSource);
                    }
                }

                if (
                    this.props.VCountryActioncom === sharedConstants.COUNTRY &&
                    this.props.vActioncom === sharedConstants.PREFILL
                ) {
                    this.props.setTranslations(this.props.vTranslations);
                    if (this.props.vCountry && this.props.vBac) {
                        this.state.actioncom = sharedConstants.SUMMBAC;
                        getSummaryBAC(this.props.vCountry, this.props.vBac);
                    } else {
                        this.handleProceedDirectPage();
                    }

                    // CHECKS TO SEE IF THE ORIGIN IS A BRANDSITE ORIGIN
                    if (
                        this.props.vUltimateOrigin &&
                        this.props.vCountryOptions.BRAND_SITE_ORIGINS.includes(this.props.vUltimateOrigin)
                    ) {
                        this.props.setBrandsiteOrigin(true);
                    }
                }

                if (this.props.vActioncom === sharedConstants.SUMMBAC) {
                    if (this.props.vFlowSource === constants.EditAppointmentFlow) {
                        if(this.props.transportationCallComplete){
                            this.state.actioncom = sharedConstants.EDTCOM;
                            editAppointmentAxios(this.state.editAppointmentRecordLocator);
                        }else{
                            getAdvisorsTransportOptions();
                        }
                    } else {
                        this.props.setPrefillHeader(this.props.vPrefill);
                        this.handleProceedDirectPage();
                    }
                }

                if (
                    this.props.vActioncom === sharedConstants.EDTCOM &&
                    this.props.VCountryActioncom !== sharedConstants.COUNTRY_EDTCOM
                ) {
                    setupDateLocalization();
                    this.state.actioncom = sharedConstants.COUNTRY_EDTCOM;

                    let locale = this.props.vLocale;
                    if (this.props.vLangcd === localizationConstants.frCA) {
                        locale = this.props.vLangcd;
                    }
                    getCountryOptions(this.props.vCountry, locale, this.props.vFlowSource);
                }

                if (this.props.VCountryActioncom === sharedConstants.COUNTRY_EDTCOM) {
                    this.props.setTranslations(this.props.vTranslations);
                    this.handleProceedDirectPage();
                }
            }
        }
    }

    handleProceedDirectPage() {
        this.getOssGlobals();
    }

    handleGoToLandingPage() {
        const brand = this.props.dealershipSummary.brands;
        // If authenticated Flow and No theme
        if ((this.props.vFlowSource === constants.AuthenticatedFlow || this.props.vFlowSource === constants.AuthenticatedOCPlus) && !this.props.vThemeMake && this.props.vMake) {
            CSSRenderer(this.props.vMake);
        } else if (
            this.props.vThemeMake === '' &&
            brand &&
            brand.length === 1 &&
            (this.props.vFlowSource !== constants.AuthenticatedFlow || this.props.vFlowSource !== constants.AuthenticatedOCPlus)
        ) {
            // if the vThemeMake ='' , not Authenticatedflow, and brand === 1 call cssRender(brand)

            let brandTheme = brand[0];
            brandTheme = brandTheme[0].toUpperCase() + brandTheme.slice(1).toLowerCase();

            CSSRenderer(brandTheme);
        } else {
            CSSRenderer(this.props.vThemeMake);
        }

        this.props.history.push({
            pathname: this.props.vTranslations[findNextFlow(this.props.vFlowSource, 'EntryParam', 'Forward')],
        });
    }

    // Render the Page in the DOM
    render() {
        const loadingTextStyle = {
            position: 'absolute',
            width: '100%',
            top: '32%',
            display: 'inline',
            textAlign: 'center',
        };

        // let update = this.props.vAttempt;
        const div = (
            <>
                {this.state.showSpinner ? (
                    <div>
                        <h3 style={loadingTextStyle}>{this.props.vTranslations.PEGA_LOADING}</h3>
                        <CircularProgress />
                    </div>
                ) : (
                    <div />
                )}
            </>
        );
        return div;
    }
}

const mapStateToProps = (state) => ({
    vMake: state.prefillReducer.make,
    vYear: state.prefillReducer.year,
    vModel: state.prefillReducer.model,
    vPlus: state.prefillReducer.plus,
    vCallCount: state.ossAppGlobals.countPegaCurr,
    vCountry: state.prefillReducer.country,
    vVin: state.prefillReducer.vin,
    vBac: state.prefillReducer.bac,
    vMileage: state.prefillReducer.miles,
    VCountry: state.prefillReducer.country,
    vMaxPegaAttempt: state.prefillReducer.maxPegaAttempts,
    vUrlParamsString: state.prefillReducer.urlParamString,
    vTranslations: state.countryOptionsReducer.translations,
    vFlowSource: state.prefillReducer.flowSource,
    vGCIN: state.prefillReducer.gcin,
    vRequiredParams: state.prefillReducer.failRequiredParams,
    vActioncom: state.prefillReducer.actioncom,
    vEditAppointmentRecordLocator: state.prefillReducer.editAppointmentRecordLocator,
    vRecordLocator: state.prefillReducer.recordLocator,
    vLocale: state.prefillReducer.locale,
    vLangcd: state.prefillReducer.langCd,
    VCountryActioncom: state.countryOptionsReducer.actioncom,
    vPrefill: state.prefillReducer,
    vUnits: state.prefillReducer.units,
    newAppointmentFlag: state.confirmationReducer.newAppointmentFlag,
    record: state.reviewReducer.recordLocator
        ? state.reviewReducer.recordLocator
        : state.prefillReducer.editAppointmentRecordLocator,
    vThemeMake: state.prefillReducer.themeMake,
    vCountryOptions: state.countryOptionsReducer.countryOptions,
    vUltimateOrigin: state.prefillReducer.ultimateOrigin,
    dealershipSummary: state.prefillReducer.dealerSummaryObject.dealershipSummary,
    transportationCallComplete: state.appointmentReducer.transportationCallComplete
});

export default connect(mapStateToProps, {
    setAppConfig,
    setAppGlobals,
    setCallCount,
    setPrefill,
    setPrefillHeader,
    setSpinner,
    setTranslations,
    setBrandsiteOrigin,
    setVehicleData,
})(EntryParams);
withRouter(EntryParams);
