import * as React from "react";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import store from "../../Redux/Store";
import {setVanError} from "../../Redux/ActionCreator";
import SharedBasicModal from "../../shared/Modal/SharedBasicModal";



const VanErrorModal = ()=>{
    let history = useHistory();
    const {translations} = useSelector((state) => state.countryOptionsReducer);
    const {showVanError} = useSelector((state) => state.appointmentReducer);


    const handleClick=()=>{
        history.push('/Appointment');
        store.dispatch(setVanError('false'));

    }


    return <>
        <SharedBasicModal show={showVanError}
                          hideCloseIcon={true}
                          title={translations.APP_UNAVAILABLE_TITLE || 'APP_UNAVAILABLE_TITLE'}
                          bodyAsText={translations.MOBILE_SERVICE_VAN_ERROR || 'MOBILE_SERVICE_VAN_ERROR'}
                          primaryButton={translations.MOBILE_SERVICE_ERROR_RETRY || 'MOBILE_SERVICE_ERROR_RETRY'}
                          handleClick={handleClick}/>
    </>
}

export default VanErrorModal
