/**
 * @SAGA : Redux Saga middleware for side effects. Help for Redux async call.
 * @Enhancers: Connect your browser Redux Tool to your store. You must install Redux-DevTool as browser add in to make this to work
 * @Logger : Logs on browser console with Previous state, action and next state.
 */

/** * Importing all dependencies ** */
import { createStore, compose, applyMiddleware, combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import appointmentReducer from './AppointmentReducer';
import confirmationReducer from './confirmationReducer';
import CountryOptionsReducer from './CountryOptionsReducer';
import headerReducer from './headerReducer';
import locatorReducer from './locatorReducer';
import MyGarageReducer from './MyGarageReducer';
import MyRewardsReducer from './MyRewardsReducer';
import OssAppGlobalsReducer from './OssAppGlobalsReducer';
import prefillReducer from './prefillReducer';
import reviewReducer from './reviewReducer';
import rootSaga from './RootSaga';
import VehicleReducer from './VehicleReducer';
import SessionReducer from './SessionReducer'
import MobileServiceReducer from "./mobileServiceReducer";

/** * create the Saga Middleware ** */
const sagaMiddleware = createSagaMiddleware();

const middleware = [sagaMiddleware];

/** The combineReducers helper function turns an object whose values are different
 reducing functions into a single reducing function you can pass to createStore * */
export const rootReducer = combineReducers({
    // reducer goes here
    appointmentReducer,
    confirmationReducer,
    confReducer: confirmationReducer,
    countryOptionsReducer: CountryOptionsReducer,
    headReducer: headerReducer,
    locReducer: locatorReducer,
    myGarageReducer: MyGarageReducer,
    myRewardsReducer: MyRewardsReducer,
    ossAppGlobals: OssAppGlobalsReducer,
    prefillReducer,
    reviewReducer,
    vehicleReducer: VehicleReducer,
    sessionReducer: SessionReducer,
    mobileServiceReducer:MobileServiceReducer
});

/** * Enable Redux-Logger and @Enhancers only on dev mode * */
let store = '';
const env = process.env.NODE_ENV;

if (env === 'development') {
    const reduxLogger = require('redux-logger');
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    const loggerMiddleware = reduxLogger.createLogger();
    middleware.push(loggerMiddleware);
    store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middleware)));
} else {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middleware)));
}

/** run the saga * */
sagaMiddleware.run(rootSaga);

export default store;
