import React, { Component } from 'react';
import { connect } from 'react-redux';
import { rewardsRan, setIsBannerDisplayed } from '../../Redux/ActionCreator';
import { AuthenticatedFlow } from '../../shared/RoutingFlow/FlowComponentsConstants';
import {
    Buick,
    Cadillac,
    Chevrolet,
    CONFIRMATION,
    Crossbrand,
    DEALERSHIP_LOC,
    GMC,
    Gold,
    Platinum,
    Silver,
    myChevroletRewardsLink,
    myBuickRewardsLink,
    myGMCRewardsLink,
    myCadillacRewardsLink,
    ExperienceRewardsLink,
    notApplicable,
} from '../../shared/Constants/SharedConstants';
import { MyRewardsBackgroundRenderer } from './scss/MyRewardsBackgroundRenderer';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getMyRewardsDetails } from '../../shared/BackendCalls/SharedBackendGets';
import EnrollMyRewards from './EnrollMyRewards';
import LearnMoreMyRewards from './LearnMoreMyRewards';

export function setPreferredBrand(preferredBrand, themeMake, make, dealerBrands) {
    // Handle brand for un-auth/enrolled users and when preferred is not known
    if (preferredBrand === '') {
        if (themeMake !== '') {
            preferredBrand = themeMake;
        } else if (make !== '') {
            preferredBrand = make;
        } else if (dealerBrands && dealerBrands.length == 1) {
            const dealerBrand = dealerBrands[0];
            if (dealerBrand.toUpperCase() === Buick.toUpperCase()) {
                preferredBrand = Buick;
            } else if (dealerBrand.toUpperCase() === Cadillac.toUpperCase()) {
                preferredBrand = Cadillac;
            } else if (dealerBrand.toUpperCase() === Chevrolet.toUpperCase()) {
                preferredBrand = Chevrolet;
            } else if (dealerBrand.toUpperCase() === GMC.toUpperCase()) {
                preferredBrand = GMC;
            }
        }
    }
    return preferredBrand;
}


export function setBrandText(translations, preferredBrand, themeMake, make, dealerBrands) {
    let brandText;
    preferredBrand = setPreferredBrand(preferredBrand, themeMake, make, dealerBrands);
    switch (preferredBrand) {
        case Buick: {
            return (brandText = translations.MY_BUICK_REWARDS);
        }
        case Cadillac: {
            return (brandText = translations.MY_CADILLAC_REWARDS);
        }
        case Chevrolet: {
            return (brandText = translations.MY_CHEVROLET_REWARDS);
        }
        case GMC: {
            return (brandText = translations.MY_GMC_REWARDS);
        }
        case Crossbrand: {
            return (brandText = translations.MY_REWARDS);
        }
        default: {
            return (brandText = translations.MY_REWARDS);
        }
    }
}

export function setBrandLink(preferredBrand, themeMake, make, dealerBrands) {
    let brandLink;
    preferredBrand = setPreferredBrand(preferredBrand, themeMake, make, dealerBrands);

    switch (preferredBrand) {
        case Buick: {
            return (brandLink = myBuickRewardsLink);
        }
        case Cadillac: {
            return (brandLink = myCadillacRewardsLink);
        }
        case Chevrolet: {
            return (brandLink = myChevroletRewardsLink);
        }
        case GMC: {
            return (brandLink = myGMCRewardsLink);
        }
        case Crossbrand: {
            return (brandLink = ExperienceRewardsLink);
        }
        default: {
            return (brandLink = ExperienceRewardsLink);
        }
    }
}

class MyRewards extends Component {
    constructor(props) {
        super(props);
    }

    // Function to return tier user is enrolled in
    setTier() {
        let { translations, tierName } = this.props;
        let tier;
        switch (tierName) {
            case Silver: {
                return (tier = translations.SILVER_TIER_MEMBER);
            }
            case Gold: {
                return (tier = translations.GOLD_TIER_MEMBER);
            }
            case Platinum: {
                return (tier = translations.PLATINUM_TIER_MEMBER);
            }
            default: {
                return (tier = '');
            }
        }
    }

    // Function returns formatted points in its own container
    redeemable() {
        let { translations, redeemablePointsDollarValue, totalPoints } = this.props;
        return (
            <div className="my-rewards-redeemable-container">
                <div className="my-rewards-points">
                    {totalPoints.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                </div>
                <div className="my-rewards-redeemable-points">
                    <div className="my-rewards-redeemable">{translations.REDEEMABLE_POINTS.toUpperCase()}</div>
                    <div className="my-rewards-cash-value">
                        {'$' + redeemablePointsDollarValue.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                    </div>
                </div>
            </div>
        );
    }

    // Function returns users name; use preferred first name if it exists, else use regular name
    setFirstName() {
        let { preferredFirstName, firstName, customerFirstName } = this.props;
        let name;
        if (preferredFirstName !== '') {
            name = preferredFirstName;
        } else {
            if (firstName !== '') {
                name = firstName;
            } else {
                name = customerFirstName;
            }
        }
        return name;
    }

    render() {
        let {
            brands,
            translations,
            bac,
            customerEmail,
            email,
            getLoyalty,
            headerNav,
            softEnroll,
            loyaltyError,
            preferredBrand,
            active,
            nav,
            themeMake,
            make,
            gpmsProfileId,
            getLoyaltyError,
        } = this.props;
        let div = null;

        if (getLoyalty === 'true' && headerNav !== '' && headerNav !== DEALERSHIP_LOC && gpmsProfileId !== "") {
            // Check for soft enroll error, if true -> skip all reprocessing attempts
            if (!softEnroll) {
                // If the saved Nav is different than current nav - new page, try executing again for error flow
                if (nav !== headerNav && (getLoyaltyError === undefined || getLoyaltyError === true)) {
                    this.props.rewardsRan(headerNav);
                    getMyRewardsDetails(bac, gpmsProfileId);
                }
            }
            if (!softEnroll && !loyaltyError && active) {
                // good result, show loyalty
                this.props.setIsBannerDisplayed(true);
                div = (
                    <>
                        {this.props.showSpinner ? (
                            <div>
                                <CircularProgress />
                            </div>
                        ) : (
                            <div
                                className="my-rewards"
                                style={{
                                    backgroundImage: `url(${MyRewardsBackgroundRenderer(
                                        this.props.preferredBrand,
                                        this.props.tierName
                                    )})`,
                                }}
                            >
                                <div className={`my-rewards-container ${preferredBrand}`}>
                                    <div className="my-rewards-title">
                                        {setBrandText(translations, preferredBrand, themeMake, make, brands).toUpperCase()}
                                    </div>
                                    <div
                                        className={'my-rewards-tier-headline ' + this.props.tierName.toLowerCase()}
                                        id="tierHeadline"
                                    >
                                        {this.setTier().toUpperCase()}
                                    </div>
                                    <div className="my-rewards-row">
                                        {this.redeemable()}
                                        <div className="my-rewards-welcome">
                                            {translations.WELCOME_COMMA + ' ' + this.setFirstName()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </>
                );
            } else if (
                softEnroll === true &&
                this.props.headerNav === CONFIRMATION &&
                gpmsProfileId &&
                this.props.modifyFlag === false
            ) {
                // user not found, ask to enroll
                div = <EnrollMyRewards />;
                this.props.setIsBannerDisplayed(true);
            } else if (
                (this.props.headerNav === CONFIRMATION &&
                    this.props.flowSource !== AuthenticatedFlow &&
                    this.props.useMyRewardsIndicator === notApplicable &&
                    this.props.active !== null &&
                    this.props.active !== false &&
                    this.props.modifyFlag === false) ||
                (this.props.headerNav === CONFIRMATION &&
                    this.props.flowSource === AuthenticatedFlow &&
                    gpmsProfileId === '' &&
                    this.props.modifyFlag === false)
            ) {
                div = <LearnMoreMyRewards />;
                this.props.setIsBannerDisplayed(true);
            } else {
                this.props.setIsBannerDisplayed(false);
            }
        }

        return div;
    }
}

const mapStateToProps = (state) => ({
    tries: state.myRewardsReducer.tries,
    brands: state.prefillReducer.dealerSummaryObject.dealershipSummary.brands,
    translations: state.countryOptionsReducer.translations,
    getLoyalty: state.countryOptionsReducer.countryOptions.GET_LOYALTY, // Flag determines whether to show or hide loyalty
    bac: state.prefillReducer.bac,
    email: state.prefillReducer.email,
    softEnroll: state.myRewardsReducer.softEnroll,
    loyaltyError: state.myRewardsReducer.loyaltyError,
    headerNav: state.headReducer.headerNavigation,
    firstName: state.prefillReducer.firstName,
    customerFirstName: state.appointmentReducer.customerFirstName,

    showSpinner: state.myRewardsReducer.showSpinner,
    backgroundImage: state.myRewardsReducer.backgroundImage,

    active: state.myRewardsReducer.active,
    memberNumber: state.myRewardsReducer.memberNumber,
    preferredBrand: state.myRewardsReducer.preferredBrand,
    preferredFirstName: state.myRewardsReducer.preferredFirstName,
    redeemEligible: state.myRewardsReducer.redeemEligible,
    redeemablePoints: state.myRewardsReducer.redeemablePoints,
    redeemablePointsDollarValue: state.myRewardsReducer.redeemablePointsDollarValue,
    tierName: state.myRewardsReducer.tierName,
    totalPoints: state.myRewardsReducer.totalPoints,
    nav: state.myRewardsReducer.nav,
    themeMake: state.prefillReducer.themeMake,
    make: state.prefillReducer.make,
    profileId: state.myGarageReducer.profileId,

    customerEmail: state.appointmentReducer.customerEmail,
    flowSource: state.prefillReducer.flowSource,
    gpmsProfileId: state.prefillReducer.gpmsProfileId,
    useMyRewardsIndicator: state.prefillReducer.useMyRewardsIndicator,
    getLoyaltyError: state.myRewardsReducer.getLoyaltyError,
    modifyFlag: state.appointmentReducer.modifyFlag,
});

export default connect(mapStateToProps, { rewardsRan, setIsBannerDisplayed })(MyRewards);
