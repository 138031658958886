import React from 'react';
import { connect } from 'react-redux';
import SharedModal from '../../../shared/Modal/SharedModal';
import Dealership from './Dealership'


class DealerInfoPopup extends React.Component {
    constructor() {
        super();
    }

    render() {
        const dealerInfoContent = (
            <>
                <div className="dealership-headline">
                    <h2 className="dealership-headline-text">{this.props.vTranslations.DEALER_INFO}</h2>
                    <div className="headline-border" />
                </div>
                <Dealership />
                <button
                    id="btnCloseDealerInfo"
                    type='button'
                    onClick={this.props.closeDealerInfoPopup}
                    className="stat-button-close submit-button button primary-button"
                > {this.props.vTranslations.CLOSE} </button>
            </>
        );



        return (

            <SharedModal
                content={dealerInfoContent}
                handleClose={this.props.closeDealerInfoPopup}
                show={this.props.showDealerInfoPopup}
                simpleModal
                backdrop="static"
                keyboard={false}
            />

        );
    }
}

const mapStateToProps = (state) => ({
    vMiles: state.prefillReducer.miles,
    vTranslations: state.countryOptionsReducer.translations,
});

export default connect(mapStateToProps)(DealerInfoPopup);