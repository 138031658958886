import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import dayjs from 'dayjs';
import React from 'react';
import { setAppointmentDate, setShowTimesFlag, setStartDate, setTimeIndex } from '../../../Redux/ActionCreator';
import { getAppointments } from '../../../shared/BackendCalls/SharedBackendGets';
import store from '../../../Redux/Store';

const customParseFormat = require('dayjs/plugin/customParseFormat');

dayjs.extend(customParseFormat);

class Day extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            touchstartX: 0,
        };

        this.handleTap = this.handleTap.bind(this);
        this.handleTouchMove = this.handleTouchMove.bind(this);
        this.handleTouchStart = this.handleTouchStart.bind(this);
    }

    componentDidMount() {
        const s = document.getElementById('swiper');
        if (s != null) {
            s.addEventListener('touchend', this.handleTap, false);
            s.addEventListener('touchmove', this.handleTouchMove, false);
            s.addEventListener('touchstart', this.handleTouchStart, false);
        }
    }

    /**
     * Record site of first touch event
     * @param {*} event
     */
    handleTouchStart(event) {
        this.setState({ touchstartX: event.changedTouches[0].screenX });
    }

    /**
     * Determine if swipe is left or right
     * @param {*} event
     */
    handleTouchMove(event) {
        const touchEndX = event.changedTouches[0].screenX;

        if (touchEndX < this.state.touchstartX - 100) {
            this.getNextAvailableDates();
        } else if (touchEndX > this.state.touchstartX + 100) {
            this.getPrevAvailableDates();
        }
    }

    handleTap() {
        return false;
    }

    /**
     * Set selected date
     * @param {*} dateItem - Object with date/selected/availability info
     * @param {*} index - int of selected index
     */
    setDate(dateItem, index) {
        store.dispatch(setShowTimesFlag('true'));
        store.dispatch(setTimeIndex(1));

        if (dateItem.isAvailable) {
            store.dispatch(setAppointmentDate(this.props.availableDates[index].time[0]));
        } else {
            store.dispatch(setAppointmentDate('null'));
            store.dispatch(setShowTimesFlag('false'));
        }
    }

    getDateList() {
        const dateList = [];
        let selected = false;
        const { selectedDate } = this.props;

        this.props.availableDates.forEach((date) => {
            const available = date.time.length > 0;
            selected = available && dayjs(date.date).isSame(selectedDate, 'day');
            const dateItem = {
                date: date.date,
                isAvailable: available,
                isSelected: selected,
            };

            if (selected) {
                store.dispatch(setShowTimesFlag('true'));
            }

            dateList.push(dateItem);
        });

        return dateList;
    }

    /**
     * @desc Get next num of days to display
     */
    getNextAvailableDates() {
        const startDate = dayjs(this.props.startDate).add(this.props.numDaysToDisplay, 'day');

        // Update store
        store.dispatch(setShowTimesFlag('false'));
        store.dispatch(setStartDate(startDate));

        getAppointments(startDate);

        this.renderDateList();
    }

    /**
     * @desc Get prev num of days to display
     */
    getPrevAvailableDates() {
        if (dayjs().isBefore(this.props.startDate)) {
            let startDate = dayjs(this.props.startDate).subtract(this.props.numDaysToDisplay, 'day');

            if (dayjs().isAfter(startDate)) {
                startDate = dayjs();
            }

            store.dispatch(setStartDate(startDate));
            store.dispatch(setShowTimesFlag('false'));

            getAppointments(startDate);
            this.renderDateList();
        }
    }

    /**
     * @desc Check if the dates requested are greater than the max lookahead date
     * @return boolean
     */
    greaterThanLookaheadDate() {
        const isSameOrAfter = require('dayjs/plugin/isSameOrAfter');
        const maxLookahead = parseInt(this.props.countryOptions.MAXIMUM_APPOINTMENT_LOOKAHEAD);
        let greaterThanLookaheadDate = false;
        dayjs.extend(isSameOrAfter);

        const maxLookaheadDate = dayjs().add(maxLookahead, 'day').subtract(this.props.numDaysToDisplay, 'day');

        if (this.props.startDate.add(this.props.numDaysToDisplay + 1, 'day').isSameOrAfter(maxLookaheadDate, 'day')) {
            greaterThanLookaheadDate = true;
        }

        return greaterThanLookaheadDate;
    }

    /**
     * @desc Render list of dates
     * @return a list of elements which display date information
     */
    renderDateList() {
        if (this.props.availableDates) {
            const dateList = this.getDateList();

            return dateList.map((dateItem, index) => {
                const { date, isAvailable, isSelected } = dateItem;
                return (
                    <div key={dayjs(date).format()} className="appointment-day">
                        <div className="appointment-day-of-week">
                            {dayjs(date).format('dddd').slice(0, 1).toUpperCase()}
                        </div>
                        <form
                            className={`appointment-day-of-month${!isAvailable ? ' disabled primary-button' : ' '}${
                                isSelected ? ' selected primary-button' : isAvailable ? ' secondary-button' : ''
                            }`}
                            onClick={() => {
                                this.setDate(dateItem, index);
                            }}
                            id="swiper"
                            value={date}
                            onTouchEnd={this.handleTap}
                        >
                            {dayjs(date).format('D')}
                        </form>
                    </div>
                );
            });
        }
    }

    getEndDate() {
        return dayjs(this.props.startDate).add(this.props.numDaysToDisplay - 1, 'day');
    }

    render() {
        return (
            <>
                {this.props.vshowSpinner || this.props.loadingFlag ? (
                    <div className="spinner-container">
                        <CircularProgress />
                    </div>
                ) : (
                    <div className="appointment-days-container">
                        <div className="appointment-days-selector">
                            <div
                                id="btnLastWeek"
                                className={`prev-btn icon arrow-left${
                                    this.props.startDate.isBefore(dayjs()) ? '-disabled disabled' : ''
                                }`}
                                onClick={this.getPrevAvailableDates.bind(this)}
                            />
                            <div
                                className="appointment-list"
                                id="swiper"
                                onTouchMove={this.handleTouchMove}
                                onTouchStart={this.handleTouchStart}
                            >
                                {this.props.startDate && this.renderDateList()}
                            </div>
                            <div
                                id="btnNextWeek"
                                className={`next-btn icon arrow-right${
                                    this.greaterThanLookaheadDate() ? '-disabled disabled' : ''
                                }`}
                                onClick={this.getNextAvailableDates.bind(this)}
                            />
                        </div>
                    </div>
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    availableDates: state.appointmentReducer.availableDates,
    countryOptions: state.countryOptionsReducer.countryOptions,
    numDaysToDisplay: state.appointmentReducer.numDaysToDisplay,
    selectedDate: state.appointmentReducer.selectedDate,
    startDate: state.appointmentReducer.startDate,
    vshowSpinner: state.ossAppGlobals.showSpinner,
    loadingFlag: state.appointmentReducer.loadingFlag,
});

export default connect(mapStateToProps, {
    setAppointmentDate,
    setShowTimesFlag,
    setStartDate,
    setTimeIndex,
})(Day);
