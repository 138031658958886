import {
    SET_DELETE_APPOINTMENT_FLAG,
    SET_SHOW_CANCEL_BUTTONS_FLAG,
    SET_NEW_APPOINTMENT_FLAG,
    SET_EDIT_FLAG_ANALYTICS,
    SET_STAR_RATING,
    SET_FEEDBACK_REASON,
    SET_CANCEL_ERROR,
} from './ActionTypes';

const initialState = {
    deleteAppointmentFlag: false,
    showCancelButtonsFlag: false,
    newAppointmentFlag: false,
    editFlagAnalytics: '',
    starRating: 0,
    selectedReason: '',
    cancelError: false,
};

const confirmationReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DELETE_APPOINTMENT_FLAG: {
            return { ...state, deleteAppointmentFlag: action.payload };
        }
        case SET_SHOW_CANCEL_BUTTONS_FLAG: {
            return { ...state, showCancelButtonsFlag: action.payload };
        }
        case SET_NEW_APPOINTMENT_FLAG: {
            return { ...state, newAppointmentFlag: action.payload };
        }
        case SET_EDIT_FLAG_ANALYTICS: {
            return { ...state, editFlagAnalytics: action.payload };
        }
        case SET_STAR_RATING: {
            return { ...state, starRating: action.payload };
        }
        case SET_FEEDBACK_REASON: {
            return { ...state, selectedReason: action.payload };
        }
        case SET_CANCEL_ERROR: {
            return { ...state, cancelError: action.payload };
        }
        default:
            return state;
    }
};

export default confirmationReducer;
