import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Car from './Car';
import './MyGarage.scss';
import { setVehicleData, setGarageVin, setVehicleSelected } from '../../Redux/ActionCreator';
import { getAppGlobals } from '../../shared/BackendCalls/SharedBackendGets';

class MyGarage extends Component {
    setSelectedVehicle(vehicles, selectedIndex) {
        if (vehicles[selectedIndex].vin !== null) {
            this.props.setGarageVin(vehicles[selectedIndex].vin);
        }
        this.props.setVehicleData({
            key: 'selectedMake',
            value: vehicles[selectedIndex].make,
        });

        // Save Model
        this.props.setVehicleData({
            key: 'selectedModel',
            value: vehicles[selectedIndex].model,
        });

        // Save Year
        this.props.setVehicleData({
            key: 'selectedYear',
            value: vehicles[selectedIndex].year,
        });

        // Sets a flag to tell the services page it needs to go back to the
        this.props.setVehicleSelected(1);

        getAppGlobals(
            this.props.vCountry,
            vehicles[selectedIndex].vin,
            this.props.dealerSummary.dealershipSummary.bac,
            vehicles[selectedIndex].year,
            vehicles[selectedIndex].make,
            vehicles[selectedIndex].model,
            this.props.vLocale,
            '',
            this.props.vPlus,
            this.props.vehicleMiles,
            this.props.vUnits,
            this.props.record
        );
    }

    // Resets vehicle data in case the user selected a vehicle then decided to go back and fill out YMM
    clearVehicleData() {
        this.props.setGarageVin('');
        this.props.setVehicleData({
            key: 'selectedMake',
            value: '',
        });
        this.props.setVehicleData({
            key: 'selectedModel',
            value: '',
        });
        this.props.setVehicleData({
            key: 'selectedYear',
            value: '',
        });

        // Sets a flag to determine if the YMM page needs to come back to garage page
        this.props.setVehicleSelected(2);
    }

    render() {
        let { firstName, vehicles, translations, selectedIndex } = this.props;
        return (
            <div className="my-garage-container">
                <div className="my-garage-headline headline2">{firstName + translations.USERS_GARAGE}</div>
                <div className="garage-headline-border headline-border" />
                <Car />
                <Grid container spacing={3} className="footer-buttons">
                    <Grid item xs="auto">
                        <Link id="btnNoVehicle" className="button secondary-button" to="/Vehicle" onClick={() => this.clearVehicleData()}>
                            {translations.DO_NOT_SEE_VEHICLE}
                        </Link>
                    </Grid>
                    <Grid item xs="auto">
                        <Link
                            id="btnContinue"
                            className="button primary-button"
                            onClick={() => this.setSelectedVehicle(vehicles, selectedIndex)}
                            to="/OssLandingPage"
                        >
                            {translations.CONTINUE}
                        </Link>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    translations: state.countryOptionsReducer.translations,
    vehicles: state.myGarageReducer.vehicles,
    firstName: state.myGarageReducer.firstName,
    selectedIndex: state.myGarageReducer.selectedIndex,
    dealerSummary: state.prefillReducer.dealerSummaryObject,
    vLocale: state.prefillReducer.locale,
    vPlus: state.vehicleReducer.selectedMotor,
    vehicleMiles: state.vehicleReducer.selectedMileage,
    vUnits: state.prefillReducer.units,
    vCountry: state.prefillReducer.country,
    record: state.reviewReducer.recordLocator
        ? state.reviewReducer.recordLocator
        : state.prefillReducer.editAppointmentRecordLocator,
});

export default connect(mapStateToProps, { setVehicleData, setGarageVin, setVehicleSelected })(MyGarage);
