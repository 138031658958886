export const SERVICE_QUERY_WITH_VIN = (bac,vin,locale,mileage) => `
 {
  getAllLineItems(
    bac: "${bac}"
    vin: "${vin}"
    locale: "${locale}"
    mileage: "${mileage}"
  ) {
    recommended {
      name
      code
      glc
      couponCode
      lineItemType
      lineItemTypePlus
      namePrefix
      safetyRisk
      remedy
      price {
        price
        tagLine
        disclaimerNumeric
        disclaimerTagLine
      }
      description
      disclaimer
      displayOrder
      offers {
        name
        code
        glc
        couponCode
        lineItemType
        lineItemTypePlus
        namePrefix
        price {
          price
          tagLine
          disclaimerNumeric
          disclaimerTagLine
        }
        description
        disclaimer
        displayOrder
      }
      maintenances
    }
    additional {
      name
      code
      glc
      couponCode
      lineItemType
      lineItemTypePlus
      namePrefix
      price {
        price
        tagLine
        disclaimerNumeric
        disclaimerTagLine
      }
      description
      disclaimer
      displayOrder
      offers {
        name
        code
        glc
        couponCode
        lineItemType
        lineItemTypePlus
        namePrefix
        price {
          price
          tagLine
          disclaimerNumeric
          disclaimerTagLine
        }
        description
        disclaimer
        displayOrder
      }
      maintenances
    }
  }
}
`;

// queries.js

export const GET_SERVICE_WITHOUT_VIN_QUERY = (bac, locale) => `
  {
    getDealerConfigEnabledServicesAndOffers(bac: "${bac}", locale: "${locale}") {
      name
      code
      glc
      couponCode
      lineItemType
      lineItemTypePlus
      namePrefix
      price {
        price
        tagLine
        disclaimerNumeric
        disclaimerTagLine
      }
      description
      disclaimer
      displayOrder
      offers {
        name
        code
        glc
        couponCode
        lineItemType
        lineItemTypePlus
        namePrefix
        price {
          price
          tagLine
          disclaimerNumeric
          disclaimerTagLine
        }
        description
        disclaimer
        displayOrder
      }
      maintenances
    }
  }
`;

