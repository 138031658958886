import {useSelector} from "react-redux";
import SharedBasicModal from "../../shared/Modal/SharedBasicModal";
import React from "react";
import {Box} from "@material-ui/core";
import {setMobileServiceNotAvailable} from "../../Redux/ActionCreator";
import store from "../../Redux/Store";
import {useHistory} from "react-router-dom";




const MobileServiceNotAvailable = ()=> {
    let history = useHistory();
    const {translations} = useSelector((state) => state.countryOptionsReducer);
    const {displayMobileServiceNotAvailable,mobileServiceNextPage} = useSelector((state) => state.mobileServiceReducer);

    const bodyContent = <Box className="mobile-service-not-available" marginBottom={4}>
        <div align="center">
            <i className="icon alert-caution-yellow" />
            {translations.MOBILE_SERVICE_ACK_UNSUPPORTED || "MOBILE_SERVICE_ACK_UNSUPPORTED"}
        </div>
    </Box>

    return <>
        <SharedBasicModal
            show={displayMobileServiceNotAvailable}
            onClose={()=>{store.dispatch(setMobileServiceNotAvailable(false))}}
            handleClick={()=>{store.dispatch(setMobileServiceNotAvailable(false))}}
            handleSecondaryButtonClick={()=> {
                store.dispatch(setMobileServiceNotAvailable(false))
                history.push(mobileServiceNextPage);
            }}
            title={translations.MOBILE_SERVICE_UNSUP_TITLE || "MOBILE_SERVICE_UNSUP_TITLE"}
            bodyAsText={bodyContent}
            primaryButton={translations.MOBILE_SERVICE_ACK_BUTTON_CHANGE || "MOBILE_SERVICE_ACK_BUTTON_CHANGE"}
            secondaryButton={translations.MOBILE_SERVICE_ACK_BUTTON_SCHEDULE || "MOBILE_SERVICE_ACK_BUTTON_SCHEDULE"}

        />
    </>

}



export default MobileServiceNotAvailable