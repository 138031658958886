import store from '../../Redux/Store';

const constants = require('../../shared/RoutingFlow/FlowComponentsConstants');
const localizationConstants = require('../../shared/Constants/LocalizationConstants');

export function getAddress(dealer) {
    const localStore = store.getState();
    const { address } = dealer;

    let { postalZone } = address;
    if (localStore.prefillReducer.country === localizationConstants.US) {
        postalZone = postalZone.slice(0, 5);
    }

    const { addressLine1 } = address;
    const { addressLine2 } = address;
    const { cityName } = address;
    const { countrySubentity } = address;
    const pt1 = [addressLine1, addressLine2, cityName].filter(Boolean).join(' ');
    const branchDealerAddress = [pt1, postalZone].filter(Boolean).join(', ');
    const branchDealerAddressDisplay = branchDealerAddress.replace(', , ', '');

    const pt2 = [addressLine1, cityName].filter(Boolean).join(' ');
    const dealerAddress = [pt2, countrySubentity, postalZone].filter(Boolean).join(', ');
    const dealerAddressDisplay = dealerAddress.replace(', , ', '');

    if (localStore.locReducer.branchFlow && localStore.prefillReducer.flowSource === constants.OssLocatorFlow) {
        return branchDealerAddressDisplay;
    }
    return dealerAddressDisplay;
}
