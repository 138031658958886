

import * as React from 'react';
import {Box,Typography,Button} from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import useSharedBasicModalStyle from './SharedBasicModal.style';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";




export default function SharedBasicModal({show,title,bodyAsText,primaryButton,secondaryButton,handleClick,handleSecondaryButtonClick,hideCloseIcon,onClose,disabledPrimaryButton,addScroller, buttonAsLink, linkUrl}) {
     const classes = useSharedBasicModalStyle();
     const scrollerClass = addScroller ? classes.addScroller : "";

     return (
        <div>
            <Modal open={show}>
                <Box className={`${classes.model} ${scrollerClass}`}>
                    {
                        !hideCloseIcon &&  <Box textAlign="right">
                            <IconButton onClick={onClose}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    }
                    {
                      title &&   <Typography id="modal-modal-title" variant="h6" component="h2">
                            {title}
                            <div className="headline-border"/>
                        </Typography>
                    }

                    {
                        bodyAsText &&  <Box id="modal-modal-description"  marginTop={3} marginBottom={3} >{bodyAsText}</Box>
                    }


                    {
                        (primaryButton || secondaryButton) &&   <Box align="left" >
                            {primaryButton && <button className="primary-button" onClick={handleClick} disabled={disabledPrimaryButton}>{primaryButton}</button>}
                            {secondaryButton && <button className="secondary-button" onClick={handleSecondaryButtonClick}>{secondaryButton}</button>}

                         </Box>
                    }
                    {
                        (buttonAsLink) && <Box align="center">
                            <br/>
                            {buttonAsLink && <a href={linkUrl} target="_blank">{buttonAsLink}</a>}
                        </Box>
                    }



                </Box>
            </Modal>
        </div>
    );
}