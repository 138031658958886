import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import $ from 'jquery';
import Disclaimer from './Disclaimer';
import CustomerInfo from './CustomerInfo';
import SelectedServices from './SelectedServices';
import AppointmentDetails from './AppointmentDetails';
import DateAndTime from './DateAndTime';
import AnalyticsService from '../../shared/Utils/analytics.service';
import { adobeAnalytics } from '../../shared/Utils/Utils';
import { setModifyEmpty, submitAppointment } from '../../shared/BackendCalls/SharedBackendGets';
import { EditAppointmentFlow } from '../../shared/RoutingFlow/FlowComponentsConstants';
import NoTimeSlotErrorModal from "./NoTimeSlotErrorModal";
import VanErrorModal from "./VanErrorModal";
import OnTracErrorOnSubmission from "./OnTracErrorOnSubmmission";

const constants = require('../../shared/Constants/SharedConstants');

class SubmitAppointment extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        // Initialize the Analytics Functionality if Appropriate
        if (AnalyticsService().loggingEnabled) {
            // Append the Analytics Object to the DOM
            $('body').append(adobeAnalytics('form-step'));
            console.log('Analytics Added for Page to DOM: Submit Appointment');
        }
    }

    render() {
        window.scrollTo(0, 0);
        return (
            <div className="review-confirm-container">
                <div className="review-header-container">
                    <div className="review-title-container">
                        <div className="review-confirm-title headline2">{this.props.vTranslations.REVIEW_APP}</div>
                        <div className="review-confirm-subtitle headline4">
                            {this.props.vTranslations.REVIEW_APP_DETAILS}
                        </div>
                        <div className="review-confirm-headline-border headline-border " />
                    </div>
                </div>
                <div className="review-confirm-grid">
                    <DateAndTime />
                    <SelectedServices />
                    <AppointmentDetails />
                    <CustomerInfo />
                    <Disclaimer />
                </div>
                <NoTimeSlotErrorModal/>
                <OnTracErrorOnSubmission/>
                <VanErrorModal/>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    vTranslations: state.countryOptionsReducer.translations,
    flowSource: state.prefillReducer.flowSource,
    modifyFlag: state.appointmentReducer.modifyFlag,
    footerButtonLink: state.headReducer.footerButtonLink,
});
export default connect(mapStateToProps, { setModifyEmpty, submitAppointment })(SubmitAppointment);
withRouter(SubmitAppointment);
