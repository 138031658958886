import React from 'react';
import Modal from 'react-bootstrap/Modal';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';

/**
 This is the SharedModal component. Fill the below props to dictate the features of the modal:

 Usage instructions. ctrl+f and search the relevant Step X) for the example:
 Step 0) import the SharedModal component using the relevant path,
          also decide and import which Buttons to use to open the modal, and if any are needed for within the modal.
 Step 1) Create your constructor(props) and include the this.state.[modalStateName] boolean set to false.
         (specificity in the name is helpful, even if it's the only modal
         ex. openImageModal)
 Step 2) Bind handlers for both open and close methods.
 Step 3) create handlerOpen method and use this.setState({[modalStateName]: true})
         create handlerClose method and use this.setState({[modalStateName]: false})
 Step 4) Within your render create consts for any required features to pass as props to the shared modal component.
 Step 5) Add the SharedModal component and define the available props as you wish.
         A list of the props, the type of the prop, any defaults, and their descriptions are explained below:

 Available Props:
 buttonToOpen= node;
         REQUIRED You must define a button component of your choice to be able to open the modal!
         Define the component you would like to use as html content as shown below.
 show= boolean;
       REQUIRED You must define this or the modal can't open!;
       Define as this.state.[modalStateName] from Step 1);
 handleClose= function;
              REQUIRED You must define this or the modal can't close!
              Define as the close handler method from Step 3;
 headerContent= node;
                OPTIONAL (if not required, set simpleModal prop to true);
                this can contain a simple text string, or you can define html content;
 footerContent= node;
                OPTIONAL (defaults to empty);
               This can contain a simple text string, or you can define html content;
 content= node;
          OPTIONAL (defaults to empty);
          This can contain a simple text string, or you can define html content;
          If you would like to send text string translated, send it as a node component;
 simpleModal= boolean;
              OPTIONAL (defaults to false);
              Define as true if you don't want a header/footer -- useful for just displaying a pop-up image for example;
 primaryButton= node;
                     OPTIONAL (defaults to empty);
                     Define the button component you would like to use as html content as shown below.;
                     Don't forget to add in an onClick function and track state as you'd like;
 secondaryButton= node;
                define the component you would like to use as html content as shown below;
                OPTIONAL (defaults to empty);
                Define the button component you would like to use as html content as shown below;
                Don't forget to add in an onClick function and track state as you'd like

 Usage Example:
 //Step 0) Import the SharedModal component from the shared dir (use the correct path from your component)
    import SharedModal from '../../shared/Modal/SharedModal'
    // button of choice as well
    import Button from 'react-bootstrap/Button'

 //Step 1) dictate the state of the modal
      class NeatComponent extends React.Component{
        constructor(props){
            super(props);
            this.state = {
                openModal: false
            };

//Step 2) bind thy handlers
            this.handleOpenModal = this.handleOpenModal.bind(this);
            this.handleCloseModal = this.handleCloseModal.bind(this);
        }

//Step 3) create open handler method
        handleOpenModal(){
            this.setState({openModal: true});
        }
//Step 3) create close handler method
        handleCloseModal(){
            this.setState({openModal: false});
        }

//Step 4) define the props you may want to include.
        render(){
            const headerText = <>{i18n.t('LABEL_HEADER_TEXT')}</>;
            const footerText = "wow a footer";
            const content = (
              <div>
                  {i18n.t('LABEL_HEADER_CONTENT')}
                  <br />
                  <img src={"https://media.kingston.com/hyperx/features/hx-features-headset-cloud-ii-red.jpg"} alt={"hyperX headset"} />
              </div>
              );
            const buttonStandardModal = (
              <Button onClick={this.handleOpenModal}>
                  SharedModal
              </Button>
            );
            const confirmationButton = (
              <Button onClick={this.addInACustomHandlerForConfirmationOrThisWontWork}>
                  Confirm
              </Button>
            );
            const declineButton = (
              <Button onClick={this.handleCloseModalOrWhateverFunctionalityYouWantJustCreateAHandlerAbove}>
                  Decline
              </Button>
            );

            return
                <div>
//Step 5) add the SharedModal component and define the relevant props to what you wish
                    <SharedModal
                      headerContent={headerText}
                      footerContent={footerText}
                      content={content}
                      buttonToOpen={buttonStandardModal}
                      show={this.state.openModal}
                      handleClose={this.handleCloseModal}
                      simpleModal={false}
                      primaryButton={confirmationButton}
                      secondaryButton={declineButton}
                    />
                </div>
        }
    }
 */
export default class SharedModal extends React.Component {
    render() {
        const {
            headerContent,
            footerContent,
            content,
            buttonToOpen,
            show,
            primaryButton,
            secondaryButton,
            handleClose,
            simpleModal,
            backdrop,
            keyboard,
            headerClose,
        } = this.props;
        let header = <div>{headerContent}</div>;
        let footer = (
            <div>
                <ModalFooter>
                    <span>{footerContent}</span>
                    <span>{primaryButton}</span>
                    <span>{secondaryButton}</span>
                </ModalFooter>
            </div>
        );
        if (!headerContent) {
            header = '';
        }
        if (!footerContent) {
            footer = '';
        }

        return (
            <div className="ac-modal-wrapper">
                {buttonToOpen}
                <Modal
                    className={simpleModal ? 'ac-simple-modal' : ''}
                    header={header}
                    show={show}
                    onHide={handleClose}
                    backdrop={backdrop}
                    keyboard={keyboard}
                >
                    <div className="modal-header">
                        <div className="header-close" onClick={handleClose} data-testid="header-close">
                            <div className="header-close-icon icon close" id="btnClose" />
                            <div className="header-close-title">{headerClose}</div>
                        </div>
                        <Modal.Title>{header}</Modal.Title>
                    </div>
                    <ModalBody>{content}</ModalBody>
                    {footer}
                </Modal>
            </div>
        );
    }
}
