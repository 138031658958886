import {useEffect} from "react";
import {useFlags} from "launchdarkly-react-client-sdk";
import {batch, useSelector} from "react-redux";
import {checkVinEligibility} from "../../shared/BackendCalls/SharedBackendGets";
import store from "../../Redux/Store";
import {
    showMobileServiceNotAvailableForDealer,
    showMobileServicePlusAcknowledgeModel,
    setVinEligibility, setMobileServiceNotAvailableForDealer, setMobileServicePlusAcknowledgeModel
} from "../../Redux/ActionCreator";
import ServiceAcknowledge from "./ServiceAcknowledge";
import MobileServiceUnenrolledDealerModal from "./MobileServiceUnenrolledDealerModal";


const ServiceAcknowledgementWrapper = ()=>{
    const { enableMobileServicePlus } = useFlags();
    const {triggerEligibilityCall}  = useSelector((state) => state.mobileServiceReducer);
    const {flowSource,dealerSummaryObject,vin} = useSelector((state) => state.prefillReducer);

      useEffect(async ()=>{
        if(enableMobileServicePlus && triggerEligibilityCall && vin){
              try {
                  const response = await checkVinEligibility(vin);

                  if(response && response.status === 200) {
                      store.dispatch(setVinEligibility(response.data));
                      if(response.data){
                          (flowSource === 'DealerUS' && !dealerSummaryObject?.mobileServicePlusEnabled) ?
                              store.dispatch(setMobileServiceNotAvailableForDealer(true))
                              : store.dispatch(setMobileServicePlusAcknowledgeModel(true))
                             }
                        }
                }
              catch(error){
                  console.log("Error occur while trying to call vin eligibility api", error);
              }

          }

      },[])
    return <>
          <ServiceAcknowledge/>
          <MobileServiceUnenrolledDealerModal/>
    </>
}

export default ServiceAcknowledgementWrapper

