import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

/**
SHARED RadioButton COMPONENT

Props:
This components accept of array of below items
 checked:boolean (*Required)
 disabled:boolean (*Required)
 handleClick:function (Required only if disabled is false)
 handleOnChange:function (Required only if disabled is false)
 label: string, default to "" (Optional);
 id:string,default to "" (Optional),
 value:string,default to "" (Optional)

Usage:
    1) Import the Checkbox component from shared/RadioButton/RadioButton
Example: import SharedToggle from '../../shared/RadioButton/SharedRadioButton';

2) Add the Checkbox component and pass in the items as a prop, for example:
const sampleOnClick = (e)=> {
    console.log('e',e);
}

 const radioButton = [
 {handleClick:sampleOnClick,checked:false,disabled:false,label:"Checkbox 1",handleOnChange:sampleOnClick,id:"Cb1"},
 {handleClick:sampleOnClick,checked:true,disabled:true,label:"Checkbox 2",handleOnChange:sampleOnClick,id:'cb2'},
 {handleClick:sampleOnClick,checked:true,disabled:false,label:"Checkbox 3",handleOnChange:sampleOnClick,id:'cb3'}
];
<SharedRadioButton radioButtonProps={radioButton}/>

*/
const SharedRadioButton = (props) => {
    const radioButtonObj = props.radioButtonProps;

    return (
        <>
            {radioButtonObj.length > 0 && radioButtonObj.length > 0
                ? radioButtonObj.map((item, index) => {
                      const label = item.label || '';
                      const handleClick = item.disabled ? () => false : item.handleClick;
                      const { disabled } = item;
                      const { checked } = item;
                      const id = item.id || '';
                      const value = item.value || '';
                      const handleOnChange = item.disabled ? () => false : item.handleOnChange;
                      const customClass = classNames({
                          checkmark: true,
                          checked: item.checked,
                          disabled: item.disabled,
                      });

                      return (
                          <label className="container stat-radio" key={index}>
                              {label}
                              <input
                                  type="checkbox"
                                  id={id}
                                  value={value}
                                  checked={checked}
                                  onClick={handleClick}
                                  disabled={disabled}
                                  onChange={handleOnChange}
                                  data-dtm="service:offers"
                               />
                              <span className={customClass} />
                          </label>
                      );
                  })
                : null}
        </>
    );
};

export default SharedRadioButton;

/** * Prop Types Checking ** */
SharedRadioButton.propTypes = {
    radioButtonProps: PropTypes.arrayOf(
        PropTypes.shape({
            checked: PropTypes.bool.isRequired,
            disabled: PropTypes.bool.isRequired,
            id: PropTypes.string,
            value: PropTypes.string,
            label: PropTypes.string,
            handleOnChange: (props, propName, componentName) => {
                if (!props.disabled && !props.handleOnChange) {
                    return new Error(
                        `Invalid prop \`${propName}\` supplied to` + ` \`${componentName}\`. Validation failed.`
                    );
                }
            },
            handleClick: (props, propName, componentName) => {
                if (!props.disabled && !props.handleClick) {
                    return new Error(
                        `Invalid prop \`${propName}\` supplied to` + ` \`${componentName}\`. Validation failed.`
                    );
                }
            },
        })
    ).isRequired,
};

/** * Default Props ** */
SharedRadioButton.defaultProps = {
    radioButtonProps: [
        {
            checked: true,
            id: 'defaultId',
            value: 'defaultValue',
            disabled: false,
            label: 'Default Checkbox',
            handleOnChange: () => false,
            handleClick: () => false,
        },
    ],
};
