import React from 'react';
import { connect } from 'react-redux';
import { getGeoLocation } from './getDealerships';

class UseLocationButton extends React.Component {
    render() {
        return (
            <button
                data-dtm="select a dealer"
                className={'secondary-button ' + 'stat-button-link'}
                onClick={() => {
                    getGeoLocation();
                }}
            >
                {this.props.vTranslations.USE_CURRENT_LOCATION}
            </button>
        );
    }
}

const mapStateToProps = (state) => ({
    vTranslations: state.countryOptionsReducer.translations,
});

export default connect(mapStateToProps)(UseLocationButton);
