import React from "react";
import {useSelector} from "react-redux";

const DisplayRecalls = (props)=>{

   const  formatRecallString = (name) =>{
        const DASH_STR = ' - ';
        let formattedString = '';

        if (name) {
            const tempStr = name.replace('Recall - ', '');
            const tempArray = tempStr.split(DASH_STR);

            tempArray.forEach((str, index) => {
                str = str.charAt(0).toUpperCase() + str.substr(1).toLowerCase();
                formattedString += str;

                if (index < tempArray.length - 1) {
                    formattedString += DASH_STR;
                }
            });
        } else {
            formattedString = '';
        }

        return formattedString;
    }

    const {translations} = useSelector((state)=>state.ossAppGlobals);
    return <div className="recall">
        <div className="recall-header">
            <i className="recall-header-icon icon alert-caution-red" />
            <div className="recall-header-label">
                {translations.COMMONSERVICE_RECALL}
            </div>
        </div>
        <div className="recall-title">{formatRecallString(props.name)}</div>

    </div>
}

export default DisplayRecalls