import React from 'react';
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import { setStarRating, setFeedbackReason } from '../../Redux/ActionCreator';
import { submitFeedback } from '../../shared/BackendCalls/SharedBackendGets';
import { EditAppointmentFlow } from '../../shared/RoutingFlow/FlowComponentsConstants';

class FeedBack extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hover: 0, submitted: false };
    }

    handleOptionClicked(option) {
        if (option === this.props.vFeedbackReason) {
            this.props.setFeedbackReason('');
        } else {
            this.props.setFeedbackReason(option);
        }
    }

    handleSubmit() {
        this.setState({ submitted: true });
        submitFeedback(this.props.vStarRating, this.props.vFeedbackReason);
    }

    render() {
        const feedbackOptions = JSON.parse(this.props.vCountryOptions.CFUI_FEEDBACK_REASONS, (key, value) => {
            if (key !== '') {
                return value.toString().split(',');
            }
            return value;
        });

        const feedbackSelections = this.props.vStarRating ? (
            <Grid container spacing={1} className="feed-back-options">
                {feedbackOptions[this.props.vStarRating].map((option) => {
                    return (
                        <Grid item key={option} className="feed-back-selection">
                            {this.props.vTranslations[option] && (
                                <Card
                                    className="feed-back-option"
                                    variant={option === this.props.vFeedbackReason ? 'outlined' : null}
                                    onClick={() => this.handleOptionClicked(option)}
                                >
                                    {this.props.vTranslations[option]}
                                </Card>
                            )}
                        </Grid>
                    );
                })}
            </Grid>
        ) : (
            ''
        );

        return (
            <>
                {this.props.vCountryOptions.CFUI_FEEDBACK_ENABLED === 'true' &&
                    this.props.vDeploymentMode !== 'REQUEST_FORM' &&
                    this.props.modifyFlag === false &&
                    this.props.deleteAppointmentFlag === false &&
                    this.props.flowSource !== EditAppointmentFlow &&
                    (!this.state.submitted ? (
                        <div className="feed-back-box paper">
                            <div className="headline3">{this.props.vTranslations.CUSTOMER_FEEDBACK_TITLE}</div>
                            <div className="star-rating">
                                {[...Array(5)].map((star, index) => {
                                    index += 1;
                                    return (
                                        <button
                                            id={'btnStar' + (index - 1)}
                                            type="button"
                                            key={index}
                                            className={index <= this.state.hover ? 'star-button on' : 'star-button off'}
                                            onClick={() => this.props.setStarRating(index)}
                                            onMouseEnter={() => this.setState({ hover: index })}
                                            onMouseLeave={() => this.setState({ hover: this.props.vStarRating })}
                                        >
                                            {index <= this.state.hover ? (
                                                <span className="star">&#9733;</span>
                                            ) : (
                                                <span className="star">&#9734;</span>
                                            )}
                                        </button>
                                    );
                                })}
                            </div>
                            {feedbackSelections}
                            <div className="feed-back-submit">
                                <button
                                    id="btnSubmitFeedback"
                                    className={`button secondary-button${
                                        this.props.showSpinner ||
                                        (this.props.vStarRating === 0 && this.props.vFeedbackReason === '')
                                            ? ' disabled'
                                            : ''
                                    }`}
                                    onClick={() => this.handleSubmit()}
                                >
                                    {this.props.vTranslations.SUBMIT}
                                </button>
                            </div>
                        </div>
                    ) : (
                        <div className="feed-back-box paper">
                            <div className="headline3">{this.props.vTranslations.THANK_YOU}</div>
                            <div
                                className="feed-back-submitted"
                                dangerouslySetInnerHTML={{
                                    __html: this.props.vTranslations.FEEDBACK_SUBMITTED,
                                }}
                            ></div>
                        </div>
                    ))}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    vTranslations: state.countryOptionsReducer.translations,
    vStarRating: state.confirmationReducer.starRating,
    vFeedbackReason: state.confirmationReducer.selectedReason,
    vCountryOptions: state.countryOptionsReducer.countryOptions,
    vDeploymentMode: state.prefillReducer.dealerSummaryObject.dealershipSummary.deploymentMode,
    showSpinner: state.ossAppGlobals.showSpinner,
    modifyFlag: state.appointmentReducer.modifyFlag,
    deleteAppointmentFlag: state.confirmationReducer.deleteAppointmentFlag,
    flowSource: state.prefillReducer.flowSource,
});

export default connect(mapStateToProps, { setStarRating, setFeedbackReason })(FeedBack);
