import { connect } from 'react-redux';
import React from 'react';
import { stringToTitleCase } from '../AppointmentHelpers';
const localizationConstants = require('../../../shared/Constants/LocalizationConstants');
import { Tab, Tabs, Grid } from '@material-ui/core';
import dayjs from 'dayjs';
const customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(customParseFormat);
const localizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(localizedFormat);
import { getPhoneNum } from './DealershipPhone';

class Dealership extends React.Component {
    constructor() {
        super();
        this.state = {
            departments: 0,
        };
    }

    getDistance = () => {
        let distance = '';

        if (this.props.countryOptions.USE_MILES === 'true') {
            if (this.props.selectedDealer.miles) {
                distance = `${this.props.selectedDealer.miles} ${this.props.translations.L_MILES}`;
            }
        } else if (this.props.selectedDealer.kilometers) {
            distance = `${this.props.selectedDealer.kilometers} ${this.props.translations.KILOMETERS}`;
        }

        return distance;
    };

    formatCityZipSpacing() {
        if (this.props.dealershipSummary.address.postalZone) {
            return ', ';
        }
        return '';
    }
    formatSubentityZipSpacing() {
        if (this.props.dealershipSummary.address.countrySubentity) {
            return ', ';
        }
        return '';
    }
    returnPostalZone() {
        if (this.props.dealershipSummary.address.postalZone) {
            if (this.props.vCountry === localizationConstants.US) {
                return this.props.dealershipSummary.address.postalZone.slice(0, 5);
            }
            return this.props.dealershipSummary.address.postalZone;
        }
        return null;
    }
    //tab selector
    tabsHandler = (e, val) => {
        e.preventDefault();
        this.setState({ departments: val });
    };

    serviceContent = (department) => {
        //departments: Parts, Sales, and Services
        const dept = this.props.dealershipSummary.departments;
        let days = '';
        let hoursMap = '';
        for (let i = 0; i < dept.length; i++) {
            if (dept[i].name === 'SERVICE' && department === 0) {
                days = Object.keys(dept[i].hours.hoursMap);
                hoursMap = dept[i].hours.hoursMap;
            } else if (dept[i].name === 'SALES' && department === 1) {
                days = Object.keys(dept[i].hours.hoursMap);
                hoursMap = dept[i].hours.hoursMap;
            } else if (dept[i].name === 'PARTS' && department === 2) {
                days = Object.keys(dept[i].hours.hoursMap);
                hoursMap = dept[i].hours.hoursMap;
            }
        }
        days.push(days.shift()); //take the first element and insert at the end.
        return (
            <>
                {days.map((day) => {
                    return (
                        <div>
                            <Grid container>
                                <Grid item xs={6} className="dealership-info-day">
                                    {this.props.translations[day.toUpperCase()]}
                                </Grid>
                                {hoursMap[day]
                                    ? dayjs(hoursMap[day].start, 'HH:mm:ss').format('LT') +
                                      ' - ' +
                                      dayjs(hoursMap[day].end, 'HH:mm:ss').format('LT')
                                    : this.props.translations.CLOSED}
                            </Grid>
                        </div>
                    );
                })}
            </>
        );
    };

    render() {
        const distance = this.getDistance();
        let countries = this.props.vCountry;
        const countryLookup = require('country-code-lookup');
        const amenity = this.props.dealershipSummary.amenities;
        const dept = this.props.dealershipSummary.departments;
        let isService,
            isSales,
            isParts = false;

        for (let i = 0; i < dept.length; i++) {
            if (dept[i].name === 'SERVICE' && dept[i].hours) isService = true;
            else if (dept[i].name === 'SALES' && dept[i].hours) isSales = true;
            else if (dept[i].name === 'PARTS' && dept[i].hours) isParts = true;
        }

        return (
            <>
                <div className="dealer-information-container paper">
                    <div className="dealership-header bold">{this.props.translations.DEALERSHIP}</div>
                    <div className="dealership-name">{this.props.dealershipSummary.name}</div>

                    <div>{stringToTitleCase(this.props.dealershipSummary.address.addressLine1)}</div>
                    <div>{stringToTitleCase(this.props.dealershipSummary.address.addressLine2)}</div>
                    <div>
                        {stringToTitleCase(this.props.dealershipSummary.address.cityName)}
                        {this.formatCityZipSpacing()}
                        {this.props.dealershipSummary.address.countrySubentity}
                        {this.formatSubentityZipSpacing()}
                        {this.returnPostalZone()}
                    </div>
                    <div>{countryLookup.byIso(countries).country}</div>
                    <div>
                        {distance ? (
                            (this.props.selectedDealer.miles || this.props.selectedDealer.kilometers) &&
                            !this.props.selectedDealer.preferred &&
                            (this.props.selectedDealer.miles !== this.props.translations.ERROR_CALCULATING_DISTANCE ||
                                this.props.selectedDealer.kilometers !==
                                    this.props.translations.ERROR_CALCULATING_DISTANCE) ? (
                                <div>{distance}</div>
                            ) : null
                        ) : null}
                    </div>

                    <a
                        className="dealership-phone hide-on-desktop"
                        href={`tel:${getPhoneNum(this.props.dealershipSummary, this.props.vCountry)}`}
                    >
                        {this.props.translations.CALL}
                    </a>
                    <div>
                        {`${getPhoneNum(this.props.dealershipSummary, this.props.vCountry)}`}
                    </div>
                </div>

                <div className="dealer-information-hours-container paper">
                    <div className="dealership-hours">{this.props.translations.DEPT_HOURS}</div>

                    <Tabs indicatorColor="primary" value={this.state.departments} onChange={this.tabsHandler}>
                        {isService && <Tab label={this.props.translations.SERVICES} />}
                        {isSales && <Tab label={this.props.translations.SALES_DEPT} />}
                        {isParts && <Tab label={this.props.translations.PARTS_DEPT} />}
                    </Tabs>
                    {this.state.departments !== null && this.serviceContent(this.state.departments)}
                </div>

                {amenity && (
                    <div className="dealer-information-amenities-container paper">
                        <div className="dealership-amenities">{this.props.translations.AMENITIES}</div>
                        <div>
                            {amenity.map((amenityKey) => (
                                <div>{this.props.translations[amenityKey.code]}</div>
                            ))}
                        </div>
                    </div>
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    countryOptions: state.countryOptionsReducer.countryOptions,
    dealershipSummary: state.prefillReducer.dealerSummaryObject.dealershipSummary,
    selectedDealer: state.locReducer.selectedDealer,
    translations: state.countryOptionsReducer.translations,
    vCountry: state.prefillReducer.country,
});

export default connect(mapStateToProps)(Dealership);
