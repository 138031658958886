import { connect } from 'react-redux';
import React from 'react';
import DealerInfoPopup from './DealerInfoPopup';
import Dealership from './Dealership'

class DealerShipInformation extends React.Component {
    constructor() {
        super();
        this.state = {
            showDealerInfoPopup: false,
        }
    }
    dealerInformation() {
        this.setState({
            showDealerInfoPopup: !this.state.showDealerInfoPopup,
        });
        window.scrollTo(0, 0);
    }

    render() {
        const container = (
            <div className='dealership-information-container'>
                <div className= 'dealership-information-name'>
                    {this.props.dealershipSummary.name}
                </div>

                {!this.state.showDealerInfoPopup ? (
                    <a
                        id="btnDealerInfo"
                        onClick={this.dealerInformation.bind(this)}
                    >
                        {this.props.vTranslations.DEALER_INFO}
                    </a>
                ) : (
                        <DealerInfoPopup
                            closeDealerInfoPopup={this.dealerInformation.bind(this)}
                            showDealerInfoPopup={this.state.showDealerInfoPopup}
                        />
                    )}

            </div>

        )
        return (
            <p>{container}</p>
            );

    }
}
const mapStateToProps = (state) => ({
    vTranslations: state.countryOptionsReducer.translations,
    dealershipSummary: state.prefillReducer.dealerSummaryObject.dealershipSummary,
})

export default connect(mapStateToProps)(DealerShipInformation);

