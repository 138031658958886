import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
    setAppGlobals,
    setPrefillHeader,
    setPrefillMocked,
    setRewardsPoints,
    setTranslations,
} from '../../Redux/ActionCreator';
import findNextFlow from '../../shared/RoutingFlow/RoutingFlow';
import {
    editAppointmentAxios,
    getAdvisorsTransportOptions,
    getAppGlobals,
    getCountryOptions,
    getSummaryBAC,
} from '../../shared/BackendCalls/SharedBackendGets';
import { setupDateLocalization } from '../../shared/Utils/TimeLocalization';
import { CSSRenderer } from '../../shared/Utils/CSSRenderer';
import { URLParamsarray } from '../../shared/Constants/urlParamNames';

const constants = require('../../shared/RoutingFlow/FlowComponentsConstants');
const sharedConstants = require('../../shared/Constants/SharedConstants');
const localizationConstants = require('../../shared/Constants/LocalizationConstants');
const flowConstants = require('../../shared/RoutingFlow/FlowComponentsConstants');

class DeveloperEntryParams extends Component {
    constructor(props) {
        super(props);

        // Go ahead and set the local state
        this.state = {
            actioncom: '',
            actionUrl: process.env.REACT_APP_MEDIATOR_SERVICE_URL,
            bac: '112227',
            city: 'Chandler',
            country: localizationConstants.US,
            customerComments: 'please dont mess with my seat settings',
            displaySmsAlertOption: true,
            editAppointmentRecordLocator: '',
            email: 'fake.email@gm.com',
            engine: 'GoGoJuiceConverter2000',
            failed: '',
            firstName: 'John',
            flowSource: 'Authenticated',
            fuelType: 'unleaded',
            gcin: 'B3A73B55-5190-44c1-9FCE-A76D2491B27A',
            gpmsProfileId: '4a984ae7-9ac1-42ca-b22e-17167601651b',
            hideAddToCalendar: false,
            hideReturn: 'true',
            isAuthenticated: true,
            isEditAppointment: false,
            lastName: 'Doe',
            locale: 'en-US',
            make: 'Chevrolet',
            miles: '86753',
            model: 'Equinox',
            nationalId: '12',
            origin: 'OSSRedesign',
            phoneCell: '602-567-8901',
            phoneDay: '480-867-5309',
            phoneEvening: '520-678-9012',
            postalCode: '85224',
            programmaticallyDetectFlow: false,
            programmaticURLDetectFlow: false,
            regionCode: 'AZ',
            registrationMonth: '4',
            registrationYear: '2017',
            rego: 'REGOTEST90',
            serviceLineItems: '',
            serviceNotes: 'I am losing ponies from da ranch',
            showSpinner: false,
            street: '2900 W Geronimo Pl.',
            theme: '',
            ultimateOrigin: 'TESTPAGE',
            urlPath: '',
            view: 'm',
            vin: '1GT49WEYXRF130760',
            year: '2016',
            localhostRedirect: window.location.hostname === 'localhost' ? true : false,
        };
    }

    componentDidUpdate() {
        if (!this.state.showSpinner && !this.state.failed && this.state.actioncom !== sharedConstants.STOP) {
            if (this.props.actioncom === this.state.actioncom || this.props.countryActioncom === this.state.actioncom) {
                this.state.actioncom = '';

                if (this.props.actioncom === sharedConstants.PREFILL && !this.props.countryActioncom) {
                    this.props.setPrefillHeader(this.props.prefill);
                    if (this.props.flowSource === constants.EditAppointmentFlow) {
                        this.state.actioncom = sharedConstants.SUMMBAC;
                        getSummaryBAC(this.props.country, this.props.bac);
                    } else {
                        setupDateLocalization();
                        this.state.actioncom = sharedConstants.COUNTRY;
                    }

                    if (this.props.locale === localizationConstants.frCA) {
                        this.state.locale = this.props.locale;
                    } else {
                        this.state.locale = this.props.locale;
                    }

                    getCountryOptions(this.state.country, this.state.locale, this.props.flowSource);
                }

                if (
                    this.props.countryActioncom === sharedConstants.COUNTRY &&
                    this.props.actioncom === sharedConstants.PREFILL
                ) {
                    this.props.setTranslations(this.props.translations);

                    if (this.state.country && this.state.bac) {
                        this.state.actioncom = sharedConstants.SUMMBAC;
                        getSummaryBAC(this.state.country, this.state.bac);
                    } else {
                        this.getOssGlobals();
                    }
                }

                if (this.props.actioncom === sharedConstants.SUMMBAC) {
                    if (this.props.flowSource === constants.EditAppointmentFlow) {
                        this.state.actioncom = sharedConstants.EDTCOM;
                        getAdvisorsTransportOptions();
                        editAppointmentAxios(this.state.editAppointmentRecordLocator);
                    } else {
                        this.getOssGlobals();
                    }
                }

                if (
                    this.props.actioncom === sharedConstants.EDTCOM &&
                    this.props.countryActioncom !== sharedConstants.COUNTRY_EDTCOM
                ) {
                    setupDateLocalization();
                    this.state.actioncom = sharedConstants.COUNTRY_EDTCOM;

                    let locale = locale;
                    if (this.props.locale === localizationConstants.frCA) {
                        locale = this.props.locale;
                    }
                    getCountryOptions(this.props.country, locale, this.props.flowSource);
                }

                if (this.props.countryActioncom === sharedConstants.COUNTRY_EDTCOM) {
                    this.props.setTranslations(this.props.translations);
                    this.getOssGlobals();
                }
            }
        }
    }

    handleGoToLandingPage() {
        CSSRenderer(this.props.theme);

        this.props.history.push({
            pathname: this.props.translations[findNextFlow(this.props.flowSource, 'EntryParam', 'Forward')],
        });
    }

    handleSubmitPage(e) {
        e.preventDefault(); //React binds onclick() twice for some reason, so we need to prevent the first (default) call here.
        if (this.state.programmaticURLDetectFlow) {
            if (!this.state.urlPath) {
                alert('A value must be input into the Spoof/Fake/URL field ');
            } else {
                let parameterURL = this.determineActionURL() + '?' + this.state.urlPath.split('?')[1]; //grab parameters from supplied URL
                if (this.state.localhostRedirect) {
                    parameterURL += '&localhostRedirect=' + String(window.location.port);
                }
                window.location.replace(parameterURL);
            }
        } else if (!this.state.bac || !this.state.country) {
            if (this.state.flowSource === constants.OssLocatorFlow && !this.state.branchFlow) {
                this.state.actioncom = sharedConstants.PREFILL;
                let parameterURL = '';
                parameterURL = this.determineActionURL() + this.appendParamsToURL();
                window.location.replace(parameterURL);
            } else {
                alert('A Valid BAC and Country must be Input');
            }
        } else {
            this.state.actioncom = sharedConstants.PREFILL;
            let parameterURL = '';
            parameterURL = this.determineActionURL() + this.appendParamsToURL();
            window.location.replace(parameterURL);
        }
    }

    getOssGlobals() {
        this.state.actioncom = sharedConstants.STOP;

        // MK: Added this condition so that PEGA is called only for Authenticated view
        if (
            this.state.flowSource === constants.AuthenticatedFlow ||
            this.state.flowSource === constants.TireFinderFlow
        ) {
            if (this.props.vin && this.props.bac && this.props.mileage && this.props.country) {
                getAppGlobals(
                    this.props.country,
                    this.props.vin,
                    this.props.bac,
                    this.props.year,
                    this.props.make,
                    this.props.model,
                    this.props.locale,
                    '',
                    this.props.plus,
                    this.props.mileage,
                    this.props.units,
                    this.props.record
                );
                this.handleGoToLandingPage();
            } else {
                // Send to error page
                this.props.history.push({
                    pathname: '/Error',
                });
            }
        } else {
            this.handleGoToLandingPage();
        } // for non authenticated
    }

    // Handle change country in the record locator
    handleChangeCountryLocator = (event) => {
        this.setState({ country: event.target.value });
    };

    // Handle the change events for the entry fields
    handleChangeCountry = (event) => {
        this.setState({ country: event.target.value });

        if (event.target.value) {
            switch (event.target.value) {
                case localizationConstants.US: {
                    this.setState({ bac: '114814', locale: 'en-US' });
                    break;
                }
                case localizationConstants.AR: {
                    this.setState({ bac: '272546', locale: 'es-AR', postalCode: '6315' });
                    break;
                }
                case localizationConstants.AU: {
                    this.setState({ bac: '225737', locale: 'en-AU', postalCode: '5734', phoneCell: '0434 433 293' });
                    break;
                }
                case localizationConstants.BR: {
                    this.setState({ bac: '198567', locale: 'pt-BR' });
                    break;
                }
                case localizationConstants.CA: {
                    this.setState({
                        bac: '121024',
                        locale: 'en-CA',
                        postalCode: 'A1A1A1',
                        phoneCell: '(604) 567-8901',
                    });
                    break;
                }
                case localizationConstants.CL: {
                    this.setState({ bac: '232510', locale: 'es-CL' });
                    break;
                }
                case localizationConstants.CO: {
                    this.setState({ bac: '232380', locale: 'es-CO' });
                    break;
                }
                case localizationConstants.EC: {
                    this.setState({ bac: '316508', locale: 'es-EC' });
                    break;
                }
                case localizationConstants.MX: {
                    this.setState({ bac: '122031', locale: 'es-MX' });
                    break;
                }
                case localizationConstants.NZ: {
                    this.setState({ bac: '224926', locale: 'en-NZ', postalCode: '7175' });
                    break;
                }
                case localizationConstants.PE: {
                    this.setState({ bac: '260816', locale: 'es-PE' });
                    break;
                }

                default:
                    this.setState({ bac: '' });
                    break;
            }
        }
    };

    handleChangeBAC = (event) => {
        this.setState({ bac: event.target.value });
    };

    handleChangeLocale = (event) => {
        this.setState({ locale: event.target.value });
    };

    handleChangeUltimateOrigin = (event) => {
        this.setState({ ultimateOrigin: event.target.value });
    };

    handleChangeOrigin = (event) => {
        this.setState({ origin: event.target.value });
    };

    handleChangeHideReturn = (event) => {
        this.setState({ hideReturn: event.target.value });
    };

    handleChangeVIN = (event) => {
        this.setState({ vin: event.target.value });
    };

    handleChangeRego = (event) => {
        this.setState({ rego: event.target.value });
    };

    handleChangeYear = (event) => {
        this.setState({ year: event.target.value });
    };

    handleChangeMake = (event) => {
        this.setState({ make: event.target.value });
    };

    handleChangeModel = (event) => {
        this.setState({ model: event.target.value });
    };

    handleChangeEngine = (event) => {
        this.setState({ engine: event.target.value });
    };

    handleChangeMiles = (event) => {
        this.setState({ miles: event.target.value });
    };

    handleChangeFuelType = (event) => {
        this.setState({ fuelType: event.target.value });
    };

    handleChangeRegistrationYear = (event) => {
        this.setState({ registrationYear: event.target.value });
    };

    handleChangeRegistrationMonth = (event) => {
        this.setState({ registrationMonth: event.target.value });
    };

    handleChangeFirstName = (event) => {
        this.setState({ firstName: event.target.value });
    };

    handleChangeLastName = (event) => {
        this.setState({ lastName: event.target.value });
    };

    handleChangeNationalId = (event) => {
        this.setState({ nationalId: event.target.value });
    };

    handleChangeGcin = (event) => {
        this.setState({ gcin: event.target.value });
    };

    handleChangeGpms = (event) => {
        this.setState({ gpmsProfileId: event.target.value });
    };

    handleChangeStreet = (event) => {
        this.setState({ street: event.target.value });
    };

    handleChangeCity = (event) => {
        this.setState({ city: event.target.value });
    };

    handleChangeRegionCode = (event) => {
        this.setState({ regionCode: event.target.value });
    };

    handleChangePostalCode = (event) => {
        this.setState({ postalCode: event.target.value });
    };

    handleChangeEmail = (event) => {
        this.setState({ email: event.target.value });
    };

    handleChangeServiceNotes = (event) => {
        this.setState({ serviceNotes: event.target.value });
    };

    handleChangeServiceLineItems = (event) => {
        this.setState({ serviceLineItems: event.target.value });
    };

    handleChangePhoneDay = (event) => {
        this.setState({ phoneDay: event.target.value });
    };

    handleChangePhoneCell = (event) => {
        this.setState({ phoneCell: event.target.value });
    };

    handleChangePhoneEvening = (event) => {
        this.setState({ phoneEvening: event.target.value });
    };

    handleChangeCustomerComments = (event) => {
        this.setState({ customerComments: event.target.value });
    };

    handleChangeView = (event) => {
        this.setState({ view: event.target.value });
    };

    handleChangeDisplaySmsAlertOption = (event) => {
        this.setState({
            displaySmsAlertOption: !this.state.displaySmsAlertOption,
        });
    };

    handleChangeDisplayAddToCalendar = (event) => {
        this.setState({ hideAddToCalendar: !this.state.hideAddToCalendar });
    };

    handleChangeFlowSource = (event) => {
        this.setState({ flowSource: event.target.value });

        if (event.target.value === constants.AuthenticatedFlow || event.target.value === 'OcMobile') {
            if (event.target.value === 'OcMobile') {
                this.setState({ isOcMobile: true });
            } else {
                this.setState({ isOcMobile: false });
            }
        } else if (event.target.value === constants.EditAppointmentFlow) {
            this.setState({ isOcMobile: false });
        } else {
            this.setState({ vin: '', isOcMobile: false });
        }
    };

    handleChangeTheme = (event) => {
        this.setState({ theme: event.target.value });
    };

    handleChangeRecordLocator = (event) => {
        this.setState({ editAppointmentRecordLocator: event.target.value });
    };

    handleChangeURL = (event) => {
        this.setState({ urlPath: event.target.value });
    };

    handleChangeProgrammaticURLDetect = (event) => {
        this.setState({
            programmaticURLDetectFlow: !this.state.programmaticURLDetectFlow,
        });
    };

    determineActionURL = () => {
        return this.determineRouteByFlow(this.props.flowSource);
    };

    determineRouteByFlow = (flowSource) => {
        if (flowSource === flowConstants.OssLocatorFlow) {
            return `/oss/o/b/${this.state.country}/${this.state.theme}?`;
        } else if (flowSource === flowConstants.EditAppointmentFlow) {
            return `/oss/oss/dealership/${this.state.bac}/${this.state.editAppointmentRecordLocator}`;
        } else {
            if (this.state.locale) {
                return `/oss/o/d/${this.state.country}/${this.state.bac}?langCd=${this.state.locale}&`;
            } else {
                return `/oss/o/d/${this.state.country}/${this.state.bac}?`;
            }
        }
    };

    appendParamsToURL = () => {
        let params = '';

        let urlParamsItems = URLParamsarray;
        for (let i = 0; i < urlParamsItems.length; i++) {
            let item = urlParamsItems[i];
            if (this.state[item[0]]) {
                params += item[1] + '=' + String(this.state[item[0]]) + '&';
            }
        }

        return params.replace(/&+$/, '');
    };

    clearVehicle = () => {
        this.setState({
            vin: '',
            year: '',
            make: '',
            model: '',
            engine: '',
            miles: '',
            rego: '',
        });
    };

    clearCustomer = () => {
        this.setState({
            firstName: '',
            lastName: '',
            phoneDay: '',
            phoneCell: '',
            phoneEvening: '',
            email: '',
            nationalId: '',
            street: '',
            city: '',
            regionCode: '',
            postalCode: '',
            gcin: '',
            gpmsProfileId: '',
            serviceNotes: '',
            customerComments: '',
        })
    };

    clearAll = () => {
        this.clearVehicle();
        this.clearCustomer();
    };

    // Render the Page in the DOM
    render() {
        const clearSection = (
            <div>
                <p><b>Clear Button Section</b></p>
                <button type="button" onClick={() => this.clearVehicle()}>Clear Vehicle Data</button>
                <button type="button" onClick={() => this.clearCustomer()}>Clear Customer Data</button>
                <button type="button" onClick={() => this.clearAll()}>Clear All Data</button>
            </div>
        );

        const submitSection = (
            <div>
                <h1>Entry Params for OSS CFUI</h1>
                <input type="submit" className="button primary-button" value="Submit Form" />
                {clearSection}
            </div>
        );

        const divDirectURLFlow1 = (
            <div>
                <p>
                    <b>Spoof URL</b>
                </p>

                <label>
                    Start App using params from spoofed URL
                    <input
                        type="checkbox"
                        name="programmaticURLDetect"
                        onChange={this.handleChangeProgrammaticURLDetect}
                        value={this.state.programmaticURLDetectFlow}
                        checked={this.state.programmaticURLDetectFlow}
                    />
                </label>
            </div>
        );

        const divDirectURLFlow2 = (
            <div>
                <label>
                    Spoof/Fake/URL
                    <input
                        name="recordURL"
                        onChange={this.handleChangeURL}
                        style={{ width: '700px' }}
                        type="text"
                        value={this.state.urlPath}
                    />
                </label>
            </div>
        );

        const divAuthenticatedInput = (
            <>
                <h2>Authenticated Prefill</h2>
                <h3>Vehicle</h3>
                <label>
                    Year
                    <input
                        name="vehicle:buy:year"
                        onChange={this.handleChangeYear}
                        pattern="\d{4}"
                        type="text"
                        value={this.state.year}
                    />
                </label>
                <label>
                    Make
                    <input
                        name="vehicle:buy:make"
                        onChange={this.handleChangeMake}
                        type="text"
                        value={this.state.make}
                    />
                </label>
                <label>
                    Model
                    <input
                        name="vehicle:buy:model"
                        onChange={this.handleChangeModel}
                        type="text"
                        value={this.state.model}
                    />
                </label>
                <label>
                    Engine
                    <input
                        type="text"
                        name="vehicle:buy:engine"
                        onChange={this.handleChangeEngine}
                        value={this.state.engine}
                    />
                </label>
                <label>
                    Miles
                    <input
                        name="vehicle:buy:odometer:mi"
                        onChange={this.handleChangeMiles}
                        pattern="\d+"
                        type="text"
                        value={this.state.miles}
                    />
                </label>
                <label>
                    REGO
                    <input name="rego" onChange={this.handleChangeRego} type="text" value={this.state.rego} />
                </label>
                <h3>Customer</h3>
                <label>
                    First Name
                    <input
                        name="contact:name:first"
                        onChange={this.handleChangeFirstName}
                        type="text"
                        value={this.state.firstName}
                    />
                </label>
                <label>
                    Last Name
                    <input
                        type="text"
                        name="contact:name:last"
                        onChange={this.handleChangeLastName}
                        value={this.state.lastName}
                    />
                </label>
                <label>
                    Phone (day)
                    <input
                        type="tel"
                        name="contact:phone:phone:day"
                        onChange={this.handleChangePhoneDay}
                        value={this.state.phoneDay}
                    />
                </label>
                <label>
                    Phone (cell)
                    <input
                        type="tel"
                        name="contact:phone:cellphone"
                        onChange={this.handleChangePhoneCell}
                        value={this.state.phoneCell}
                    />
                </label>
                <label>
                    Phone (evening)
                    <input
                        type="tel"
                        name="contact:phone:phone:evening"
                        onChange={this.handleChangePhoneEvening}
                        value={this.state.phoneEvening}
                    />
                </label>
                <label>
                    Email
                    <input
                        name="contact:email"
                        onChange={this.handleChangeEmail}
                        type="email"
                        value={this.state.email}
                    />
                </label>
                <label>
                    National ID
                    <input
                        type="text"
                        name="contact:nationalid"
                        onChange={this.handleChangeNationalId}
                        value={this.state.nationalId}
                    />
                </label>
                <label>
                    Street
                    <input
                        type="text"
                        name="contact:address:home:street"
                        onChange={this.handleChangeStreet}
                        value={this.state.street}
                    />
                </label>
                <label>
                    City
                    <input
                        type="text"
                        name="contact:address:home:city"
                        onChange={this.handleChangeCity}
                        value={this.state.city}
                    />
                </label>
                <label>
                    Region Code
                    <input
                        type="text"
                        name="contact:address:home:regioncode"
                        onChange={this.handleChangeRegionCode}
                        value={this.state.regionCode}
                    />
                </label>
                <label>
                    Postal Code
                    <input
                        type="text"
                        name="contact:address:home:postalcode"
                        onChange={this.handleChangePostalCode}
                        value={this.state.postalCode}
                    />
                </label>
                <label>
                    GCIN
                    <input type="text" name="contact:gcin" onChange={this.handleChangeGcin} value={this.state.gcin} />
                </label>
                <label>
                    GPMS Profile ID
                    <input
                        type="text"
                        name="contact:profileid"
                        onChange={this.handleChangeGpms}
                        value={this.state.gpmsProfileId}
                    />
                </label>
                Service Notes
                <textarea
                    type="text"
                    name="service:description"
                    onChange={this.handleChangeServiceNotes}
                    value={this.state.serviceNotes}
                />
                Customer Comments
                <textarea
                    type="text"
                    name="service:additionalcomments"
                    onChange={this.handleChangeCustomerComments}
                    value={this.state.customerComments}
                />
            </>
        );

        const div = (
            <>
                {this.state.showSpinner ? (
                    <div>
                        <h3 className="pega-loading-container">{this.props.translations.PEGA_LOADING}</h3>
                        <CircularProgress />
                    </div>
                ) : (
                    <form
                        className="dev-entry"
                        method="post"
                        onSubmit={this.handleSubmitPage.bind(this)}
                        action={this.determineActionURL()}
                    >
                        {submitSection}
                        {divDirectURLFlow1}
                        <h2>Entry Params</h2>
                        <label>
                            Flow Source
                            <select
                                disabled={this.state.programmaticallyDetectFlow}
                                name="flowSource"
                                onChange={this.handleChangeFlowSource}
                                type="text"
                                value={this.state.flowSource}
                            >
                                <option value="Authenticated">Authenticated</option>
                                <option value="OcMobile">Authenticated (OC Mobile)</option>
                                <option value="Brand">Quantum Brand</option>
                                <option value="Dealer">Dealer</option>
                                <option value="EditAppointment">EditAppointment</option>
                                <option value="OssLocator">OSS Locator/Branch Dealer</option>
                                <option value="TireFinder">TireFinder</option>
                            </select>
                        </label>
                        <label>
                            Country
                            <select
                                type="text"
                                name="country"
                                onChange={this.handleChangeCountry}
                                value={this.state.country}
                            >
                                <option value="US">United States</option>
                                <option value="AR">Argentina</option>
                                <option value="AU">Australia</option>
                                <option value="BR">Brazil</option>
                                <option value="CA">Canada</option>
                                <option value="CL">Chile</option>
                                <option value="CO">Columbia</option>
                                <option value="EC">Ecuador</option>
                                <option value="MX">Mexico</option>
                                <option value="NZ">New Zealand</option>
                                <option value="PE">Peru</option>
                            </select>
                        </label>
                        <label>
                            BAC
                            <input
                                max="6"
                                min="6"
                                name="bac"
                                onChange={this.handleChangeBAC}
                                pattern="\d{6}"
                                required
                                type="text"
                                value={this.state.bac}
                            />
                        </label>
                        <label>
                            VIN
                            <input
                                name="vehicle:buy:vin"
                                onChange={this.handleChangeVIN}
                                onBlur={()=>{
                                    if(this.state.vin){
                                        this.setState({ vin: this.state.vin.trim() });
                                    }
                                }}
                                type="text"
                                value={this.state.vin}
                            />
                        </label>
                        {this.state.flowSource === constants.EditAppointmentFlow ? (
                            <label>
                                Record Locator
                                <input
                                    name="recordLocator"
                                    onChange={this.handleChangeRecordLocator}
                                    required
                                    type="text"
                                    value={this.state.editAppointmentRecordLocator}
                                />
                            </label>
                        ) : null}
                        {this.state.flowSource === 'TireFinder' ? (
                            <label>
                                Service Line Items (separated by | )(TireFinder Scenario)
                                <input
                                    type="text"
                                    name="service:lineitems"
                                    onChange={this.handleChangeServiceLineItems}
                                    value={this.state.serviceLineItems}
                                />
                            </label>
                        ) : null}
                        <label>
                            Locale
                            <select
                                name="locale"
                                onChange={this.handleChangeLocale}
                                required
                                type="text"
                                value={this.state.locale}
                            >
                                <option value="en-US">en-US</option>
                                <option value="en-AU">en-AU</option>
                                <option value="en-CA">en-CA</option>
                                <option value="en-NZ">en-NZ</option>
                                <option value="es-AR">es-AR</option>
                                <option value="es-CL">es-CL</option>
                                <option value="es-CO">es-CO</option>
                                <option value="es-EC">es-EC</option>
                                <option value="es-MX">es-MX</option>
                                <option value="es-PE">es-PE</option>
                                <option value="fr-CA">fr-CA</option>
                                <option value="pt-BR">pt-BR</option>
                                <option value="">None</option>
                            </select>
                        </label>
                        <label>
                            Theme Selector
                            <select
                                name="themeSelector"
                                onChange={this.handleChangeTheme}
                                type="text"
                                value={this.state.theme}
                            >
                                <option value="Multi">MultiBrand (GM Blue)</option>
                                <option value="BU">Buick (BU)</option>
                                <option value="CA">Cadillac (CA)</option>
                                <option value="CH">Chevrolet (CH)</option>
                                <option value="GM">GMC (GM)</option>
                            </select>
                        </label>
                        <label>
                            Origin
                            <input
                                name="origin"
                                onChange={this.handleChangeOrigin}
                                type="text"
                                value={this.state.origin}
                            />
                        </label>
                        <label>
                            Ultimate Origin
                            <input
                                name="origin"
                                onChange={this.handleChangeUltimateOrigin}
                                type="text"
                                value={this.state.ultimateOrigin}
                            />
                        </label>
                        <label>
                            Hide Return
                            <input
                                name="hideReturn"
                                onChange={this.handleChangeHideReturn}
                                type="text"
                                value={this.state.hideReturn}
                            />
                        </label>
                        <label>
                            Hide Add To Calendar Option
                            <input
                                type="checkbox"
                                name="displayAddToCalendar"
                                onChange={this.handleChangeDisplayAddToCalendar}
                                value={this.state.hideAddToCalendar}
                                checked={this.state.hideAddToCalendar}
                            />
                        </label>
                        {divAuthenticatedInput}
                    </form>
                )}
            </>
        );

        if (this.state.programmaticURLDetectFlow) {
            return (
                <div>
                    {submitSection}
                    {divDirectURLFlow1}
                    {divDirectURLFlow2}
                </div>
            );
        }
        return div;
    }
}

const mapStateToProps = (state) => ({
    country: state.prefillReducer.country,
    vin: state.prefillReducer.vin,
    flowSource: state.prefillReducer.flowSource,
    bac: state.prefillReducer.bac,
    mileage: state.prefillReducer.miles,
    actioncom: state.prefillReducer.actioncom,
    countryActioncom: state.countryOptionsReducer.actioncom,
    translations: state.countryOptionsReducer.translations,
    locale: state.prefillReducer.locale,
    year: state.prefillReducer.year,
    make: state.prefillReducer.make,
    model: state.prefillReducer.model,
    plus: state.vehicleReducer.selectedMotor,
    prefill: state.prefillReducer,
    units: state.prefillReducer.units,
    newAppointmentFlag: state.confirmationReducer.newAppointmentFlag,
    record: state.prefillReducer.editAppointmentRecordLocator,
    theme: state.prefillReducer.themeMake,
});

export default connect(mapStateToProps, {
    setAppGlobals,
    setPrefillHeader,
    setPrefillMocked,
    setRewardsPoints,
    setTranslations,
})(DeveloperEntryParams);
withRouter(DeveloperEntryParams);
