// Countries
export const AR = 'AR';
export const AU = 'AU';
export const BR = 'BR';
export const CA = 'CA';
export const CL = 'CL';
export const CO = 'CO';
export const EC = 'EC';
export const MX = 'MX';
export const NZ = 'NZ';
export const PE = 'PE';
export const US = 'US';

// Locales
export const enCA = 'en-CA';
export const frCA = 'fr-CA';
