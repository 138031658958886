import { connect } from 'react-redux';
import dayjs from 'dayjs';
import React from 'react';
import store from '../../../Redux/Store';
import { formatTime } from '../../../shared/Utils/TimeLocalization';
import { MIN_WIDTH_DESKTOP, NUM_TIMES_DESKTOP, NUM_TIMES_MOBILE } from '../AppointmentConstants';

class Time extends React.Component {
    constructor(props) {
        super(props);

        this.handleTap = this.handleTap.bind(this);
        this.handleTouchMove = this.handleTouchMove.bind(this);
        this.handleTouchStart = this.handleTouchStart.bind(this);
        this.updateNumTimesDisplayed = this.updateNumTimesDisplayed.bind(this);

        this.state = {
            numTimesToDisplay: NUM_TIMES_MOBILE,
        };
    }

    componentDidMount() {
        this.updateNumTimesDisplayed();
        window.addEventListener('resize', this.updateNumTimesDisplayed);

        const s = document.getElementById('swiper');
        if (s != null) {
            s.addEventListener('touchend', this.handleTap, false);
            s.addEventListener('touchmove', this.handleTouchMove, false);
            s.addEventListener('touchstart', this.handleTouchStart, false);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateNumTimesDisplayed);
    }

    updateNumTimesDisplayed() {
        if (window.innerWidth >= MIN_WIDTH_DESKTOP) {
            this.setState({ numTimesToDisplay: NUM_TIMES_DESKTOP });
        } else {
            this.setState({ numTimesToDisplay: NUM_TIMES_MOBILE });
        }
    }

    /**
     * Record site of first touch event
     * @param {*} event
     */
    handleTouchStart(event) {
        this.setState({ touchstartX: event.changedTouches[0].screenX });
    }

    /**
     * Determine if swipe is left or right
     * @param {*} event
     */
    handleTouchMove(event) {
        const touchEndX = event.changedTouches[0].screenX;

        if (touchEndX < this.state.touchstartX) {
            this.getNextAvailableTimes();
        } else if (touchEndX > this.state.touchstartX) {
            this.getPrevAvailableTimes();
        }
    }

    handleTap() {
        return false;
    }

    getTimesForDate(selectedDate) {
        let times = [];

        this.props.availableDates.forEach((date) => {
            if (dayjs(date.date).isSame(selectedDate, 'day')) {
                times = date.time;
            }
        });

        return times;
    }

    /**
     * @desc Return a list of times to display
     * @return array - List of times to be displayed
     */
    getTimeList() {
        const availableTimes = this.getTimesForDate(this.props.selectedDate);
        const timeList = [];

        for (
            let i = this.props.timeIndex;
            i < availableTimes.length && i < this.state.numTimesToDisplay + this.props.timeIndex;
            i++
        ) {
            const timeItem = {
                time: availableTimes[i],
                isSelected: dayjs(availableTimes[i]).isSame(this.props.selectedDate),
            };

            timeList.push(timeItem);
        }

        return timeList;
    }

    /**
     * @desc Get next <numTimesToDisplay> times
     */
    getNextAvailableTimes() {
        const index = this.props.timeIndex + this.state.numTimesToDisplay;

        if (index < this.getTimesForDate(this.props.selectedDate).length) {
            store.dispatch(setTimeIndex(index));
        }
    }

    /**
     * @desc Determine if next button should be disabled
     * @return string with classname to be added to button
     */
    nextBtnIsDisabled() {
        let className = '';

        if (
            this.props.timeIndex + this.state.numTimesToDisplay >=
            this.getTimesForDate(this.props.selectedDate).length
        ) {
            className = '-disabled';
        }
        return className;
    }

    /**
     * @desc Get previous <numTimesToDisplay> times
     */
    getPrevAvailableTimes() {
        let index;
        if (this.props.timeIndex === this.state.numTimesToDisplay) {
            index = 1;
        } else {
            index = this.props.timeIndex - this.state.numTimesToDisplay;
        }

        if (index >= 0) {
            store.dispatch(setTimeIndex(index));
        }
    }

    /**
     * @desc Render list of elements to display times
     * @return Elements with time info
     */
    renderTimeList() {
        if (this.props.availableDates) {
            const timeList = this.getTimeList();

            return timeList.map((timeItem) => {
                const { time, isSelected } = timeItem;
                return (
                    <div
                        className={`appointment-time${isSelected ? ' selected primary-button' : ' secondary-button'}`}
                        id="swiper"
                        key={time}
                        onClick={() => {
                            store.dispatch(setDate(time));
                        }}
                        onTouchEnd={this.handleTap}
                        value={timeItem}
                    >
                        {formatTime(time)}
                    </div>
                );
            });
        }
    }

    render() {
        return (
            <>
                {this.props.vshowSpinner ||
                !(this.props.showTimes && this.props.selectedDate) ||
                this.props.loadingFlag ? null : (
                    <div className="appointment-time-container">
                        <div className="appointment-time-selector">
                            <div
                                className={`load-prev-times-btn icon arrow-left${
                                    this.props.timeIndex === 0 ? '-disabled' : ''
                                }`}
                                onClick={this.getPrevAvailableTimes.bind(this)}
                            />
                            <div
                                className="appointment-time-list"
                                id="swiper"
                                onTouchMove={this.handleTouchMove}
                                onTouchStart={this.handleTouchStart}
                            >
                                {this.renderTimeList()}
                            </div>
                            <div
                                className={`load-more-times-btn icon arrow-right${this.nextBtnIsDisabled()}`}
                                onClick={this.getNextAvailableTimes.bind(this)}
                            />
                        </div>
                        <div className={`appointment-time-see-more-label${this.nextBtnIsDisabled()}`}>
                            {this.props.translations.SWIPE}
                        </div>
                    </div>
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    availableDates: state.appointmentReducer.availableDates,
    selectedDate: state.appointmentReducer.selectedDate,
    showTimes: state.appointmentReducer.showTimes,
    startDate: state.appointmentReducer.startDate,
    timeIndex: state.appointmentReducer.timeIndex,
    translations: state.countryOptionsReducer.translations,
    loadingFlag: state.appointmentReducer.loadingFlag,
});

function setDate(time) {
    return {
        type: 'SET_APPT_DATE',
        payload: time,
    };
}

function setTimeIndex(index) {
    return {
        type: 'SET_TIME_INDEX',
        payload: index,
    };
}

export default connect(mapStateToProps)(Time);
