import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import './Car.scss';
import Container from '@material-ui/core/Container';
import NoImage from '../../assets/icons/image-not-available-xs.svg';
import { setGarageIndex } from '../../Redux/ActionCreator';

class Car extends Component {
    render() {
        let { vehicles, translations, selectedIndex } = this.props;

        return (
            <Container>
                <Grid container spacing={2} className="garage-container">
                    {vehicles.map(({ vin, year, make, model, imageUrl }, index) => (
                        <Grid id={"chkVehicle" + index} item key={vin} className="car-container" xs={6}>
                            <Card
                                className="card"
                                variant={index === selectedIndex ? 'outlined' : null}
                                onClick={() => this.props.setGarageIndex(index)}
                            >
                                <div className="car-radio">
                                    <Radio checked={index === selectedIndex} />
                                </div>
                                <img
                                    className="car-image"
                                    src={imageUrl}
                                    onError={(e) => {
                                        if (!e.target.src.includes(NoImage.slice(1))) {
                                            e.target.onerror = null;
                                            e.target.src = NoImage;
                                            e.target.className = 'no-car-image';
                                        }
                                    }}
                                />
                                <div className="car-line" />
                                <div className="vehicle-info">
                                    {index === selectedIndex ? (
                                        <div className="car-selected desc">
                                            <i className="icon checkmark-grey" />
                                            {translations.SELECTED.toUpperCase()}
                                        </div>
                                    ) : (
                                        <div className="car-selected desc" />
                                    )}

                                    <div className="car-ymm">
                                        {year} {make} {model}
                                    </div>
                                    {vin ? (
                                        <div className="desc">
                                            {translations.VIN_ENDING} {vin.slice(-4)}
                                        </div>
                                        ) : null}
                                </div>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        );
    }
}

const mapStateToProps = (state) => ({
    vehicles: state.myGarageReducer.vehicles,
    translations: state.countryOptionsReducer.translations,
    selectedIndex: state.myGarageReducer.selectedIndex,
});

export default connect(mapStateToProps, { setGarageIndex })(Car);
