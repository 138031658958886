import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

/**
 SHARED Checkbox COMPONENT

 Props:
 checked:boolean (*Required)
 readOnly:boolean (*Required)
 onChange:function (*Required only if readOnly is false)

 OPTIONAL
 className= string
 id=string
 name=string
 value=string
 label = bool

 Usage:
 1) Import the Checkbox component from /shared/Checkbox/CheckBox
 Example: import Checkbox from '../../shared/Checkbox/checkbox'

 2). Props
 a) checked:boolean, state of the checked,Required
 b) onChange:function , required only if ready only is false
 c) readOnly : boolean, default to false,Required
 d) label : boolean , default to false
 e) className : string,default to default class
 f) id : string, default to empty
 g) name : string , default to empty
 h) value : any , default to empty

 3) Add the Checkbox component and pass in the items as a prop, for example:
 const sampleOnChange = (e)=> {
 console.log('e',e);
 }
 <Checkbox onChange={sampleOnChange}  checked={true} readOnly={true}   name="New york" value="HELLO" label={true}/>

 */

const SharedCheckBox = (props) => {
    const isLabel = props.label || false;
    const readOnly = props.readOnly || false;
    const name = props.name || '';
    const value = props.value || '';
    const checked = props.checked ? props.checked : false;
    const id = props.id || '';
    const onChange = props.onChange ? props.onChange : () => false;
    const onClick = props.onClick ? props.onClick : () => false;

    const className = classNames({
        'default-checkbox': !props.className,
        'sharable-checkbox': props.className,
        active: props.checked,
    });
    return (
        <div className="checkbox-container">
            <input
                className={className}
                data-testid={id}
                id={id}
                type="checkbox"
                checked={checked}
                onChange={onChange}
                disabled={readOnly}
                name={name}
                value={value}
                onClick={onClick}
            />
            {isLabel && <label htmlFor={props.id}>{props.name}</label>}
        </div>
    );
};
/** * Prop Types Checking ** */
SharedCheckBox.propTypes = {
    checked: PropTypes.bool.isRequired,
    onChange: (props, propName, componentName) => {
        if (!props.readOnly && !props.onChange) {
            return new Error(`Invalid prop \`${propName}\` supplied to` + ` \`${componentName}\`. Validation failed.`);
        }
    },
    readOnly: PropTypes.bool.isRequired,
    id: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.any,
    label: PropTypes.bool,
    onClick: PropTypes.func,
};

/** * Default Props ** */
SharedCheckBox.defaultProps = {
    checked: true,
    readOnly: false,
    id: 'checkboxDefault',
    value: 'defaultCheckboxValue',
    label: true,
    name: '',
    onClick: () => false,
    onChange: () => false,
};

export default SharedCheckBox;
