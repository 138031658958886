import dayjs from 'dayjs';
import store from '../../Redux/Store';

// Import necessary localization files
import 'dayjs/locale/en-ca';
import 'dayjs/locale/en-au';
import 'dayjs/locale/en-nz';
import 'dayjs/locale/fr-ca';
import 'dayjs/locale/es';
import 'dayjs/locale/pt-br';

const advancedFormat = require('dayjs/plugin/advancedFormat');
const localizedFormat = require('dayjs/plugin/localizedFormat');
const localizationConstants = require('../Constants/LocalizationConstants');

dayjs.extend(advancedFormat);
dayjs.extend(localizedFormat);

export function setupDateLocalization() {
    const localStore = store.getState();
    const { country } = localStore.prefillReducer;

    if (country === localizationConstants.AU) {
        dayjs.locale('en-au');
    } else if (country === localizationConstants.NZ) {
        dayjs.locale('en-nz');
    } else if (country === localizationConstants.CA) {
        if (localStore.prefillReducer.locale.toLowerCase() === 'fr-ca') {
            dayjs.locale('fr-ca');
        } else {
            dayjs.locale('en-ca');
        }
    } else if (country === localizationConstants.BR) {
        dayjs.locale('pt-br');
    } else if (country === localizationConstants.US) {
        dayjs.locale('en');
    } else if (
        country === localizationConstants.AR ||
        country === localizationConstants.CL ||
        country === localizationConstants.CO ||
        country === localizationConstants.EC ||
        country === localizationConstants.MX ||
        country === localizationConstants.PE
    ) {
        dayjs.locale('es');
    } else {
        dayjs.locale('en');
    }
}

export function formatDateLong(date) {
    const localStore = store.getState();
    const { country } = localStore.prefillReducer;
    let formattedDate = '';

    if (country === localizationConstants.US) {
        formattedDate = dayjs(date).format('dddd, MMMM Do YYYY h:mmA');
    } else {
        formattedDate = dayjs(date).format('LLL');
    }

    return formattedDate;
}

function isEnglish() {
    const localStore = store.getState();
    const { country } = localStore.prefillReducer;
    let isEnglish = false;

    if (
        country === localizationConstants.AU ||
        country === localizationConstants.NZ ||
        country === localizationConstants.US ||
        localStore.prefillReducer.locale.toLowerCase() === 'en-ca'
    ) {
        isEnglish = true;
    }

    return isEnglish;
}

export function formatTime(date) {
    let formattedTime = '';

    if (isEnglish()) {
        formattedTime = dayjs(date).format('h:mm');
    } else {
        formattedTime = dayjs(date).format('H:mm');
    }

    return formattedTime;
}
