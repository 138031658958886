import {
    SET_DEALER_ELIGIBILITY_FOR_MOBILE_PLUS,
    SET_MOBILE_SERVICE_ADDRESS_AND_PHONE_NUMBER,
    SET_MOBILE_SERVICE_NEXT_PAGE,
    SET_MOBILE_SERVICE_NOT_AVAILABLE,
    SET_MOBILE_SERVICE_NOT_AVAILABLE_FOR_DEALER,
    SET_MOBILE_SERVICE_PLUS_ACKNOWLEDGE_MODEL, SET_STOP_PRESELECT_MOBILE_SERVICE,
    SET_VIN_ELIGIBILITY_FOR_MOBILE_PLUS
} from "./ActionTypes";


const initialState = {
    isVinEligibleForMobileService :false,
    showAcknowledgeModel:false,
    triggerEligibilityCall:true,
    triggerDealerEnrollment:true,
    displayMobileServiceNotAvailable:false,
    displayMobileServiceNotAvailableForDealer: false,
    mobileServiceNextPage:"",
    serviceAddresses: null,
    serviceCommunications: null,
    stopPreSelectMobileTransportOption:false
}


const MobileServiceReducer = (state = initialState, action) => {

    switch (action.type) {
        case SET_VIN_ELIGIBILITY_FOR_MOBILE_PLUS: return {...state,isVinEligibleForMobileService:action.payload,triggerEligibilityCall:false}
        case SET_MOBILE_SERVICE_PLUS_ACKNOWLEDGE_MODEL: return {...state,showAcknowledgeModel:action.payload}
        case SET_MOBILE_SERVICE_NOT_AVAILABLE: return {...state,displayMobileServiceNotAvailable:action.payload}
        case SET_MOBILE_SERVICE_NOT_AVAILABLE_FOR_DEALER: return {...state,displayMobileServiceNotAvailableForDealer:action.payload}
        case SET_MOBILE_SERVICE_NEXT_PAGE: return {...state,mobileServiceNextPage:action.payload}
        case SET_STOP_PRESELECT_MOBILE_SERVICE: return {...state,stopPreSelectMobileTransportOption:action.payload}
        case SET_MOBILE_SERVICE_ADDRESS_AND_PHONE_NUMBER:{
            return {...state,
                serviceAddresses: action.payload.serviceAddresses,
                serviceCommunications: action.payload.serviceCommunications
            }
        }
        default:return state
    }


}

export default MobileServiceReducer