import React, { useEffect, useState } from 'react';
import { Grid, TextField } from "@material-ui/core";
import RegexParser from "regex-parser";
import { useSelector } from "react-redux";
import {loadGoogleMapsAPI} from "./GoogleMapsApi";


const AddressForm = ({ onChange, initialState, showFormError, showRangeError, showPhoneNumber,id }) => {
    const { translations, countryOptions } = useSelector((state) => state.countryOptionsReducer);
    const { country } = useSelector((state) => state.prefillReducer);

    const [addressData, setAddressData] = useState({
        address1: initialState?.address1 || '',
        address2: initialState?.address2 || '',
        city: initialState?.city || '',
        postalCode: initialState?.postalCode || '',
        state: initialState?.state || '',
        country: initialState?.country || '',
        fullAddress: initialState?.fullAddress || '',
        phoneNumber:initialState?.phoneNumber || '',
        isGoogleAddress: false
    });
    const [touched, setTouched] = useState({
        address1: false,
        address2: false,
        city: false,
        postalCode: false,
        state: false,
        country: false,
        phoneNumber:false
    });



    const handlePlaceChanged = (place) => {
        let address1 = "";
        const fullAddress = place.formatted_address;
        if (fullAddress) {
            setAddressData((prevState) => ({ ...prevState, fullAddress, isGoogleAddress: true }));
            for (const component of place.address_components) {
                const componentType = component.types[0];
                switch (componentType) {
                    case "street_number":
                        address1 = `${component.long_name} ${address1}`;
                        break;
                    case "route":
                        address1 += component.short_name;
                        setAddressData((prevState) => ({ ...prevState, address1 }));
                        break;
                    case "postal_code":
                        const postcode = `${component.short_name}`;
                        setAddressData((prevState) => ({ ...prevState, postalCode: postcode }));
                        break;
                    case "locality":
                        const locality = component.long_name;
                        setAddressData((prevState) => ({ ...prevState, city: locality }));
                        break;
                    case "administrative_area_level_1":
                        const state = component.short_name;
                        setAddressData((prevState) => ({ ...prevState, state }));
                        break;
                    case "country":
                        const country = component.short_name;
                        setAddressData((prevState) => ({ ...prevState, country }));
                        break;
                }
            }

        }

    };

    useEffect(() => {

        const initializeAutocomplete = () => {
            loadGoogleMapsAPI().then(() => {
                const autocompleteInput = document.getElementById(id);
                const options = {
                    types: ['address'],
                    fields: ['address_components', 'formatted_address', 'place_id', 'geometry', 'name'],
                    componentRestrictions: { country: country.toLowerCase() },
                };

                if (autocompleteInput) {
                    const autocomplete = new window.google.maps.places.Autocomplete(autocompleteInput, options);
                    autocomplete.addListener('place_changed', () => {
                        const places = autocomplete.getPlace();
                        handlePlaceChanged(places);
                    });
                }
            });
        };

        initializeAutocomplete();
    }, []);

    useEffect(() => {
        const updateParentComponent = () => {
            const isValidPhoneNumber = showPhoneNumber ? isValidAddress('phoneNumber'):true;
            onChange({
                address: addressData,
                isValidAddress:
                    isValidAddress('address1') &&
                    isValidAddress('address2') &&
                    isValidAddress('city') &&
                    isValidAddress('state') &&
                    isValidAddress('postalCode') &&
                    isValidPhoneNumber
            });
        };

        updateParentComponent();
    }, [addressData]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if(name === 'phoneNumber'){
            handlePhoneNumberChange(event);
        }
        else{
            setAddressData((prevState) => ({
                ...prevState,
                [name]: value,
                isGoogleAddress: false
            }));
        }
    };

    const handleOnBlur = (event) => {
        if (event) {
            const { name } = event.target;
            setTouched((prevState) => ({
                ...prevState,
                [name]: true
            }));
        }
    };

    const isValidAddress = (componentName) => {
        const noSpecialChars = countryOptions.ADDRESS_REGEX_CFUI ? RegexParser(countryOptions.ADDRESS_REGEX_CFUI) : null;
        const cityNameRegex = countryOptions.CITY_NAME_REGEX ? RegexParser(countryOptions.CITY_NAME_REGEX) : null;
        const postCodeRegex = countryOptions.POSTCODE_REGEX ? RegexParser(countryOptions.POSTCODE_REGEX) : null;
        const phoneNumberRegex = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;

        switch (componentName) {
            case 'address1':
                return addressData.address1.length > 5 && (!noSpecialChars || !addressData.address1.match(noSpecialChars));
            case 'address2':
                return true;
            case 'city':
                return addressData.city.length > 0 && (!noSpecialChars || !addressData.city.match(noSpecialChars)) && cityNameRegex && cityNameRegex.test(addressData.city);
            case 'state':
                return addressData.state.length === 2 && (!noSpecialChars || !addressData.state.match(noSpecialChars));
            case 'postalCode':
                return addressData.postalCode.length >= 5 && (!noSpecialChars || !addressData.postalCode.match(noSpecialChars)) && postCodeRegex && postCodeRegex.test(addressData.postalCode);
            case 'phoneNumber':
                return phoneNumberRegex.test(addressData.phoneNumber);
            default:
                return false;
        }
    };

    const  handlePhoneNumberChange = (event) => {
        let { name, value } = event.target;
        value = value.replace(/\D/g,'');
        let size = value.length;
        if (size>0) {value="("+value}
        if (size>3) {value=value.slice(0,4)+") "+value.slice(4,11)}
        if (size>6) {value=value.slice(0,9)+"-" +value.slice(9)}
        setAddressData((prevState) => ({
            ...prevState,
            [name]: value,
            isGoogleAddress: false
        }));
    };



    const addressUIData = [
        {
            id: id,
            name: "address1",
            label: translations.STREET,
            value: addressData.address1,
            space: "12",
            maxLength: 100,
        },
        {
            id: "",
            name: "address2",
            label: `${translations.STREET}2`,
            value: addressData.address2,
            space: "12",
            maxLength: 50
        },
        {
            id: "",
            name: "city",
            label: translations.CITY_NAME,
            value: addressData.city,
            space: "6",
            maxLength: 30
        },
        {
            id: "",
            name: "state",
            label: translations.STATE,
            value: addressData.state,
            space: "3",
            maxLength: 2
        },
        {
            id: "",
            name: "postalCode",
            label: translations.ZIP_CODE,
            value: addressData.postalCode,
            space: "3",
            maxLength: translations.POSTCODE_LENGTH
        }
    ];

    if(showPhoneNumber){
        addressUIData.push({
            id: "",
            name: "phoneNumber",
            label: translations.PHONE_NUMBER,
            value: addressData.phoneNumber,
            space: "12",
            maxLength: translations.PHONE_TYPING_LENGTH,
            errorMessage:(addressData.phoneNumber.length === 0) ? translations.ENTER_VALID_PHONE : translations.INVALID_INPUT
        })
    }

    if(!id){
        console.log("Id is field required to use Address Form")
        return null
    }

    return (
        <div>

            <Grid container spacing={1}>
                {
                    showFormError &&  <div className="error-message">
                        {translations.ADDRESS_FORM_SUBMISSION_ERROR || "ADDRESS_FORM_SUBMISSION_ERROR"}
                    </div>
                }
                {
                    (showRangeError) && <div className="error-message">
                        {translations.ADDRESS_OUT_OF_RANGE_ERROR || "ADDRESS_OUT_OF_RANGE_ERROR"}
                    </div>
                }
                {addressUIData.length > 0 && addressUIData.map((item) => {
                    const convertedValue = Number(item.space);
                    return (
                        <Grid item xs={convertedValue} key={item.name}>
                            <TextField
                                id={item.id}
                                key={item.name}
                                name={item.name}
                                variant="filled"
                                className="appointment-concierge-input-pickup"
                                label={item.label}
                                value={item.value}
                                inputProps={{ maxLength: item.maxLength }}
                                onChange={handleInputChange}
                                onBlur={handleOnBlur}
                                error={((touched[item.name] || showFormError) && !isValidAddress(item.name))}
                                helperText={ item.errorMessage || (item.value.length === 0 ? translations.REQUIRED_FIELD_VALIDATOR : translations.INVALID_INPUT)}
                                required={item.name !== 'address2'}
                            />
                        </Grid>
                    );
                })}

            </Grid>
        </div>
    );
};

export default AddressForm;
