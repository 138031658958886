import {
    SET_LOYALTY_DETAILS,
    USE_LOYALTY_POINTS,
    SOFT_ENROLL,
    GET_LOYALTY_ERROR,
    ENROLL_MY_REWARDS_FLAG,
    ENROLL_MY_REWARDS_ERROR,
    SHOW_ENROLL_SPINNER,
    REWARDS_RAN,
    SHOW_ERROR,
    SET_MY_REWARDS_POINTS_INDICATOR,
    IS_BANNER_DISPLAYED,
} from './ActionTypes';

import { notApplicable } from '../shared/Constants/SharedConstants';

const initialState = {
    tries: 0,
    showSpinner: true,
    usePoints: false,
    softEnroll: false,
    getLoyaltyError: undefined,
    enrollMyRewardsError: undefined,
    enrollRewards: false,
    nav: '',
    showErrorMessage: false,
    showEnrollSpinner: false,

    active: null,
    memberNumber: '',
    preferredBrand: '',
    preferredFirstName: '',
    redeemEligible: null,
    redeemablePoints: 0,
    redeemablePointsDollarValue: 0,
    tierName: '',
    totalPoints: 0,
    isBannerDisplayed: false,
};

const MyRewardsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_LOYALTY_DETAILS: {
            let {
                memberNumber,
                preferredBrand,
                preferredFirstName,
                redeemEligible,
                redeemablePoints,
                redeemablePointsDollarValue,
                tierName,
                totalPoints,
            } = action.payload;
            return {
                ...state,
                active: true,
                memberNumber,
                preferredBrand,
                preferredFirstName,
                redeemEligible,
                redeemablePoints,
                redeemablePointsDollarValue,
                tierName,
                totalPoints,
                getLoyaltyError: false,
                showSpinner: false,
                softEnroll: false,
                loyaltyError: false,
                isBannerDisplayed: false,
            };
        }
        case USE_LOYALTY_POINTS: {
            let usePoints = action.payload;
            return { ...state, usePoints };
        }
        case SOFT_ENROLL: {
            let softEnroll = action.payload;
            return { ...state, softEnroll, showSpinner: false, active: undefined };
        }
        case GET_LOYALTY_ERROR: {
            let getLoyaltyError = action.payload;
            let tries = state.tries + 1;
            return { ...state, getLoyaltyError, showSpinner: false, tries };
        }
        case ENROLL_MY_REWARDS_ERROR: {
            let enrollMyRewardsError = action.payload;
            return { ...state, enrollMyRewardsError };
        }
        case ENROLL_MY_REWARDS_FLAG: {
            let enroll = action.payload;
            return { ...state, enrollRewards: enroll };
        }
        case REWARDS_RAN: {
            let nav = action.payload;
            return { ...state, nav };
        }
        case SHOW_ERROR: {
            return { ...state, showError: action.payload };
        }
        case SHOW_ENROLL_SPINNER: {
            return { ...state, showEnrollSpinner: action.payload };
        }
        case SET_MY_REWARDS_POINTS_INDICATOR: {
            let useMyRewardsIndicator = action.payload;
            let active = state.active;
            if (useMyRewardsIndicator !== notApplicable && state.active !== true) active = '';
            return { ...state, active };
        }
        case IS_BANNER_DISPLAYED: {
            return { ...state, isBannerDisplayed: action.payload };
        }
        default:
            return state;
    }
};

export default MyRewardsReducer;
