import { Grid, InputAdornment } from '@material-ui/core';
import { Field, Formik, Form } from 'formik';
import { TextField } from 'formik-material-ui';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import SignInError from './SignInError';
import logo from '../../assets/logo/gm-brandmark.png';
import { Link, Redirect } from 'react-router-dom';
import { loginIAM } from '../../shared/BackendCalls/SharedBackendGets';
import { resetGarageError } from '../../Redux/ActionCreator';
import React, { useEffect } from 'react';
const RegexParser = require('regex-parser');

const EMAIL_MAX_LENGTH = 256;
const PASSWORD_MAX_LENGTH = 64;

const SignIn = () => {
    const translations = useSelector((state) => state.countryOptionsReducer.translations);
    let garage = useSelector((state) => state.myGarageReducer);
    const locale = useSelector((state) => state.prefillReducer.locale);
    const dispatch = useDispatch();
    const emailSyntax = RegexParser(translations.EMAIL_SYNTAX);
    const loginSchema = Yup.object({
        email: Yup.string()
            .trim()
            .matches(emailSyntax, translations.INVALID_USERNAME)
            .required(translations.REQUIRED_FIELD_VALIDATOR),
        password: Yup.string().required(translations.REQUIRED_FIELD_VALIDATOR),
    });

    const errorAdornment = (
        <InputAdornment position="end">
            <div className="icon error" />
        </InputAdornment>
    );

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // Function to check if the user has vehicles in their MyGarage, when true route to MyGarage, else route to Vehicle page
    function gotCars() {
        if (garage.vehicles.length > 0) {
            return <Redirect to="/MyGarage" />;
        } else {
            return <Redirect to="/Vehicle" />;
        }
    }

    return (
        <div className="login-container">
            <h2>{translations.SIGN_IN}</h2>
            <div className="headline-border" />
            <Formik
                enableReinitialize={true}
                initialValues={{ email: '', password: '' }}
                validationSchema={loginSchema}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(false);
                    dispatch(resetGarageError());
                    loginIAM(values.email, values.password, locale);
                }}
            >
                {({ errors, isSubmitting, touched }) => (
                    <Form className="login-form">
                        {garage.loginStatus ? gotCars() : garage.garageFailed ? <Redirect to="/Vehicle" /> : null}
                        <Grid justifyContent="center" container spacing={2}>
                            <SignInError />
                            <Grid item xs={12}>
                                <Field
                                    component={TextField}
                                    disabled={garage.loading}
                                    fullWidth
                                    inputProps={{
                                        autoComplete: 'username',
                                        id: 'email-field',
                                        'data-dtm': 'email-field',
                                        'data-testid': 'email-field',
                                        maxLength: EMAIL_MAX_LENGTH,
                                    }}
                                    InputProps={{
                                        endAdornment: touched.email && errors.email && errorAdornment,
                                    }}
                                    label={translations.EMAIL}
                                    type="email"
                                    name="email"
                                    required
                                    variant="filled"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    component={TextField}
                                    disabled={garage.loading}
                                    fullWidth
                                    inputProps={{
                                        autoComplete: 'current-password',
                                        id: 'password-field',
                                        'data-testid': 'password-field',
                                        'data-dtm': 'password-field',
                                        maxLength: PASSWORD_MAX_LENGTH,
                                    }}
                                    InputProps={{
                                        endAdornment: touched.password && errors.password && errorAdornment,
                                    }}
                                    label={translations.PASSWORD}
                                    type="password"
                                    name="password"
                                    required
                                    variant="filled"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <div
                                    id="brnForgotUser"
                                    className="forgot-password-text"
                                    dangerouslySetInnerHTML={{
                                        __html: translations.FORGOT_PASSWORD,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <button
                                    id="btnSignIn"
                                    className="login-submit primary-button"
                                    data-dtm="submit-login"
                                    type="submit"
                                    disabled={garage.loading}
                                >
                                    {translations.SIGN_IN}
                                </button>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
            <Link id="btnContinue" className="main-button button secondary-button guest-button" to="/Vehicle">
                {translations.CONTINUE_GUEST}
            </Link>
        </div>
    );
};

export default SignIn;
