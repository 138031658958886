import React, {useState} from "react";
import {useSelector} from "react-redux";
import SharedRadioButton from "../RadioButton/SharedRadioButton";
import {renderPriceWithPriceSymbol} from "../Utils/PriceLocalization";
import SharedModal from "../Modal/SharedModal";




const ServicesOffers = (props)=>{
    const [servicesItem,setServicesItem] = useState(props.servicesItem);

    const [openModal,setOpenModal] = useState(false);
    const {translations} = useSelector((state)=>state.ossAppGlobals);
    let radioButton = [];


    const selectedOffer = (selectedItem)=>{

        const updatedOffers = servicesItem.offers.map((item) =>
            item.uniqueId === selectedItem.uniqueId ? { ...item, isSelected: true } : { ...item, isSelected: false }
        );


        setServicesItem({
            ...servicesItem,
            isSelected:true,
            offers: updatedOffers
        });

    }

    const handleUpdate = ()=>{
        console.log("STuff",)
    }



const handleClose = ()=>{
        setServicesItem(props.servicesItem);
        setOpenModal(false);
}

    const contentSimple = (
        <div className="offer-container">
            <div className="offer-headline">
                <div className="offer-headline-text headline2">
                    {servicesItem.name}
                </div>
                <div className="headline-border" />
            </div>
            <div className="offer-table table" id="Offers">
                {servicesItem.offers.map((item, index) => {
                    radioButton = [
                        {
                            checked: item.isSelected,
                            className: 'form-check-input',
                            disabled: false,
                            handleClick: ()=>{return false},
                            handleOnChange: ()=>{selectedOffer(item)},
                            id: "offers" + index,
                            label: item.name,
                            value: item.name,

                        },
                    ];
                    return (
                        <div className="offer-item paper" key={item.uniqueId}>
                            <div className="radio-button-label">
                             <SharedRadioButton
                                    className="stat-radio offer-radio-button bold"
                                    radioButtonProps={radioButton}
                                />

                            </div>
                            {item.subtext ? (
                                <div className="offer-subtitle">{item.name}</div>
                            ) : (
                                <div className="offer-subtitle">{item.subtitle}</div>
                            )}
                            <div className="display-price">
                                {
                                    item.price?.price ? renderPriceWithPriceSymbol(item.price):''
                                }
                                <div className="display-price-tagline">
                                    {item.price.tagLine ? item.price.tagLine : ''}
                                    {item.price.disclaimerTagLine ? item.price.disclaimerTagLine : ''}
                                </div>
                            </div>
                            <div className="offer-description">{item.description}</div>
                            <div className="offer-disclaimer">{item.disclaimer}</div>
                        </div>
                    );
                })}
            </div>
            <button
                id="btnUpdateOffer"
                className="stat-button-link add-offer-btn button primary-button"
                disabled={!servicesItem.isSelected}
                onClick={handleUpdate}
                type="button"
                data-dtm="service:offers"
            >
                {translations.UPDATE}
            </button>
        </div>
    );

    return <>
         <div>
            <button
                 id={"offersLink" + props.index}
                 className="stat-button-link offer-modal-link link"
                 onClick={()=>setOpenModal(true)}
                 onFocus={(event) => event.stopPropagation()}
                 type="button"
                 data-dtm={`service:${servicesItem.name}`}
                >
                 {translations.OFFERS || "OFFERS"}
             </button>
         </div>
        <SharedModal
            backdrop="static"
            className="offers-modal"
            content={contentSimple}
            handleClose={handleClose}
            keyboard={false}
            show={openModal}
            simpleModal
        />
    </>
}

export default ServicesOffers;