import React from 'react';
import { connect } from 'react-redux';
import { addMISCService } from '../../Redux/ActionCreator';

class CommentsBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.getInitalValue(),
            lengthRemaining: this.props.vRemainingLength,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleOnFocus = this.handleOnFocus.bind(this);
        this.handleOnBlur = this.handleOnBlur.bind(this);
    }

    getInitalValue() {
        return this.props.vmiscService ? this.props.vmiscService : this.props.initialText;
    }

    handleChange(event) {
        const filteredValues = [...event.target.value].filter((x) => x.match(/[^{};<>]/) !== null).join('');
        this.setState({
            value: filteredValues,
            lengthRemaining: 300 - filteredValues.length,
        });
        this.props.addMISCService(filteredValues.trim());
    }

    handleOnFocus(event) {
        this.state.value === this.props.initialText ? this.setState({ value: '', lengthRemaining: 300 }) : null;
    }

    handleOnBlur(event) {
        this.props.addMISCService(event.target.value.trim());
        event.target.value.length === 0
            ? this.setState({ value: this.props.initialText, lengthRemaining: 300 })
            : this.setState({
                  value: event.target.value,
                  lengthRemaining: 300 - event.target.value.length,
              });
    }

    render() {
        return (
            <form className="submit-appointment-comments">
                <label className="submit-appointment-label">
                    {this.props.label}
                    <textarea
                        id="txtAdditionalComments"
                        className="stat-input-field submit-appointment-comments-box"
                        placeholder={this.state.value}
                        maxLength="300"
                        type="text"
                        value={this.state.value}
                        onChange={this.handleChange}
                        onFocus={this.handleOnFocus}
                        onBlur={this.handleOnBlur}
                        data-dtm="service needs"
                    />
                </label>
                <div className="submit-appointment-length-remaining">
                    {this.state.lengthRemaining} {`${this.props.vTranslations.CHARACTERS_REMAINING.toLowerCase()}...`}
                </div>
            </form>
        );
    }
}

const mapStateToProps = (state) => ({
    vmiscService: state.ossAppGlobals.miscService,
    vbooleanVal: state.ossAppGlobals.booleanVal,
    vRemainingLength: state.ossAppGlobals.remainingLength,
    vTranslations: state.countryOptionsReducer.translations,
});

export default connect(mapStateToProps, { addMISCService })(CommentsBox);
