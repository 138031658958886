import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import $ from 'jquery';
import Disclaimer from '../Review/Disclaimer';
import CustomerInfo from '../Review/CustomerInfo';
import SelectedServices from '../Review/SelectedServices';
import AppointmentDetails from '../Review/AppointmentDetails';
import FeedBack from '../Review/FeedBack';
import DateAndTime from '../Review/DateAndTime';
import CancelAppointment from './CancelAppointment';
import { setNewAppointmentFlag, setEditFlagAnalytics } from '../../Redux/ActionCreator';
import AnalyticsService from '../../shared/Utils/analytics.service';
import { adobeAnalytics } from '../../shared/Utils/Utils';
import {EditAppointmentFlow} from "../../shared/RoutingFlow/FlowComponentsConstants";
import NoTimeSlotErrorModal from "../Review/NoTimeSlotErrorModal";
import VanErrorModal from "../Review/VanErrorModal";
import OnTracErrorOnSubmission from "../Review/OnTracErrorOnSubmmission";


class Confirmation extends Component {
    state = {
        keepAppointment: true,
    };

    constructor(props) {
        super(props);
        this.props.setNewAppointmentFlag(false);
    }

    componentDidUpdate() {
        if (AnalyticsService().loggingEnabled) {
            if (this.props.editFlagAnalytics === 'SUBMIT') {
                $('body').append(adobeAnalytics('form-complete'));
                console.log('Analytics Added for Page to DOM: Appointment Confirmation');
                this.props.setEditFlagAnalytics('');
            } else if (this.props.showCancelButtonsFlag || this.props.deleteAppointmentFlag) {
                $('body').append(adobeAnalytics('form-step'));
                console.log('Analytics Added for Page to DOM: Appointment Cancellation');
                this.state.keepAppointment = false;
            } else if (!this.state.keepAppointment) {
                $('body').append(adobeAnalytics('form-step'));
                console.log('Analytics Added for Page to DOM: Appointment Keep');
                this.state.keepAppointment = true;
            }
        }
    }

    componentDidMount() {
        // Initialize the Analytics Functionality if Appropriate
        if (AnalyticsService().loggingEnabled) {
            // Append the Analytics Object to the DOM
            if (this.props.editFlagAnalytics === 'EDIT') {
                $('body').append(adobeAnalytics('edit-complete'));
                console.log('Analytics Added for Page to DOM: Appointment Confirmation');
                this.props.setEditFlagAnalytics('');
            } else if (this.props.editFlagAnalytics === 'CANCEL') {
                $('body').append(adobeAnalytics('form-step'));
                console.log('Analytics Added for Page to DOM: Appointment Confirmation');
                this.props.setEditFlagAnalytics('');
            }
        }
    }

    // Checking same criteria from FeedBack that determines if feedback section is displayed
    isFeedbackVisible() {
        return this.props.vCountryOptions.CFUI_FEEDBACK_ENABLED === 'true' &&
            this.props.vDeploymentMode !== 'REQUEST_FORM' &&
            this.props.modifyFlag === false &&
            this.props.deleteAppointmentFlag === false &&
            this.props.flowSource !== EditAppointmentFlow;
    }

    render() {
        window.scrollTo(0, 0);
        let appointmentTitle = '';

        if (this.props.deleteAppointmentFlag) {
            appointmentTitle = this.props.vTranslations.CANCEL_SUCCESS;
        } else if (this.props.showCancelButtonsFlag) {
            appointmentTitle = this.props.vTranslations.CANCEL_APPOINTMENT;
        } else if (this.props.showSpinner) {
            appointmentTitle = this.props.vTranslations.IN_PROGRESS_VALIDATOR;
        } else if (this.props.vDeploymentMode === 'REQUEST_FORM') {
            appointmentTitle = this.props.vTranslations.THANKS_MESSAGE1;
        } else if (!this.isFeedbackVisible()) {
            appointmentTitle = this.props.vTranslations.APP_ALL_SET_SUBMIT;
        } else {
            appointmentTitle = this.props.vTranslations.APP_ALL_SET;
        }

        return (
            <>
                {this.props.networkErrorFlag && <Redirect to="/Error" />}
                <div className="review-confirm-container">
                    <div className="review-confirm-title headline2">{appointmentTitle}</div>
                    <div className="review-confirm-headline-border headline-border " />
                    {this.props.showCancelButtonsFlag ? (
                        <div className="review-confirm-grid">
                            <CancelAppointment />
                        </div>
                    ) : (
                        <div className="review-confirm-grid">
                            <DateAndTime />
                            <SelectedServices />
                            <AppointmentDetails />
                            <CustomerInfo />
                            <Disclaimer />
                            <FeedBack />
                        </div>
                    )}
                </div>
                <NoTimeSlotErrorModal/>
                <VanErrorModal/>
                <OnTracErrorOnSubmission/>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    reviewComments: state.reviewReducer.comments,
    headerTitle: state.headReducer.headerTitle,
    deleteAppointmentFlag: state.confirmationReducer.deleteAppointmentFlag,
    vTranslations: state.countryOptionsReducer.translations,
    networkErrorFlag: state.appointmentReducer.networkErrorFlag,
    showCancelButtonsFlag: state.confirmationReducer.showCancelButtonsFlag,
    showSpinner: state.ossAppGlobals.showSpinner,
    recordLocator: state.reviewReducer.recordLocator,
    editFlagAnalytics: state.confirmationReducer.editFlagAnalytics,
    vDeploymentMode: state.prefillReducer.dealerSummaryObject.dealershipSummary.deploymentMode,
    vCountryOptions: state.countryOptionsReducer.countryOptions,
    modifyFlag: state.appointmentReducer.modifyFlag,
    flowSource: state.prefillReducer.flowSource,
});

export default connect(mapStateToProps, { setNewAppointmentFlag, setEditFlagAnalytics })(Confirmation);
withRouter(Confirmation);
