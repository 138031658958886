import React from 'react';
import { Route, Router, Switch, withRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { connect } from 'react-redux';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import AppRoutes from './AppRoutes';
import { setupTitle } from '../../shared/Utils/Utils';
import { setHeaderTitle, setTranslations, setSession } from '../../Redux/ActionCreator';
import EntryParams from '../Util/EntryParams';
import AnalyticsService, {
    getPageNameForAnalytics,
    resetPageNameForAnalytics
} from '../../shared/Utils/analytics.service';
import MyRewards from '../MyRewards/MyRewards';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';

const constants = require('../../shared/Constants/SharedConstants');
const { CONFIRMATION, SIGN_IN, LANDING } = constants;

export const browserHistory = createBrowserHistory({
    basename: '/oss_redesign/ui-modules_redesign/',
});

// Called when React Router change
browserHistory.listen(() => {
    setupTitle();
});

const theme = createTheme({
    palette: {
        primary: {
            main: '#343a40',
        },
    },
});
class App extends React.Component {
    constructor(props) {
        super(props);
        let session = document.querySelector("meta[name='oss-session']").getAttribute("content");
        this.props.setSession(session ? session : '');
        browserHistory.listen(() => {
            const currentLocation = window.location.pathname;
            this.props.setHeaderTitle(currentLocation);
            if (
                currentLocation !== '/oss_redesign/ui-modules_redesign/DevelopEntryParams' &&
                currentLocation !== '/oss_redesign/ui-modules_redesign/EntryParams' &&
                currentLocation !== '/oss_redesign/ui-modules_redesign/'
            ) {
                const analyticsService = AnalyticsService();
                if (analyticsService.loggingEnabled) {
                    let analyticsDesc = browserHistory.location.pathname;
                    const readFromSession = getPageNameForAnalytics('analyticsPageName');

                    if (analyticsDesc) {
                        switch (analyticsDesc) {
                            case '/Vehicle':
                                analyticsDesc = 'Vehicle';
                                break;
                            case '/Locator':
                                analyticsDesc = 'Dealer Locator';
                                break;
                            case '/OssLandingPage':
                                resetPageNameForAnalytics("analyticsPageName");
                                analyticsDesc = 'Service';
                                break;
                            case '/Appointment':
                                if (readFromSession){
                                    analyticsDesc = "modify-appointment"
                                } else{
                                    analyticsDesc = 'Appointment';
                                }
                                // case "/appointment/:edit/:isBranch":
                                // case "/appointment/:edit":
                                break;
                            case '/SubmitAppointment':
                                if (readFromSession){
                                    if(readFromSession === "cancel"){
                                        analyticsDesc = "cancel"
                                    }else{
                                        analyticsDesc = "modify-appointment:confirm"
                                    }
                                } else{
                                    analyticsDesc = 'Confirm';
                                }
                                // case "/contact/:edit/:isBranch":
                                // case "/contact/:edit":
                                break;
                            case '/Confirmation':
                                if (readFromSession){
                                    if(readFromSession === "cancelAppointment"){
                                        analyticsDesc = "app cancelled"
                                    } else{
                                        analyticsDesc = "modify-appointment:submitted"
                                    }
                                } else{
                                    analyticsDesc = 'App Submitted';
                                }
                                // case "/success/:edit":

                                break;
                        }

                        console.log(`AppJS ANALYTICS:${analyticsDesc}`);

                        if (analyticsDesc === 'App Submitted') {
                            // appointment submission, so we will log a custom event along with page visit
                            analyticsService.trackAppointmentSubmissionPageAndEvent(analyticsDesc);
                        } else {
                            analyticsService.trackPage(analyticsDesc);
                            console.log(`Calling the Analytics Track Page:${analyticsDesc}`);
                        }
                    }
                }
            }
        });
    }

    // eslint-disable-next-line react/state-in-constructor
    state = {
        sideDrawerOpen: false,
    };

    componentDidMount() {
        setupTitle();
    }

    render() {
        const routeComponents = AppRoutes.map(({ path, component }, key) => (
            <Route exact path={path} component={component} key={key} />
        ));

        return (
            <Router history={browserHistory}>
                <MuiThemeProvider theme={theme}>
                    <div className="app-container">
                        {!(
                            this.props.headerNav === CONFIRMATION ||
                            this.props.headerNav === SIGN_IN ||
                            this.props.headerNav === LANDING
                        ) ? (
                            <Header />
                        ) : null}
                        <MyRewards />
                        <Switch>
                            {routeComponents}
                            <Route component={EntryParams} />
                        </Switch>
                        <Footer />
                    </div>
                </MuiThemeProvider>
            </Router>
        );
    }
}

const mapStateToProps = (state) => ({
    headerNav: state.headReducer.headerNavigation,
    active: state.myRewardsReducer.active,
});

export default connect(mapStateToProps, { setHeaderTitle, setTranslations, setSession })(App);
withRouter(App);
