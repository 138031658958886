import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setShowCancelButtonsFlag } from '../../Redux/ActionCreator';
import { formatDateLong } from '../../shared/Utils/TimeLocalization';
import { handleDelete } from '../../shared/BackendCalls/SharedBackendGets';
import CircularProgress from "@material-ui/core/CircularProgress";
import {Grid, Grow} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import AnalyticsService from "../../shared/Utils/analytics.service";

class CancelAppointment extends React.Component {
    setShowCancelButtonsFalse = () => {
        AnalyticsService().trackAppointmentSubmissionPageAndEvent("app submitted");
        this.props.setShowCancelButtonsFlag(false);
    };

    render() {
        const formatDate = formatDateLong(this.props.selectedDate);
        let { cancelError, showSpinner, vTranslations } = this.props;

        // Conditional logic for cancelError and showSpinner to show/hide appointment cancel error message and processing spinner respectively
        return (
            <div>
                {cancelError ?
                    <Grid item xs={12}>
                        <Grow in={cancelError}>
                            <Alert className="login-error" icon={<i className="icon alert-caution-red" />} severity="error">
                                {vTranslations.CANCEL_ERROR}
                            </Alert>
                        </Grow>
                    </Grid> : null }
                <div className="cancel-appointment-content">
                    {showSpinner ? <CircularProgress /> :
                        <div>
                            <div className="cancel-appointment-title-subtitle paper">
                                <div className="cancel-appointment-date headline3">{formatDate}</div>
                                <div className="cancel-appointment-text-container">
                                    <div className="icon alert-caution-red" />
                                    <div className="cancel-appointment-text">{this.props.vTranslations.CONFIRM_CANCEL}</div>
                                </div>
                            </div>
                            <div className="cancel-appointment-button-container">
                                <input
                                    id="btnCancelAppt"
                                    data-dtm="confirm cancel"
                                    onClick={handleDelete}
                                    type="submit"
                                    value={this.props.vTranslations.CANCEL_APPOINTMENT}
                                    className="stat-button-link cancel-appointment-button button primary-button"
                                />
                                <input
                                    data-dtm="confirm cancel"
                                    onClick={this.setShowCancelButtonsFalse}
                                    type="submit"
                                    value={this.props.vTranslations.KEEP_APPOINTMENT}
                                    className="stat-button-link keep-appointment-button button secondary-button"
                                />
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    selectedDate: state.appointmentReducer.selectedDate,
    deleteAppointmentFlag: state.confirmationReducer.deleteAppointmentFlag,
    vTranslations: state.countryOptionsReducer.translations,
    showSpinner: state.ossAppGlobals.showSpinner,
    cancelError: state.confirmationReducer.cancelError,
});

export default connect(mapStateToProps, { setShowCancelButtonsFlag })(CancelAppointment);
withRouter(CancelAppointment);
