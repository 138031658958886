import { useSelector } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import { Grid, Grow } from '@material-ui/core';

const SignInError = () => {
    const translations = useSelector((state) => state.countryOptionsReducer.translations);
    const garage = useSelector((state) => state.myGarageReducer);

    return garage.message ? (
        <Grid item xs={12}>
            <Grow in={garage.message !== ''}>
                <Alert className="login-error" icon={<i className="icon alert-caution-red" />} severity="error">
                    {translations[garage.message]}
                </Alert>
            </Grow>
        </Grid>
    ) : (
        <p className="landing-prompt">{translations.START_BOOKING}</p>
    );
};

export default SignInError;
