import React from 'react';
import { connect } from 'react-redux';
import { setExpandedOffer, setSelectedOffer } from '../../Redux/ActionCreator';
import { renderCurrencyValue, renderPrice } from '../../shared/Utils/PriceLocalization';

import * as consts from '../../shared/Constants/ServiceConstants';

class MoreInfo extends React.Component {
    handleChange = (panel) => () => {
        if (this.props.expandedOffer !== panel) {
            this.props.setExpandedOffer(panel);
        } else {
            this.props.setExpandedOffer(false);
        }
    };

    renderSecondaryPrice() {
        return (
            <div className="item-price-secondary">
                {this.props.displayPrice && this.props.displayPrice[1] ? renderPrice(this.props.displayPrice[1]) : ''}
            </div>
        );
    }

    render() {

        console.log('Props',this.props);

        function createMarkup(innerHTMLText) {
            return {
                __html: innerHTMLText,
            };
        }

        const showLessButton = (
            <button
                className="service-item-details-link link stat-text-link"
                data-dtm={`service:need-something-else:${this.props.name}`}
                onClick={this.handleChange(this.props.serviceFEID)}
                type="button"
            >
                {this.props.vTranslations.SHOW_LESS}
            </button>
        );

        let contentSimple = '';
        if (this.props.serviceType === consts.PRIORITY_SERVICE) {
            contentSimple = (
                <div className="more-info-container recall-notice">
                    <div className="item-text">
                        <div className="item-label">{this.props.vTranslations.DESCRIPTION}</div>
                        <div className="item-description">{this.props.itemDescription}</div>
                    </div>
                    <div className="item-text">
                        <div className="item-label">{this.props.vTranslations.SAFETY_RISK}</div>
                        <div className="item-description">{this.props.safetyDescription}</div>
                    </div>
                    <div className="item-text">
                        <div className="item-label">{this.props.vTranslations.REMEDY}</div>
                        <div className="item-description">{this.props.remedyDescription}</div>
                    </div>
                    {showLessButton}
                </div>
            );
        } else if (this.props.serviceType === consts.CRITICAL_SERVICE) {
            if (this.props.offers && this.props.offers.length > 0) {
                contentSimple = (
                    <div className="more-info-container">
                        <div className="offers-title">
                            {this.props.name} {this.props.vTranslations.OFFERS}
                        </div>
                        <div className="offers-desc">{this.props.itemDescription}</div>
                        {this.props.offers
                            .filter((offerCode) => offerCode.code === this.props.code)
                            .map((desc) => (
                                <li className="item-desc-item" key={desc.serviceFEID}>
                                    <div className="desc-title">{desc.name}</div>
                                    <div className="desc-body">
                                        {this.props.vTranslations.DETAILS}:{desc.offerSubTitle}{' '}
                                        {this.props.vTranslations.DISCLAIMER}:{desc.disclaimer}{' '}
                                    </div>
                                </li>
                            ))}
                        {showLessButton}
                    </div>
                );
            } else {
                contentSimple = (
                    <div className="more-info-container">
                        <div className="item-title bold">{this.props.name}</div>
                        <div className="item-description">{this.props.itemDescription}</div>
                        {showLessButton}
                    </div>
                );
            }
        } else if (this.props.serviceType === consts.NON_CRITICAL_SERVICE) {
            contentSimple = (
                <div className="more-info-container">
                    <div className="item-label">{this.props.vTranslations.MAINT_INC}:</div>
                    {this.props.vmaintenanceItems
                        .filter((offerCode) => offerCode.code === this.props.code)
                        .map((desc) => (
                            <li className="desc-title body2" key={desc.serviceFEID}>
                                {desc.name}
                            </li>
                        ))}
                    {showLessButton}
                </div>
            );
        } else if (this.props.serviceType === consts.SERVICES_ITEM_SERVICE) {
            contentSimple = (
                <div className="more-info-container">
                    {this.renderSecondaryPrice()}
                    <div className="item-disclaimer">{this.props.priceDisclaimer}</div>
                    <div className="item-label">
                        {this.props.disclaimer ? this.props.vTranslations.DISCLAIMER : null}
                    </div>
                    <div className="item-disclaimer">{this.props.disclaimer}</div>
                    {showLessButton}
                </div>
            );
        } else if (this.props.serviceType === consts.CONSOLIDATED_ITEM_SERVICE) {
            contentSimple = (
                <div className="more-info-container">
                    <div className="item-subtitle">{this.props.offerSubTitle}</div>
                    {this.renderSecondaryPrice()}
                    <div className="item-description">{this.props.itemDescription}</div>
                    <div className="item-disclaimer">{this.props.disclaimer}</div>
                    {showLessButton}
                </div>
            );
        } else if (
            this.props.serviceType === consts.PEGA_ENTERPRISE_SERVICE ||
            this.props.serviceType === consts.PEGA_ADDITIONAL_SERVICE
        ) {
            contentSimple = (
                <div className="more-info-container">
                    <div className="item-title">
                        {this.props.displayPrice && !this.props.displayPrice[0].tagLine && !this.props.displayPrice[1]
                            ? renderCurrencyValue(this.props.displayPrice[0])
                            : ''}
                    </div>
                    <div className="item-subtitle">{this.props.offerSubTitle}</div>
                    {this.renderSecondaryPrice()}
                    <div className="item-description">{this.props.itemDescription}</div>
                    <div className="item-disclaimer">{this.props.disclaimer}</div>
                    {showLessButton}
                </div>
            );
        } else if (this.props.serviceType === consts.MAINTENANCE_SERVICE) {
            contentSimple = (
                <div className="more-info-container">
                    {this.renderSecondaryPrice()}
                    <div className="item-subtitle">
                        {this.props.replace ? this.props.vTranslations.REPLACE_HEADER : null}
                    </div>
                    <div className="item-disclaimer">{this.props.replace}</div>
                    <div className="item-label">{this.props.vTranslations.INSPECT_HEADER}</div>
                    <div className="item-disclaimer">{this.props.inspect}</div>
                    <div className="item-disclaimer">{this.props.priceDetails}</div>
                    <div className="item-disclaimer">
                        {this.props.priceDisclaimer}
                        <div className="item-disclaimer-label">
                            {this.props.disclaimer ? this.props.vTranslations.DISCLAIMER : null}
                        </div>
                    </div>
                    <div className="item-disclaimer">{this.props.disclaimer}</div>
                    {showLessButton}
                </div>
            );
        } else if (this.props.serviceType === consts.DEALERSHIP_ITEM_SERVICE) {
            contentSimple = (
                <div className="more-info-container">
                    {this.renderSecondaryPrice()}
                    <div className="item-subtitle"> {this.props.subtext}</div>
                    <div
                        className="item-description"
                        dangerouslySetInnerHTML={createMarkup(this.props.itemDescription)}
                    />
                    <div className="item-disclaimer" dangerouslySetInnerHTML={createMarkup(this.props.disclaimer)} />
                    {showLessButton}
                </div>
            );
        } else if (this.props.serviceType === consts.DEALER_CONFIG_ITEM_SERVICE) {
            contentSimple = (
                <div className="more-info-container">
                    {this.renderSecondaryPrice()}
                    <div className="item-disclaimer">{this.props.disclaimer}</div>
                    {showLessButton}
                </div>
            );
        }

        return contentSimple;
    }
}

const mapStateToProps = (state) => ({
    expandedOffer: state.ossAppGlobals.expandedOffer,
    vmaintenanceItems: state.ossAppGlobals.maintenanceItems,
    vTranslations: state.countryOptionsReducer.translations,
});

export default connect(mapStateToProps, {
    setExpandedOffer,
    setSelectedOffer,
})(MoreInfo);
