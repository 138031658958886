import dayjs from 'dayjs';
import {
    setActComplete,
    setAdvisor,
    setDateFromEditAppointment,
    setHideContactInfoFlag,
    setOssGlobalEdit,
    setPrefillEdit,
    setTransportation,
    addAdditionalComments,
    setMyRewardsPointsIndicator,
} from './ActionCreator';
import { setupDateLocalization } from '../shared/Utils/TimeLocalization';
import store from './Store';

const customParseFormat = require('dayjs/plugin/customParseFormat');
const sharedConstants = require('../shared/Constants/SharedConstants');

dayjs.extend(customParseFormat);

export default function editAppointmentHelper(data) {
    const transportationOption = getTransportationObject(data.transportationOption);

    store.dispatch(setMyRewardsPointsIndicator(data.useMyRewardsIndicator));
    store.dispatch(setPrefillEdit(data));
    store.dispatch(setOssGlobalEdit(data));
    store.dispatch(setTransportation(transportationOption));
    store.dispatch(addAdditionalComments(data.customerComments));
    store.dispatch(setHideContactInfoFlag('true'));

    if (data.advisor) {
        store.dispatch(setAdvisor(data.advisor));
    }

    setupDateLocalization();

    const date = dayjs(data.appointmentDate + data.appointmentTime.start, 'YYYY-MM-DDHH:mm:ss');

    store.dispatch(setDateFromEditAppointment(date));

    // Once all the below async threads have spawned then set the flag to true
    store.dispatch(setActComplete(sharedConstants.EDTCOM));
}

function getTransportationObject(code) {
    const localStore = store.getState();
    let transportationOption = null;

    const { transportationOptions } = localStore.appointmentReducer;

    transportationOptions.forEach((option) => {
        if (option.code === code) {
            transportationOption = option;
        }
    });

    return transportationOption;
}
