import React from 'react';
import LegacyServices from "./LegacyServices";
import {useFlags} from "launchdarkly-react-client-sdk";
import NewServicesPage from "./NewServicesPage/NewServicesPage";



const Services = ()=>{
    const { enableServicesV2 } = useFlags();


    return ( enableServicesV2 ? <NewServicesPage/> :
        <>
            <LegacyServices/>
        </>
        )
}

export default Services

