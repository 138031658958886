import {useSelector} from "react-redux";
import * as React from "react";


const MobileServiceIndicator = ()=>{
    const { translations } = useSelector((state) => state.countryOptionsReducer);


    return <div className="dealer-locator-indicator">
        <i className="mobile-service-icon icon info" />
        <span>{translations.MOBILE_SERVICE_DEALER_ELIGIBLE || "MOBILE_SERVICE_DEALER_ELIGIBLE"} </span>
        </div>
}

export default MobileServiceIndicator