import React from 'react';
import { connect } from 'react-redux';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import SharedModal from '../../shared/Modal/SharedModal';

class OdometerPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mileage: this.props.mileage,
            showErrorMessage: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({
            mileage: event.target.value,
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        const { mileage } = this.state;
        let mileageReplace = mileage.toString().replace(/[,]/g, '');
        if (mileageReplace !== '0') {
            mileageReplace = mileageReplace.toString().replace(/^0+/, '');
        }
        if (mileageReplace.toString().match(/^\d+$/) && mileageReplace >= 0 && mileageReplace < 1000000) {
            this.hideErrorMessage();
            this.props.submitOdometerValue(mileageReplace);
        } else {
            this.displayErrorMessage();
        }
    }

    displayErrorMessage() {
        this.setState({
            showErrorMessage: true,
        });
    }

    hideErrorMessage() {
        this.setState({
            showErrorMessage: false,
        });
    }

    render() {
        const adornment = this.state.showErrorMessage ? (
            // eslint-disable-next-line react/jsx-filename-extension
            <InputAdornment position="end">
                <div className="icon error" />
            </InputAdornment>
        ) : null;

        const odometerModalContent = (
            <form className="odometer-form" onSubmit={this.handleSubmit}>
                <div className="odometer-headline">
                    <h2 className="odometer-headline-text">{this.props.vTranslations.EDIT_MILEAGE}</h2>
                    <div className="headline-border" />
                </div>
                <TextField
                    className="stat-input-field mileage-input"
                    data-dtm="select vehicle"
                    error={this.state.showErrorMessage}
                    helperText={this.props.vTranslations.ODOMETER_MSG}
                    InputProps={{
                        endAdornment: adornment,
                    }}
                    inputProps={{
                        maxLength: '6',
                    }}
                    label={this.props.vTranslations.MILEAGE}
                    onChange={this.handleChange}
                    value={this.state.mileage}
                    variant="filled"
                />
                <input
                    type="submit"
                    value={this.props.vTranslations.UPDATE}
                    className="stat-button-close submit-button button primary-button"
                    data-dtm="mileage change"
                    disabled={this.state.mileage.toString().replace(',', '') === this.props.vMiles}
                />
            </form>
        );

        return (
            <div className="odometer-container">
                <SharedModal
                    className="odometer-modal"
                    content={odometerModalContent}
                    handleClose={this.props.closeOdometerPopup}
                    show={this.props.showOdometerPopup}
                    simpleModal
                    backdrop="static"
                    keyboard={false}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    vMiles: state.prefillReducer.miles,
    vTranslations: state.countryOptionsReducer.translations,
});

export default connect(mapStateToProps)(OdometerPopup);
