import React, {useEffect} from "react";
import {getListOfServices2} from "../../../shared/BackendCalls/SharedBackendGets";
import {useSelector} from "react-redux";
import RecommendedServiceList from "./RecommandedServiceList";


const NewServicesPage = ()=>{
    const {country, vin, bac, year, make, model, locale, plus, miles} = useSelector((state)=>state.prefillReducer);
    const {previousBac,newServiceList,translations} = useSelector((state)=>state.ossAppGlobals);



    const additionalServices = newServiceList ? newServiceList.additionalService?.sort((item)=>item.displayOrder) :[];
    const recommendedService = newServiceList ? newServiceList.recommendedService :[];




    useEffect(()=>{
        getListOfServices2(country, vin, bac, year, make, model, locale, previousBac, plus, miles)
    },[])



    return <div className="services-container">

        {
            recommendedService.length >0 && <div>
                <div className="services-title headline2">{translations.RECOMMENDED_SERVICES_TITLE}</div>
                <div className="headline-border" />
                <RecommendedServiceList item={recommendedService}/>
                <RecommendedServiceList item={additionalServices}/>

            </div>
        }
        {
            additionalServices.length >0 && <div>
                <div className="services-title headline2">{translations.RECOMMENDED_SERVICES_TITLE}</div>
                <div className="headline-border" />
                <RecommendedServiceList item={additionalServices}/>

            </div>
        }

      </div>
}

export default NewServicesPage