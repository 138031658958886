import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import AdditionalComments from './AdditionalComments';
import { vehicleRenderer } from '../../shared/BackendCalls/SharedBackendGets';
import { formatCustomerPhoneNumber } from './SubmitAppointmentHelpers';
import {setBrandText} from '../MyRewards/MyRewards';

const constants = require('../../shared/Constants/SharedConstants');
const flowConstants = require('../../shared/RoutingFlow/FlowComponentsConstants');

class CustomerInfo extends React.Component {
    additionalCommentsController() {
        if (
            this.props.headerNavigation === constants.REVIEW ||
            (this.props.headerNavigation === constants.CONFIRMATION && this.props.reviewComments)
        ) {
            return <AdditionalComments startingText={this.props.translations.ADD_COM} />;
        }
        return null;
    }

    displayCustomerInfo() {
        if (
            this.props.flowSource === flowConstants.EditAppointmentFlow ||
            ((this.props.flowSource === flowConstants.AuthenticatedFlow ||
                this.props.flowSource === flowConstants.AuthenticatedOCPlus) &&
                this.props.modifyFlag)
        ) {
            return (
                <div className="review-confirm-customer-info-hidden">
                    {this.props.translations.CUSTOMER_INFO_PRIVACY}
                </div>
            );
        }
        return (
            <div className="review-confirm-customer-personal-info">
                <div className="review-confirm-customer-name">{this.props.customerFirstName}</div>
                <div className="review-confirm-customer-phone">
                    {this.props.validFlag === true
                        ? formatCustomerPhoneNumber(this.props.customerPhoneNumber, this.props.country)
                        : null}
                </div>
            </div>
        );
    }

    render() {
        let { active, totalPoints, redeemablePoints, translations, preferredBrand, themeMake, make, brands } = this.props;
        return (
            <div className="review-confirm-customer-info paper">
                <div className="review-confirm-customer-title-container">
                    <div className="review-confirm-customer-title headline3">{this.props.translations.CUST_INFO}</div>
                    {this.props.headerNavigation !== constants.CONFIRMATION && this.props.selectedDate !== null ? (
                        <div className="review-confirm-customer-edit hide-on-mobile">
                            <Link id="btnInfoEdit" className="customer-edit-link" to={{ pathname: '/Appointment' }}>
                                {this.props.translations.EDIT}
                            </Link>
                        </div>
                    ) : null}
                </div>
                <div className="review-confirm-customer-details">
                    <div className="review-confirm-customer-columns">
                        <div className="review-confirm-customer-text">
                            {this.displayCustomerInfo()}

                            <div className="review-confirm-customer-vehicle-details">
                                <div className="review-confirm-customer-ymm">
                                    {this.props.year} {this.props.make} {this.props.model}
                                </div>
                            </div>

                            {active && this.props.countryOptions.GET_LOYALTY ? (
                                <div>
                                    <div className="review-confirm-my-rewards">
                                        {setBrandText(translations, preferredBrand, themeMake, make, brands)}
                                    </div>
                                    <div className="review-confirm-points">
                                        <div className="review-confirm-total-points">
                                            {translations.TOTAL_POINTS +
                                                totalPoints.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                        </div>
                                        <div className="review-confirm-redeemable-points">
                                            {translations.REDEEMABLE_POINTS.charAt(0) +
                                                translations.REDEEMABLE_POINTS.substring(1).toLowerCase() +
                                                ': ' +
                                                redeemablePoints.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                        </div>
                                    </div>
                                </div>
                            ) : null}

                            <div className="review-confirm-customer-points">
                                {this.props.gCIN !== '' &&
                                this.props.rewardsPoints !== '' &&
                                this.props.countryOptions.GET_LOYALTY
                                    ? Number(this.props.countryOptions.LOYALTY_POINTS_THRESHOLD) <=
                                      this.props.rewardsPoints
                                        ? `${this.props.translations.MYREWARDS_POINTS} ${this.props.rewardsPoints}`
                                        : null
                                    : null}
                            </div>
                        </div>
                        {this.props.flowSource !== flowConstants.AuthenticatedFlow ||
                        this.props.flowSource !== flowConstants.AuthenticatedOCPlus ||
                        this.props.modifyFlag === true ? null : (
                            <img className="review-confirm-vehicle-image hide-on-mobile" src={this.props.image} />
                        )}
                    </div>
                    {this.additionalCommentsController()}
                    {this.props.headerNavigation !== constants.CONFIRMATION && this.props.selectedDate !== null ? (
                        <div className="review-confirm-customer-edit hide-on-desktop">
                            <Link className="customer-edit-link" to={{ pathname: '/Appointment' }}>
                                {this.props.translations.EDIT}
                            </Link>
                        </div>
                    ) : null}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    brands: state.prefillReducer.dealerSummaryObject.dealershipSummary.brands,
    country: state.prefillReducer.country,
    countryOptions: state.countryOptionsReducer.countryOptions,
    customerEmail: state.appointmentReducer.customerEmail,
    customerFirstName: state.appointmentReducer.customerFirstName,
    customerPhoneNumber: state.appointmentReducer.customerPhoneNumber,
    editAppointmentName: state.prefillReducer.editAppointmentObject.name,
    flowSource: state.prefillReducer.flowSource,
    headerNavigation: state.headReducer.headerNavigation,
    hideContactInfoFlag: state.appointmentReducer.hideContactInfoFlag,
    modifyFlag: state.appointmentReducer.modifyFlag,
    reviewComments: state.reviewReducer.comments,
    rewardsPoints: state.prefillReducer.rewardsPoints,
    selectedDate: state.appointmentReducer.selectedDate,
    validFlag: state.appointmentReducer.validFlag,
    gCIN: state.prefillReducer.gcin,
    image: state.reviewReducer.vehicleImage,
    vin: state.prefillReducer.vin,
    make: state.vehicleReducer.selectedMake ? state.vehicleReducer.selectedMake : state.prefillReducer.make,
    model: state.vehicleReducer.selectedModel ? state.vehicleReducer.selectedModel : state.prefillReducer.model,
    translations: state.countryOptionsReducer.translations,
    year: state.vehicleReducer.selectedYear ? state.vehicleReducer.selectedYear : state.prefillReducer.year,
    active: state.myRewardsReducer.active,
    totalPoints: state.myRewardsReducer.totalPoints,
    redeemablePoints: state.myRewardsReducer.redeemablePoints,
    preferredBrand: state.myRewardsReducer.preferredBrand,
    themeMake: state.prefillReducer.themeMake,
});

export default connect(mapStateToProps)(CustomerInfo);
withRouter(CustomerInfo);
