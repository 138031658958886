import {
    ADD_MISC_SERVICE,
    ADD_TIRE_FINDER_SERVICE_STRING,
    ADD_TIRE_FINDER_SERVICE,
    CHOOSE_ONE_SERVICE_OFFER,
    DISPLAY_OFFERS_SERVICES,
    POPULATE_SELECTED_OFFER,
    POPULATE_SELECTED_SERVICE_OFFER,
    RESET_PREVIOUS_BAC,
    RESTORE_SELECTED_SERVICE_OFFER,
    SET_APP_GLOBALS,
    SET_CALL_COUNT,
    SET_EXPANDED_OFFER,
    SET_OIL_CHANGE_OFFER,
    SET_OSSGLOBALEDIT,
    SET_SELECTED_OFFER,
    SET_SERVICE_GLOBALS,
    SET_SERVICE_SELECT,
    SET_SERVICES_ERROR_FLAG,
    SET_SPINNER,
    SET_TRANSLATIONS, SET_NEW_SERVICE_LIST,
} from './ActionTypes';
import {
    addMISCService,
    addTireFinderService,
    addTireFinderServiceString,
    populateSelectedOffer,
    populateSelectedServiceOffer,
    resetPreviousBac,
    restoreSelectedServiceOffer,
    setAppGlobals,
    setCallCount,
    setChooseOneServiceOffer,
    setDisplayOffersServices,
    setGlobalsFromPrefill,
    setNetworkErrorFlgServices,
    setSelectedOffer,
    setServiceSelect,
    setSpinner,
} from './OssAppGlobalsReducerHelper';

const initialState = {
    bac: '',
    booleanVal: true,
    countPegaCurr: 0,
    country: '',
    expandedOffer: null,
    intervalDistance: 0,
    lat: '',
    long: '',
    miscService: '',
    needSomethingElse: false,
    networkErrorFlag: false,
    pega_response: null,
    previousBac: '',
    remainingLength: 300,
    selectedCount: 0,
    selectedOffer: '',
    showSpinner: false,
    tires: '',
    tiresString: '',
    translations: '',
    vehicleImage:
        'https://www.classictexoma.com/assets/stock/colormatched_01/white/640/cc_2014gmc005a_01_640/cc_2014gmc005a_01_640_gaz.jpg?auto=compress&cs=tinysrgb&h=50',
    vehicleMake: '',
    vehicleMiles: '',
    vehicleModel: '',
    vehicleYear: '',
    vin: '',
    newServiceList:{
        recommendedService: [],
        additionalService:[]
    }
};

const OssAppGlobalsReducer = (state = initialState, action) => {
    switch (action.type) {
        //Set App Globals this is first called by the Landing Page
        case SET_APP_GLOBALS: {
            return Object.assign({}, state, {
                newState: setAppGlobals(state, action),
            });
        }

        //Set App Service values that is first called by the Landing Page
        case SET_SERVICE_GLOBALS: {
            return Object.assign({}, state, {
                newState: setServiceGlobals(state, action),
            });
        }
        //Set Service Select is called when a user selects/deselects a service, by default all
        //priority and maintenance services are checked by default
        case SET_SERVICE_SELECT: {
            return Object.assign({}, state, {
                newState: setServiceSelect(state, action),
            });
        }

        //Set Service Select is called when a user selects/deselects additional offera, by default all
        //offers are unchecked
        case SET_SELECTED_OFFER: {
            return Object.assign({}, state, {
                newState: setSelectedOffer(state, action),
            });
        }

        case DISPLAY_OFFERS_SERVICES: {
            return Object.assign({}, state, {
                newState: setDisplayOffersServices(state, action),
            });
        }

        case CHOOSE_ONE_SERVICE_OFFER: {
            return Object.assign({}, state, {
                newState: setChooseOneServiceOffer(state, action),
            });
        }

        //Set OilChangeOffers is called when a user selects/deselects Oil Change offer, by default all
        //offers are unchecked
        case SET_OIL_CHANGE_OFFER: {
            return Object.assign({}, state, {
                newState: setOilChangeOffer(state, action),
            });
        }

        //Populate the selected Oil Change offer on Landing Page
        case POPULATE_SELECTED_OFFER: {
            return Object.assign({}, state, {
                newState: populateSelectedOffer(state, action),
            });
        }

        case POPULATE_SELECTED_SERVICE_OFFER: {
            return Object.assign({}, state, {
                newState: populateSelectedServiceOffer(state, action),
            });
        }

        case RESTORE_SELECTED_SERVICE_OFFER: {
            return Object.assign({}, state, {
                newState: restoreSelectedServiceOffer(state, action),
            });
        }

        //The below is called to keep track of the number of Pega Service calls
        case SET_CALL_COUNT: {
            return Object.assign({}, state, {
                newState: setCallCount(state, action),
            });
        }

        //Caputure MISC Service Item from Additional Comments box on Additional Offers Page
        case ADD_MISC_SERVICE: {
            return Object.assign({}, state, {
                newState: addMISCService(state, action),
            });
        }

        case ADD_TIRE_FINDER_SERVICE: {
            return Object.assign({}, state, {
                newState: addTireFinderService(state, action),
            });
        }

        case ADD_TIRE_FINDER_SERVICE_STRING: {
            return Object.assign({}, state, {
                newState: addTireFinderServiceString(state, action),
            });
        }

        //Set spinner to true/false
        case SET_SPINNER: {
            return Object.assign({}, state, {
                newState: setSpinner(state, action),
            });
        }
        //Reset previous bac to new bac on Services Page
        case RESET_PREVIOUS_BAC: {
            return Object.assign({}, state, {
                newState: resetPreviousBac(state, action),
            });
        }
        case SET_OSSGLOBALEDIT: {
            return Object.assign({}, state, {
                newState: setGlobalsFromPrefill(state, action),
            });
        }
        case SET_SERVICES_ERROR_FLAG: {
            return Object.assign({}, state, {
                newState: setNetworkErrorFlgServices(state, action),
            });
        }
        case SET_EXPANDED_OFFER: {
            return Object.assign({}, state, {
                newState: (state.expandedOffer = action.payload),
            });
        }
        case SET_TRANSLATIONS: {
            state.translations = action.payload;
            return state;
        }
        case SET_NEW_SERVICE_LIST: return {
            ...state, newServiceList:{
                recommendedService: action.payload.recommendedService ? action.payload.recommendedService:state.newServiceList.recommendedService,
                additionalService: action.payload.additionalService ? action.payload.additionalService:state.newServiceList.additionalService
            }
        }
        default:
            return state;
    }
};

export default OssAppGlobalsReducer;
