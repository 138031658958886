import React, { Component } from 'react';
import _ from 'lodash';

class TableBody extends Component {
    renderCell = (item, column) => {
        if (column.content) {
            return column.content(item);
        }
        return _.get(item, column.path);
    };

    createKey = (item, column) => item._id + (column.path || column.key);

    render() {
        const { data, columns } = this.props;

        return (
            <tbody>
                {data.map((item, index) => (
                    <tr id={"btnDealer" + index} key={item.bac}>
                        {columns.map((column) => (
                            <td key={this.createKey(item, column)}>{this.renderCell(item, column)}</td>
                        ))}
                    </tr>
                ))}
            </tbody>
        );
    }
}

export default TableBody;
