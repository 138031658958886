import { connect } from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import React from 'react';
import {
    setAdvisor,
    setPickupAddress,
    setTransportation,
    stopPreselectMobileService,
} from '../../../Redux/ActionCreator';
import { getNextAvailable } from '../../../shared/BackendCalls/SharedBackendGets';
const appointmentConstants = require('../AppointmentConstants');
const flowConstants = require('../../../shared/RoutingFlow/FlowComponentsConstants');
const sharedConstants = require('../../../shared/Constants/SharedConstants');

import MobileServiceAddress from '../../MobileService/MobileServiceAddress';
import store from '../../../Redux/Store';

import NewConciergeForm from '../ConciergeForm/NewConciergeForm';

class Details extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isCadillacConciergeEnabled: this.isCadillacConciergeEnabled(
                this.props.make,
                this.props.dealerSummary.displayCadillacPremiumConcierge,
                this.props.countryOptions.ENABLE_CADILLAC_PREMIUM_CONCIERGE
            ),
            showConciergeForm: false,
        };

        this.handleAdvisorChange = this.handleAdvisorChange.bind(this);
        this.handleTransportChange = this.handleTransportChange.bind(this);
        this.openConciergeForm = this.openConciergeForm.bind(this);
    }

    isCadillacConciergeEnabled(make, dealerEnabled, countryOptionEnabledString) {
        let isEnabled;
        const countryOptionEnabled = countryOptionEnabledString === 'true';

        if (make.toLowerCase() === sharedConstants.b_cadillac && countryOptionEnabled) {
            isEnabled = true;
        } else {
            isEnabled = false;
        }

        return isEnabled;
    }

    /**
     * @desc Save selected advisor object to store
     * @param {*} event from advisor select component
     */
    handleAdvisorChange(event) {
        const advisorId = event.target.value;
        const advisor = this.getAdvisor(advisorId);

        this.props.setAdvisor(advisor);
        getNextAvailable(this.props.dealerSummary.dealershipSummary.bac);
    }

    /**
     * Finds advisor object by advisor id
     * @param {*} id
     * @returns {object} advisor
     */
    getAdvisor(id) {
        let advisor;

        this.props.advisors.forEach((option) => {
            if (id === option.id) {
                advisor = option;
            }
        });

        return advisor;
    }

    /**
     * @desc Save selected transport code to store
     * @param {*} event from transport select component
     */
    handleTransportChange(event) {
        this.props.setTransportation(this.findTransportOption(event.target.value));
        store.dispatch(setPickupAddress('empty'));
        const isPPDL =
            event.target.value === appointmentConstants.PREMIUM_CONCIERGE ||
            event.target.value === appointmentConstants.PREMIUM_CONCIERGE_WITH_LOANER ||
            event.target.value === appointmentConstants.MOBILE_SERVICE ||
            (event.target.value === appointmentConstants.CADILLAC_PREMIUM_CONCIERGE &&
                this.state.isCadillacConciergeEnabled);
        if (!isPPDL) {
            getNextAvailable(this.props.dealerSummary.dealershipSummary.bac);
        }
        store.dispatch(stopPreselectMobileService(true));
    }

    closeConciergeForm() {
        this.setState({ showConciergeForm: false });
        if (this.props.flowSource === flowConstants.EditAppointmentFlow) {
            //comment out on 08-17-23 since its reverting the transportation options
            // this.props.setTransportation(this.findTransportOption(appointmentConstants.PREMIUM_CONCIERGE));
        }
    }

    openConciergeForm() {
        this.setState({ showConciergeForm: true });
        window.scrollTo(0, 0);
    }

    populateAdvisorSelect(advisors) {
        let advisorList;
        let i = -1;
        if (advisors !== null) {
            if (Object.prototype.hasOwnProperty.call(advisors, 'length')) {
                advisorList = advisors.map((advisor) => {
                    i += 1;
                    return (
                        <MenuItem id={'advisor' + i} key={advisor.id} value={advisor.id}>
                            {advisor.name}
                        </MenuItem>
                    );
                });
            }
            return advisorList;
        } else {
            const advisor = this.props.defaultAdvisorObject;
            advisor.name = this.props.translations.NO_PREFERENCE;
            i += 1;

            return (
                <MenuItem id={'advisor' + i} key={advisor.id} value={advisor.id}>
                    {advisor.name}
                </MenuItem>
            );
        }
    }

    isEnabledTransportOption(option) {
        let isEnabledLane;

        if (
            (this.props.serviceLane === appointmentConstants.EXPRESS_SERVICE && option.expressEnabled) ||
            (this.props.serviceLane === appointmentConstants.DEFAULT &&
                option.standardEnabled &&
                option.code !== appointmentConstants.CADILLAC_PREMIUM_CONCIERGE)
        ) {
            isEnabledLane = true;
        } else if (
            option.code === appointmentConstants.CADILLAC_PREMIUM_CONCIERGE &&
            this.state.isCadillacConciergeEnabled
        ) {
            isEnabledLane = true;
        } else {
            isEnabledLane = false;
        }

        return isEnabledLane;
    }

    populateTransportSelect(transport) {
        let menuItems;
        let i = -1;

        if (transport) {
            menuItems = transport.map((option) => {
                let desc = option.code;
                i += 1;

                if (option.description) {
                    desc = option.description;
                }

                return this.isEnabledTransportOption(option) ? (
                    <MenuItem id={'transportationOption' + i} key={option.code} value={option.code}>
                        {this.props.translations[desc] ? this.props.translations[desc] : option.code}
                    </MenuItem>
                ) : null;
            });
        }

        return menuItems;
    }

    findTransportOption(code) {
        let option = '';

        this.props.transportationOptions.forEach((opt) => {
            if (code === opt.code) {
                option = opt;
            }
        });

        return option;
    }

    /**
     * Renders corresponding disclaimer to selected transport option.
     */
    renderTransportInfo() {
        const { code } = this.props.transportation;
        let option;
        let disclaimer;
        let hideDisclaimer = false;

        if (this.props.transportationOptions) {
            option = this.findTransportOption(code);
        }

        if (option) {
            disclaimer = this.props.translations[option.disclaimer];

            if (
                option.code === appointmentConstants.PREMIUM_CONCIERGE ||
                option.code === appointmentConstants.PREMIUM_CONCIERGE_WITH_LOANER ||
                (option.code === appointmentConstants.CADILLAC_PREMIUM_CONCIERGE &&
                    this.state.isCadillacConciergeEnabled)
            ) {
                hideDisclaimer = true;
            }

            if (option.transportationCustomMessage?.customMessage) {
                disclaimer = option.transportationCustomMessage.customMessage;
            }

            return (
                <>
                    <div
                        className={`transport-info paper ${
                            this.props.showPickUpAddressError ? 'transport-info-error' : ''
                        }`}
                    >
                        <i className="transport-info-icon icon info" />
                        <div className="transport-info-text">
                            <div className="transport-info-headline bold">
                                {this.props.translations[option.description]
                                    ? this.props.translations[option.description]
                                    : option.description}
                            </div>
                            {this.props.transportation.code === appointmentConstants.MOBILE_SERVICE ? (
                                <MobileServiceAddress />
                            ) : hideDisclaimer ? (
                                <div className="transport-info-addresses">
                                    {this.props.pickupAddress ? (
                                        <>
                                            <div className="transport-info-pickup-title">
                                                {this.props.addressIsSame
                                                    ? this.props.translations.PICK_UP_AND_DROPOFF ||
                                                      'PICK_UP_AND_DROPOFF'
                                                    : this.props.translations.PICK_UP || 'PICK_UP'}
                                            </div>

                                            <div className="transport-info-pickup-address">
                                                {this.props.pickupAddress}
                                            </div>
                                            {this.props.addressIsSame ? null : (
                                                <div className="dropoff-group">
                                                    <div className="transport-info-dropoff-title">
                                                        {this.props.translations.DROPOFF}
                                                    </div>
                                                    <div className="transport-info-dropoff-address">
                                                        {this.props.dropoffAddress}
                                                    </div>
                                                </div>
                                            )}
                                        </>
                                    ) : (
                                        <div className="review-confirm-customer-info-hidden">
                                            {this.props.flowSource === flowConstants.EditAppointmentFlow
                                                ? this.props.translations.CUSTOMER_INFO_PRIVACY
                                                : disclaimer}
                                        </div>
                                    )}
                                    <button className="edit-button link" onClick={this.openConciergeForm}>
                                        {this.props.pickupAddress
                                            ? this.props.translations.EDIT || 'EDIT'
                                            : this.props.translations.TRANSPORTATION_OPTION_SET_PPDL ||
                                              'TRANSPORTATION_OPTION_SET_PPDL'}
                                    </button>
                                </div>
                            ) : (
                                <div className="transport-info-disclaimer">{disclaimer}</div>
                            )}
                        </div>
                    </div>
                    {this.props.showPickUpAddressError && (
                        <div className="appointment-address-error-message">
                            <i className="icon alert-caution-red" />
                            <span className="error-message">
                                {this.props.transportation.code === appointmentConstants.MOBILE_SERVICE
                                    ? this.props.translations.MOBILE_SERVICE_ADDRESS_ERROR_MESSAGE
                                    : this.props.translations.TRANSPORTATION_OPTION_SET_PPDL_ERROR ||
                                      'TRANSPORTATION_OPTION_SET_PPDL_ERROR'}
                            </span>
                        </div>
                    )}
                </>
            );
        }
    }

    renderConciergeForm() {
        let conciergeForm;
        if (
            this.props.transportation &&
            (this.props.transportation.code === appointmentConstants.PREMIUM_CONCIERGE ||
                this.props.transportation.code === appointmentConstants.PREMIUM_CONCIERGE_WITH_LOANER ||
                (this.props.transportation.code === appointmentConstants.CADILLAC_PREMIUM_CONCIERGE &&
                    this.state.isCadillacConciergeEnabled))
        ) {
            conciergeForm = (
                <NewConciergeForm
                    closeConciergeForm={this.closeConciergeForm.bind(this)}
                    showConciergeForm={this.state.showConciergeForm}
                />
            );
        } else {
            conciergeForm = null;
        }

        return conciergeForm;
    }

    render() {
        if (this.props.dealerSummary) {
            const advisorDropdown = (
                <FormControl>
                    <InputLabel className="advisor-dropdown-label caps">{this.props.translations.ADVISOR}</InputLabel>
                    <Select
                        id="advisor-dropdown"
                        className="advisor-dropdown"
                        labelId="advisor-dropdown-label"
                        onChange={this.handleAdvisorChange}
                        value={this.props.advisor.id}
                        variant="filled"
                        displayEmpty
                    >
                        {this.populateAdvisorSelect(this.props.advisors)}
                    </Select>
                </FormControl>
            );

            const transportDropdown = (
                <>
                    {this.props.transportationOptions && this.props.transportationOptions.length ? (
                        <FormControl>
                            <InputLabel className="transport-dropdown-label caps" shrink>
                                {this.props.translations.TRANSPORTATION}
                            </InputLabel>

                            <Select
                                id="transport-dropdown"
                                className="transport-dropdown"
                                labelId="transport-dropdown-label"
                                onChange={this.handleTransportChange}
                                value={this.props.transportation ? this.props.transportation.code : ''}
                                variant="filled"
                                displayEmpty
                            >
                                {this.props.serviceLane &&
                                    this.populateTransportSelect(this.props.transportationOptions)}
                            </Select>
                        </FormControl>
                    ) : null}
                </>
            );

            return (
                <div className="appointment-details-container">
                    <div className="appointment-details-dropdowns">
                        {this.props.dealerSummary.displayAdvisors ? (
                            <div className="appointment-details-advisor">{advisorDropdown}</div>
                        ) : null}
                        {this.props.dealerSummary.displayTransportationOptions && this.props.transportationOptions ? (
                            <div className="appointment-details-transport">{transportDropdown}</div>
                        ) : null}
                    </div>
                    {this.renderTransportInfo()}
                    {this.renderConciergeForm()}
                </div>
            );
        }
    }
}

const mapStateToProps = (state) => ({
    addressIsSame: state.appointmentReducer.conciergeAddressIsSame,
    advisor: state.appointmentReducer.advisor,
    advisors: state.appointmentReducer.advisors,
    country: state.prefillReducer.country,
    countryOptions: state.countryOptionsReducer.countryOptions,
    dealerSummary: state.prefillReducer.dealerSummaryObject,
    defaultAdvisorObject: state.appointmentReducer.defaultAdvisorObject,
    dropoffAddress: state.appointmentReducer.dropoffAddress,
    make: state.vehicleReducer.selectedMake ? state.vehicleReducer.selectedMake : state.prefillReducer.make,
    pickupAddress: state.appointmentReducer.pickupAddress,
    serviceLane: state.appointmentReducer.serviceLane,
    translations: state.countryOptionsReducer.translations,
    transportation: state.appointmentReducer.transportation,
    transportationOptions: state.appointmentReducer.transportationOptions,
    flowSource: state.prefillReducer.flowSource,
    editAppointmentTransportation: state.prefillReducer.editAppointmentObject.transportationOption,
    showPickUpAddressError: state.appointmentReducer.showPickUpAddressError,
});

export default connect(mapStateToProps, { setAdvisor, setTransportation })(Details);
