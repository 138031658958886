import dayjs from 'dayjs';
import parsePhoneNumber from 'libphonenumber-js';
import { getAppointments, getPPDAvailability } from '../../shared/BackendCalls/SharedBackendGets';
import {
    setAdvisors,
    setBusinessNameFlag,
    setTransportationOptions,
    setTransportationCallComplete,
    setAppointmentDate,
    setLoadingFlag, setAvailabilityGuid, setTransportation, setFirstAvailDateEmpty,
} from '../../Redux/ActionCreator';
import { EditAppointmentFlow } from '../../shared/RoutingFlow/FlowComponentsConstants';
import appointmentObject from '../../shared/Utils/POST/AppointmentObject';
import store from '../../Redux/Store';
import {MOBILE_SERVICE, PREMIUM_CONCIERGE_WITH_LOANER} from "./AppointmentConstants";

const customParseFormat = require('dayjs/plugin/customParseFormat');
const appointmentConstants = require('../Appointment/AppointmentConstants');

dayjs.extend(customParseFormat);

/**
 * @desc Convert a string to title case
 * @param {*} string
 * @return string
 */
export function stringToTitleCase(string) {
    if (string) {
        return string.replace(/\w\S*/g, (word) => word.charAt(0).toUpperCase() + word.substr(1).toLowerCase());
    }
    return '';
}

/**
 * @desc Format 10 digit number as phone num
 * @param {*} string - string 10 digit number
 * @param country - string 2 char
 * @return string - formatted number
 */
export function formatPhoneNum(string, country) {
    let formattedNumber = '';

    if (string) {
        formattedNumber = string
            .split('')
            .filter((e) => e !== '(' && e !== '-' && e !== ' ' && e !== ')')
            .join('');
    }

    if (string && !isNaN(formattedNumber)) {
        const phoneNumber = parsePhoneNumber(string, country);
        formattedNumber = phoneNumber.formatNational();
    } else {
        formattedNumber = '';
    }

    return formattedNumber;
}

/**
 * @desc Parse and store data from get appointments call
 * @param {*} data
 */
export function parseDatesResData(data) {
    const dates = [];
    const defaultLane = data.appointmentAvailabilities.DEFAULT;
    const expressLane = data.appointmentAvailabilities.EXPRESS_SERVICE;
    const localStore = store.getState();
    let serviceLane;
    let firstAvailDate = '';

    if (defaultLane) {
        serviceLane = defaultLane;
        store.dispatch(setServiceLane('DEFAULT'));
    } else if (expressLane) {
        serviceLane = expressLane;
        store.dispatch(setServiceLane('EXPRESS_SERVICE'));
    }

    serviceLane.forEach((day) => {
        data.vendorErrorMessage && localStore.appointmentReducer.transportation.code === appointmentConstants.LOANER_CAR
            ? (day.time = [])
            : null;
        const timeList = parseTimeList(day.date, day.time);

        if (!firstAvailDate && timeList[0]) {
            firstAvailDate = timeList[0];
        }

        dates.push({
            date: dayjs(day.date, 'YYYY-MM-DD'),
            time: timeList,
        });
    });
    store.dispatch(setAvailabilityGuid(data.availabilityGuid ? data.availabilityGuid:'' ));
    store.dispatch(setDates(dates));
    if (
        localStore.appointmentReducer.transportation.code === appointmentConstants.PREMIUM_CONCIERGE ||
        localStore.appointmentReducer.transportation.code === appointmentConstants.PREMIUM_CONCIERGE_WITH_LOANER ||
        localStore.prefillReducer.editAppointmentObject.transportationOption === appointmentConstants.PREMIUM_CONCIERGE
    ) {
        //For PPD first available will be set by first available call
        if(!firstAvailDate){
            for(let i = 0; i<=dates.length; i++){
                const tempTime =  dates[i]?.time;
                if(tempTime.length > 0){
                    firstAvailDate = dates[i].time[0];
                    break;
                }

            }
        }
        store.dispatch(firstAvailDate ? setFirstAvailDate(firstAvailDate):setFirstAvailDateEmpty(true));

        localStore.appointmentReducer.firstAvailDate !== null
            ? store.dispatch(setDate(localStore.appointmentReducer.selectedDate))
            : store.dispatch(setDate('null'));
        store.dispatch(setLoadingFlag('false'));
    }
}

export function ppdFirstAvail(data) {
    const apptObject = appointmentObject();
    const defaultLane = data.appointmentAvailabilities.DEFAULT;
    const expressLane = data.appointmentAvailabilities.EXPRESS_SERVICE;
    const localStore = store.getState();
    let serviceLane;
    let firstAvailDate = '';

    if (defaultLane) {
        serviceLane = defaultLane;
        store.dispatch(setServiceLane('DEFAULT'));
    } else if (expressLane) {
        serviceLane = expressLane;
        store.dispatch(setServiceLane('EXPRESS_SERVICE'));
    }

    serviceLane.forEach((day) => {
        const timeList = parseTimeList(day.date, day.time);

        if (!firstAvailDate && timeList[0]) {
            firstAvailDate = timeList[0];
        }
    });

    store.dispatch(setFirstAvailDate(firstAvailDate));
    store.dispatch(setPrevAppointmentObj(apptObject));
    getPPDAvailability(
        localStore.appointmentReducer.dealerBac,
        null,
        localStore.appointmentReducer.startDate,
        ''
    );
}

function parseTimeList(date, timeList) {
    const parsedTimeList = [];

    timeList.forEach((time) => {
        const parsedDate = dayjs(date + time, 'YYYY-MM-DDHH:mm');
        parsedTimeList.push(parsedDate);
    });

    return parsedTimeList;
}

/**
 * @desc Parse and store data from dealer options call
 * @param {*} data
 */
export function extractAdvisorTransportOptions(data) {
    const localStore = store.getState();

    // Don't override info set by extractDealerResData unless results are returned
    if (data.transportationOptions.length) {
        // Add fields missing from objects returned from DMS
        data.transportationOptions.forEach((option) => {
            option.expressEnabled = true;
            option.standardEnabled = true;
        });

        const transportationOptions = _.sortBy(data.transportationOptions, (option) => option.displayOrder);


        store.dispatch(setTransportationOptions(transportationOptions));
        if(localStore.mobileServiceReducer.isVinEligibleForMobileService && localStore.prefillReducer.flowSource !== EditAppointmentFlow && !localStore.mobileServiceReducer.stopPreSelectMobileTransportOption){
            const mobileServiceTransOption = transportationOptions.length && transportationOptions.filter((item)=>item.code === MOBILE_SERVICE);
            const ppdlTransOption = transportationOptions.length && transportationOptions.filter((item)=>item.code === PREMIUM_CONCIERGE_WITH_LOANER);


                if(mobileServiceTransOption.length > 0){
                   store.dispatch(setTransportation(mobileServiceTransOption[0]));
               }
                else {
                    if(ppdlTransOption.length > 0){
                   store.dispatch(setTransportation(ppdlTransOption[0]));
               }
           }
        }


    }

    if (data.advisors.length) {
        localStore.appointmentReducer.defaultAdvisorObject.name =
            localStore.countryOptionsReducer.translations.NO_PREFERENCE;
        data.advisors.unshift(localStore.appointmentReducer.defaultAdvisorObject);

        store.dispatch(setAdvisors(data.advisors));
    }

    store.dispatch(setTransportationCallComplete(true));
}

/**
 * @desc Parse and store data from first availability call
 * @param {*} data
 * @param runAvailability
 */
export function parseAvailableResData(data, runAvailability) {
    const apptObject = appointmentObject();
    let serviceLane = '';
    const defaultLane = data.appointmentAvailabilities.DEFAULT;
    const expressLane = data.appointmentAvailabilities.EXPRESS_SERVICE;
    const localStore = store.getState();
    let selectedDate = dayjs();
    let firstAvailDate = selectedDate;
    let dateStr = '';

    if (defaultLane) {
        serviceLane = defaultLane;
        store.dispatch(setServiceLane('DEFAULT'));
    } else if (expressLane) {
        serviceLane = expressLane;
        store.dispatch(setServiceLane('EXPRESS_SERVICE'));
    }

    store.dispatch(setAvailabilityGuid(data.availabilityGuid ? data.availabilityGuid : ''));


    // Check that a date has been returned
    if (serviceLane[0]) {
        if (serviceLane[0].time.length) {
            dateStr = serviceLane[0].date + serviceLane[0].time;
            selectedDate = dayjs(dateStr, 'YYYY-MM-DDHH:mm');
            firstAvailDate = selectedDate;
        } else {
            selectedDate = 'null';
            firstAvailDate = 'null';
        }

        store.dispatch(setAvailabilityErrorFlag('false'));

        if (localStore.prefillReducer.flowSource === EditAppointmentFlow) {
            selectedDate = localStore.appointmentReducer.startDate;
        }

        // Only set date and time if null
        // or any changes have been made in appointment object
        if (
            appointmentObjectChanged(localStore.appointmentReducer.prevAppointmentObject, apptObject) ||
            !localStore.appointmentReducer.selectedDate
        ) {
            if (localStore.prefillReducer.flowSource === EditAppointmentFlow) {
                selectedDate = localStore.appointmentReducer.startDate;

                if (firstAvailDate.isAfter(selectedDate)) {
                    firstAvailDate = selectedDate;
                }
            }

            store.dispatch(setDate(selectedDate));
            store.dispatch(setFirstAvailDate(firstAvailDate));
            store.dispatch(setShowTimesFlag('true'));
            store.dispatch(setTimeIndex(1));
        }
    } else {
        store.dispatch(setAvailabilityErrorFlag('true'));
        store.dispatch(setDate('null'));
        store.dispatch(setShowTimesFlag('false'));
    }

    store.dispatch(setPrevAppointmentObj(apptObject));
    if (runAvailability) {
        if (selectedDate !== 'null') {
            store.dispatch(setStartDate(selectedDate));
            getAppointments(selectedDate);
        } else {
            store.dispatch(setStartDate(dayjs()));
            getAppointments(dayjs());
        }
    }
}

/**
 * @desc Compare two appointmentObjects to see if changes have been made to bac,
 *  transportation, selected services, or advisor
 * @param {*} object1 First appointment object to compare
 * @param {*} object2 Second appointment object to compare
 */
export function appointmentObjectChanged(object1, object2) {
    return (
        !object1 ||
        object1.bac !== object2.bac ||
        !_.isEqual(object1.lineItems, object2.lineItems) ||
        object1.advisor.id !== object2.advisor.id ||
        object1.transportationOption !== object2.transportationOption
    );
}

function setAvailabilityErrorFlag(showError) {
    return {
        type: 'SET_AVAIL_ERROR_FLG',
        payload: showError,
    };
}

function setDate(date) {
    return {
        type: 'SET_APPT_DATE',
        payload: date,
    };
}

function setDates(dates) {
    return {
        type: 'SET_DATES',
        payload: dates,
    };
}

function setFirstAvailDate(date) {
    return {
        type: 'SET_FIRST_AVAIL_DATE',
        payload: date,
    };
}

function setPrevAppointmentObj(object) {
    return {
        type: 'SET_PREV_APPT_OBJ',
        payload: object,
    };
}

function setServiceLane(lane) {
    return {
        type: 'SET_SERVICE_LANE',
        payload: lane,
    };
}

function setShowTimesFlag(showTimes) {
    return {
        type: 'SET_SHOW_TIMES_FLG',
        payload: showTimes,
    };
}

function setStartDate(date) {
    return {
        type: 'SET_START_DATE',
        payload: date,
    };
}

function setTimeIndex(index) {
    return {
        type: 'SET_TIME_INDEX',
        payload: index,
    };
}

/**
 * @desc validate string for cpf
 * @param {*} cpf
 * @return string
 */
export function cpfValidation(cpf) {
    if (!cpf) {
        return false;
    }

    const cpfString = cpf
        .split('')
        .filter((e) => e !== '.' && e !== '-')
        .join('');

    if (cpfString.length !== 11) {
        return false;
    }
    if (
        cpfString === '00000000000' ||
        cpfString === '11111111111' ||
        cpfString === '22222222222' ||
        cpfString === '33333333333' ||
        cpfString === '44444444444' ||
        cpfString === '55555555555' ||
        cpfString === '66666666666' ||
        cpfString === '77777777777' ||
        cpfString === '88888888888' ||
        cpfString === '99999999999'
    ) {
        return false;
    }
    const validationCalculator = [];

    // Compute 1st verification digit.
    validationCalculator[0] =
        parseInt(cpfString.charAt(0)) + 2 * parseInt(cpfString.charAt(1)) + 3 * parseInt(cpfString.charAt(2));
    validationCalculator[0] +=
        4 * parseInt(cpfString.charAt(3)) + 5 * parseInt(cpfString.charAt(4)) + 6 * parseInt(cpfString.charAt(5));
    validationCalculator[0] +=
        7 * parseInt(cpfString.charAt(6)) + 8 * parseInt(cpfString.charAt(7)) + 9 * parseInt(cpfString.charAt(8));
    validationCalculator[0] %= 11;
    validationCalculator[0] %= 10;

    // Compute 2nd verification digit.
    validationCalculator[1] =
        parseInt(cpfString.charAt(1)) + 2 * parseInt(cpfString.charAt(2)) + 3 * parseInt(cpfString.charAt(3));
    validationCalculator[1] +=
        4 * parseInt(cpfString.charAt(4)) + 5 * parseInt(cpfString.charAt(5)) + 6 * parseInt(cpfString.charAt(6));
    validationCalculator[1] +=
        7 * parseInt(cpfString.charAt(7)) + 8 * parseInt(cpfString.charAt(8)) + 9 * validationCalculator[0];
    validationCalculator[1] %= 11;
    validationCalculator[1] %= 10;

    // True if verification digits are as expected.
    return (
        validationCalculator[0] === parseInt(cpfString.charAt(9)) &&
        validationCalculator[1] === parseInt(cpfString.charAt(10))
    );
}

/**
 * @desc validate string for cnpj
 * @param {*} cnpj
 * @return boolean
 */
export function validateCNPJ(cnpj) {
    if (!cnpj) {
        return false;
    }

    const cnpjString = cnpj
        .split('')
        .filter((e) => e !== '.' && e !== '-' && e !== '/')
        .join('');

    if (cnpjString.length !== 14) {
        return false;
    }

    // check for dump data
    if (
        cnpjString === '00000000000000' ||
        cnpjString === '11111111111111' ||
        cnpjString === '22222222222222' ||
        cnpjString === '33333333333333' ||
        cnpjString === '44444444444444' ||
        cnpjString === '55555555555555' ||
        cnpjString === '66666666666666' ||
        cnpjString === '77777777777777' ||
        cnpjString === '88888888888888' ||
        cnpjString === '99999999999999'
    ) {
        return false;
    }

    let digit13Result;
    let digit14Result;

    // Compute 13th verification digit.
    digit13Result =
        5 * parseInt(cnpjString.charAt(0)) +
        4 * parseInt(cnpjString.charAt(1)) +
        3 * parseInt(cnpjString.charAt(2)) +
        2 * parseInt(cnpjString.charAt(3)) +
        9 * parseInt(cnpjString.charAt(4)) +
        8 * parseInt(cnpjString.charAt(5)) +
        7 * parseInt(cnpjString.charAt(6)) +
        6 * parseInt(cnpjString.charAt(7)) +
        5 * parseInt(cnpjString.charAt(8)) +
        4 * parseInt(cnpjString.charAt(9)) +
        3 * parseInt(cnpjString.charAt(10)) +
        2 * parseInt(cnpjString.charAt(11));

    digit13Result = 11 - (digit13Result % 11);
    if (digit13Result >= 10) {
        digit13Result = 0;
    }
    // Compute 14th verification digit.
    digit14Result =
        6 * parseInt(cnpjString.charAt(0)) +
        5 * parseInt(cnpjString.charAt(1)) +
        4 * parseInt(cnpjString.charAt(2)) +
        3 * parseInt(cnpjString.charAt(3)) +
        2 * parseInt(cnpjString.charAt(4)) +
        9 * parseInt(cnpjString.charAt(5)) +
        8 * parseInt(cnpjString.charAt(6)) +
        7 * parseInt(cnpjString.charAt(7)) +
        6 * parseInt(cnpjString.charAt(8)) +
        5 * parseInt(cnpjString.charAt(9)) +
        4 * parseInt(cnpjString.charAt(10)) +
        3 * parseInt(cnpjString.charAt(11)) +
        2 * parseInt(cnpjString.charAt(12));

    digit14Result = 11 - (digit14Result % 11);
    if (digit14Result >= 10) {
        digit14Result = 0;
    }

    // True if verification digits are as expected.
    return digit13Result === parseInt(cnpjString.charAt(12)) && digit14Result === parseInt(cnpjString.charAt(13));
}

/**
 * @desc format string for cpf
 * @param {*} cpf
 * @return string
 */
export function formatCPF(cpf) {
    let rawCPF = '';

    if (cpf) {
        rawCPF = cpf
            .split('')
            .filter((e) => e !== '.' && e !== '-')
            .join('');
    }

    if (rawCPF && rawCPF.length === 11) {
        rawCPF = `${rawCPF.slice(0, 3)}.${rawCPF.slice(3, 6)}.${rawCPF.slice(6, 9)}-${rawCPF.slice(9, 11)}`;
    }
    if (rawCPF && rawCPF.length === 9) {
        rawCPF = `${rawCPF.slice(0, 3)}.${rawCPF.slice(3, 6)}.${rawCPF.slice(6, 9)}`;
    }

    return rawCPF;
}

/**
 * @desc format string for cnpj
 * @param {*} cnpj
 * @return string
 */
export function formatCNPJ(cnpj) {
    let rawCNPJ = '';

    if (cnpj) {
        rawCNPJ = cnpj
            .split('')
            .filter((e) => e !== '.' && e !== '-' && e !== '/')
            .join('');
    }

    if (rawCNPJ && rawCNPJ.length === 14) {
        rawCNPJ = `${rawCNPJ.slice(0, 2)}.${rawCNPJ.slice(2, 5)}.${rawCNPJ.slice(5, 8)}/${rawCNPJ.slice(
            8,
            12
        )}-${rawCNPJ.slice(12, 14)}`;
    }
    return rawCNPJ;
}

/**
 * Sets flag which shows whether business radio button should be shown
 * @param {*} showBusinessName
 */
export function setShowBusinessName(showBusinessName) {
    if (showBusinessName) {
        store.dispatch(setBusinessNameFlag('true'));
    } else {
        store.dispatch(setBusinessNameFlag('false'));
    }
}
