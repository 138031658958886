import React from 'react';
import { connect } from 'react-redux';
import { setMarketingOptOutFlag, setOptInFlag, setPrivacyOptInFlag, setValidFlag } from '../../../Redux/ActionCreator';
import PrivacyPolicyPopup from '../TermsAndConditions/PrivacyPolicyPopup';
import SharedCheckBox from '../../../shared/Checkbox/SharedCheckBox';
import TermsAndConditionsPopup from '../TermsAndConditions/TermsAndConditionsPopup';

class OptIn extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            canContinueFlag: this.props.validFlag,
            showPrivacyPopup: false,
            showTACPopup: false,
        };

        if (
            this.props.countryOptions.SELECT_PRIVACY_CHECKBOX !== undefined &&
            this.props.countryOptions.SELECT_PRIVACY_CHECKBOX === 'true'
        ) {
            this.props.setPrivacyOptInFlag('true');
        }
    }

    componentDidUpdate() {
        if (this.props.countryOptions.REQUIRE_PRIVACY_CHECKBOX === 'true') {
            if (
                this.props.privacyOptInFlag &&
                this.state.canContinueFlag &&
                (this.props.flowSource === 'Authenticated' ||  this.props.flowSource !== 'AuthenticatedOCPlus') &&
                this.props.isValidated
            ) {
                this.props.setValidFlag('true');
            } else if (this.props.flowSource === 'Authenticated' ||  this.props.flowSource !== 'AuthenticatedOCPlus') {
                this.props.setValidFlag('false');
            }
        }
    }

    combineCustomerInfoMethods() {
        return [this.renderOptInCheckBox(), this.renderMarketingOptOut(), this.renderPrivacyNotice()];
    }

    toggleTACPopup() {
        this.setState({ showTACPopup: !this.state.showTACPopup });
        window.scrollTo(0, 0);
    }

    togglePrivacyPopup() {
        this.setState({ showPrivacyPopup: !this.state.showPrivacyPopup });
        window.scrollTo(0, 0);
    }

    renderOptInCheckBox = () => {
        let deploymentMode;

        if (this.props.countryOptions.SMS_CONFIRMATION_MODES) {
            deploymentMode = this.props.countryOptions.SMS_CONFIRMATION_MODES.includes(
                this.props.dealershipSummary.deploymentMode
            );
        }

        if (this.props.displaySmsAlertOption && deploymentMode) {
            return (
                <div className="contact-opt-in-container" key="contact-opt">
                    <i className="contact-warning-icon icon alert-caution-grey" />
                    <SharedCheckBox
                        checked={this.props.optInFlag}
                        onChange={(e) => {
                            if (e.target.checked) {
                                this.props.setOptInFlag('true');
                            } else {
                                this.props.setOptInFlag('false');
                            }
                        }}
                    />
                    <div className="contact-opt-in-label">
                        {this.props.translations.OPTIN_TEXTMESS === undefined ? (
                            '!!OPTIN_TEXTMESS'
                        ) : (
                            <span dangerouslySetInnerHTML={{ __html: this.props.translations.OPTIN_TEXTMESS }} />
                        )}
                    </div>

                    <TermsAndConditionsPopup
                        closeTACPopup={this.toggleTACPopup.bind(this)}
                        showTACPopup={this.state.showTACPopup}
                    />
                </div>
            );
        }
        return null;
    };

    renderMarketingOptOut = () => {
        const showMarketingOpt = this.props.countryOptions.SHOW_MARKETING_OPT_OUT;

        if (showMarketingOpt === 'true') {
            return (
                <div className="contact-opt-in-container" key="marketing-opt">
                    <i className="contact-warning-icon icon alert-caution-grey" />
                    <SharedCheckBox
                        checked={this.props.marketingOptOutFlag}
                        onChange={(e) => {
                            if (e.target.checked) {
                                this.props.setMarketingOptOutFlag('true');
                            } else {
                                this.props.setMarketingOptOutFlag('false');
                            }
                        }}
                    />
                    <div
                        className="contact-opt-in-label"
                        dangerouslySetInnerHTML={{
                            __html: this.props.translations.MARKETING_OPT_OUT_CHECKBOX.replace(
                                '{{dealership_name}}',
                                this.props.dealershipName
                            ).replace('{{dealership_url}}', this.props.dealershipURl),
                        }}
                    />
                </div>
            );
        }
        return null;
    };

    renderPrivacyNotice = () => {
        if (this.props.countryOptions.REQUIRE_PRIVACY_CHECKBOX === 'true') {
            return (
                <div className="contact-opt-in-container" key="privacy-opt">
                    <i className="contact-warning-icon icon alert-caution-grey" />
                    <SharedCheckBox
                        id="privacyCheckBox"
                        checked={this.props.privacyOptInFlag}
                        onChange={() => {
                            if (!this.props.privacyOptInFlag) {
                                this.props.setPrivacyOptInFlag('true');
                            } else {
                                this.props.setPrivacyOptInFlag('false');
                            }
                        }}
                    />
                    <div className="contact-label-container">
                        <div
                            className="contact-opt-in-label"
                            dangerouslySetInnerHTML={{
                                __html: this.props.translations.POLICY_NOTICE,
                            }}
                        />
                        <div className="error-message error">
                            {!this.props.privacyOptInFlag ? this.props.translations.REQUIRED_FIELD_VALIDATOR : null}
                        </div>
                    </div>
                </div>
            );
        }
        return null;
    };

    render() {
        return <div className="contact-info-disclaimers">{this.combineCustomerInfoMethods()}</div>;
    }
}

const mapStateToProps = (state) => ({
    countryOptions: state.countryOptionsReducer.countryOptions,
    dealershipName: state.prefillReducer.dealerSummaryObject.dealershipSummary.name,
    dealershipSummary: state.prefillReducer.dealerSummaryObject.dealershipSummary,
    dealershipURl: state.prefillReducer.dealerSummaryObject.dealershipSummary.url,
    displaySmsAlertOption: state.prefillReducer.dealerSummaryObject.displaySmsAlertOption,
    dni: state.appointmentReducer.dni,
    flowSource: state.prefillReducer.flowSource,
    isValidated: state.appointmentReducer.isValidated,
    marketingOptOutFlag: state.appointmentReducer.marketingOptOutFlag,
    optInFlag: state.appointmentReducer.optInFlag,
    privacyOptInFlag: state.appointmentReducer.privacyOptInFlag,
    translations: state.countryOptionsReducer.translations,
    validFlag: state.appointmentReducer.validFlag,
});

export default connect(mapStateToProps, {
    setMarketingOptOutFlag,
    setOptInFlag,
    setPrivacyOptInFlag,
    setValidFlag,
})(OptIn);
