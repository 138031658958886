import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    setAppGlobals,
    setCallCount,
    setSpinner,
    setValidFlag,
    searchVehicleByLicensePlateRespBackButtonClicked,
} from '../../Redux/ActionCreator';
import { getAppGlobals, getDealersByRegion } from '../../shared/BackendCalls/SharedBackendGets';
import Stepper from './CustomStepper';
import store from '../../Redux/Store';
import Alert from '@material-ui/lab/Alert';

const constants = require('../../shared/Constants/SharedConstants');
const flowConstants = require('../../shared/RoutingFlow/FlowComponentsConstants');

class Header extends React.Component {
    getOssGlobals(bac) {
        if (bac && this.props.vCountry) {
            if (this.props.vCallCount <= this.props.vMaxPegaAttempt + this.props.vMaxPegaAttemptsBooster) {
                getAppGlobals(
                    this.props.vCountry,
                    this.props.vVin,
                    bac,
                    this.props.vYear,
                    this.props.vMake,
                    this.props.vModel,
                    this.props.vLocale,
                    this.props.vprevBac,
                    this.props.vPlus,
                    this.props.vMiles,
                    this.props.vUnits,
                    this.props.record
                );
            } else {
                this.props.history.push({
                    pathname: '/Error',
                });
            }
        }
    }

    render() {
        let backButton;

        if (
            this.props.backButtonLink &&
            this.props.headerNav !== constants.CONFIRMATION &&
            this.props.modifyFlag !== true
        ) {
            backButton = (
                <Link
                    className="button icon arrow-left"
                    to={
                        (this.props.vehicleSelected === 1 && this.props.headerNav === constants.TITLE_SERVICES) ||
                        (this.props.vehicleSelected === 2 && this.props.headerNav === constants.SELECT_VEHICLE)
                            ? '/MyGarage'
                            : this.props.backButtonLink
                    }
                    onClick={() => {
                        this.props.headerNav === constants.DEALERSHIP_LOC &&
                        this.props.selectedDealer.bac !== this.props.vprevBac
                            ? this.getOssGlobals(this.props.selectedDealer.bac)
                            : this.props.setValidFlag('true');
                        // Fix for back button in Quantum brand flow from Vehicle page
                        if (this.props.flowSource === flowConstants.BrandFlow) {
                            if (this.props.dealerships.length === 0) {
                                getDealersByRegion();
                            }
                        }
                        if (
                            this.props.backButtonLink === constants.VEHICLE &&
                            this.props.searchByLicensePlateRespReceived === true
                        ) {
                            // Inform VehicleReducer to change state on back button click when viewing search by license results
                            store.dispatch(searchVehicleByLicensePlateRespBackButtonClicked());
                        }
                    }}
                />
            );
        }

        let mybrandURL = '';
        if (this.props.vUrl) {
            mybrandURL =
                '<a href=' +
                this.props.vUrl +
                ' target="_blank">' +
                this.props.vUrl.replace(/^https?:\/\//, '').split('/')[0] +
                '</a>';
        } else if (this.props.selectedDealer?.serviceCertifiedResponse?.url) {
            mybrandURL =
                '<a href=' +
                this.props.selectedDealer.serviceCertifiedResponse.url +
                ' target="_blank">' +
                this.props.selectedDealer.serviceCertifiedResponse.url.replace(/^https?:\/\//, '').split('/')[0] +
                '</a>';
        }
        return (
            <>
                <header className="header">
                    <nav className="header-navigation">
                        <div className="header-logo">{backButton}</div>
                        <Stepper />
                    </nav>
                </header>
                {this.props.countryOptions.EV_DEALER_LOCATOR === 'true' &&
                    ((this.props.headerNav === constants.TITLE_SERVICES &&
                        this.props.vCertified === false &&
                        this.props.flowSource !== flowConstants.AuthenticatedFlow && (
                            <Alert className="warning-message" severity="warning">
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: this.props.vTranslations[
                                            'SERVICES_PAGE_SERVICE_WARNING_' + this.props.vCode
                                        ].replace('{{mybrandURL}}', mybrandURL),
                                    }}
                                ></div>
                            </Alert>
                        )) ||
                        (this.props.headerNav === constants.DEALERSHIP_LOC &&
                            this.props.selectedDealer?.serviceCertifiedResponse?.certified === false && (
                                <Alert className="warning-message" severity="warning">
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: this.props.vTranslations[
                                                'LOCATOR_PAGE_SERVICE_WARNING_' +
                                                    this.props.selectedDealer.serviceCertifiedResponse.code
                                            ].replace('{{mybrandURL}}', mybrandURL),
                                        }}
                                    ></div>
                                </Alert>
                            )))}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    backButtonLink: state.headReducer.backButtonLink,
    headerNav: state.headReducer.headerNavigation,
    headerTitle: state.headReducer.headerTitle,
    modifyFlag: state.appointmentReducer.modifyFlag,
    searchByLicensePlateRespReceived: state.vehicleReducer.searchByLicensePlateRespReceived,
    selectedDealer: state.locReducer.selectedDealer,
    vCallCount: state.ossAppGlobals.countPegaCurr,
    vCountry: state.prefillReducer.country,
    vLocale: state.prefillReducer.locale,
    vMake: state.prefillReducer.make,
    vMaxPegaAttempt: state.prefillReducer.maxPegaAttempts,
    vMaxPegaAttemptsBooster: state.prefillReducer.maxPegaAttemptsBooster,
    vMiles: state.prefillReducer.miles,
    vModel: state.prefillReducer.model,
    vPlus: state.vehicleReducer.selectedMotor,
    vprevBac: state.ossAppGlobals.bac,
    vTranslations: state.countryOptionsReducer.translations,
    vVin: state.prefillReducer.vin,
    vYear: state.prefillReducer.year,
    flowSource: state.prefillReducer.flowSource,
    dealerships: state.locReducer.dealerships,
    region: state.prefillReducer.postalCode,
    postalZone: state.prefillReducer.dealerSummaryObject.dealershipSummary.address.postalZone,
    vUnits: state.prefillReducer.units,
    newAppointmentFlag: state.confirmationReducer.newAppointmentFlag,
    record: state.reviewReducer.recordLocator
        ? state.reviewReducer.recordLocator
        : state.prefillReducer.editAppointmentRecordLocator,
    vehicleSelected: state.myGarageReducer.vehicleSelected,
    make: state.prefillReducer.make,
    themeMake: state.prefillReducer.themeMake,
    countryOptions: state.countryOptionsReducer.countryOptions,
    vCertified: state.prefillReducer.certified,
    vUrl: state.prefillReducer.url,
    vCode: state.prefillReducer.code,
});

export default withRouter(
    connect(mapStateToProps, {
        setAppGlobals,
        setSpinner,
        setCallCount,
        setValidFlag,
    })(Header)
);
