export const loadGoogleMapsAPI = () => {
    return new Promise((resolve) => {
        if (window.google && window.google.maps) {
            resolve();
        } else {
            const script = document.createElement('script');
            script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`;
            script.defer = true;
            script.onload = resolve;
            document.body.appendChild(script);
        }
    });
};