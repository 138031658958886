export const RECOMMENDATIONS = 'recommendations';
export const CRITICAL_ITEMS = 'criticalItems';
export const NON_CRITICAL_ITEMS = 'nonCriticalItems';
export const ITEM_CATEGORY = 'itemCategories';
export const ENTERPRISE = 'enterprise';
export const PRIORITY_ITEMS = 'priorityItems';
export const ONE = 1;
export const TWO = 2;
export const THREE = 3;
export const FOUR = 4;
export const FIVE = 5;
export const SIX = 6;
export const SEVEN = 7;
export const EIGHT = 8;
export const NINE = 9;
export const TEN = 10;
export const ELEVEN = 11;
export const SERVICE = 'Service';
export const OFFER = 'Offer';
export const IVH = 'IVH';
export const PRIORITY_SERVICE = 'PItems';
export const CRITICAL_SERVICE = 'CEO';
export const NON_CRITICAL_SERVICE = 'MItem';
export const MAINTENANCE_SERVICE = 'MaintenanceItems';
export const PEGA_ENTERPRISE_SERVICE = 'AddtlOffers';
export const PEGA_ADDITIONAL_SERVICE = 'AddtlOffers';
export const SERVICES_ITEM_SERVICE = 'SOffers';
export const DEALERSHIP_ITEM_SERVICE = 'DealershipOffers';
export const CONSOLIDATED_ITEM_SERVICE = 'COffers';
export const DEALER_CONFIG_ITEM_SERVICE = 'DealerConfigServices';
export const NAME_PREFIX_DEALER_CONFIG = 'DealerConfig';
export const SCHEDULED_MAINTENANCE = 'Scheduled Maintenance';
export const LINE_ITEM_TYPE_SERVICE = 'SERVICE';
export const LINE_ITEM_TYPE_PLUS_MAINTENANCE = 'MAINTENANCE';
export const LINE_ITEM_TYPE_PLUS_MAINTENANCE_ITEM = 'MaintenanceItems';
export const LINE_ITEM_TYPE_PLUS_RECALL = 'RECALL';
export const LINE_ITEM_TYPE_OFFER = 'OFFER';
export const LINE_ITEM_TYPE_PLUS_PEGA_RECALL = 'PEGA_RECALL';
export const LINE_ITEM_TYPE_PLUS_PEGA_ENT = 'PEGA_ENT';
export const LINE_ITEM_TYPE_PLUS_PEGA_DIAG = 'PEGA_DIAG';
export const LINE_ITEM_NAME_PREFIX_CRITICAL = 'CRITICAL';
export const LINE_ITEM_TYPE_PLUS_MAINTENANCE_SCHEDULED = 'MAINTENANCE  SCHEDULE';
export const LINE_ITEM_CODE_NEXT_BEST_OFFER = 'NEXT_BEST_OFFER';
export const LINE_ITEM_TYPE_PLUS_PEGA_OTHER = 'PEGA_OTHER';
export const LINE_ITEM_TYPE_PLUS_NATIONAL = 'NATIONAL';
export const LINE_ITEM_TYPE_PLUS_DEALER = 'DEALER';
export const LINE_ITEM_NAME_PREFIX = 'CONSOLIDATED';
export const LINE_ITEM_TYPE_PLUS_COMMON = 'COMMON';




