import React, { useState } from "react";
import { useSelector } from "react-redux";

const LineItemWrapper = (props) => {
    const [hideDetails, setHideDetails] = useState(true);
    const { translations } = useSelector((state) => state.ossAppGlobals);

    const toggleDetails = () => {
        setHideDetails(!hideDetails);
    };


    return (
        <div>
            {hideDetails ? (
                <button
                    className="service-item-details-link link stat-text-link"
                    data-dtm={`service:need-something-else:${props.name}`}
                    onClick={toggleDetails}
                    type="button"
                >
                    {translations.DETAILS}
                </button>
            ) : (
                <>
                    <div>{props.children}</div>
                    <button
                        className="service-item-details-link link stat-text-link"
                        data-dtm={`service:need-something-else:${props.name}`}
                        onClick={toggleDetails}
                        type="button"
                    >
                        {translations.SHOW_LESS}
                    </button>
                </>
            )}
        </div>
    );
};

export default LineItemWrapper;
