import {batch, useSelector} from "react-redux";
import * as React from 'react';
import {Box, Checkbox, FormControlLabel, TextField, Typography} from "@material-ui/core";
import {useState} from "react";
import store from "../../Redux/Store";
import {
    setMobileServiceAddressAndPhoneNumber,
    setPickupAddress
} from "../../Redux/ActionCreator";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import SharedBasicModal from "../../shared/Modal/SharedBasicModal";
import {getNextAvailable} from "../../shared/BackendCalls/SharedBackendGets";
import AddressForm from "../../shared/AddressForm/AddressForm";




const MobileServiceAddress = ()=>{
    const [isValidAddress, setIsValidAddress] = useState(false);
    const [formError,setFormError] = useState(false);
    const [modelOpen,setModelOpen] = useState(false);
    const [isChecked,setIsChecked] = useState(false);
    const {street,city,regionCode,postalCode,country} = useSelector((state)=>state.prefillReducer);
    const {translations,} = useSelector((state) => state.countryOptionsReducer);
    const {bac} = useSelector((state) => state.prefillReducer.dealerSummaryObject.dealershipSummary);
    const {serviceAddresses,serviceCommunications} = useSelector((state) => state.mobileServiceReducer);
    const {conciergePhoneNumber,pickupAddress} = useSelector((state)=>state.appointmentReducer);
    const initialPickUpAddress = [street, city, regionCode, postalCode,street && country].filter(Boolean).join(",");
    const [addressComponent,setAddressComponent] = useState('');



    const addressInitialState = {
        address1: serviceAddresses?.length > 0 ? serviceAddresses[0].addressLine1:street ? street:'',
        address2: serviceAddresses?.length > 0 ? serviceAddresses[0].addressLine2:'',
        city: serviceAddresses?.length > 0 ? serviceAddresses[0].cityName:city ? city:'',
        postalCode:serviceAddresses?.length > 0 ? serviceAddresses[0].postalZone:postalCode ? postalCode:'',
        state: serviceAddresses?.length > 0 ? serviceAddresses[0].countrySubentity: regionCode ? regionCode:'',
        country:serviceAddresses?.length > 0 ? serviceAddresses[0].countrySubentityCode.split("-")[0]:country ? country:'',
        phoneNumber:serviceCommunications?.length > 0 ? serviceCommunications[0].value:conciergePhoneNumber ? conciergePhoneNumber:'',
        fullAddress:pickupAddress ? pickupAddress: initialPickUpAddress ? initialPickUpAddress : ''
    }

  const handleAcknowledgeButton = ()=>{
        if(isValidAddress){
            setFormError(false);

           const pickUpAddress = addressComponent.isGoogleAddress
               ? addressComponent.fullAddress
               :[addressComponent.address1, addressComponent.city, addressComponent.state, addressComponent.postalCode,addressComponent.country].filter(Boolean).join(",");

           const serviceAddressPayload = [{
               type: 'SERVICE',
               addressLine1:addressComponent.address1,
               cityName:addressComponent.city,
               countrySubentityCode: addressComponent.country + "-" + addressComponent.state,
               countrySubentity: addressComponent.state,
               postalZone:addressComponent.postalCode,

           }]
            if(addressComponent.address2){
                serviceAddressPayload[0].addressLine2 = addressComponent.address2;
            }


           batch(()=>{
               store.dispatch(setPickupAddress(pickUpAddress));
               store.dispatch(setMobileServiceAddressAndPhoneNumber({
                   serviceAddresses: serviceAddressPayload ,
                   serviceCommunications: [{
                       type: "SERVICE_PHONE",
                       "value":  addressComponent.phoneNumber
                   }]

               }));

           })
           getNextAvailable(bac);
           setModelOpen(false);
       }
       else {
            console.log("Invalid Address",addressComponent)
            setFormError(true);
       }
     }

   const mobileServiceAddressContent = <React.Fragment>
       <Box>
           <AddressForm
            id="mobile_service_form"
            onChange={(data)=>{
            setIsValidAddress(data.isValidAddress);
            setAddressComponent(data.address);
            }} initialState={addressInitialState}
            showFormError={formError}
            showRangeError={false}
             showPhoneNumber={true} />
        </Box>
       <Box marginTop={2} marginBottom={2}>
           <List><b> {translations.MOBILE_SERVICE_GUIDELINES_TITLE}</b>
               <ListItem>{translations.MOBILE_SERVICE_GUIDELINES_1 || 'MOBILE_SERVICE_GUIDELINES_1'}</ListItem>
               <ListItem>{translations.MOBILE_SERVICE_GUIDELINES_2 || 'MOBILE_SERVICE_GUIDELINES_2'}</ListItem>
               <ListItem>{translations.MOBILE_SERVICE_GUIDELINES_3 || 'MOBILE_SERVICE_GUIDELINES_3'}</ListItem>
               <ListItem>
                   <span dangerouslySetInnerHTML={{__html:`${translations.MOBILE_SERVICE_GUIDELINES_4 || 'MOBILE_SERVICE_GUIDELINES_4'}`}}/>
               </ListItem>
           </List>
       </Box>


        <FormControlLabel
            control={<Checkbox
                checked={isChecked}
                size="small"
                color="primary"
                onChange={()=>setIsChecked(!isChecked)}
                style={{paddingRight:'8px'}} />}
            label={translations.MOBILE_SERVICE_GUIDELINES_CONFIRM || 'MOBILE_SERVICE_GUIDELINES_CONFIRM'}  />
        <Box  marginTop={2} marginBottom={2}>
           <Typography>{translations.MOBILE_SERVICE_GUIDELINES_EDIT || 'MOBILE_SERVICE_GUIDELINES_EDIT'}</Typography>
       </Box>
   </React.Fragment>


    return <>
            <div className="transport-info-text">
                   <Typography>{pickupAddress ? pickupAddress: translations.MOBILE_SERVICE_ADDRESS_TITLE}</Typography>
                    <Box marginTop={2}>
                        <button className="link" onClick={()=>{setModelOpen(true)}}>
                            {pickupAddress ?  translations.MOBILE_SERVICE_LOCATION_UPDATE: translations?.MOBILE_SERVICE_LOCATION_SET}
                        </button>
                    </Box>
            </div>


        <SharedBasicModal   onClose={()=>setModelOpen(false)}
                            show={modelOpen}
                            title={translations.MOBILE_SERVICE_LOCATION_TITLE || "MOBILE_SERVICE_LOCATION_TITLE"}
                            bodyAsText={mobileServiceAddressContent}
                            primaryButton={translations.MOBILE_SERVICE_GUIDELINES_ACK}
                            handleClick={handleAcknowledgeButton}
                            disabledPrimaryButton={!isChecked}
                            handleSecondaryButtonClick={()=>setModelOpen(false)}
                            secondaryButton={translations.MOBILE_SERVICE_GUIDELINES_DENY}
                            addScroller={true}
                            />
    </>

}

export default MobileServiceAddress