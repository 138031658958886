// Reducer Helper Methods
// Set the state for the entry values for OSS APP

import * as consts from '../shared/Constants/ServiceConstants.js';

export const setEntryState = (state, action) => {
    state.vin = action.payload;
    return state;
};

export const unselectCheckedNonPegaMaintenanceService = (state, id) => {
    const index = state.servicesArrayList.findIndex((x) => x.serviceFEID === id);
    if (state.servicesArrayList[index].serviceType === 'MaintenanceItems') {
        state.servicesArrayList.forEach((service) => {
            if (service.serviceType === consts.MAINTENANCE_SERVICE && service.serviceFEID !== id) {
                service.selected = false;
                service.selectedDisplay = false;
                state.selectedCount = getTotalCheckedCount(state);
            }
        });
    }
};

let index = '';
export const setServiceSelect = (state, action) => {
    unselectCheckedNonPegaMaintenanceService(state, action.payload.id);
    index = state.servicesArrayList.findIndex((x) => x.serviceFEID === action.payload.id);
    state.servicesArrayList[index].selected = !state.servicesArrayList[index].selected;
    state.selectedCount = getTotalCheckedCount(state);
    if (!action.payload.checked) {
        state.servicesArrayList[index].offerSelected = '';
        state.servicesArrayList[index].selectedDisplay = false;
        state.servicesArrayList[index].selected = false;
        state.servicesArrayList[index].offers.forEach((x) => {
            x.selectedDisplay = false;
            x.selected = false;
        });
    }
    state.booleanVal = !state.booleanVal;
    return state;
};

//filters the offers based on what the user searches
export const setDisplayOffersServices = (state, action) => {
    //Loop through all the offers to decide what to display
    for (let i = 0; i < state.servicesArrayList.length; i++) {
        if (!state.servicesArrayList[i].requiredOrCommonService) {
            let foundService = false;
            state.servicesArrayList[i].isCurrentSearch = false;

            //Reset all offers to true
            state.servicesArrayList[i].display = true;

            //Display the service if it has been checked
            if (state.servicesArrayList[i].selected) {
                foundService = true;
            }
            //Check if the service returned matches the current service
            for (let j = 0; j < action.payload.length; j++) {
                //Display the service if it matches the user's search results
                if (state.servicesArrayList[i].serviceFEID === action.payload[j].serviceFEID) {
                    foundService = true;
                    state.servicesArrayList[i].isCurrentSearch = true;
                }
            }

            //remove the item from the display if it was not found above
            if (!foundService) {
                state.servicesArrayList[i].display = false;
            }

            //Remove "Schedule Maintenance" if Maintenance Item "Maintenance Schedule" exists
            if (state.servicesArrayList[i].name === 'Scheduled Maintenance') {
                const duplicateServices = state.servicesArrayList.find(
                    ({ categoryDescription }) => categoryDescription === 'MAINTENANCE  SCHEDULE'
                )
                    ? true
                    : false;
                state.servicesArrayList[i].display = duplicateServices ? false : true;
            }
        }
    }
    return state;
};

export const sortDisplay = (state) => {
    //Sort by ID so that the services/offers are sorted in the order received
    state.servicesArrayList.sort((a, b) => a.serviceFEID - b.serviceFEID);
    state.servicesArrayList.sort((a, b) => a.secondaryOrdering - b.secondaryOrdering);
    state.servicesArrayList.sort((a, b) => a.primaryOrdering - b.primaryOrdering);
    //Sort the checked before the unchecked except if the requiredOrCommonService variable is true
    state.servicesArrayList = [
        ...state.servicesArrayList.filter((x) => x.requiredOrCommonService),
        ...state.servicesArrayList.filter((x) => !x.requiredOrCommonService).sort((a, b) => b.selected - a.selected),
    ];
    state.booleanVal = !state.booleanVal;
    return state;
};

export const setChooseOneServiceOffer = (state, action) => {
    const index = state.servicesArrayList.findIndex((x) => x.serviceFEID === action.payload.serviceID);
    //Only one selected value can be true, but all selected values could be false
    state.servicesArrayList[index].offers.map((x) => {
        if (x.hasOwnProperty('id')) {
            x['id'] === action.payload.offerID
                ? (x['selectedDisplay'] = action.payload.checked)
                : (x['selectedDisplay'] = false);
        } else {
            x.serviceFEID === action.payload.offerID
                ? (x['selectedDisplay'] = action.payload.checked)
                : (x['selectedDisplay'] = false);
        }
    });
    state.booleanVal = !state.booleanVal;
};

export const getTotalCheckedCount = (state) => {
    const countMisc = state.miscService.length > 0;
    let countRecommended;
    if (state.servicesArrayList) {
        countRecommended = state.servicesArrayList.filter((x) => x.selected).length;
    }
    const countTireFinder = state.tires ? state.tires.length : 0;
    return countMisc + countRecommended + countTireFinder;
};

export const updateOfferOrServiceList = (state, id, checked, selectedOffer) => {
    index = state.servicesArrayList.findIndex((x) => x.serviceFEID === id);
    if (selectedOffer !== '') {
        state.servicesArrayList[index].selected = checked;
    }

    if (!checked) {
        state.servicesArrayList[index].offers.map((x) => (x.selected = false));
        state.servicesArrayList[index].offers.map((x) => (x.selectedDisplay = false));
        if (state.servicesArrayList[index].offers && state.servicesArrayList[index].offers.length > 0) {
            state.servicesArrayList[index].displayPrice = null;
        }
    }

    state.servicesArrayList[index].selectedOffer = selectedOffer;
    if (selectedOffer === null && !checked) {
        state.servicesArrayList[index].selectedOffer = '';
    }

    state.selectedCount = getTotalCheckedCount(state);
    state.booleanVal = !state.booleanVal;
};

export const setSelectedOffer = (state, action) => {
    state.booleanVal = !state.booleanVal;
    //First update the Offer or Service List
    updateOfferOrServiceList(state, action.payload.id, action.payload.checked, null);
    const index = state.servicesArrayList.findIndex((x) => x.serviceFEID === action.payload.id);

    //remove an item if it is unchecked and not part of the current search
    if (!action.payload.checked && action.payload.currentSearch && !state.servicesArrayList[index].isCurrentSearch) {
        for (let k = 0; k < state.servicesArrayList.length; k++) {
            if (state.servicesArrayList[k].serviceFEID === action.payload.id) {
                state.servicesArrayList[k].display = false;
            }
        }
    }

    //Sort the updated display
    sortDisplay(state);
    return state;
};

export const populateSelectedOffer = (state, action) => {
    index = state.servicesArrayList.findIndex((x) => x.code === action.payload);

    //Default offer and price in case the user didn't select any option.
    state.servicesArrayList[index].offerSelected = '';

    //Set offer and price to which ever selectedDisplay is true
    const offers = state.servicesArrayList[index].offers;
    if (offers) {
        for (let p = 0; p < offers.length; p++) {
            state.servicesArrayList[index].offerSelected = offers[p].name;
            state.servicesArrayList[index].displayPrice = offers[p].displayPrice;
            //Update the selected to match the selectedDisplay
            state.servicesArrayList[index].offers[p].selected = offers[p].selectedDisplay;
        }
    }
    state.booleanVal = !state.booleanVal;
    return state;
};

export const restoreSelectedServiceOffer = (state, action) => {
    const index = state.servicesArrayList.findIndex((x) => x.serviceFEID === action.payload);
    state.servicesArrayList[index].offers.map((x) => (x['selectedDisplay'] = x['selected']));
};

export const populateSelectedServiceOffer = (state, action) => {
    //If the user doesn't click an option, just return
    if (Object.keys(action.payload).length === 0) {
        return;
    }
    const index = state.servicesArrayList.findIndex((x) => x.serviceFEID === action.payload.serviceID);
    //If the user checked a specific offer, update the selectedOffer value to display to the user
    //If the user unchecked a specific offer, update the selectedOffer to empty string so nothing is shown to the user
    action.payload.checked
        ? (state.servicesArrayList[index].selectedOffer = action.payload.item)
        : (state.servicesArrayList[index].selectedOffer = '');

    //Change the checked flag on the displayOffersServices
    state.servicesArrayList[index].selected = action.payload.checked;

    //If the Offer has a price, save it so it is shown on the initial screen
    const additionalOfferIndex = state.servicesArrayList[index].offers.filter((x) =>
        x.selectedDisplay ? x.displayPrice : ''
    );
    if (additionalOfferIndex && additionalOfferIndex.length > 0) {
        state.servicesArrayList[index].displayPrice = additionalOfferIndex[0].displayPrice;
    }

    //Copy the selectedDisplay to selected.
    //selectedDisplay is a temp variable in case the pop up is closed and the data needs to be restored.
    state.servicesArrayList[index].offers.map((x) => (x.selected = x.selectedDisplay));

    //Update the Service or Offers array list
    updateOfferOrServiceList(
        state,
        action.payload.serviceID,
        action.payload.checked,
        state.servicesArrayList[index].selectedOffer
    );

    //Sort the display in case the checked values have changed
    sortDisplay(state);
    state.booleanVal = !state.booleanVal;
    return state;
};

export const addMISCService = (state, action) => {
    state.miscService = action.payload;
    state.remainingLength = 300 - action.payload.length;
    state.selectedCount = getTotalCheckedCount(state);
    state.booleanVal = !state.booleanVal;

    return state;
};

export const addTireFinderService = (state, action) => {
    state.tires = action.payload;
    state.selectedCount = getTotalCheckedCount(state);

    return state;
};

export const addTireFinderServiceString = (state, action) => {
    state.tiresString = action.payload;

    return state;
};

export const setCallCount = (state) => {
    state.countPegaCurr = state.countPegaCurr + 1;
    state.booleanVal = !state.booleanVal;
    return state;
};

export const setSpinner = (state, action) => {
    state.showSpinner = action.payload;
    //state.selectedCount = action.payload ? 0 : '';
    state.booleanVal = !state.booleanVal;
    return state;
};

export const resetPreviousBac = (state, action) => {
    state.previousBac = action.payload.bac;
    state.booleanVal = !state.booleanVal;
    return state;
};

let record = '';
let precheck_dealerConfig = '';
let precheck_maintenanceData = '';
export const setAppGlobals = (state, action) => {
    state.selectedCount = 0;
    state.vCallCount = 0;
    state.countPegaCurr = 0;
    state.bac = action.payload.bac;
    state.previousBac === '' ? (state.previousBac = state.bac) : (state.previousBac = action.payload.previousBac);
    state.country = action.payload.country;
    let recommendations;
    let pegaAdditionalServices;
    let enterprise;
    let criticalItems;
    let priorityItems;
    let nonCriticalItems;
    let ivhItems;
    let maintenanceData;
    let dealershipConfigService;
    let consolidatedOffers;
    let serviceOfferData;
    let dealershipOffers;
    let commonServicesData;
    record = action.payload.record;
    precheck_dealerConfig = action.payload.precheck_dealerConfig;
    precheck_maintenanceData = action.payload.precheck_maintenanceData;
    if (Object.keys(action.payload).length > 0) {
        if (action.payload.data.recommendedServices) {
            recommendations = action.payload.data.recommendedServices.pega;
            state.pega_response = recommendations;
            if (recommendations) {
                criticalItems = recommendations[consts.CRITICAL_ITEMS];
                priorityItems = recommendations[consts.PRIORITY_ITEMS];
                nonCriticalItems = recommendations[consts.NON_CRITICAL_ITEMS];
                state.vin = recommendations['vin'];
                state.vehicleYear = recommendations['modelYear'];
                state.vehicleMake = recommendations['make'];
                state.vehicleModel = recommendations['model'];
                state.vehicleMiles = recommendations['odoReading'];
            }
        }
        if (action.payload.data.additionalServices) {
            pegaAdditionalServices = action.payload.data.additionalServices.pegaAdditionalOffers;
        }
        if (action.payload.data.additionalServices) {
            enterprise = action.payload.data.additionalServices.pegaEnterpriseOffers;
        }
        if (action.payload.data.recommendedServices && action.payload.data.recommendedServices.ivh !== null) {
            ivhItems = action.payload.data.recommendedServices.ivh.serviceNeedList;
        }
        if (action.payload.data.recommendedServices) {
            maintenanceData = action.payload.data.recommendedServices.maintenancesData;
        }
        if (action.payload.data.recommendedServices) {
            commonServicesData = action.payload.data.recommendedServices.dealerConfigAddtlServices;
        }

        if (action.payload.data.additionalServices) {
            dealershipConfigService = action.payload.data.additionalServices.dealerConfigAddtlServices;
            consolidatedOffers = action.payload.data.additionalServices.consolidatedOffers;
            serviceOfferData = action.payload.data.additionalServices.serviceOfferData;
            dealershipOffers = action.payload.data.additionalServices.dealershipOffers;
        }

        //counter for each element in serviceArrayList array
        let counter = 0;
        state.servicesArrayList = [];

        //Populate merged services with IVH items
        if (ivhItems) {
            counter = populateIvhServices(state, ivhItems, counter);
        }

        //recall info, priority items
        if (priorityItems) {
            //Populate merged services with recall items;
            counter = populatePegaPriorityService(state, priorityItems, counter);
        }

        //Change Engine Oil, Battery Info, critical items
        if (criticalItems) {
            const cItemCategory = criticalItems[consts.ITEM_CATEGORY];
            counter = populatePegaCriticalServices(state, cItemCategory, counter);
        }

        //Maintenance Schedule, Non critical items
        if (nonCriticalItems) {
            if (nonCriticalItems.itemCategories) {
                const ncItemCategory = nonCriticalItems[consts.ITEM_CATEGORY];
                state.maintenanceItems = [];
                counter = populatePegaNonCriticalService(state, ncItemCategory, counter);
            }
        }

        //Maintenance Date (non-PEGA)
        if (maintenanceData) {
            const maintenaceDateItems = maintenanceData.maintenances;
            counter = populateMaintenaceService(state, maintenaceDateItems, counter);
        }

        //Common Service Data
        if (commonServicesData) {
            counter = setDealerConfigAdditionalServices(state, commonServicesData, counter, true);
        }

        //Additional Offers
        if (pegaAdditionalServices) {
            //Populate additionalOffersList with additional Offers;
            counter = populatePegaAdditionalOffer(state, pegaAdditionalServices, counter);
        }

        //Populate Enterprise Offers
        if (enterprise && enterprise.offers) {
            counter = populatePegaEnterpriseOffers(state, enterprise, counter);
        }

        //Populate Consolidated Offers
        if (consolidatedOffers) {
            counter = populateConsolidatedOffers(state, consolidatedOffers, counter);
        }

        //Populate Service Offer Data
        if (serviceOfferData) {
            counter = populateServiceOfferData(state, serviceOfferData, counter);
        }

        //Populate DealershipOffers
        if (dealershipOffers) {
            counter = populateDealershipOffers(state, dealershipOffers, counter);
        }

        if (dealershipConfigService) {
            setDealerConfigAdditionalServices(state, dealershipConfigService, counter, false);
        }
    }

    sortDisplay(state);
    state.selectedCount = getTotalCheckedCount(state);
    state.booleanVal = !state.booleanVal;
    return state;
};

export const emptyServiceObject = (serviceTemp) => {
    serviceTemp = [];
    serviceTemp.serviceType = '';
    serviceTemp.primaryOrdering = '';
    serviceTemp.secondaryOrdering = '';
    serviceTemp.serviceFEID = '';
    serviceTemp.requiredOrCommonService = false;
    serviceTemp.selected = false;
    serviceTemp.name = '';
    serviceTemp.namePrefix = '';
    serviceTemp.type = '';
    serviceTemp.typePlus = null;
    serviceTemp.code = '';
    serviceTemp.couponCode = '';
    serviceTemp.globalLaborCode = '';
    serviceTemp.itemDescription = '';
    serviceTemp.categoryDescription = '';
    serviceTemp.displayPrice = '';
    serviceTemp.priceDetails = '';
    serviceTemp.priceDisclaimer = '';
    serviceTemp.disclaimer = '';
    serviceTemp.offers = [];
    serviceTemp.display = true;
    serviceTemp.selectedOffer = '';
    return serviceTemp;
};

export const populateIvhServices = (state, ivhItems, counter) => {
    let serviceTemp = [];
    //Populate merged services with IVH items
    for (let p = 0; p < ivhItems.length; p++) {
        serviceTemp = emptyServiceObject();
        serviceTemp.serviceType = consts.IVH;
        serviceTemp.primaryOrdering = consts.ONE;
        serviceTemp.secondaryOrdering = counter++;
        serviceTemp.serviceFEID = counter++;
        serviceTemp.requiredOrCommonService = true;
        serviceTemp.selected = true;
        serviceTemp.name = ivhItems[p].campaignDescription;
        serviceTemp.namePrefix = ivhItems[p].campaignType;
        serviceTemp.type = 'OFFER';
        serviceTemp.typePlus = 'RECALL';
        serviceTemp.code = ivhItems[p].campaignNumber;
        state.servicesArrayList.push(serviceTemp);
    }
    return counter;
};

//PEGA Recall
export const populatePegaPriorityService = (state, priorityItems, counter) => {
    let serviceTemp = [];
    for (let p = 0; p < priorityItems.length; p++) {
        serviceTemp = emptyServiceObject();
        serviceTemp.serviceType = consts.PRIORITY_SERVICE;
        serviceTemp.primaryOrdering = consts.TWO;
        serviceTemp.secondaryOrdering = counter++;
        serviceTemp.serviceFEID = counter++;
        serviceTemp.requiredOrCommonService = true;
        serviceTemp.selected = true;
        serviceTemp.name = priorityItems[p].mfrRecallNumber
            ? 'Recall - ' + priorityItems[p].recallTitle
            : priorityItems[p].recallTitle;
        serviceTemp.namePrefix = 'PRIORITY';
        serviceTemp.type = 'OFFER';
        serviceTemp.typePlus = 'PEGA_RECALL';
        serviceTemp.code = priorityItems[p].mfrRecallNumber;
        serviceTemp.itemDescription = priorityItems[p].recallDescription;

        //Other
        serviceTemp.safetyDescription = priorityItems[p].safetyRiskDescription;
        serviceTemp.remedyDescription = priorityItems[p].remedyDescription;

        state.servicesArrayList.push(serviceTemp);
    }
    return counter;
};

export const populatePegaCriticalServices = (state, cItemCategory, counter) => {
    let serviceTemp = [];
    for (let ic = 0; ic < cItemCategory.length; ic++) {
        const cSuggestedItems = cItemCategory[ic]['suggestedItems'];
        const cServiceCategoryCode = cItemCategory[ic]['serviceCategoryCode'];
        const cCategoryDescription = cItemCategory[ic]['categoryDescription'];
        for (let p = 0; p < cSuggestedItems.length; p++) {
            const cRelatedOffers = cSuggestedItems[p]['relatedOffers'];
            serviceTemp = emptyServiceObject();
            serviceTemp.serviceType = consts.CRITICAL_SERVICE;
            serviceTemp.primaryOrdering = consts.THREE;
            serviceTemp.secondaryOrdering = counter++;
            serviceTemp.serviceFEID = counter++;
            serviceTemp.requiredOrCommonService = true;
            serviceTemp.selected = true;
            serviceTemp.name = cSuggestedItems[p].itemName;
            serviceTemp.namePrefix = 'CRITICAL';
            serviceTemp.type = 'SERVICE';
            serviceTemp.typePlus = 'PEGA_DIAG';
            serviceTemp.code = cServiceCategoryCode;
            serviceTemp.globalLaborCode = cSuggestedItems[p].itemOpCode;
            serviceTemp.itemDescription = cSuggestedItems[p].itemOpCodeDescription;
            serviceTemp.categoryDescription = cCategoryDescription;
            serviceTemp.displayPrice = cSuggestedItems[p].displayPrice;

            if (cRelatedOffers) {
                for (let ro = 0; ro < cRelatedOffers.length; ro++) {
                    let offerTemp = [];
                    offerTemp.serviceFEID = 'OCO' + counter++;
                    offerTemp.selected = false;
                    offerTemp.selectedDisplay = false;
                    offerTemp.name = cRelatedOffers[ro].offerTitle;
                    offerTemp.offerSubTitle = cRelatedOffers[ro].offerSubTitle;
                    offerTemp.disclaimer = cRelatedOffers[ro].disclaimer;
                    offerTemp.code = cServiceCategoryCode;
                    offerTemp.repeatService = false;
                    offerTemp.type = 'OFFER';
                    offerTemp.typePlus = 'PEGA_DIAG';
                    offerTemp.secondaryPrice = cRelatedOffers[ro].secondaryValueA;
                    offerTemp.namePrefix = 'CRITICAL';
                    offerTemp.globalLaborCode = cSuggestedItems[p].itemOpCode;
                    offerTemp.couponCode = cRelatedOffers[ro].couponNumber;
                    offerTemp.displayPrice = cRelatedOffers[ro].displayPrice;
                    offerTemp.additionalServiceDisclaimers = cRelatedOffers[ro].additionalServiceDisclaimers;
                    offerTemp.additionalService = cRelatedOffers[ro].additionalService;
                    serviceTemp.offers.push(offerTemp);
                }
            }

            state.servicesArrayList.push(serviceTemp);
        }
    }
    return counter;
};

export const populatePegaNonCriticalService = (state, ncItemCategory, counter) => {
    let serviceTemp = [];
    for (let ic = 0; ic < ncItemCategory.length; ic++) {
        const ncSuggestedItems = ncItemCategory[ic]['suggestedItems'];
        const ncServiceCategoryCode = ncItemCategory[ic]['serviceCategoryCode'];
        const ncCategoryDescription = ncItemCategory[ic]['categoryDescription'];

        serviceTemp = emptyServiceObject();
        serviceTemp.serviceType = consts.NON_CRITICAL_SERVICE;
        serviceTemp.primaryOrdering = consts.FOUR;
        serviceTemp.secondaryOrdering = counter++;
        serviceTemp.serviceFEID = counter++;
        serviceTemp.requiredOrCommonService = true;
        serviceTemp.selected = true;
        serviceTemp.name =
            ncCategoryDescription === 'MAINTENANCE  SCHEDULE'
                ? 'Maintenance Schedule - ' + ncSuggestedItems[0].intervalDistance + ' ' + state.translations.L_MILES
                : /*ncCategoryDescription*/ ncSuggestedItems[0].itemName;
        serviceTemp.namePrefix = 'NONCRITICAL';
        serviceTemp.type = ncCategoryDescription === 'MAINTENANCE  SCHEDULE' ? 'MAINTENANCE' : 'SERVICE';
        serviceTemp.typePlus = ncCategoryDescription === 'MAINTENANCE  SCHEDULE' ? 'PEGA_MAINT' : 'PEGA_DIAG';
        serviceTemp.code = ncServiceCategoryCode;
        serviceTemp.categoryDescription = ncCategoryDescription;
        serviceTemp.globalLaborCode = ncSuggestedItems[0].itemOpCode;

        //Populate Maintenance Items
        for (let p = 0; p < ncSuggestedItems.length; p++) {
            const cRelatedOffers = ncSuggestedItems[p]['relatedOffers'];
            let maintenaceItemTemp = [];
            maintenaceItemTemp.serviceFEID = consts.SERVICE + counter++;
            maintenaceItemTemp.name = ncSuggestedItems[p].itemName;
            maintenaceItemTemp.code = ncServiceCategoryCode;
            serviceTemp.relatedOffers = cRelatedOffers;
            state.maintenanceItems.push(maintenaceItemTemp);
            if (serviceTemp.type != 'MAINTENANCE') {
                serviceTemp.serviceType = consts.CRITICAL_SERVICE;
                serviceTemp.itemDescription = ncSuggestedItems[p].itemOpCodeDescription;
            }
            //Populate related offers
            if (cRelatedOffers) {
                for (let ro = 0; ro < cRelatedOffers.length; ro++) {
                    let offerTemp = [];
                    offerTemp.serviceFEID = counter++;
                    offerTemp.selected = false;
                    offerTemp.selectedDisplay = false;
                    offerTemp.name = cRelatedOffers[ro].offerTitle;
                    offerTemp.offerSubTitle = cRelatedOffers[ro].offerSubTitle;
                    offerTemp.disclaimer = cRelatedOffers[ro].disclaimer;
                    offerTemp.code = ncSuggestedItems[p].itemOpCode + '-' + cRelatedOffers[ro].couponNumber;
                    offerTemp.repeatService = false;
                    offerTemp.type = 'OFFER';
                    offerTemp.typePlus = 'PEGA_DIAG';
                    offerTemp.secondaryPrice = cRelatedOffers[ro].secondaryValueA;
                    offerTemp.namePrefix = 'NONCRITICAL';
                    offerTemp.globalLaborCode = ncSuggestedItems[p].itemOpCode;
                    offerTemp.couponCode = cRelatedOffers[ro].couponNumber;
                    offerTemp.displayPrice = cRelatedOffers[ro].displayPrice;
                    offerTemp.additionalOfferDisclaimers = cRelatedOffers[ro].additionalOfferDisclaimers;
                    offerTemp.additionalOffer = cRelatedOffers[ro].additionalOffer;
                    serviceTemp.offers.push(offerTemp);
                }
            }
        }

        state.servicesArrayList.push(serviceTemp);
    }
    return counter;
};

export const populateMaintenaceService = (state, maintenanceItems, counter) => {
    let serviceTemp = [];
    for (let p = 0; p < maintenanceItems.length; p++) {
        serviceTemp = emptyServiceObject();
        serviceTemp.serviceType = consts.MAINTENANCE_SERVICE;
        serviceTemp.primaryOrdering = consts.FIVE;
        serviceTemp.secondaryOrdering = counter++;
        serviceTemp.serviceFEID = counter++;
        serviceTemp.requiredOrCommonService = true;
        serviceTemp.selected = JSON.parse(precheck_maintenanceData)
            ? maintenanceItems[p].recommended
                ? true
                : false
            : false;
        serviceTemp.name = maintenanceItems[p].title;
        serviceTemp.namePrefix = 'MAINTENANCE';
        serviceTemp.type = 'MAINTENANCE';
        serviceTemp.code = maintenanceItems[p].id;
        serviceTemp.displayPrice = maintenanceItems[p].displayPrice;
        serviceTemp.priceDetails = maintenanceItems[p].priceDetails;
        serviceTemp.priceDisclaimer = maintenanceItems[p].priceDisclaimer;
        serviceTemp.disclaimer = maintenanceItems[p].disclaimer;

        //Other
        serviceTemp.recommended = maintenanceItems[p].recommended;
        serviceTemp.inspect = maintenanceItems[p].inspect;
        serviceTemp.replace = maintenanceItems[p].replace;
        state.servicesArrayList.push(serviceTemp);
    }
    return counter;
};

export const populatePegaEnterpriseOffers = (state, enterprise, counter) => {
    let serviceTemp = [];
    for (let p = 0; p < enterprise.offers.length; p++) {
        serviceTemp = emptyServiceObject();
        serviceTemp.serviceType = consts.PEGA_ENTERPRISE_SERVICE;
        serviceTemp.primaryOrdering = consts.SIX;
        serviceTemp.secondaryOrdering = counter++;
        serviceTemp.serviceFEID = counter++;
        serviceTemp.requiredOrCommonService = false;
        serviceTemp.selected = record ? enterprise[p].selected : false;
        serviceTemp.name = enterprise.offers[p].name;
        serviceTemp.namePrefix = 'ADDITIONAL';
        serviceTemp.type = 'OFFER';
        serviceTemp.typePlus = 'PEGA_ENT';
        serviceTemp.code = 'NEXT_BEST_OFFER';
        serviceTemp.itemDescription = enterprise.offers[p].description;
        serviceTemp.displayPrice = enterprise.offers[p].displayPrice;
        state.servicesArrayList.push(serviceTemp);
    }
    return counter;
};

export const populatePegaAdditionalOffer = (state, pegaAdditionalServices, counter) => {
    let serviceTemp = [];
    for (let p = 0; p < pegaAdditionalServices.length; p++) {
        serviceTemp = emptyServiceObject();
        serviceTemp.serviceType = consts.PEGA_ADDITIONAL_SERVICE;
        serviceTemp.primaryOrdering = consts.SEVEN;
        serviceTemp.secondaryOrdering = pegaAdditionalServices[p].displaySequence;
        serviceTemp.serviceFEID = counter++;
        serviceTemp.requiredOrCommonService = false;
        serviceTemp.selected = record ? pegaAdditionalServices[p].selected : false;
        serviceTemp.name = pegaAdditionalServices[p].offerTitle;
        serviceTemp.namePrefix = 'ADDITIONAL';
        serviceTemp.type = 'OFFER';
        serviceTemp.typePlus = 'PEGA_OTHER';
        serviceTemp.code = pegaAdditionalServices[p].couponNumber;
        serviceTemp.couponCode = pegaAdditionalServices[p].couponNumber;
        serviceTemp.itemDescription = pegaAdditionalServices[p].offerText;
        serviceTemp.displayPrice = pegaAdditionalServices[p].displayPrice;
        serviceTemp.disclaimer = pegaAdditionalServices[p].disclaimer;
        serviceTemp.offerSubTitle = pegaAdditionalServices[p].offerSubTitle;
        state.servicesArrayList.push(serviceTemp);
    }
    return counter;
};

export const populateServiceOfferData = (state, serviceOfferData, counter) => {
    let serviceTemp = [];
    const serviceOffers = serviceOfferData.serviceOffers;
    for (let p = 0; p < serviceOffers.length; p++) {
        serviceTemp = emptyServiceObject();
        serviceTemp.serviceType = consts.SERVICES_ITEM_SERVICE;
        serviceTemp.primaryOrdering = consts.EIGHT;
        serviceTemp.secondaryOrdering = counter++;
        serviceTemp.serviceFEID = counter++;
        serviceTemp.requiredOrCommonService = false;
        serviceTemp.selected = record ? serviceOffers[p].selected : false;
        serviceTemp.name = serviceOffers[p].title;
        serviceTemp.namePrefix = 'SERVICE_OFFER';
        serviceTemp.type = 'OFFER';
        serviceTemp.typePlus = 'NATIONAL';
        serviceTemp.code = serviceOffers[p].id;
        serviceTemp.itemDescription = serviceOffers[p].title;
        serviceTemp.displayPrice = serviceOffers[p].displayPrice;
        serviceTemp.priceDetails = serviceOffers[p].priceDetails;
        serviceTemp.priceDisclaimer = serviceOffers[p].priceDisclaimer;
        serviceTemp.disclaimer = serviceOffers[p].disclaimer;
        state.servicesArrayList.push(serviceTemp);
    }
    return counter;
};

export const populateDealershipOffers = (state, dealershipOffers, counter) => {
    let serviceTemp = [];
    for (let i = 0; i < dealershipOffers.length; i++) {
        let offersDisplayArray = Object.keys(dealershipOffers[i].offerDisplays);
        let offersDisplayLocale = offersDisplayArray[0];
        let dealershipOfferDisplay = dealershipOffers[i].offerDisplays[offersDisplayLocale];

        serviceTemp = emptyServiceObject();
        serviceTemp.serviceType = consts.DEALERSHIP_ITEM_SERVICE;
        serviceTemp.primaryOrdering = consts.NINE;
        serviceTemp.secondaryOrdering = dealershipOffers[i].displayOrder;
        serviceTemp.serviceFEID = counter++;
        serviceTemp.requiredOrCommonService = false;
        serviceTemp.selected = record ? dealershipOffers[i].selected : false;
        serviceTemp.name = dealershipOfferDisplay.title;
        serviceTemp.namePrefix = 'SDO:';
        serviceTemp.type = 'OFFER';
        serviceTemp.typePlus = 'DEALER';
        serviceTemp.code = dealershipOffers[i].code;
        serviceTemp.couponCode = dealershipOfferDisplay.couponCode;
        serviceTemp.itemDescription = dealershipOfferDisplay.offerDescription;
        serviceTemp.displayPrice = dealershipOffers[i].displayPrice;
        serviceTemp.priceDetails = dealershipOfferDisplay.priceDetails;
        serviceTemp.disclaimer = dealershipOfferDisplay.offerDisclaimer;
        serviceTemp.offerSubTitle = dealershipOfferDisplay.offerText;
        serviceTemp.subtext = dealershipOfferDisplay.subtext;
        state.servicesArrayList.push(serviceTemp);
    }
    return counter;
};

export const populateConsolidatedOffers = (state, consolidatedOffers, counter) => {
    let serviceTemp = [];
    for (let p = 0; p < consolidatedOffers.length; p++) {
        serviceTemp = emptyServiceObject();
        serviceTemp.serviceType = consts.CONSOLIDATED_ITEM_SERVICE;
        serviceTemp.primaryOrdering = consts.TEN;
        serviceTemp.secondaryOrdering = consolidatedOffers[p].displaySequence;
        serviceTemp.serviceFEID = counter++;
        serviceTemp.requiredOrCommonService = false;
        serviceTemp.selected = record ? consolidatedOffers[p].selected : false;
        serviceTemp.name = consolidatedOffers[p].consolidatedOfferDisplays[0].title;
        serviceTemp.namePrefix = 'CONSOLIDATED';
        serviceTemp.type = 'OFFER';
        serviceTemp.code = consolidatedOffers[p].offerCode;
        serviceTemp.couponCode = consolidatedOffers[p].couponCode;
        serviceTemp.itemDescription = consolidatedOffers[p].consolidatedOfferDisplays[0].subtitle;
        serviceTemp.categoryDescription = '';
        serviceTemp.displayPrice = consolidatedOffers[p].displayPrice;
        serviceTemp.disclaimer = consolidatedOffers[p].consolidatedOfferDisplays[0].disclaimer;
        serviceTemp.offerSubTitle = consolidatedOffers[p].consolidatedOfferDisplays[0].subtitle;
        state.servicesArrayList.push(serviceTemp);
    }
    return counter;
};

export const setDealerConfigAdditionalServices = (state, dealerConfigAddtlServ, counter, common) => {
    //Put the items returned into a common array
    Object.values(dealerConfigAddtlServ).forEach((dealerService) => {
        let serviceTemp = emptyServiceObject();
        serviceTemp.serviceType = consts.DEALER_CONFIG_ITEM_SERVICE;
        serviceTemp.primaryOrdering = consts.ELEVEN;
        serviceTemp.secondaryOrdering = dealerService.additionalService.ordering;
        serviceTemp.serviceFEID = counter++;
        serviceTemp.requiredOrCommonService = common ? true : false;
        serviceTemp.selected = common ? JSON.parse(precheck_dealerConfig) : record ? dealerService.selected : false;
        serviceTemp.name = dealerService.additionalService.name;
        serviceTemp.namePrefix = common ? 'SERVICE' : 'DEALERCONFIG';
        serviceTemp.type = 'SERVICE';
        serviceTemp.typePlus = 'COMMON';
        serviceTemp.code = dealerService.additionalService.leadCode;
        serviceTemp.globalLaborCode = dealerService.additionalService.glc;
        serviceTemp.displayPrice = dealerService.displayPrice;
        serviceTemp.disclaimer =
            dealerService.additionalServiceDisclaimers && dealerService.additionalServiceDisclaimers.length > 0
                ? dealerService.additionalServiceDisclaimers[0].disclaimer
                : '';

        //Other
        serviceTemp.isCurrentSearch = false;
        serviceTemp.selectedOffer = '';
        serviceTemp.additionalTerms = [];

        //Add the additional Offers
        if (dealerService.dealerConfigAdditionalOffers) {
            dealerService.dealerConfigAdditionalOffers.forEach((x) => {
                let offerTemp = [];
                offerTemp.id = x.additionalOffer.offersId;
                offerTemp.ordering = x.additionalOffer.ordering;
                offerTemp.brand = x.additionalOffer.brand;
                offerTemp.serviceFEID = counter++;
                offerTemp.name = x.additionalOffer.name;
                offerTemp.code = x.additionalOffer.leadCode;
                offerTemp.couponCode = x.additionalOffer.coupon_code;
                offerTemp.selected = record ? x.selected : false;
                offerTemp.selectedDisplay = false;
                offerTemp.type = 'OFFER';
                offerTemp.price = x.price;
                offerTemp.namePrefix = 'DEALERCONFIG';
                offerTemp.typePlus = 'COMMON';
                offerTemp.globalLaborCode = dealerService.additionalService.glc;
                offerTemp.disclaimer =
                    x.additionalOfferDisclaimers && x.additionalOfferDisclaimers.length
                        ? x.additionalOfferDisclaimers[0].disclaimer
                        : '';
                offerTemp.description = x.additionalOffer.description;
                offerTemp.subtitle = x.additionalOffer.subtitle;
                offerTemp.displayPrice = x.displayPrice;
                offerTemp.secondaryPrice = '';
                offerTemp.offerSubTitle = '';
                offerTemp.subtext = x.additionalOffer.subtext;
                offerTemp.selected
                    ? ((serviceTemp.selectedOffer = offerTemp.name),
                      (serviceTemp.selected = true),
                      (serviceTemp.displayPrice = offerTemp.displayPrice))
                    : '';
                serviceTemp.offers.push(offerTemp);
            });
        }

        //if the service has offers, sort the offers
        serviceTemp.offers.sort((a, b) => a.ordering - b.ordering);

        //Add the additional search terms
        if (dealerService.additionalSearchTerms) {
            dealerService.additionalSearchTerms.forEach((x) => {
                let searchTermsTemp = [];
                searchTermsTemp.push(action.payload.translations[x.code]);
                serviceTemp.additionalTerms.push(searchTermsTemp);
            });
        }
        state.servicesArrayList.push(serviceTemp);
    });

    sortDisplay(state);
    return counter;
};

export const setGlobalsFromPrefill = (state, action) => {
    state.vin = action.payload.vehicle.vin;
    state.bac = action.payload.bac;
    state.vehicleMake = action.payload.vehicle.vehicle.make.make;
    state.vehicleModel = action.payload.vehicle.vehicle.model.model;
    state.vehicleMiles = action.payload.vehicle.mileage;
    state.vehicleYear = action.payload.vehicle.vehicle.year;

    return state;
};

export const setNetworkErrorFlgServices = (state, action) => {
    state.networkErrorFlag = action.payload;
    return state;
};
