import React, { useState } from "react";
import LineItemWrapper from "./LineItemWrapper";
import * as consts from "../Constants/ServiceConstants";
import {renderCurrencyValue} from "../Utils/PriceLocalization";
import {useSelector} from "react-redux";
import {LINE_ITEM_TYPE_PLUS_PEGA_RECALL} from "../Constants/ServiceConstants";

const LineItemDetails = (props) => {
    const { translations } = useSelector((state) => state.ossAppGlobals);

    const lineItem = props.lineItem;
    const LINE_ITEM_TYPE_PLUS = lineItem.lineItemTypePlus;
    const serviceType = 'PItems';

    const  createMarkup = (innerHTMLText) =>{
        return {
            __html: innerHTMLText,
        };
    }


    let contentSimple = '';
    //ToDO:We need to change the logic to capture the data and display them.
    if (LINE_ITEM_TYPE_PLUS === LINE_ITEM_TYPE_PLUS_PEGA_RECALL) {
        console.log("is it here",lineItem)
        contentSimple = (
            <div className="more-info-container recall-notice">
                <div className="item-text">
                    <div className="item-label">{translations.DESCRIPTION}</div>
                    <div className="item-description">{lineItem.description}</div>
                </div>
                <div className="item-text">
                    <div className="item-label">{translations.SAFETY_RISK}</div>
                    <div className="item-description">{lineItem.safetyRisk}</div>
                </div>
                <div className="item-text">
                    <div className="item-label">{translations.REMEDY}</div>
                    <div className="item-description">{lineItem.remedy}</div>
                </div>
             </div>
        );
    } else if (serviceType === consts.CRITICAL_SERVICE) {
        if (this.props.offers && this.props.offers.length > 0) {
            contentSimple = (
                <div className="more-info-container">
                    <div className="offers-title">
                        {this.props.name} {translations.OFFERS}
                    </div>
                    <div className="offers-desc">{lineItem.itemDescription}</div>
                    {this.props.offers
                        .filter((offerCode) => offerCode.code === this.props.code)
                        .map((desc) => (
                            <li className="item-desc-item" key={desc.serviceFEID}>
                                <div className="desc-title">{desc.name}</div>
                                <div className="desc-body">
                                    {translations.DETAILS}:{desc.offerSubTitle}{' '}
                                    {translations.DISCLAIMER}:{desc.disclaimer}{' '}
                                </div>
                            </li>
                        ))}
                </div>
            );
        } else {
            contentSimple = (
                <div className="more-info-container">
                    <div className="item-title bold">{lineItem.name}</div>
                    <div className="item-description">{lineItem.description}</div>
                 </div>
            );
        }
    } else if (serviceType === consts.NON_CRITICAL_SERVICE) {
        contentSimple = (
            <div className="more-info-container">
                <div className="item-label">{translations.MAINT_INC}:</div>
                {this.props.vmaintenanceItems
                    .filter((offerCode) => offerCode.code === this.props.code)
                    .map((desc) => (
                        <li className="desc-title body2" key={desc.serviceFEID}>
                            {desc.name}
                        </li>
                    ))}
            </div>
        );
    } else if (serviceType === consts.SERVICES_ITEM_SERVICE) {
        contentSimple = (
            <div className="more-info-container">
                {this.renderSecondaryPrice()}
                <div className="item-disclaimer">{lineItem.priceDisclaimer}</div>
                <div className="item-label">
                    {lineItem.disclaimer ? translations.DISCLAIMER : null}
                </div>
                <div className="item-disclaimer">{lineItem.disclaimer}</div>
            </div>
        );
    } else if (serviceType === consts.CONSOLIDATED_ITEM_SERVICE) {
        contentSimple = (
            <div className="more-info-container">
                <div className="item-subtitle">{lineItem.offerSubTitle}</div>
                {this.renderSecondaryPrice()}
                <div className="item-description">{lineItem.itemDescription}</div>
                <div className="item-disclaimer">{lineItem.disclaimer}</div>
            </div>
        );
    } else if (
        serviceType === consts.PEGA_ENTERPRISE_SERVICE ||
        serviceType === consts.PEGA_ADDITIONAL_SERVICE
    ) {
        contentSimple = (
            <div className="more-info-container">
                <div className="item-title">
                    {lineItem.displayPrice && !lineItem.displayPrice[0].tagLine && !lineItem.displayPrice[1]
                        ? renderCurrencyValue(lineItem.displayPrice[0])
                        : ''}
                </div>
                <div className="item-subtitle">{lineItem.offerSubTitle}</div>
                {this.renderSecondaryPrice()}
                <div className="item-description">{lineItem.itemDescription}</div>
                <div className="item-disclaimer">{lineItem.disclaimer}</div>
            </div>
        );
    } else if (serviceType === consts.MAINTENANCE_SERVICE) {
        contentSimple = (
            <div className="more-info-container">
                {this.renderSecondaryPrice()}
                <div className="item-subtitle">
                    {lineItem.replace ? translations.REPLACE_HEADER : null}
                </div>
                <div className="item-disclaimer">{lineItem.replace}</div>
                <div className="item-label">{translations.INSPECT_HEADER}</div>
                <div className="item-disclaimer">{lineItem.inspect}</div>
                <div className="item-disclaimer">{lineItem.priceDetails}</div>
                <div className="item-disclaimer">
                    {lineItem.priceDisclaimer}
                    <div className="item-disclaimer-label">
                        {lineItem.disclaimer ? translations.DISCLAIMER : null}
                    </div>
                </div>
                <div className="item-disclaimer">{lineItem.disclaimer}</div>
            </div>
        );
    } else if (serviceType === consts.DEALERSHIP_ITEM_SERVICE) {
        contentSimple = (
            <div className="more-info-container">
                {this.renderSecondaryPrice()}
                <div className="item-subtitle"> {lineItem.subtext}</div>
                <div
                    className="item-description"
                    dangerouslySetInnerHTML={createMarkup(lineItem.itemDescription)}
                />
                <div className="item-disclaimer" dangerouslySetInnerHTML={createMarkup(lineItem.disclaimer)} />
             </div>
        );
    } else if (serviceType === consts.DEALER_CONFIG_ITEM_SERVICE) {
        contentSimple = (
            <div className="more-info-container">
                {this.renderSecondaryPrice()}
                <div className="item-disclaimer">{lineItem.disclaimer}</div>
            </div>
        );
    }


    return (
        <div>
            <LineItemWrapper>
                <div>{contentSimple}</div>
            </LineItemWrapper>
        </div>
    )
};

export default LineItemDetails;
