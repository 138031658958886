export const CSSRenderer = (make) => {
    const style = make;

    switch (style) {
        case 'Buick':
            import('./scss/buickStyles.scss');
            break;
        case 'Cadillac':
            import('./scss/cadillacStyles.scss');
            break;
        case 'Chevrolet':
            import('./scss/chevyStyles.scss');
            break;
        case 'GMC':
            import('./scss/gmcStyles.scss');
            break;
        default:
            import('./scss/generalStyles.scss');
    }
    import('../../../src/components/MyRewards/MyRewards.scss');
    import('../../../src/components/MyRewards/EnrollMyRewards.scss');
};

export default CSSRenderer;
