import EntryParams from '../Util/EntryParams';
import OSSLandingPage from '../Services/Services';
import Locator from '../Locator/Locator';
import Appointment from '../Appointment/Appointment';
import Confirmation from '../Confirmation/Confirmation';
import SubmitAppointment from '../Review/Review';
import DevelopEntryParams from '../Util/DeveloperEntryParams';
import ErrorPage from '../ErrorPage/ErrorPage';
import Vehicle from '../Vehicle/Vehicle';
import Landing from '../Login/Landing';
import SignIn from '../Login/SignIn';
import MyGarage from '../../components/MyGarage/MyGarage';

const routes = [
    {
        path: '/',
        component: EntryParams,
        title: 'ENTRY_PARAMS_START',
    },

    {
        path: '/Vehicle',
        component: Vehicle,
        title: 'VEHICLE',
    },

    {
        path: '/DevelopEntryParams',
        component: DevelopEntryParams,
        title: 'ENTRY_PARAMS_START_DEVELOP',
    },

    {
        path: '/index.ossUI.html',
        component: EntryParams,
        title: 'ENTRY_PARAMS_START',
    },

    {
        path: '/oss/o/d/*',
        component: EntryParams,
        title: 'ENTRY_PARAMS_START',
    },

    {
        path: '/OssLandingPage',
        component: OSSLandingPage,
        title: 'LABEL_TITLE_OSS_LANDING_PAGE',
    },

    {
        path: '/Locator',
        component: Locator,
        title: 'LABEL_TITLE_LOCATOR',
    },

    {
        path: '/Appointment',
        component: Appointment,
        title: 'LABEL_TITLE_APPOINTMENT',
    },

    {
        path: '/SubmitAppointment',
        component: SubmitAppointment,
        title: 'LABEL_TITLE_SUBMIT',
    },

    {
        path: '/Confirmation',
        component: Confirmation,
        title: 'LABEL_TITLE_CONFIRMATION',
    },

    {
        path: '/Landing',
        component: Landing,
        title: 'LABEL_TITLE_LANDING',
    },

    {
        path: '/SignIn',
        component: SignIn,
        title: 'LABLE_TITILE_SIGNIN',
    },

    {
        path: '/MyGarage',
        component: MyGarage,
        title: 'LABEL_TITLE_MYGARAGE'
    },

    {
        path: '/Error',
        component: ErrorPage,
        title: 'LABEL_GENERAL_ERROR',
    },
];

export default routes;
