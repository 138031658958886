import { formatPhoneNum } from '../AppointmentHelpers';

export function getPhoneNum(dealershipSummary, country) {
    let phone = '';

    if (dealershipSummary) {
        const { departments } = dealershipSummary;

        departments.forEach((department) => {
            if (department.name === 'SERVICE') {
                if (department.phoneNumber) {
                    phone = formatPhoneNum(department.phoneNumber, country);
                } else {
                    phone = '';
                }
            }
        });
    }

    return phone;
}
