import {useSelector} from "react-redux";
import SharedBasicModal from "../../shared/Modal/SharedBasicModal";
import React, {useEffect} from "react";
import {Box, Typography} from "@material-ui/core";
import {setMobileServiceNotAvailableForDealer, showMobileServiceNotAvailableForDealer} from "../../Redux/ActionCreator";
import store from "../../Redux/Store";
import {Buick, Cadillac, Chevrolet, GMC} from "../../shared/Constants/SharedConstants";
import {useFlags} from "launchdarkly-react-client-sdk";
import {checkVinEligibility} from "../../shared/BackendCalls/SharedBackendGets";



const MobileServiceUnenrolledDealerModal = ()=> {
    const {translations} = useSelector((state) => state.countryOptionsReducer);
    const {displayMobileServiceNotAvailableForDealer} = useSelector((state) => state.mobileServiceReducer);

    const {dealerSummaryObject} = useSelector((state) => state.prefillReducer);
    const {selectedMake} = useSelector((state) => state.vehicleReducer);

    const zipCode = dealerSummaryObject ? dealerSummaryObject.dealershipSummary?.address?.postalZone : "";


   const getURLTranslation =()=> {
            switch(selectedMake){
                case Chevrolet:return translations.CHEVROLET_DEALER_LOCATOR_SEARCH_URL;
                case Buick:return translations.BUICK_DEALER_LOCATOR_SEARCH_URL;
                case Cadillac:return translations.CADILLAC_DEALER_LOCATOR_SEARCH_URL;
                case GMC:return translations.GMC_DEALER_LOCATOR_SEARCH_URL;
                default: return translations.CHEVROLET_DEALER_LOCATOR_SEARCH_URL
            }
        }

  const brandSiteURL =  getURLTranslation()?.replace('searchTerm=%s',`searchTerm=${zipCode ? zipCode.substring(0,5):''}`).replace('services=%s','services=282');

    const bodyContent = <Box className="mobile-service-not-available" marginBottom={4}>
        <div align="center" className="mobile-service-not-available-divider">
            <Typography paragraph={true}>{translations.MOBILE_SERVICE_ACK_PARA_1 || "MOBILE_SERVICE_ACK_PARA_1"}</Typography>
            <Typography paragraph={true}>{translations.MOBILE_SERVICE_ACK_PARA_2 || "MOBILE_SERVICE_ACK_PARA_2"}</Typography>
        </div>
        <div align="center" className="mobile-service-not-available-divider-padding">
            <i className="icon alert-caution-yellow"/>
            {translations.MOBILE_SERVICE_ACK_UNSUPPORTED || "MOBILE_SERVICE_ACK_UNSUPPORTED"}
        </div>
    </Box>

    return <>
        <SharedBasicModal
            show={displayMobileServiceNotAvailableForDealer}
            onClose={()=>{store.dispatch(setMobileServiceNotAvailableForDealer(false))}}
            handleClick={()=>{store.dispatch(setMobileServiceNotAvailableForDealer(false))}}
            title={translations.MOBILE_SERVICE_UNSUP_TITLE || "MOBILE_SERVICE_UNSUP_TITLE"}
            bodyAsText={bodyContent}
            primaryButton={translations.MOBILE_SERVICE_ACK_BUTTON_SCHEDULE || "MOBILE_SERVICE_ACK_BUTTON_SCHEDULE"}
            buttonAsLink={translations.MOBILE_SERVICE_ACK_BUTTON_FIND || "MOBILE_SERVICE_ACK_BUTTON_FIND"}
            linkUrl={brandSiteURL}
        />
    </>

}



export default MobileServiceUnenrolledDealerModal