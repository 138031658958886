export const appointment = '/appointment/';
export const appointments = 'appointments';
export const branchService = '/oss/o/d/';
export const city = '&city=';
export const count = 'count=';
export const countryOptions = '/countryoptions';
export const dealers = '/dealers/';
export const dealerGeoSearch = '/dealerSearch/geoCoordinates';
export const dealerSearchName = '/dealerSearch/names/';
export const dealerSearchRegion = '/dealerSearch/regions/';
export const dealership = '/dealership/';
export const dealerships = '/dealerships/';
export const enroll = '/enroll';
export const format = '&format=';
export const formatJSON = '&format=JSON';
export const initialBAC = 'initialBac=';
export const json = 'JSON';
export const langParam = '?langCd=';
export const latitude = '?latitude=';
export const locale = 'locale=';
export const longitude = '&longitude=';
export const loyalty = 'loyalty';
export const make = '&make=';
export const makes = '/makes';
export const member = '/member?mode=profileId';
export const members = '/members/';
export const mileage = '&mileage=';
export const plate = '&plate=true';
export const model = '&model=';
export const models = '/models';
export const noMembersFound = 'No members were found';
export const oss = 'oss/oss';
export const plateToVin = 'plate-to-vin/';
export const plus = '/plus';
export const pluss = '&plus=';
export const record = '&record=';
export const region = '?region=';
export const selectedServices = '/selectedServices?';
export const services = '/services?';
export const serviceCertified = '/serviceCertified?';
export const showBusinessNameField = '/dms/showBusinessNameField';
export const summary = '/summary';
export const units = '&units=';
export const v1Services = 'api/v1/';
export const v2Services = 'api/v2/';
export const api = 'api';
export const v4Services = 'oss/oss/api/v4.0/';
export const vehicleImage = '/vehicles/image/';
export const vehicleYears = '/vehicle/years';
export const vin = 'vin=';
export const year = '&year=';
export const iamLogin = 'api/user/login';
export const gpmsProfile = 'api/profile';
export const cookieConst = 'api/user/token';
export const feedback = '/feedback';
