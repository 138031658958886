// List of Flows
export const AuthenticatedFlow = 'Authenticated';
export const AuthenticatedOCPlus = 'AuthenticatedOCPlus';
export const EditAppointmentFlow = 'EditAppointment';
export const BrandFlow = 'Brand';
export const TireFinderFlow = 'TireFinder';
export const DealerFlow = 'Dealer';
export const DealerFlowUs = 'DealerUS';
export const OssLocatorFlow = 'OssLocator';

// Tirefinder
export const TireFinder = 'TIREFIND';
export const DlrTire = 'DLRTIRE';

// Brand
export const Buick = 'BUICK';
export const GMC = 'GMC';
export const Chevrolet = 'CHEVY';
export const Cadillac = 'CAD';

// Edit Appointment
export const AppointmentPath = '/appointment/';

// Excluded Origins from US dealer flow
export const ExcludedOrigins = [
    'CC-Chatbot',
    'DMS',
    'DRP',
    'Gubagoo',
    'HOLD',
    'OCAPP',
    'ONSTA',
    'oss-',
    'OWNCTR',
    'SCRWB',
    'sdo',
    'sno',
    'SPAC',
    'TESTPAGE',
    'TIREFIND',
    'VEH',
    'vinsolut',
];
