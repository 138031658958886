import {
    CLEAR_SELECTED_DEALER,
    DISABLE_APPT_OBJ,
    DISABLE_SHOW_MORE,
    ENABLE_SHOW_MORE,
    HIDE_DISTANCE_FALSE,
    HIDE_DISTANCE_TRUE,
    HIDE_USE_LOCATION_BUTTON,
    HIDE_USE_LOCATION_BUTTON_FALSE,
    LOAD_FLAG,
    NAME_SEARCH_COUNT,
    NAME_SEARCH_SORT,
    SELECT_FLAG,
    SELECTED_DEALER,
    SET_AVAIL_FLAG_FALSE,
    SET_BRANCH_DEALERS,
    SET_CURRENT_LAT_LNG,
    SET_DEALERS,
    SET_DISTANCE,
    SET_DISTANCE_FLAG,
    SET_MARKER_SELECTED,
    SET_RENDER_FLAG_FALSE,
    SET_RENDER_FLAG_TRUE,
    SET_SEARCH_FLAG,
    SET_SEARCH_LOCATION,
    SET_SELECTED_PLACE,
    SHOW_LIST_AND_MAP,
    SHOW_LIST_AND_MAP_FALSE,
    SHOW_LOCATION_ICON_FALSE,
    SHOW_LOCATION_ICON_TRUE,
    SHOW_MORE,
    SHOW_SEARCH_BOX_ERROR_FALSE,
    SHOW_SEARCH_BOX_ERROR_TRUE,
    SORT_FLAG,
} from './ActionTypes';
const dayjs = require('dayjs');

const initialState = {
    // Current Latitude Longitude: stores the coordinates that the Map will initialize as center and user's marker
    currentLatLng: {
        lat: 0.0,
        lng: 0.0,
    },
    selectedLatLng: {
        lat: 0.0,
        lng: 0.0,
    },
    // Array of dealerships returned from GetDealers backend call
    dealerships: [],
    // Array of dealerships sorted by Availability
    sortedDealerships: [],
    // Array for full set of dealerships
    dealershipsFull: [],
    // Array for full set of sorted dealerships
    sortedDealershipsFull: [],
    // Availability Results
    availability: [],
    // Placeholder for preferred dealer
    preferred: '',
    // Stores reference to dealership of respective marker when the user clicks on it in the Map
    selectedMarker: {},
    // When the user confirms the dealer they would like it is saved here - same object as selected Marker
    selectedDealer: {},
    //Similar to selectedDealer but for a general location from places Autocomplete
    selectedPlace: '',
    // Flag to display dealerships sorted on distance/first availability
    sortFlag: true,
    // Flag to flip when the user selects a new dealership - helps to re-render dom
    selectFlag: false,
    // Flag to flip after setting distance on perferred dealer when it is not in selected dealerships -will render miles
    distanceFlag: false,
    // Will only render list of dealers and map when set to true
    renderFlag: false,
    // Will show true when the user has selected a marker on the Map
    markerSelected: false,
    // Set true when the user does a successful search in SearchBox
    searchFlag: false,
    // Count for Show More
    showMoreCount: 0,
    // Disable Show More button flag
    disableShowMore: false,
    // Loading flag for Show More button press
    loadFlag: false,
    // Set Zoom Value to 16 when the user selected a dealership
    zoomValue: 3,
    // Flag for when user selects a new dealer
    newDealerFlag: false,
    // Name Search count for calculcating miles on search search
    nameSearchCount: 0,
    // Hide Miles/Kilometers Field
    hideDistance: false,
    // Flag for Disabling Appointment Object
    disableAppointmentObj: false,
    // Flag for if we have availabilities or not
    availFlag: true,
    branchFlow: false,
    // Flag for showing an error in the name search box
    showSearchBoxError: false,
};

const locatorReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DISTANCE: {
            return { ...state, dealerships: setDistance(state, action) };
        }
        case SET_CURRENT_LAT_LNG: {
            return { ...state, currentLatLng: setCurrentLatLng(state, action) };
        }
        case SET_DEALERS: {
            const dealershipsFull = setDealersFull(state, action);
            const selectedDealerArray = dealershipsFull.slice(0);
            const selectedDealer = setSelectedDealer(selectedDealerArray);
            const sortedDealershipsArray = dealershipsFull.slice(0);
            const sortedDealerships = setSortedDealers(sortedDealershipsArray, state);
            const dealerships = dealershipsFull.slice(0, 9);
            return {
                ...state,
                nameSearch: action.nameSearch,
                selectedDealer,
                dealershipsFull,
                renderFlag: true,
                sortedDealerships,
                dealerships,
            };
        }
        case SET_RENDER_FLAG_FALSE: {
            return { ...state, renderFlag: false };
        }
        case SET_RENDER_FLAG_TRUE: {
            return { ...state, renderFlag: true };
        }
        case SELECTED_DEALER: {
            return { ...state, selectedDealer: action.payload, selectedFlag: true };
        }
        case SET_MARKER_SELECTED: {
            const selectedLatLng = { lat: 0, lng: 0 };
            let selectedDealer = {};
            state.sortedDealerships.forEach((i) => {
                // find and flip current active dealership
                if (i.isActive) {
                    i.isActive = !i.isActive;
                    const index = state.dealerships.findIndex((x) => x.bac === i.bac);
                    if (index !== -1) {
                        state.dealerships.splice(index, 1, i);
                    }
                }
                // find matching bac and set to active dealership
                if (i.bac === action.payload.bac) {
                    i.isActive = !i.isActive;
                    selectedDealer = i;
                    // Update lat and lng of selected Marker
                    let numLat;
                    let numLng;

                    numLat = i.offsetLatitude ? i.offsetLatitude : i.latitude;
                    numLng = i.offsetLongitude ? i.offsetLongitude : i.longitude;

                    selectedLatLng.lat = Number(numLat);
                    selectedLatLng.lng = Number(numLng);
                    const index = state.dealerships.findIndex((x) => x.bac === i.bac);
                    if (index !== -1) {
                        state.dealerships.splice(index, 1, i);
                    }
                }
            });

            return {
                ...state,
                renderFlag: true,
                zoomValue: 16,
                selectedDealer,
                dealerships: state.dealerships,
                sortedDealerships: state.sortedDealerships,
                selectedLatLng,
                selectedMarker: action.payload,
            };
        }
        case SET_DISTANCE_FLAG: {
            return { ...state, distanceFlag: !state.distanceFlag };
        }
        case SET_SEARCH_LOCATION: {
            return {
                ...state,
                currentLatLng: {
                    lat: action.payload.geometry.location.lat(),
                    lng: action.payload.geometry.location.lng(),
                },
                disableShowMore: false,
                showMoreCount: 0,
                showMoreFlag: false,
                loadFlag: false,
            };
        }
        case SET_SEARCH_FLAG: {
            return { ...state, searchFlag: !state.searchFlag };
        }
        case SORT_FLAG: {
            return { ...state, sortFlag: !state.sortFlag };
        }
        case SELECT_FLAG: {
            return { ...state, selectFlag: !state.selectFlag };
        }
        case DISABLE_SHOW_MORE: {
            return { ...state, disableShowMore: true, loadFlag: true };
        }
        case ENABLE_SHOW_MORE: {
            return { ...state, disableShowMore: false, showMoreCount: 0 };
        }
        case LOAD_FLAG: {
            return { ...state, loadFlag: !state.loadFlag };
        }
        case SHOW_MORE: {
            const count = state.showMoreCount + 1;
            const dealershipsFull = state.dealershipsFull.slice(0);
            return {
                ...state,
                showMoreCount: count,
                dealerships: showMoreDealerships(dealershipsFull, count),
                sortedDealerships: showMoreSortedDealerships(state, count),
                disableShowMore: disableShowMore(state, count),
            };
        }
        case NAME_SEARCH_COUNT: {
            return { ...state, nameSearchCount: nameSearchCount(state, action) };
        }
        case NAME_SEARCH_SORT: {
            const tempArray = [...state.dealerships];
            if (action.payload) {
                const temp = tempArray[0];
                tempArray.shift();
                if (action.payload.useMiles === 'true') {
                    tempArray.sort(compareDistanceMi);
                } else {
                    tempArray.sort(compareDistanceKi);
                }
                tempArray.unshift(temp);
            }
            return { ...state, dealerships: tempArray };
        }
        case SHOW_LOCATION_ICON_TRUE: {
            return { ...state, showLocationIcon: true };
        }
        case SHOW_LOCATION_ICON_FALSE: {
            return { ...state, showLocationIcon: false };
        }
        case HIDE_USE_LOCATION_BUTTON: {
            return { ...state, hideUseLocationButton: true };
        }
        case HIDE_USE_LOCATION_BUTTON_FALSE: {
            return { ...state, hideUseLocationButton: false };
        }
        case SHOW_LIST_AND_MAP: {
            return { ...state, showListAndMap: true };
        }
        case SHOW_LIST_AND_MAP_FALSE: {
            return { ...state, showListAndMap: false };
        }
        case SET_BRANCH_DEALERS: {
            const sortedDealerships = setBranchDealers(state, action);
            return { ...state, sortedDealerships, renderFlag: true, branchFlow: true };
        }
        case CLEAR_SELECTED_DEALER: {
            return { ...state, selectedDealer: {}, selectedLatLng: { lat: 0, lng: 0 }, selectedMarker: {} };
        }
        case HIDE_DISTANCE_TRUE: {
            return { ...state, hideDistance: true };
        }
        case HIDE_DISTANCE_FALSE: {
            return { ...state, hideDistance: false };
        }
        case DISABLE_APPT_OBJ: {
            return { ...state, disableAppointmentObj: !state.disableAppointmentObj };
        }
        case SET_AVAIL_FLAG_FALSE: {
            return { ...state, availFlag: false };
        }
        case SHOW_SEARCH_BOX_ERROR_TRUE: {
            return { ...state, showSearchBoxError: true };
        }
        case SHOW_SEARCH_BOX_ERROR_FALSE: {
            return { ...state, showSearchBoxError: false };
        }
        case SET_SELECTED_PLACE: {
            return { ...state, selectedPlace: setSelectedPlace(action) };
        }
        default:
            return state;
    }
};

const setBranchDealers = (state, action) => {
    const tempDealers = [];
    const parentDealer = {
        address: {},
        amenities: [],
        bac: '',
        departments: [],
        latitude: '',
        longitude: '',
        name: '',
        url: '',
        isActive: false,
    };
    const { dealershipSummary } = action.payload;
    let i;
    let j;

    // Grab Parent Dealer Data
    parentDealer.address = dealershipSummary.address;
    parentDealer.amenities = dealershipSummary.amenities;
    parentDealer.bac = dealershipSummary.bac;
    parentDealer.departments = dealershipSummary.departments;
    parentDealer.latitude = dealershipSummary.locator.latitude;
    parentDealer.longitude = dealershipSummary.locator.longitude;
    parentDealer.name = dealershipSummary.name;
    parentDealer.url = dealershipSummary.url;
    tempDealers[0] = parentDealer;

    // Grab Child Dealer Data
    for (i = 0; i < dealershipSummary.childSummaries.length; i++) {
        const childDealer = {
            address: {},
            amenities: [],
            bac: '',
            departments: [],
            latitude: '',
            longitude: '',
            name: '',
            url: '',
            isActive: false,
        };
        childDealer.address = dealershipSummary.childSummaries[i].address;
        childDealer.amenities = dealershipSummary.childSummaries[i].amenities;
        childDealer.bac = dealershipSummary.childSummaries[i].bac;
        childDealer.departments = dealershipSummary.childSummaries[i].departments;
        childDealer.latitude = dealershipSummary.childSummaries[i].locator.latitude;
        childDealer.longitude = dealershipSummary.childSummaries[i].locator.longitude;
        childDealer.name = dealershipSummary.childSummaries[i].name;
        childDealer.url = dealershipSummary.childSummaries[i].url;
        j = i + 1;
        tempDealers[j] = childDealer;
    }
    return tempDealers;
};

const nameSearchCount = (state, action) => {
    let count;
    if (action.payload) {
        count = 0;
    } else {
        count = state.nameSearchCount + 1;
    }
    return count;
};

const showMoreDealerships = (dealershipsFull, count) => {
    let dealerships;
    if (count === 1) {
        dealerships = dealershipsFull.slice(0, 14);
    } else if (count === 2) {
        dealerships = dealershipsFull.slice(0);
    }
    return dealerships;
};

const showMoreSortedDealerships = (state, count) => {
    let sorted;
    let temp;
    if (count === 1) {
        sorted = JSON.parse(JSON.stringify(state.dealershipsFull.slice(0, 14)));
        [temp] = sorted;
        sorted.shift();
        sorted.sort(compare);
        for (let i=0; i<sorted.length; i++) {
            //move dealerships without availability to the end
            if (!sorted[i].appointmentAvailabilities) {
                sorted.push(sorted.splice(i, 1)[0]);
            }
        }
        sorted.unshift(temp);
    } else if (count === 2) {
        sorted = JSON.parse(JSON.stringify(state.dealershipsFull.slice(0)));
        [temp] = sorted;
        sorted.shift();
        sorted.sort(compare);
        for (let i=0; i<sorted.length; i++) {
            //move dealerships without availability to the end
            if (!sorted[i].appointmentAvailabilities) {
                sorted.push(sorted.splice(i, 1)[0]);
            }
        }
        sorted.unshift(temp);
    }
    return sorted;
};

const disableShowMore = (state, count) => {
    let disable;
    if (count === 1) {
        disable = state.dealershipsFull.length < 15;
    } else if (count === 2) {
        disable = true;
    }
    return disable;
};

const setDistance = (state, action) => {
    let dealerships;
    if (action.payload) {
        if (action.failedDistance) {
            dealerships = [...state.dealerships];
            dealerships[action.index].miles = action.payload;
        } else if (action.payload.slice(-2) === 'mi') {
            dealerships = [...state.dealerships];
            dealerships[action.index].miles = action.payload.substring(0, action.payload.length - 3);
        } else {
            dealerships = [...state.dealerships];
            dealerships[action.index].kilometers = action.payload.substring(0, action.payload.length - 3);
        }
    } else {
        dealerships = [...state.dealerships];
        dealerships[action.index].miles = action.payload;
    }
    return dealerships;
};

const setCurrentLatLng = (state, action) => {
    let currentLatLng;
    if (action.payload) {
        // When coordinates provided by user
        if (action.payload.coords) {
            currentLatLng = { lat: action.payload.coords.latitude, lng: action.payload.coords.longitude };
        } else {
            // When coordinates are determined with Zip
            currentLatLng = { lat: action.payload.lat, lng: action.payload.lng };
        }
    }
    return currentLatLng;
};

const setDealersFull = (state, action) => {
    let dealersDelta;

    if (action.payload) {
        // Filter out dealers returned that have 0.0 lat/lng coordinate values -> they will break the Google Map API
        dealersDelta = JSON.parse(JSON.stringify(action.payload));
        dealersDelta = dealersDelta.filter((el) => el.latitude !== '0.0' || el.longitude !== '0.0');
        // Check for dealers who did not return an appointment for availability and set error message
        // ELSE - Set availability as property of dealer object in dealerships array
        for (const i of dealersDelta) {
            if (!i.appointmentAvailabilities || i.appointmentAvailabilities === false) {
                i.appointmentAvailabilities = false;
            } else {
                // check for show more feature, skip over dealers with correct formatted date ->
                // now only dealers without a proper formatted date will be processed
                if (!i.formattedDate) {
                    if (i.appointmentAvailabilities.EXPRESS_SERVICE === undefined) {
                        if (
                            i.appointmentAvailabilities.DEFAULT[0].date &&
                            i.appointmentAvailabilities.DEFAULT[0].time.length
                        ) {
                            let dateStr =
                                i.appointmentAvailabilities.DEFAULT[0].date +
                                i.appointmentAvailabilities.DEFAULT[0].time;
                            i.appointmentAvailabilities.formattedDate = dayjs(dateStr, 'YYYY-MM-DDHH:mm').format(
                                'L LT'
                            );
                        } else {
                            i.appointmentAvailabilities = false;
                        }
                    } else if (i.appointmentAvailabilities.DEFAULT === undefined) {
                        if (
                            i.appointmentAvailabilities.EXPRESS_SERVICE[0].date &&
                            i.appointmentAvailabilities.EXPRESS_SERVICE[0].time.length
                        ) {
                            let dateStr =
                                i.appointmentAvailabilities.EXPRESS_SERVICE[0].date +
                                i.appointmentAvailabilities.EXPRESS_SERVICE[0].time;
                            i.appointmentAvailabilities.formattedDate = dayjs(dateStr, 'YYYY-MM-DDHH:mm').format(
                                'L LT'
                            );
                        } else {
                            i.appointmentAvailabilities = false;
                        }
                    }
                }
            }

            // Initialize the active dealership : //if(i.preferred){i.active = true;} else {i.active = false;}
            i.isActive = !!i.preferred;
        }
    }
    return dealersDelta;
};

const setSelectedDealer = (dealershipsFull) => {
    const filteredDealers = dealershipsFull.filter((el) => el.preferred === true);
    return filteredDealers[0];
};

const setSortedDealers = (dealershipsFull, state) => {
    const temp = dealershipsFull[0];
    // only grab the first 10 entries for sorting on availability so data is consistent with Distance sorted data
    let sortedDealerships = dealershipsFull.slice(0, 9);

    // Remove first entry (preferred dealer)
    sortedDealerships.shift();
    // Don't sort when disable appointmentObj is checked
    // Sort based on availability without preferred dealer
    if (!state.disableAppointmentObj) sortedDealerships = sortedDealerships.slice().sort(compare);
    for (let i=0; i<sortedDealerships.length; i++) {
        //move dealerships without availability to the end
        if (!sortedDealerships[i].appointmentAvailabilities) {
            sortedDealerships.push(sortedDealerships.splice(i, 1)[0]);
        }
    }
    // Add preferred dealer back to front of array
    sortedDealerships.unshift(temp);
    // Return sorted dealerships
    return sortedDealerships;
};

function compare(a, b) {
    const dateA = a.appointmentAvailabilities.formattedDate;
    const dateB = b.appointmentAvailabilities.formattedDate;

    let comparison = 0;
    if (dateA > dateB) {
        comparison = 1;
    } else if (dateA < dateB) {
        comparison = -1;
    }
    return comparison;
}

function compareDistanceMi(a, b) {
    const distanceA = Number(a.miles.replace(/,/, ''));
    const distanceB = Number(b.miles.replace(/,/, ''));

    let comparison = 0;
    if (distanceA > distanceB) {
        comparison = 1;
    } else if (distanceA < distanceB) {
        comparison = -1;
    }
    return comparison;
}

function compareDistanceKi(a, b) {
    const distanceA = Number(a.kilometers.replace(/,/, ''));
    const distanceB = Number(b.kilometers.replace(/,/, ''));

    let comparison = 0;
    if (distanceA > distanceB) {
        comparison = 1;
    } else if (distanceA < distanceB) {
        comparison = -1;
    }
    return comparison;
}

const setSelectedPlace = (action) => {
    let selectedPlace;
    if (action.payload && Object.keys(action.payload).length > 0) {
        selectedPlace = action.payload;
    }
    return selectedPlace;
};

export default locatorReducer;
