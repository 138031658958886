import {
    SET_SESSION
} from './ActionTypes';

const initialState = {
    session: '',
};

const SessionReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SESSION: {
            return {...state, session: action.payload};
        }
        default:
            return state;
    }
};

export default SessionReducer;
