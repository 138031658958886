// Transportation
export const CADILLAC_PREMIUM_CONCIERGE = 'CADILLAC_PREMIUM_CONCIERGE';
export const DEFAULT = 'DEFAULT';
export const EXPRESS_SERVICE = 'EXPRESS_SERVICE';
export const PREMIUM_CONCIERGE = 'PREMIUM_CONCIERGE';
export const LOANER_CAR = 'LOANER_CAR';
export const MOBILE_SERVICE = 'MOBILE_SERVICE';
export const PREMIUM_CONCIERGE_WITH_LOANER = 'PREMIUM_CONCIERGE_WITH_LOANER';
export const PICK_UP = 'PICK_UP';
export const DROP_OFF = 'DROP_OFF';

// Time.jsx
export const MIN_WIDTH_DESKTOP = 768;
export const NUM_TIMES_DESKTOP = 32;
export const NUM_TIMES_MOBILE = 8;
