import {
    Buick,
    Cadillac,
    Chevrolet,
    GMC,
    Crossbrand,
    Silver,
    Gold,
    Platinum,
} from '../../../shared/Constants/SharedConstants';

export const MyRewardsBackgroundRenderer = (brand, tier) => {
    const brandTier = brand + tier;

    const BuickSilver = Buick + Silver;
    const BuickGold = Buick + Gold;
    const BuickPlatinum = Buick + Platinum;

    const CadillacSilver = Cadillac + Silver;
    const CadillacGold = Cadillac + Gold;
    const CadillacPlatinum = Cadillac + Platinum;

    const ChevroletSilver = Chevrolet + Silver;
    const ChevroletGold = Chevrolet + Gold;
    const ChevroletPlatinum = Chevrolet + Platinum;

    const GMCSilver = GMC + Silver;
    const GMCGold = GMC + Gold;
    const GMCPlatinum = GMC + Platinum;

    const CrossbrandSilver = Crossbrand + Silver;
    const CrossbrandGold = Crossbrand + Gold;
    const CrossbrandPlatinum = Crossbrand + Platinum;

    if (brandTier === BuickSilver) {
        return require('../../../assets/MyRewards/Desktop/Buick/buick-bg-silver-full-xl.svg').default;
    } else if (brandTier === BuickGold) {
        return require('../../../assets/MyRewards/Desktop/Buick/buick-bg-gold-full-xl.svg').default;
    } else if (brandTier === BuickPlatinum) {
        return require('../../../assets/MyRewards/Desktop/Buick/buick-bg-platinum-full-xl.svg').default;
    } else if (brandTier === CadillacSilver) {
        return require('../../../assets/MyRewards/Desktop/Cadillac/cadillac-bg-silver-full-xl.svg').default;
    } else if (brandTier === CadillacGold) {
        return require('../../../assets/MyRewards/Desktop/Cadillac/cadillac-bg-gold-full-xl.svg').default;
    } else if (brandTier === CadillacPlatinum) {
        return require('../../../assets/MyRewards/Desktop/Cadillac/cadillac-bg-platinum-full-xl.svg').default;
    } else if (brandTier === ChevroletSilver) {
        return require('../../../assets/MyRewards/Desktop/Chevrolet/chevy-bg-silver-full-xl.svg').default;
    } else if (brandTier === ChevroletGold) {
        return require('../../../assets/MyRewards/Desktop/Chevrolet/chevy-bg-gold-full-xl.svg').default;
    } else if (brandTier === ChevroletPlatinum) {
        return require('../../../assets/MyRewards/Desktop/Chevrolet/chevy-bg-platinum-full-xl.svg').default;
    } else if (brandTier === GMCSilver) {
        return require('../../../assets/MyRewards/Desktop/GMC/gmc-bg-silver-full-xl.svg').default;
    } else if (brandTier === GMCGold) {
        return require('../../../assets/MyRewards/Desktop/GMC/gmc-bg-gold-full-xl.svg').default;
    } else if (brandTier === GMCPlatinum) {
        return require('../../../assets/MyRewards/Desktop/GMC/gmc-bg-platinum-full-xl.svg').default;
    } else if (brandTier === CrossbrandSilver) {
        return require('../../../assets/MyRewards/Desktop/CrossBrand/cross-brand-bg-silver-full-xl.svg').default;
    } else if (brandTier === CrossbrandGold) {
        return require('../../../assets/MyRewards/Desktop/CrossBrand/cross-brand-bg-gold-full-xl.svg').default;
    } else if (brandTier === CrossbrandPlatinum) {
        return require('../../../assets/MyRewards/Desktop/CrossBrand/cross-brand-bg-platinum-full-xl.svg').default;
    }
};

export default MyRewardsBackgroundRenderer;
