import * as React from "react";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import SharedBasicModal from "../../shared/Modal/SharedBasicModal";
import store from "../../Redux/Store";
import {setOnTracErrorOnSubmission} from "../../Redux/ActionCreator";



const OnTracErrorOnSubmission = ()=>{
    let history = useHistory();
    const {translations} = useSelector((state) => state.countryOptionsReducer);
    const {showOnTracError,onTracTries} = useSelector((state) => state.appointmentReducer);


    const handleClick=()=>{
        history.push('/Appointment');
        const payload = {
            showOnTracError : false,
            onTracTries: onTracTries
        }

        store.dispatch(setOnTracErrorOnSubmission(payload));
     }


    return <>
        <SharedBasicModal show={showOnTracError}
                          hideCloseIcon={true}
                          title={translations.APP_UNAVAILABLE_TITLE || 'APP_UNAVAILABLE_TITLE'}
                          bodyAsText={translations.ON_TRAC_ERROR_ON_SUBMISSION_BODY || 'An unexpected error has occurred while attempting to schedule your appointment.'}
                          primaryButton={translations.MOBILE_SERVICE_ERROR_RETRY || 'Retry'}
                          handleClick={handleClick}/>
    </>
}

export default OnTracErrorOnSubmission