import React from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

export default function SharedInput(props) {
    const errorAdornment = (
        <InputAdornment position="end">
            <div className="icon error" />
        </InputAdornment>
    );

    return (
        <TextField
            className={`shared-text-field${props.className ? ` ${props.className}` : ''}`}
            disabled={props.disabled ? props.disabled : null}
            error={props.showError}
            helperText={props.errorMessage}
            id={props.id}
            InputProps={{
                endAdornment: props.showError ? errorAdornment : null,
            }}
            label={props.label}
            value={props.value}
            onChange={props.handleInputChange}
            onBlur={props.handleInputBlur}
            required={props.required}
            type={props.type}
            variant="filled"
            inputProps={{
                'aria-invalid': props.showError,
                'data-testid': props.id,
                minLength: props.minLength ? props.minLength : 0,
                maxLength: props.maxLength ? props.maxLength : 200,
                pattern: props.pattern ? props.pattern : null,
            }}
        />
    );
}
