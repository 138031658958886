import React from 'react';
import { connect } from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import $ from 'jquery';
import {
    getVehicleMakes,
    getVehicleModels,
    getVehiclePlus,
    getVehicleYears,
} from '../../shared/BackendCalls/SharedBackendGets';
import { setPrefillMileage, setValidFlag, setVehicleData } from '../../Redux/ActionCreator';
import AnalyticsService from '../../shared/Utils/analytics.service';
import { adobeAnalytics } from '../../shared/Utils/Utils';
import Alert from '@material-ui/lab/Alert';
import { Grid, Grow } from '@material-ui/core';

const localizationConstants = require('../../shared/Constants/LocalizationConstants');

class Vehicle extends React.Component {
    REGEX_PARSER = require('regex-parser');

    state = {
        year: this.props.vSelectedYear,
        make: this.props.vSelectedMake,
        model: this.props.vSelectedModel,
        miles: this.props.vSelectedMileage,
        license: this.props.vSelectedLicense,
        motor: this.props.vSelectedMotor,
        registration: this.props.vSelectedRegistration,
        yearError: false,
        yearErrorText: '',
        makeError: false,
        makeErrorText: '',
        modelError: false,
        modelErrorText: '',
        motorError: false,
        motorErrorText: '',
        mileageError: false,
        mileageErrorText: '',
        licenseError: false,
        licenseErrorText: '',
        registrationError: false,
        registrationErrorText: '',
        searchByLicensePlateView: this.props.vSearchbyLicensePlateView,
        searchByLicensePlateRespError: this.props.vSearchByLicensePlateRespError,
        searchByLicensePlateSelectedState: this.props.vSearchbyLicenseSelectedState,
        searchByLicensePlateEnteredValue: this.props.vSearchbyLicenseSelectedLicense,
        selectedItemIndex: 'false',
        linketoOtherViewBodyText: '',
        linktoOtherViewHeaderText: '',
    };

    constructor(props) {
        super(props);
        window.scrollTo(0, 0);
        getVehicleYears(this.props.vCountry);
        !this.state.year ? this.props.setValidFlag('false') : '';
    }

    componentDidMount() {
        if (AnalyticsService().loggingEnabled) {
            const analyticsService = AnalyticsService();
            analyticsService.trackPage('Vehicle');
            // Append the Analytics Object to the DOM
            $('body').append(adobeAnalytics('form-step'));
            console.log('Analytics Added for Page to DOM: Vehicle using JQuery');
        }
        this.setState({
            linktoOtherViewHeaderText:
                this.state.searchByLicensePlateView === true
                    ? this.props.vTranslations.VEHICLE_PAGE_SEARCH_BY_MODEL_PRE_LINK_TEXT
                    : this.props.vTranslations.VEHICLE_PAGE_SEARCH_BY_LICENSE_PLATE_PRE_LINK_TEXT,
            linketoOtherViewBodyText:
                this.state.searchByLicensePlateView === true
                    ? this.props.vTranslations.VEHICLE_PAGE_SEARCH_BY_MODEL_LINK_TEXT
                    : this.props.vTranslations.VEHICLE_PAGE_SEARCH_BY_LICENSE_PLATE_LINK_TEXT,
        });
    }

    /*
     * Objective  Search by License Plate:
     *            Save information for services api call for selected vehicle of returned responses from search by kicense plate/state resp
     *
     */
    searchByLicenseResultsVehicleSelected(vin, make, model, year) {
        this.setState({ selectedItemIndex: vin });

        // ----- Save data returned from search by license plate/state api for the selected vehicle. ----- //

        // Save Make
        this.props.setVehicleData({
            key: 'searchByLicensePlate_selectedMake',
            value: make,
        });

        // Save Model
        this.props.setVehicleData({
            key: 'searchByLicensePlate_selectedModel',
            value: model,
        });

        // Save Year
        this.props.setVehicleData({
            key: 'searchByLicensePlate_selectedYear',
            value: year,
        });

        // Save VIN
        this.props.setVehicleData({
            key: 'searchByLicensePlate_selectedVin',
            value: vin,
        });

        // ------- Set variables the services page uses in UI -------------- //
        // Save Make
        this.props.setVehicleData({
            key: 'selectedMake',
            value: make,
        });

        // Save Model
        this.props.setVehicleData({
            key: 'selectedModel',
            value: model,
        });

        // Save Year
        this.props.setVehicleData({
            key: 'selectedYear',
            value: year,
        });
        // ------- SND: Set variables the services page uses -------------- //
    }

    handleChangeSearchView = (event) => {
        if (this.state.searchByLicensePlateView === false) {
            this.setState({
                searchByLicensePlateView: true,
                linktoOtherViewHeaderText: this.props.vTranslations.VEHICLE_PAGE_SEARCH_BY_MODEL_PRE_LINK_TEXT,
                linketoOtherViewBodyText: this.props.vTranslations.VEHICLE_PAGE_SEARCH_BY_MODEL_LINK_TEXT,
            });
            this.props.setVehicleData({
                key: 'searchByLicensePlateView',
                value: true,
            });
        } else {
            this.setState({
                searchByLicensePlateView: false,
                linktoOtherViewHeaderText: this.props.vTranslations.VEHICLE_PAGE_SEARCH_BY_LICENSE_PLATE_PRE_LINK_TEXT,
                linketoOtherViewBodyText: this.props.vTranslations.VEHICLE_PAGE_SEARCH_BY_LICENSE_PLATE_LINK_TEXT,
            });
            this.props.setVehicleData({
                key: 'searchByLicensePlateView',
                value: false,
            });
            this.props.setVehicleData({
                key: 'searchByLicensePlateRespReceived',
                value: false,
            });
            this.props.setVehicleData({
                key: 'searchByLicensePlate_selectedVin',
                value: '',
            });

            if (this.props.vSelectedYear) {
                getVehicleMakes(this.props.vCountry, this.state.year);
            }

            if (this.props.vSelectedMake) {
                if (!this.props.modelsList) {
                    this.setState({ model: '' });
                }
                getVehicleModels(this.props.vCountry, this.state.year, this.state.make);
            }
        }
    };

    handleChangeUSState = (event) => {
        if (AnalyticsService().loggingEnabled) {
            // eslint-disable-next-line no-undef
            _satellite?.track('stat-dropdown');
        }
        this.setState({ searchByLicensePlateSelectedState: event.target.value });

        this.props.setVehicleData({
            key: 'searchByLicensePlateSelectedState',
            value: event.target.value,
        });
    };

    handleCloseUSState = (event) => {};

    handleChangeYear = (event) => {
        // Call Analytics if enabled
        if (AnalyticsService().loggingEnabled) {
            // eslint-disable-next-line no-undef
            _satellite?.track('stat-dropdown');
        }

        this.setState({
            yearError: false,
            year: event.target.value,
            make: '',
            model: '',
            motor: '',
        });

        if (this.props.vCountryOptions.HIDDEN_VEHICLE_MAKE) {
            this.setState({ make: this.props.vCountryOptions.HIDDEN_VEHICLE_MAKE });
            getVehicleModels(this.props.vCountry, event.target.value, this.props.vCountryOptions.HIDDEN_VEHICLE_MAKE);
            this.props.setVehicleData({
                key: 'selectedMake',
                value: this.props.vCountryOptions.HIDDEN_VEHICLE_MAKE,
            });
        } else {
            getVehicleMakes(this.props.vCountry, event.target.value);
        }

        this.props.setVehicleData({
            key: 'selectedYear',
            value: event.target.value,
        });
    };

    handleCloseYear = (event) => {
        if (event.target.value || this.state.year) {
            this.setState({ yearError: false, yearErrorText: '' });
        } else {
            this.setState({
                yearError: true,
                yearErrorText: this.props.vTranslations.REQUIRED_FIELD_VALIDATOR,
            });
        }
    };

    handleChangeMake = (event) => {
        if (AnalyticsService().loggingEnabled) {
            // eslint-disable-next-line no-undef
            _satellite?.track('stat-dropdown');
        }
        this.setState({ makeError: false, make: event.target.value, model: '' });
        getVehicleModels(this.props.vCountry, this.state.year, event.target.value);
        this.props.setVehicleData({
            key: 'selectedMake',
            value: event.target.value,
        });
    };

    handleCloseMake = (event) => {
        if (event.target.value || this.state.make) {
            this.setState({ makeError: false, makeErrorText: '' });
        } else {
            this.setState({
                makeError: true,
                makeErrorText: this.props.vTranslations.REQUIRED_FIELD_VALIDATOR,
            });
        }
    };

    handleChangeModel = (event) => {
        if (AnalyticsService().loggingEnabled) {
            // eslint-disable-next-line no-undef
            _satellite?.track('stat-dropdown');
        }
        this.setState({ modelError: false, model: event.target.value, motor: '' });
        if (this.props.vCountryOptions.SHOW_VEHICLE_PLUS === 'true') {
            getVehiclePlus(this.props.vCountry, this.state.year, this.state.make, event.target.value);
        }
        this.props.setVehicleData({
            key: 'selectedModel',
            value: event.target.value,
        });
    };

    handleCloseModel = (event) => {
        if (event.target.value || this.state.model) {
            this.setState({ modelError: false, modelErrorText: '' });
        } else {
            this.setState({
                modelError: true,
                modelErrorText: this.props.vTranslations.REQUIRED_FIELD_VALIDATOR,
            });
        }
    };

    validMileage(mileage) {
        const regex = this.REGEX_PARSER(this.props.vCountryOptions.MILES_REGEX);
        return regex.test(mileage);
    }

    handleBlurMileage = (event) => {
        if (this.validMileage(event.target.value)) {
            this.setState({
                mileageError: false,
                mileageErrorText: '',
                mileage: event.target.value,
            });
            this.props.setPrefillMileage(event.target.value);
            this.props.setVehicleData({
                key: 'selectedMileage',
                value: event.target.value,
            });
        } else {
            this.setState({
                mileageError: true,
                mileageErrorText: this.props.vTranslations.MILEAGE_VALIDATOR,
            });
        }
    };

    validLicensePlate(license) {
        const regex = this.REGEX_PARSER(this.props.vCountryOptions.LICENSE_PLATE_REGEX);
        return regex.test(license);
    }

    handleBlurSearchByLicense = (event) => {
        if (event.target.value) {
            this.setState({
                licenseError: false,
                licenseErrorText: '',
                searchByLicensePlateEnteredValue: event.target.value,
            });
            this.props.setVehicleData({
                key: 'searchByLicensePlateEnteredValue',
                value: event.target.value,
            });
            this.props.setVehicleData({
                key: 'searchByLicensePlateRespError',
                value: false,
            });
        } else if (!event.target.value) {
            this.setState({
                licenseError: true,
                licenseErrorText: '',
                searchByLicensePlateEnteredValue: event.target.value,
            });
        } else {
            this.setState({
                licenseError: true,
                licenseErrorText: this.props.vTranslations.ERROR_LICENSE_PLATE_FORMAT,
            });
        }
    };

    handleChangeLicense = (event) => {
        if (this.props.vCountryOptions.REQUIRE_LICENSE_PLATE === 'true' && !event.target.value) {
            this.setState({
                licenseError: true,
                licenseErrorText: this.props.vTranslations.REQUIRED_FIELD_VALIDATOR,
            });
        } else if (!event.target.value || this.validLicensePlate(event.target.value)) {
            this.setState({
                licenseError: false,
                licenseErrorText: '',
                license: event.target.value,
            });
            this.props.setVehicleData({
                key: 'selectedLicense',
                value: event.target.value,
            });
        } else {
            this.setState({
                licenseError: true,
                licenseErrorText: this.props.vTranslations.ERROR_LICENSE_PLATE_FORMAT,
            });
        }
    };

    handleChangeMotor = (event) => {
        this.setState({ motorError: false, motor: event.target.value });
        this.props.setVehicleData({
            key: 'selectedMotor',
            value: event.target.value,
        });
    };

    handleCloseMotor = (event) => {
        if (event.target.value || this.state.motor) {
            this.setState({ motorError: false, motorErrorText: '' });
            this.setState({ motorErrorText: '' });
        } else {
            this.setState({
                motorError: true,
                motorErrorText: this.props.vTranslations.REQUIRED_FIELD_VALIDATOR,
            });
        }
    };

    validRegistration(registration) {
        const regex = this.REGEX_PARSER(this.props.vCountryOptions.REGISTRATION_REGEX);
        return regex.test(registration);
    }

    handleBlurRegistration = (event) => {
        if (this.props.vCountryOptions.REQUIRED_VEHICLE_REGISTRATION === 'true' && !event.target.value) {
            this.setState({
                registrationError: true,
                registrationErrorText: this.props.vTranslations.REQUIRED_FIELD_VALIDATOR,
            });
        } else if (this.validRegistration(event.target.value)) {
            this.setState({
                registrationError: false,
                registrationErrorText: '',
                registration: event.target.value,
            });
            this.props.setVehicleData({
                key: 'selectedRegistration',
                value: event.target.value,
            });
        } else {
            this.setState({
                registrationError: true,
                registrationErrorText: this.props.vTranslations.ERROR_VEHICLE_REGISTRATION,
            });
        }
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevState !== this.state) {
            // Handling for US only and license plate view only
            if (
                this.props.vCountryOptions.USE_PLATE_TO_VIN === 'true' &&
                this.state.searchByLicensePlateView === true
            ) {
                // Handling for US search by license plate once a response of possible vehicles is displayed to user to select from
                if (this.props.vSearchByLicensePlateRespReceived === true) {
                    if (this.state.selectedItemIndex !== 'false') {
                        const isValid = true;
                        this.props.setValidFlag(isValid.toString());
                    } else {
                        const isValid = false;
                        this.props.setValidFlag(isValid.toString());
                    }
                } else {
                    const isValid =
                        !!this.state.searchByLicensePlateSelectedState && !!this.state.searchByLicensePlateEnteredValue;
                    this.props.setValidFlag(isValid.toString());
                }
            } else {
                const isValid =
                    (this.props.vCountryOptions.SHOW_VEHICLE_YEAR === 'true'
                        ? !!this.state.year && !this.state.yearError
                        : true) &&
                    (this.props.vCountryOptions.SHOW_VEHICLE_MAKE === 'true'
                        ? !!this.state.make && !this.state.makeError
                        : true) &&
                    !!this.state.model &&
                    !this.state.modelError &&
                    (this.props.vCountryOptions.SHOW_VEHICLE_PLUS === 'true'
                        ? !!this.state.motor && !this.state.motorError
                        : true) &&
                    !this.state.mileageError &&
                    (this.props.vCountryOptions.SHOW_LICENSE_PLATE === 'true'
                        ? (this.props.vCountryOptions.REQUIRE_LICENSE_PLATE === 'true' ? !!this.state.license : true) &&
                          !this.state.licenseError
                        : true) &&
                    (this.props.vCountryOptions.SHOW_VEHICLE_REGISTRATION === 'true'
                        ? (this.props.vCountryOptions.REQUIRE_VEHICLE_REGISTRATION === 'true'
                              ? !!this.state.registration
                              : true) && !this.state.registrationError
                        : true);
                this.props.setValidFlag(isValid.toString());
            }
        } else if (
            this.props.vCountryOptions.USE_PLATE_TO_VIN === 'true' &&
            this.state.searchByLicensePlateView === true
        ) {
            if (this.props.vSearchByLicensePlateRespReceived === true) {
                if (this.state.selectedItemIndex !== 'false') {
                    const isValid = true;
                    this.props.setValidFlag(isValid.toString());
                } else {
                    const isValid = false;
                    this.props.setValidFlag(isValid.toString());
                }
            }
            if (this.props.vSearchByLicensePlateRespError === true) {
                const isValid = false;
                this.props.setValidFlag(isValid.toString());
            }
        }
    }

    populateUSStatesSelect(states) {
        if (states.length) {
            return states.map((item) => (
                <MenuItem key={item} value={item}>
                    {item}
                </MenuItem>
            ));
        }
    }

    populateYearSelect(years) {
        if (years.length) {
            return years.map((item) => (
                <MenuItem id={'year' + item} key={item} value={item}>
                    {item}
                </MenuItem>
            ));
        }
    }

    populateMakeSelect(makes) {
        makes.sort((a, b) => a.make.localeCompare(b.make));
        return makes.map((item) => (
            <MenuItem id={'make' + item.make} className="dropdown-group-element" key={item.make} value={item.make}>
                {item.make}
            </MenuItem>
        ));
    }

    populateModelSelect(models) {
        if (models && models.length) {
            return models.map((item) => (
                <MenuItem id={'model' + item} key={item} value={item}>
                    {item}
                </MenuItem>
            ));
        }
    }

    populateMotorSelect(motors) {
        if (motors && motors.length) {
            return motors.map((item) => (
                <MenuItem id={'engine' + item} key={item} value={item}>
                    {item}
                </MenuItem>
            ));
        }
    }

    vehicleRegistrationLocalizationLabel() {
        return this.props.vCountry === localizationConstants.NZ
            ? this.props.vTranslations.REGISTRATION
            : `${this.props.vTranslations.REGISTRATION} ${this.props.vTranslations.OPTIONAL_FIELD}`;
    }

    motorLocalizationLabel() {
        if (this.props.vCountry === localizationConstants.BR) {
            return this.props.vTranslations.MOTOR;
        }
        if (this.props.vCountry === localizationConstants.NZ || this.props.vCountry === localizationConstants.AU) {
            return this.props.vTranslations.VEHICLE_SERIES;
        }
        return this.props.vTranslations.VERSION;
    }

    mileageLocalizationLabel() {
        return this.props.vCountryOptions.USE_MILES === 'true'
            ? `${this.props.vTranslations.MILEAGE} ${this.props.vTranslations.OPTIONAL_FIELD}`
            : `${this.props.vTranslations.KILOMETERS_CAP} ${this.props.vTranslations.OPTIONAL_FIELD}`;
    }

    /**
     * Returns a string to show if two cols should be displayed
     */
    twoColumns() {
        let twoColumns = '';
        if (
            this.props.vCountryOptions.SHOW_LICENSE_PLATE === 'true' ||
            this.props.vCountryOptions.SHOW_VEHICLE_REGISTRATION === 'true'
        ) {
            twoColumns = ' two-columns';
        } else {
            twoColumns = '';
        }

        return twoColumns;
    }

    render() {
        let PRIVACY_STATEMENT_LINK = (
            <a
                id="btnPrivacy"
                href={this.props.vTranslations.PRIVACY_STATEMENT_LINK}
                target="_blank"
            >{` ${this.props.vTranslations.VEHICLE_PRIVACY_STATEMENT}`}</a>
        );
        const origin = this.props.origin;
        const isOrigin = this.props.vCountryOptions.DEALER_SITE_ORIGINS.includes(origin);

        const errorAdornment = (
            <InputAdornment position="end">
                <div className="icon error" />
            </InputAdornment>
        );

        if (this.props.vSearchByLicensePlateRespReceived === true) {
            this.state.linktoOtherViewHeaderText = this.props.vTranslations.DO_NOT_SEE_VEHICLE;

            // If only 1 vehicle is returned via Search by License Plate, make it the default selection
            if (this.props.vSearchByLicensePlateResp.length === 1) {
                this.state.selectedItemIndex = this.props.vSearchByLicensePlateResp[0].vin;
                this.props.vSearchByLicensePlateResp[0].image = this.props.vImage1;
            }
            if (this.props.vSearchByLicensePlateResp.length === 2) {
                this.props.vSearchByLicensePlateResp[0].image = this.props.vImage1;
                this.props.vSearchByLicensePlateResp[1].image = this.props.vImage2;
            }
        }
        this.state.vSearchByLicensePlateResp = this.props.vSearchByLicensePlateResp;

        const years = this.props.vYearsList.sort((a, b) => (a < b ? 1 : -1));
        const gmMakes = this.props.vMakesList.filter((item) => item.gm);
        const gmMakesNon = this.props.vMakesList.filter((item) => !item.gm);
        const models = this.props.vModelsList;
        const motors = this.props.vPlusList;

        const USStatesList = [
            'AL - Alabama',
            'AK - Alaska',
            'AZ - Arizona',
            'AR - Arkansas',
            'CA - California',
            'CO - Colorado',
            'CT - Connecticut',
            'DE - Delaware',
            'FL - Florida',
            'GA - Georgia',
            'HI - Hawaii',
            'ID - Idaho',
            'IL - Illinois',
            'IN - Indiana',
            'IA - Iowa',
            'KS - Kansas',
            'KY - Kentucky',
            'LA - Louisiana',
            'ME - Maine',
            'MD - Maryland',
            'MA - Massachusetts',
            'MI - Michigan',
            'MN - Minnesota',
            'MS - Mississippi',
            'MO - Missouri',
            'MT - Montana',
            'NE - Nebraska',
            'NV - Nevada',
            'NH - New Hampshire',
            'NJ - New Jersey',
            'NM - New Mexico',
            'NY - New York',
            'NC - North Carolina',
            'ND - North Dakota',
            'OH - Ohio',
            'OK - Oklahoma',
            'OR - Oregon',
            'PA - Pennsylvania',
            'RI - Rhode Island',
            'SC - South Carolina',
            'SD - South Dakota',
            'TN - Tennessee',
            'TX - Texas',
            'UT - Utah',
            'VT - Vermont',
            'VA - Virginia',
            'WA - Washington',
            'WV - West Virginia',
            'WI - Wisconsin',
            'WY - Wyoming',
        ];

        const USStatesDropdown = (
            <FormControl>
                <InputLabel className="state-dropdown-label" id="state-dropdown-label">
                    {this.props.vTranslations.STATE}
                </InputLabel>
                <Select
                    className="state-dropdown stat-dropdown"
                    data-dtm="select vehicle"
                    labelId="state-dropdown-label"
                    onChange={this.handleChangeUSState}
                    onClose={this.handleCloseUSState}
                    value={this.state.searchByLicensePlateSelectedState}
                    variant="filled"
                    required
                >
                    {this.populateUSStatesSelect(USStatesList)}
                </Select>
            </FormControl>
        );

        // Search by license plate - license plate input field
        const searchbylicensePlateTextInput = (
            <FormControl>
                <TextField
                    error={this.props.vSearchByLicensePlateRespError}
                    className="license-plate-text-input stat-input-field"
                    data-dtm="select vehicle"
                    inputProps={{ maxLength: 10 }}
                    InputProps={{
                        endAdornment: this.props.vSearchByLicensePlateRespError ? errorAdornment : null,
                    }}
                    label={this.props.vTranslations.LICENSE_PLATE_FIELD}
                    onChange={this.handleBlurSearchByLicense}
                    onBlur={this.handleBlurSearchByLicense}
                    helperText={this.props.vTranslations.LICENSE_PLATE_NOT_FOUND}
                    variant="filled"
                    defaultValue={this.state.searchByLicensePlateEnteredValue}
                />
            </FormControl>
        );

        const yearDropdown = (
            <FormControl>
                <InputLabel className="year-dropdown-label">{this.props.vTranslations.YEAR}</InputLabel>
                <Select
                    id="year-dropdown"
                    className="stat-dropdown"
                    error={this.state.yearError}
                    labelId="year-dropdown-label"
                    onChange={this.handleChangeYear}
                    onClose={this.handleCloseYear}
                    value={this.state.year}
                    variant="filled"
                    data-dtm="select vehicle"
                    disabled={this.props.visOCPLusRequired}
                    required
                >
                    {this.populateYearSelect(years)}
                </Select>
                {this.state.yearError && <FormHelperText error>{this.state.yearErrorText}</FormHelperText>}
            </FormControl>
        );

        const makeDropdown = (
            <FormControl>
                <InputLabel className="make-dropdown-label" disabled={!this.state.year}>
                    {this.props.vTranslations.MAKE}
                </InputLabel>
                <Select
                    id="make-dropdown"
                    className="stat-dropdown"
                    error={this.state.makeError}
                    labelId="make-dropdown-label"
                    onChange={this.handleChangeMake}
                    onClose={this.handleCloseMake}
                    value={this.state.make}
                    variant="filled"
                    disabled={!this.state.year || this.props.visOCPLusRequired}
                    data-dtm="select vehicle"
                    required
                >
                    <div className="menu-group-name">{this.props.vTranslations.GM}</div>
                    {this.populateMakeSelect(gmMakes)}
                    <div className="menu-group-name">{gmMakesNon.length > 0 ? this.props.vTranslations.OTHER : ''}</div>
                    {this.populateMakeSelect(gmMakesNon)}
                </Select>
                {this.state.makeError && <FormHelperText error>{this.state.makeErrorText}</FormHelperText>}
            </FormControl>
        );

        const modelDropdown = (
            <FormControl>
                <InputLabel className="model-dropdown-label" disabled={!this.state.make}>
                    {this.props.vTranslations.MODEL}
                </InputLabel>
                <Select
                    id="model-dropdown"
                    className="stat-dropdown"
                    error={this.state.modelError}
                    labelId="model-dropdown-label"
                    onChange={this.handleChangeModel}
                    onClose={this.handleCloseModel}
                    value={this.state.model}
                    variant="filled"
                    disabled={!this.state.make || this.props.visOCPLusRequired}
                    data-dtm="select vehicle"
                    required
                >
                    {this.populateModelSelect(models)}
                </Select>
                {this.state.modelError && <FormHelperText error>{this.state.modelErrorText}</FormHelperText>}
            </FormControl>
        );

        const motorDropdown = (
            <FormControl>
                <InputLabel className="motor-dropdown-label" disabled={!this.state.model}>
                    {this.motorLocalizationLabel()}
                </InputLabel>
                <Select
                    id="motor-dropdown"
                    className="motor-dropdown"
                    error={this.state.motorError}
                    labelId="motor-dropdown-label"
                    onChange={this.handleChangeMotor}
                    onClose={this.handleCloseMotor}
                    value={this.state.motor}
                    variant="filled"
                    disabled={!this.state.model}
                    required
                >
                    {this.populateMotorSelect(motors)}
                </Select>
                {this.state.motorError && <FormHelperText error>{this.state.motorErrorText}</FormHelperText>}
            </FormControl>
        );

        const vehicleRegistrationTextInput = (
            <FormControl>
                <TextField
                    id="txtLicensePlate"
                    className="vehicle-registration-text-input"
                    InputProps={{
                        endAdornment: this.state.registrationError ? errorAdornment : null,
                    }}
                    label={this.vehicleRegistrationLocalizationLabel()}
                    onChange={this.handleBlurRegistration}
                    error={this.state.registrationError}
                    helperText={this.state.registrationErrorText}
                    defaultValue={this.state.registration}
                    variant="filled"
                />
                <OverlayTrigger
                    delay={{ show: 200, hide: 200 }}
                    overlay={
                        <Tooltip className="vehicle-registration-tooltip">
                            {this.props.vTranslations.REGISTRATION_HELP_TOOL_TIP}
                        </Tooltip>
                    }
                    placement="top"
                    trigger={['hover', 'click']}
                >
                    <div className="vehicle-registration-tooltip-label">
                        <div className="icon info" />
                        <div className="registration link">{this.props.vTranslations.REGISTRATION_HELP_TEXT}</div>
                    </div>
                </OverlayTrigger>
            </FormControl>
        );

        const mileageTextInput = (
            <FormControl>
                <TextField
                    id="txtMilage"
                    className="stat-input-field"
                    label={this.mileageLocalizationLabel()}
                    inputProps={{ maxLength: 6 }}
                    InputProps={{
                        endAdornment: this.state.mileageError ? errorAdornment : null,
                    }}
                    onBlur={this.handleBlurMileage}
                    error={this.state.mileageError}
                    helperText={this.state.mileageErrorText}
                    defaultValue={this.state.miles}
                    variant="filled"
                    data-dtm="select vehicle"
                />
            </FormControl>
        );

        const licensePlateTextInput = (
            <FormControl>
                <TextField
                    id="txtLicensePlate"
                    className="license-plate-text-input"
                    inputProps={{ maxLength: 25 }}
                    InputProps={{
                        endAdornment: this.state.licenseError ? errorAdornment : null,
                    }}
                    label={
                        this.props.vCountryOptions.REQUIRE_LICENSE_PLATE === 'true'
                            ? this.props.vTranslations.LICENSE_PLATE
                            : `${this.props.vTranslations.LICENSE_PLATE} ${this.props.vTranslations.OPTIONAL_FIELD}`
                    }
                    onChange={this.handleChangeLicense}
                    error={this.state.licenseError}
                    helperText={this.state.licenseErrorText}
                    variant="filled"
                    required={this.props.vCountryOptions.REQUIRE_LICENSE_PLATE === 'true'}
                    defaultValue={this.state.license}
                />
            </FormControl>
        );

        if (this.props.vCountryOptions.USE_PLATE_TO_VIN === 'true') {
            return (
                <div className="vehicle-container">
                    <div className="vehicle-headline">
                        {this.props.vGarageFailed ? (
                            <Grid item xs={12}>
                                <Alert className="garage-error" severity="warning">
                                    {this.props.vTranslations.LOGIN_UNKNOWN_ERROR}
                                </Alert>
                            </Grid>
                        ) : null}
                        {this.state.searchByLicensePlateView === true &&
                            this.props.vSearchByLicensePlateRespReceived === false && (
                                <h2 className="vehicle-headline-text">{this.props.vTranslations.SEARCH_VEHICLE}</h2>
                            )}

                        {this.state.searchByLicensePlateView === true &&
                            this.props.vSearchByLicensePlateRespReceived === true && (
                                <h2 className="vehicle-headline-text">{this.props.vTranslations.CONFIRM_VEHICLE}</h2>
                            )}

                        {this.state.searchByLicensePlateView === false && (
                            <h2 className="vehicle-headline-text">{this.props.vTranslations.SELECT_VEHICLE}</h2>
                        )}

                        <div className="headline-border" />
                    </div>

                    {this.state.searchByLicensePlateView === true &&
                        this.props.vSearchByLicensePlateRespReceived === false && (
                            <div className="Enter-your-license-p">
                                {' '}
                                {this.props.vTranslations.LICENSE_PLATE_SERVICE_RECOMMENDATIONS}
                            </div>
                        )}

                    <div className="vehicle-fields">
                        <div className={`vehicle-fields-grid-container${this.twoColumns()}`}>
                            {this.state.searchByLicensePlateView === true &&
                                this.props.vSearchByLicensePlateRespReceived === false && (
                                    <div className="vehicle-dropdown">{USStatesDropdown}</div>
                                )}
                            {this.state.searchByLicensePlateView === true &&
                                this.props.vSearchByLicensePlateRespReceived === false && (
                                    <div className="vehicle-text">{searchbylicensePlateTextInput}</div>
                                )}

                            {/* ------------ Search by License Plate has returned a response -------------------- */}
                            {this.state.searchByLicensePlateView === true &&
                                this.props.vSearchByLicensePlateRespReceived === true && (
                                    <>
                                        {this.state.vSearchByLicensePlateResp.map((d, index) => (
                                            <div
                                                key={index}
                                                onClick={() =>
                                                    this.searchByLicenseResultsVehicleSelected(
                                                        d.vin,
                                                        d.makeCode,
                                                        d.modelDesc,
                                                        d.modelYear
                                                    )
                                                }
                                                className={`vehicle paper${
                                                    this.state.selectedItemIndex === d.vin ? ' selected' : ''
                                                }`}
                                            >
                                                <div className={`myRow${d.image ? ' withImage' : ''}`}>
                                                    {d.image && <img className="Bitmap" src={d.image} />}
                                                    <div className="vehicle-details">
                                                        <div className="vehicle-ymm bold">
                                                            {' '}
                                                            {d.modelYear} {d.makeCode} {d.modelDesc}{' '}
                                                        </div>
                                                        <div className="vehicle-vin">
                                                            {this.props.vTranslations.VIN_ENDING_IN} {d.truncatedVin}
                                                        </div>{' '}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        {/* Search by License Plate Resp - Now add Optional Mileage Entry field */}
                                        <div className="vehicle-text">{mileageTextInput}</div>
                                    </>
                                )}
                            {/* ------------ END: Search by License Plate has returned a response -------------------- */}
                            {this.state.searchByLicensePlateView === false &&
                                this.props.vCountryOptions.SHOW_VEHICLE_YEAR === 'true' && (
                                    <div className="vehicle-dropdown">{yearDropdown}</div>
                                )}
                            {this.state.searchByLicensePlateView === false &&
                                this.props.vCountryOptions.SHOW_VEHICLE_MAKE === 'true' && (
                                    <div className="vehicle-dropdown">{makeDropdown}</div>
                                )}
                            {this.state.searchByLicensePlateView === false && (
                                <div className="vehicle-dropdown">{modelDropdown}</div>
                            )}
                            {this.state.searchByLicensePlateView === false &&
                                this.props.vCountryOptions.SHOW_VEHICLE_PLUS === 'true' && (
                                    <div className="vehicle-dropdown">{motorDropdown}</div>
                                )}
                            {this.state.searchByLicensePlateView === false && (
                                <div className="vehicle-text">{mileageTextInput}</div>
                            )}
                            {this.state.searchByLicensePlateView === false &&
                                this.props.vCountryOptions.SHOW_LICENSE_PLATE === 'true' && (
                                    <div className="vehicle-text">{licensePlateTextInput}</div>
                                )}
                            {this.state.searchByLicensePlateView === false &&
                                this.props.vCountryOptions.SHOW_VEHICLE_REGISTRATION === 'true' && (
                                    <div className="vehicle-text">{vehicleRegistrationTextInput}</div>
                                )}
                            <div className="vehicle-label">
                                {this.state.linktoOtherViewHeaderText}
                                <a className="mileage-value link" onClick={this.handleChangeSearchView}>
                                    {this.state.linketoOtherViewBodyText}
                                </a>
                            </div>
                            <div>
                                {isOrigin && this.props.vCountry === localizationConstants.US && (
                                    <>
                                        {this.props.vTranslations.GM_PRIVACY_STATEMENT} {PRIVACY_STATEMENT_LINK}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            );
        } // end-if

        return (
            <div className="vehicle-container">
                <div className="vehicle-headline">
                    <h2 className="vehicle-headline-text">{this.props.vTranslations.SELECT_VEHICLE}</h2>
                    <div className="headline-border" />
                </div>

                <div className="vehicle-fields">
                    <div className={`vehicle-fields-grid-container${this.twoColumns()}`}>
                        {this.props.vCountryOptions.SHOW_VEHICLE_YEAR === 'true' && (
                            <div className="vehicle-dropdown">{yearDropdown}</div>
                        )}
                        {this.props.vCountryOptions.SHOW_VEHICLE_MAKE === 'true' && (
                            <div className="vehicle-dropdown">{makeDropdown}</div>
                        )}
                        <div className="vehicle-dropdown">{modelDropdown}</div>
                        {this.props.vCountryOptions.SHOW_VEHICLE_PLUS === 'true' && (
                            <div className="vehicle-dropdown">{motorDropdown}</div>
                        )}
                        <div className="vehicle-text">{mileageTextInput}</div>
                        {this.props.vCountryOptions.SHOW_LICENSE_PLATE === 'true' && (
                            <div className="vehicle-text">{licensePlateTextInput}</div>
                        )}
                        {this.props.vCountryOptions.SHOW_VEHICLE_REGISTRATION === 'true' && (
                            <div className="vehicle-text">{vehicleRegistrationTextInput}</div>
                        )}
                    </div>
                    <div className={`privacy-container${this.twoColumns()}`}>
                        {isOrigin && this.props.vCountry !== localizationConstants.AR && (
                            <>
                                {this.props.vTranslations.GM_PRIVACY_STATEMENT} {PRIVACY_STATEMENT_LINK}
                            </>
                        )}
                        {isOrigin && this.props.vCountry === localizationConstants.AR && (
                            <>
                                {this.props.vTranslations.AR_GM_PRIVACY_STATEMENT_ONE} {PRIVACY_STATEMENT_LINK}{' '}
                                {this.props.vTranslations.AR_GM_PRIVACY_STATEMENT_TWO}
                            </>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    vCountry: state.prefillReducer.country,
    vCountryOptions: state.countryOptionsReducer.countryOptions,
    vYearsList: state.vehicleReducer.yearsList,
    vMakesList: state.vehicleReducer.makesList,
    vModelsList: state.vehicleReducer.modelsList,
    vPlusList: state.vehicleReducer.plusList,
    vTranslations: state.countryOptionsReducer.translations,
    vSelectedYear: state.vehicleReducer.selectedYear,
    vSelectedMake: state.vehicleReducer.selectedMake,
    vSelectedModel: state.vehicleReducer.selectedModel,
    vSelectedMileage: state.vehicleReducer.selectedMileage,
    vSelectedMotor: state.vehicleReducer.selectedMotor,
    vSelectedLicense: state.vehicleReducer.selectedLicense,
    vSearchbyLicensePlateView: state.vehicleReducer.searchByLicensePlateView,
    vSearchbyLicenseSelectedState: state.vehicleReducer.searchByLicensePlateSelectedState,
    vSearchbyLicenseSelectedLicense: state.vehicleReducer.searchByLicensePlateEnteredValue,
    vSearchByLicensePlateRespReceived: state.vehicleReducer.searchByLicensePlateRespReceived,
    vSearchByLicensePlateRespError: state.vehicleReducer.searchByLicensePlateRespError,
    vSearchByLicensePlateResp: state.vehicleReducer.searchByLicensePlateResp,
    vImage1: state.reviewReducer.vehicleImage1,
    vImage2: state.reviewReducer.vehicleImage2,
    vSelectedRegistration: state.vehicleReducer.selectedRegistration,
    validFlag: state.appointmentReducer.validFlag,
    vbooleanVal: state.vehicleReducer.booleanVal,
    deleteAppointmentFlag: state.confirmationReducer.deleteAppointmentFlag,
    flowSource: state.prefillReducer.flowSource,
    vLocale: state.prefillReducer.locale,
    origin: state.prefillReducer.origin,
    vGarageFailed: state.myGarageReducer.garageFailed,
    visOCPLusRequired: state.prefillReducer.isOCPLusRequired,
    vyear: state.prefillReducer.year,
    vmake: state.prefillReducer.make,
    vmodel: state.prefillReducer.model,
    vmiles: state.prefillReducer.miles,
    vlicensePlate: state.prefillReducer.licensePlate,
    vplus: state.prefillReducer.plus,
    vrego: state.prefillReducer.rego,
});

export default connect(mapStateToProps, {
    setPrefillMileage,
    getVehicleYears,
    getVehicleMakes,
    getVehicleModels,
    getVehiclePlus,
    setVehicleData,
    setValidFlag,
})(Vehicle);
