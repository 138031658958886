const constants = require('./FlowComponentsConstants');

export const ossDetermineFlow = (
    Vin,
    ServiceLineItems,
    Origin,
    URLPath,
    BranchDealer,
    Bac,
    Locator,
    Make,
    Year,
    Model,
    isMyAccountLogin,
    isOCPLusRequired
) => {
    let flow = '';

    breakout: {
        if (URLPath.includes(constants.AppointmentPath) || URLPath.includes('/v/')) {
            flow = constants.EditAppointmentFlow;
            break breakout;
        } else if(isOCPLusRequired === true){
            flow = constants.AuthenticatedOCPlus;
            break breakout;
        } else if (ServiceLineItems || Origin == constants.TireFinder || Origin == constants.DlrTire) {
            flow = constants.TireFinderFlow;
            break breakout;
        } else if (
            (Origin.includes(constants.Buick) ||
                Origin.includes(constants.GMC) ||
                Origin.includes(constants.Chevrolet) ||
                Origin.includes(constants.Cadillac)) &&
            Bac
        ) {
            flow = constants.BrandFlow;
            break breakout;
        } else if (Vin && Make && Year && Model) {
            flow = constants.AuthenticatedFlow;
            break breakout;
        } else if (BranchDealer || Locator) {
            flow = constants.OssLocatorFlow;
            break breakout;
        } else if (
            isMyAccountLogin === 'true' &&
            !constants.ExcludedOrigins.some((substring) => Origin.includes(substring)) &&
            Origin !== 's'
        ) {
            flow = constants.DealerFlowUs;
        } else {
            flow = constants.DealerFlow;
        }
    }

    console.log('This is the detected flow', flow);
    return flow;
};
