import React, { Component } from 'react';
import { connect } from 'react-redux';
import {setBrandText} from './MyRewards';
import {
    ExperienceRewardsLink,
    myBuickRewardsLink,
    myCadillacRewardsLink,
    myChevroletRewardsLink,
    myGMCRewardsLink,
} from '../../shared/Constants/SharedConstants';

class LearnMoreMyRewards extends Component {
    enrollClick = () => {
        let { translations, preferredBrand, themeMake, make, brands } = this.props;
        const brand = setBrandText(translations, preferredBrand, themeMake, make, brands);
        switch (brand) {
            case translations.MY_CHEVROLET_REWARDS:
                window.open(myChevroletRewardsLink, '_blank');
                break;
            case translations.MY_BUICK_REWARDS:
                window.open(myBuickRewardsLink, '_blank');
                break;
            case translations.MY_GMC_REWARDS:
                window.open(myGMCRewardsLink, '_blank');
                break;
            case translations.MY_CADILLAC_REWARDS:
                window.open(myCadillacRewardsLink, '_blank');
                break;
            default:
                window.open(ExperienceRewardsLink, '_blank');
                break;
        }
    };
    render() {
        let { translations, preferredBrand, themeMake, make, getLoyalty, brands } = this.props;
        const brand = setBrandText(translations, preferredBrand, themeMake, make, brands);
        return (
            <>
                {getLoyalty === 'true' ? (
                    <div className={'my-rewards-border my-rewards-header'}>
                        <div className="my-rewards-enroll">
                            <div className="my-rewards-enroll-title">{brand}</div>
                            <div className="my-rewards-input">
                                <div className="my-rewards-enroll-body">
                                    {brand + ' '}
                                    {translations.ENROLL_MESSAGE}
                                </div>
                                <button
                                    className="my-rewards-enroll-button primary-button"
                                    onClick={(e) => this.enrollClick(e)}
                                >
                                    {translations.LEARN_MORE}
                                </button>
                            </div>
                        </div>
                    </div>
                ) : null}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    brands: state.prefillReducer.dealerSummaryObject.dealershipSummary.brands,
    translations: state.countryOptionsReducer.translations,
    preferredBrand: state.myRewardsReducer.preferredBrand,
    themeMake: state.prefillReducer.themeMake,
    make: state.prefillReducer.make,
    customerFirstName: state.appointmentReducer.customerFirstName,
    getLoyalty: state.countryOptionsReducer.countryOptions.GET_LOYALTY,
});

export default connect(mapStateToProps)(LearnMoreMyRewards);
