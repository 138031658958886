import React from 'react';
import store from '../../Redux/Store';

const CURRENCY_CODE = {
    AR: 'ARS',
    AU: 'AUD',
    BR: 'BRL',
    CA: 'CAD',
    CL: 'CLP',
    CO: 'COP',
    EC: 'USD',
    MX: 'MXN',
    PE: 'PEN',
    NZ: 'NZD',
    US: 'USD',
};

export function renderPrice(priceObject) {
    let formattedPrice;

    if (priceObject.price) {
        formattedPrice = renderCurrencyValue(priceObject);
    } else {
        formattedPrice = '';
    }

    const displayPrice = (
        <div className="display-price">
            {formattedPrice}
            <div className="display-price-tagline">
                {priceObject.tagLine ? priceObject.tagLine : ''}
                {priceObject.disclaimerTagLine ? priceObject.disclaimerTagLine : ''}
            </div>
        </div>
    );

    return displayPrice;
}

function localizeCurrencyValue(currencyValue) {
    const localStore = store.getState();
    const LOCALE = localStore.prefillReducer.locale;
    const COUNTRY = localStore.prefillReducer.country;
    var DISPLAY = LOCALE === 'fr-CA' ? 'narrowSymbol' : 'symbol';

    const formattedValue = new Intl.NumberFormat(LOCALE, {
        style: 'currency',
        currency: CURRENCY_CODE[COUNTRY],
        currencyDisplay: DISPLAY,
    }).formatToParts(currencyValue);

    return formattedValue;
}

export function localizeCurrencyValueSimple(currencyValue) {
    const localStore = store.getState();
    const LOCALE = localStore.prefillReducer.locale;
    const COUNTRY = localStore.prefillReducer.country;
    var DISPLAY = LOCALE === 'fr-CA' ? 'narrowSymbol' : 'symbol';

    let formattedValue;

    if (currencyValue) {
        formattedValue = new Intl.NumberFormat(LOCALE, {
            style: 'currency',
            currency: CURRENCY_CODE[COUNTRY],
            currencyDisplay: DISPLAY,
        }).format(currencyValue);
    } else {
        formattedValue = '';
    }

    return formattedValue;
}

export function renderSelectedServicesPrice(priceObject) {
    let formattedValue;

    if (priceObject) {
        const currencyValue = localizeCurrencyValueSimple(priceObject[0].price);

        formattedValue = (
            <div className="service-price">
                <div className="service-price-value bold">{currencyValue}</div>
                {priceObject[0].tagLine ? <div className="service-tagline">{priceObject[0].tagLine}</div> : null}
            </div>
        );
    } else {
        formattedValue = '';
    }

    return formattedValue;
}

export function renderCurrencyValue(priceObject) {
    let formattedValue;

    if (priceObject.price) {
        const currencyObject = localizeCurrencyValue(priceObject.price);

        const symbol = findTypeValue(currencyObject, 'currency');
        const ints = findIntegerValues(currencyObject);
        const group = findTypeValue(currencyObject, 'group');
        const decimal = findTypeValue(currencyObject, 'decimal');
        const fraction = findTypeValue(currencyObject, 'fraction');
        let str = '';

        if (ints.length) {
            ints.forEach((int, index) => {
                str += int;

                if (index < ints.length - 1) {
                    str += group;
                }
            });
        }

        var unitLocation =
            currencyObject[currencyObject.length - 1].type === 'currency' ? 'reverted-currency-symbol' : '';

        formattedValue = (
            <div className="display-price-currency">
                <div className={`display-price-unit ${unitLocation} body2 bold`}>{symbol}</div>
                <div className="display-price-before-decimal bold">{str}</div>
                <div className="display-price-after-decimal body2 bold">
                    {(fraction && fraction !== '00' ? decimal + fraction : '') +
                        (priceObject.disclaimerNumeric ? priceObject.disclaimerNumeric : '')}
                </div>
            </div>
        );
    } else {
        formattedValue = '';
    }

    return formattedValue;
}
export const renderPriceWithPriceSymbol = (price)=>{
    if (price.price && typeof price.price === 'string') {
        price.price = price.price.replace(/\$/g, ''); // Remove dollar sign
    }
    return renderCurrencyValue(price)
}

function findIntegerValues(currencyObjects) {
    const values = [];

    currencyObjects.forEach((obj) => {
        if (obj.type === 'integer') {
            values.push(obj.value);
        }
    });

    return values;
}

function findTypeValue(currencyObjects, type) {
    let value = '';

    currencyObjects.forEach((obj) => {
        if (obj.type === type) {
            value = obj.value;
        }
    });

    return value;
}
