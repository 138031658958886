import React, { Component } from 'react';
import { connect } from 'react-redux';
import {setBrandLink, setBrandText} from './MyRewards';
import SharedCheckBox from '../../shared/Checkbox/SharedCheckBox';
import { enrollMyRewardsFlag, showError } from '../../Redux/ActionCreator';
import { enrollMyRewards } from '../../shared/BackendCalls/SharedBackendGets';
import CircularProgress from '@material-ui/core/CircularProgress';
import { capitalizeFirstLetter } from '../../shared/Utils/Utils';
import {terms, PRIVACY_POLICY_LINK} from '../../shared/Constants/SharedConstants';
class EnrollMyRewards extends Component {
    // Handle user checking/unchecking the enroll in my rewards checkbox
    changeCheckBox(e) {
        this.props.enrollMyRewardsFlag(e.target.checked);
    }

    // Handle user clicking Enroll Now button
    enrollClick() {
        let { enrollRewards } = this.props;
        // Check enrollRewards flag - set with enroll checkbox
        if (enrollRewards) {
            // Good test, enroll try
            this.props.showError(false);
            enrollMyRewards();
        } else {
            // Bad Test, throw up error message
            this.props.showError(true);
        }
    }

    // Handler user clicking Learn More button after enrolling
    enrollButtonClick() {
        let { preferredBrand, themeMake, make, brands } = this.props;
        const url = setBrandLink(preferredBrand, themeMake, make, brands);
        window.open(url, '_blank');
    }

    render() {
        let {
            brands,
            translations,
            preferredBrand,
            themeMake,
            make,
            showErrorMessage,
            showEnrollSpinner,
            enrollMyRewardsError,
            firstName,
            getLoyalty,
            enableFinancialIncentiveNotice
        } = this.props;
        const brand = setBrandText(translations, preferredBrand, themeMake, make, brands);
        const brandLink = setBrandLink(preferredBrand, themeMake, make, brands) + '/' + terms;
        let div = null;
        let MY_REWARDS_PRIVACY_POLICY_LINK = (
            <a href={PRIVACY_POLICY_LINK} target="_blank">{` ${translations.MY_REWARDS_PRIVACY_STATEMENT}`}</a>
        );

        let MY_REWARDS_FINANCIAL_INCENTIVE_NOTICE_LINK = enableFinancialIncentiveNotice ? (
            <a href={translations.FINANCIAL_INCENTIVE_NOTICE_LINK}
               target="_blank">{` ${translations.MY_REWARDS_FINANCIAL_INCENTIVE_NOTICE}`}</a>
        ) : "";

        let TERMS_CONDITIONS = '';
        switch (brand) {
            case translations.MY_CHEVROLET_REWARDS:
                TERMS_CONDITIONS = (
                    <a href={brandLink} target="_blank">
                        {translations.TERMS_AND_CONDITIONS}
                    </a>
                );
                break;
            case translations.MY_BUICK_REWARDS:
                TERMS_CONDITIONS = (
                    <a href={brandLink} target="_blank">
                        {translations.TERMS_AND_CONDITIONS}
                    </a>
                );
                break;
            case translations.MY_GMC_REWARDS:
                TERMS_CONDITIONS = (
                    <a href={brandLink} target="_blank">
                        {translations.TERMS_AND_CONDITIONS}
                    </a>
                );
                break;
            case translations.MY_CADILLAC_REWARDS:
                TERMS_CONDITIONS = (
                    <a href={brandLink} target="_blank">
                        {translations.TERMS_AND_CONDITIONS}
                    </a>
                );
                break;
            default:
                TERMS_CONDITIONS = (
                    <a href={brandLink} target="_blank">
                        {translations.TERMS_AND_CONDITIONS}
                    </a>
                );
                break;
        }
        // Enroll My Rewards prompt
        if (enrollMyRewardsError === undefined && getLoyalty === 'true') {
            div = (
                <div>
                    {!showEnrollSpinner ? (
                        <div className={'my-rewards-border my-rewards-header'}>
                            <div className="my-rewards-enroll">
                                <div className="my-rewards-enroll-title">{brand}</div>
                                <div className="my-rewards-enroll-body">
                                    {translations.MY_REWARDS_BODY_PART1} {brand}
                                    {translations.MY_REWARDS_BODY_PART2}
                                </div>
                                <div className="my-rewards-input">
                                    <div className="my-rewards-enroll-checkbox-container">
                                        <SharedCheckBox
                                            checked={this.props.enrollRewards}
                                            onChange={(e) => this.changeCheckBox(e)}
                                        />
                                        <div className="my-rewards-enroll-checkbox-label">
                                            {translations.MY_REWARDS_CHECKBOX_LABEL} {brand} {translations.PROGRAM}{' '}
                                            {TERMS_CONDITIONS} {translations.AND} {MY_REWARDS_PRIVACY_POLICY_LINK}.
                                            {enableFinancialIncentiveNotice === 'true' &&
                                                <div className="my-rewards-financial-incentive-notice">
                                                    {translations.MY_REWARDS_FINANCIAL_INCENTIVE_NOTICE_LABEL}{MY_REWARDS_FINANCIAL_INCENTIVE_NOTICE_LINK}
                                                </div>
                                             }
                                        </div>
                                    </div>
                                    <button
                                        className="my-rewards-enroll-button primary-button"
                                        onClick={(e) => this.enrollClick(e)}
                                    >
                                        {translations.ENROLL_NOW}
                                    </button>
                                </div>
                                {showErrorMessage ? (
                                    <div className="my-rewards-error-message">
                                        {translations.MY_REWARDS_ERROR_MESSAGE} {brand}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    ) : (
                        <CircularProgress />
                    )}
                </div>
            );
        } else if (getLoyalty === 'true') {
            // Welcome messages for successful enrollment - brand specific My Rewards link
            if (enrollMyRewardsError === false) {
                div = (
                    <div className={'my-rewards-border my-rewards-header'}>
                        <div className="my-rewards-enroll">
                            <div className="my-rewards-welcome">{translations.WELCOME_COMMA + ' ' + firstName}</div>
                            <div className="my-rewards-row">
                                <div className="my-rewards-enroll-body-success">
                                    {translations.ENROLL_WELCOME_PART1} {brand}. {translations.ENROLL_WELCOME_PART2}
                                </div>
                                <button
                                    className="my-rewards-enroll-button primary-button"
                                    onClick={() => this.enrollButtonClick()}
                                >
                                    {translations.LEARN_MORE}
                                </button>
                            </div>
                        </div>
                    </div>
                );
                // Error message when the enrollment API fails - provide link to brand rewards site to enroll manually
            } else if (enrollMyRewardsError === true) {
                div = (
                    <div className={'my-rewards-border my-rewards-header'}>
                        <div className="my-rewards-enroll">
                            <div className="my-rewards-row">
                                <div className="my-rewards-enroll-body-error">
                                    {translations.ENROLL_ERROR} {translations.PLEASE} {translations.GO_TO} {brand}{' '}
                                    {translations.TO_ENROLL}{' '}
                                </div>
                                <button
                                    className="my-rewards-failed-enroll-button primary-button"
                                    onClick={() => this.enrollButtonClick()}
                                >
                                    {capitalizeFirstLetter(translations.GO_TO)} {brand} {translations.TO_ENROLL}
                                </button>
                            </div>
                        </div>
                    </div>
                );
            }
        }

        return div;
    }
}

const mapStateToProps = (state) => ({
    brands: state.prefillReducer.dealerSummaryObject.dealershipSummary.brands,
    translations: state.countryOptionsReducer.translations,
    preferredBrand: state.myRewardsReducer.preferredBrand,
    enrollRewards: state.myRewardsReducer.enrollRewards,
    themeMake: state.prefillReducer.themeMake,
    make: state.prefillReducer.make,
    showErrorMessage: state.myRewardsReducer.showError,
    showEnrollSpinner: state.myRewardsReducer.showEnrollSpinner,
    enrollMyRewardsError: state.myRewardsReducer.enrollMyRewardsError,
    firstName: state.prefillReducer.firstName,
    getLoyalty: state.countryOptionsReducer.countryOptions.GET_LOYALTY,
    enableFinancialIncentiveNotice: state.countryOptionsReducer.countryOptions.ENABLE_FINANCIAL_INCENTIVE_NOTICE
});

export default connect(mapStateToProps, { enrollMyRewardsFlag, showError })(EnrollMyRewards);
