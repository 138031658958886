import { useEffect, useState } from 'react';
import { LDProvider } from 'launchdarkly-react-client-sdk';
import App from './App';


const AppWithLDProvider = () => {
    const [launchDarklyLoading, setLaunchDarklyLoading] = useState(false);
    const [ldClientID, setLDClientId] = useState('');



    useEffect(() => {
        setLaunchDarklyLoading(true);
        const launchDarklyElement = document.querySelector("meta[name='launch-darkly-id']");
        const launchDarklyId = launchDarklyElement ? launchDarklyElement.getAttribute('content') : '';

        if(launchDarklyId){
            setLDClientId(launchDarklyId)
        }
        setLaunchDarklyLoading(false);

    }, []);

    return (
        <>
            {!launchDarklyLoading && (
                <>
                    {ldClientID ? (
                        <LDProvider clientSideID={ldClientID}>
                            <App />
                        </LDProvider>
                    ) : (
                        <App />
                    )}
                </>
            )}
        </>
    );
};

export default AppWithLDProvider;