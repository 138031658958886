import LineItemWrapper from "./LineItemWrapper";
import React from "react";
import {useSelector} from "react-redux";


const Maintenances = (props)=>{
    const { translations } = useSelector((state) => state.ossAppGlobals);

    return <div>
        <LineItemWrapper name={props.name}>
            <div className="more-info-container">
                <div className="item-label">{translations.MAINT_INC}:</div>
                {props.maintenances
                    .map((desc) => (
                        <li className="desc-title body2" key={desc.serviceFEID}>
                            {desc}
                        </li>
                    ))}

            </div>
        </LineItemWrapper>
    </div>
}

export default Maintenances