import { formatPhoneNum } from '../Appointment/AppointmentHelpers';

export function formatCustomerPhoneNumber(phoneNumber, country) {
    let blurredphoneNum;
    let lastFourDigits;
    let phoneNum;

    if (phoneNumber) {
        phoneNum = formatPhoneNum(phoneNumber, country);
        lastFourDigits = phoneNum.slice(-4);
        blurredphoneNum = phoneNum.replace(/[0-9]/g, '*');
        blurredphoneNum = blurredphoneNum.substring(0, blurredphoneNum.length - 4) + lastFourDigits;
    }
    return blurredphoneNum;
}
