import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    checkSession, setCity, setCustomerEmail,
    setCustomerFirstName,
    setCustomerLastName, setCustomerPhoneNumber, setEmail, setFirstName,
    setGarageData, setGarageLoading, setLastName, setMyGarageError, setPostalCode, setProfileId, setRegionCode,
    setSessionActive, setStreet, setAttemptedLogin, setSession
} from '../../Redux/ActionCreator';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import { cookieConst, gpmsProfile } from '../../shared/BackendCalls/BackendCallsConstants';
import {loginGPMS, loginGpmsWithRouting} from '../../shared/BackendCalls/SharedBackendGets';
import store from '../../Redux/Store';
import {useFlags} from "launchdarkly-react-client-sdk";

function Landing() {

    const { enableCfuiCommonLoginPage } = useFlags();
    const dispatch = useDispatch();
    const history = useHistory();

    const { translations } = useSelector((state) => state.countryOptionsReducer);

    const { checkSessionFlag, vehicles, attemptedLogin } = useSelector((state) => state.myGarageReducer);

    const { session } = useSelector((state) => state.sessionReducer)

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleLogin = () => {
        if(enableCfuiCommonLoginPage){
            commonLogin();
        }else{
            legacyLogin();
        }
    }

    const legacyLogin = () => {
        if(session){
            handleSession(session);
        }else{
            dispatch(setSessionActive(false));
            dispatch(checkSession(false));
            history.push('/SignIn');
        }
    }

    const handleSession = (session) => {
        dispatch(setAttemptedLogin(true));
        dispatch(setSession(session));
        dispatch(setSessionActive(true));
        dispatch(checkSession(false));
        loginGPMS(session, handleRouting);
    }

    const handleRouting = (vehicles) => {
        if (vehicles?.length > 0) {
            history.push('/MyGarage');
        } else {
            history.push('/Vehicle');
        }
    }

    const popupTimer = (loginWindow) => {

        try{

            let ossId = loginWindow.document.getElementById("ossId");
            let errorPage = document.evaluate("//*[text()='Whitelabel Error Page'] | //*[text()='Login with OAuth 2.0']", loginWindow.document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue;
            let ossError = loginWindow.document.getElementById("ossError") || errorPage;

            if(ossId || ossError){

                if(ossId?.innerHTML?.length > 0){
                    handleSession(ossId.innerHTML);
                }else{
                    handleRouting(null);
                }

                loginWindow.close();
                return;

            }

        } catch (ignore) {}

        setTimeout(() => popupTimer(loginWindow), 500);

    }

    const commonLogin = () => {

        if (attemptedLogin) {
            handleRouting(vehicles);
            return;
        }
        
        let loginWindow = window.open("/user/login", "_blank", "height=830,width=415,toolbar=no,statusbar=no");

        setTimeout(() => popupTimer(loginWindow, 500));

    };

    return (
        <div>
            {!checkSessionFlag ? (
                <div className="landing-container">
                    <h2>{translations.SIGN_IN}</h2>
                    <div className="headline-border" />
                    <div className="landing-body">
                        <p className="landing-prompt">{translations.START_BOOKING}</p>
                        <button id="btnSignIn" className="button primary-button sign-in" onClick={handleLogin}>
                            {translations.SIGN_IN}
                        </button>
                        <Link id="btnContinueSignInPage" className="button secondary-button continue" to="/Vehicle">
                            {translations.CONTINUE_GUEST}
                        </Link>
                    </div>
                    <div className="landing-subtitle">{translations.GM_OSS}</div>
                    <div
                        className="helpful-links desc"
                        dangerouslySetInnerHTML={{
                            __html: translations.SIGN_IN_TEXT,
                        }}
                    ></div>
                </div>
            ) : (
                <div className="loading">
                    <CircularProgress />
                </div>
            )}
        </div>
    );
}

export default Landing;