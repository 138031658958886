import React, { Component } from 'react';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import scrollIntoView from 'scroll-into-view-if-needed';
import Table from '../../shared/Table/Table';
import PreferredDealerButton from '../../shared/PreferredDealerButton/preferredDealerButton';
import { formatPhoneNum } from '../Appointment/AppointmentHelpers';
import { getAddress } from '../Util/SharedAddress';
import { getEVCertified } from '../../shared/BackendCalls/SharedBackendGets';
import {AuthenticatedOCPlus} from "../../shared/RoutingFlow/FlowComponentsConstants";
import MobileServiceIndicator from "../MobileService/MobileServiceIndicator";

const customParseFormat = require('dayjs/plugin/customParseFormat');
const LocalizedFormat = require('dayjs/plugin/customParseFormat');
const constants = require('../../shared/RoutingFlow/FlowComponentsConstants');

dayjs.extend(customParseFormat);
dayjs.extend(LocalizedFormat);

class LocatorTable extends Component {
    grabServicePhoneNumber = (departments) => {
        for (const i in departments) {
            if (departments.hasOwnProperty(i)) {
                if (departments[i].name === 'SERVICE') {
                    // If the department phone number is missing set value to false - At component level use ternary to show/hide phone number line
                    if (departments[i].phoneNumber) {
                        return formatPhoneNum(departments[i].phoneNumber, this.props.vCountry);
                    }
                    return undefined;
                }
            }
        }
    };

    grabPartsPhoneNumber = (departments) => {
        for (const i in departments) {
            if (departments.hasOwnProperty(i)) {
                if (departments[i].name === 'PARTS') {
                    // If the department phone number is missing set value to false - At component level use ternary to show/hide phone number line
                    if (departments[i].phoneNumber) {
                        return formatPhoneNum(departments[i].phoneNumber, this.props.vCountry);
                    }
                    return undefined;
                }
            }
        }
    };

    grabSalesPhoneNumber = (departments) => {
        for (const i in departments) {
            if (departments.hasOwnProperty(i)) {
                if (departments[i].name === 'SALES') {
                    // If the department phone number is missing set value to false - At component level use ternary to show/hide phone number line
                    if (departments[i].phoneNumber) {
                        return formatPhoneNum(departments[i].phoneNumber, this.props.vCountry);
                    }
                    return undefined;
                }
            }
        }
    };

    // Will check dealership URL is valid with either
    getValidUrl = (url) => {
        let newUrl = url;
        newUrl = newUrl.trim().replace(/\s/g, '');

        if (/^(:\/\/)/.test(newUrl)) {
            return `http${newUrl}`;
        }
        if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
            return `http://${newUrl}`;
        }

        return newUrl;
    };

    cell = (dealer, props) => {
        const { name, miles, kilometers, departments, appointmentAvailabilities, preferred, url, evCertified } = dealer;

        const formattedAddress = getAddress(dealer);
        let active = false;
        let dealerWebsiteURL;
        let dealerWebsiteUserLink;

        if (url) {
            dealerWebsiteURL = this.getValidUrl(url);
            dealerWebsiteUserLink = new URL(dealerWebsiteURL).host;
        }

        if (dealer.isActive) {
            active = true;
        }

        const servicePhoneNumber = `tel:${this.grabServicePhoneNumber(departments)}`;
        const salesPhoneNumber = `tel:${this.grabSalesPhoneNumber(departments)}`;
        const partsPhoneNumber = `tel:${this.grabPartsPhoneNumber(departments)}`;

        const showMobileServiceIndicator = dealer.mobileServicePlusEnabled ? <MobileServiceIndicator/>:null;

        if (props.branchFlow && props.flowSource === constants.OssLocatorFlow) {
            // Special return for Branch Dealers - Use Ternary operator on department phone number components to show/hide line if phone number is missing

            return (
                <div
                    id={dealer.bac}
                    data-dtm="select a dealer"
                    className={`dealer${active ? ' active' : ''} ` + 'stat-button-link'}
                    onClick={function () {
                        const el = document.getElementById(dealer.bac);
                        scrollIntoView(el, { behavior: 'smooth', scrollMode: 'if-needed' });
                        props.setMarkerSelected(dealer);
                    }}
                >
                    <div className="dealer-name-container">
                        <div className="dealer-name label">{name}</div>
                    </div>
                    <div className="dealer-address">{formattedAddress}</div>
                    {salesPhoneNumber !== 'tel:undefined' ? (
                        <div className="dealer-sales-num">
                            {this.props.vTranslations.SALES_PHONE}:{' '}
                            <a className="dealer-phone-link" href={salesPhoneNumber}>
                                {this.grabSalesPhoneNumber(departments)}
                            </a>
                        </div>
                    ) : null}
                    {servicePhoneNumber !== 'tel:undefined' ? (
                        <div className="dealer-service-num">
                            {this.props.vTranslations.SERVICE_PHONE}:{' '}
                            <a className="dealer-phone-link" href={servicePhoneNumber}>
                                {this.grabServicePhoneNumber(departments)}
                            </a>
                        </div>
                    ) : null}
                    {partsPhoneNumber !== 'tel:undefined' ? (
                        <div className="dealer-parts-num">
                            {this.props.vTranslations.PARTS_PHONE}:{' '}
                            <a className="dealer-phone-link" href={partsPhoneNumber}>
                                {this.grabPartsPhoneNumber(departments)}
                            </a>
                        </div>
                    ) : null}
                    <div className="branch-dealer-url">
                        <a className="dealer-url" href={dealerWebsiteURL} target="_blank" rel="noreferrer">
                            {dealerWebsiteUserLink}
                        </a>
                    </div>
                </div>
            );
        }
        return (
            <div
                id={dealer.bac}
                data-dtm="select a dealer"
                className={`dealer${active ? ' active' : ''} ` + 'stat-button-link'}
                onClick={function () {
                    const el = document.getElementById(dealer.bac);
                    scrollIntoView(el, { behavior: 'smooth', scrollMode: 'if-needed' });
                    props.setMarkerSelected(dealer);
                }}
            >
                <div className="dealer-name-container">
                    <div className="dealer-name label">{name}</div>
                    {servicePhoneNumber !== 'tel:false' ? (
                        <a className="dealer-phone-num-link" href={servicePhoneNumber}>
                            {this.props.vTranslations.CALL}
                        </a>
                    ) : null}
                </div>
                {evCertified && this.props.countryOptions.EV_DEALER_LOCATOR === 'true' && (
                    <div className="dealer-ev-tag">
                        <i className="icon battery" />
                        {this.props.vTranslations.EV_CERTIFIED_DEALER}
                    </div>
                )}
                <div className="dealer-address">{formattedAddress}</div>
                <div className="dealer-service-num">{this.grabServicePhoneNumber(departments)}</div>
                {this.props.hideDistance ||
                miles === this.props.vTranslations.ERROR_CALCULATING_DISTANCE ||
                miles === 0 ||
                miles === null ? null : (
                    <div className="dealer-distance">
                        {this.props.useMiles === 'true' ? miles : kilometers} {this.props.vTranslations.L_MILES}
                    </div>
                )}

                {this.props.flowSource === constants.AuthenticatedFlow || this.props.flowSource === AuthenticatedOCPlus ? (
                    <>
                        <div className="dealer-availability">
                            {this.props.vTranslations.AVAIL_COL +
                                (appointmentAvailabilities
                                    ? appointmentAvailabilities.formattedDate
                                    : this.props.vTranslations.AVAIL_ERROR)}
                        </div>
                        {showMobileServiceIndicator}
                        <PreferredDealerButton preferred={preferred} />
                    </>
                ) : showMobileServiceIndicator}

            </div>
        );
    };

    columns = [
        {
            path: 'title',
            label: 'Title',
            content: (dealer) => this.cell(dealer, this.props),
        },
    ];

    render() {
        const { locations } = this.props;
        return <Table columns={this.columns} data={locations} />;
    }
}

const mapStateToProps = (state) => ({
    branchFlow: state.locReducer.branchFlow,
    flowSource: state.prefillReducer.flowSource,
    hideDistance: state.locReducer.hideDistance,
    selectedDealer: state.locReducer.selectedDealer,
    useMiles: state.countryOptionsReducer.countryOptions.USE_MILES,
    vTranslations: state.countryOptionsReducer.translations,
    vCountry: state.prefillReducer.country,
    vCertified: state.prefillReducer.certified,
    countryOptions: state.countryOptionsReducer.countryOptions,
    year: state.prefillReducer.year,
    make: state.prefillReducer.make,
    model: state.prefillReducer.model,
    locale: state.prefillReducer.locale,
});

const mapDispatchToProps = (dispatch) => ({
    setMarkerSelected: (dealer) =>
        dispatch({
            type: 'SET_MARKER_SELECTED',
            payload: dealer,
        }),
});

export default connect(mapStateToProps, mapDispatchToProps)(LocatorTable);
