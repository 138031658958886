import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import InputAdornment from '@material-ui/core/InputAdornment';
import { getGeoLocation } from './getDealerships';
import { getDealersByCords } from '../../shared/BackendCalls/SharedBackendGets';
import { setSelectedPlace } from '../../Redux/ActionCreator';

const constants = require('../../shared/RoutingFlow/FlowComponentsConstants');

class SearchBox extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedAddress: '',
            query: this.props.selectedPlace,
            places: [],
            bac: this.props.bac,
            showErrorMessage: false,
        };
    }

    handleQueryChange = (event) => {
        this.props.setSelectedPlace(event.target.value);
    };

    render() {
        const startAdornment = (
            <InputAdornment position="start">
                <Icon>
                    <div className="icon search" id="searchIcon" />
                </Icon>
            </InputAdornment>
        );

        const errorAdornment = (
            <InputAdornment position="end">
                <div className="icon error" />
            </InputAdornment>
        );

        const locationAdornment = (
            <InputAdornment position="end">
                <IconButton
                    onClick={() => {
                        getGeoLocation();
                        document.getElementById('standAlone').value = '';
                    }}
                >
                    <div className="icon location-airplane" />
                </IconButton>
            </InputAdornment>
        );

        return (
            <TextField
                className={`stat-input-field dl-search-input${this.props.hide ? ' hide' : ''}`}
                error={this.props.showError}
                helperText={this.props.vTranslations.LOCATE_DEALER_NO_RESULTS}
                id="standAlone"
                InputProps={{
                    endAdornment: (
                        <>
                            {this.props.showError ? errorAdornment : null}
                            {this.props.showLocationIcon || this.props.flowSource !== constants.OssLocatorFlow
                                ? locationAdornment
                                : null}
                        </>
                    ),
                    startAdornment,
                }}
                inputProps={{
                    maxLength: '100',
                }}
                data-dtm="search a dealer"
                onChange={this.handleQueryChange}
                placeholder={this.props.vTranslations.SEARCH_TEXT}
                type="text"
                value={this.props.selectedPlace}
                variant="filled"
            />
        );
    }
}

const mapStateToProps = (state) => ({
    country: state.prefillReducer.country,
    bac: state.prefillReducer.bac,
    places: state.locReducer.searchLocation ? state.locReducer.searchLocation.places : [],
    searchLocation: state.locReducer.searchLocation,
    searchFlag: state.locReducer.searchFlag,
    dealerships: state.locReducer.dealerships,
    vTranslations: state.countryOptionsReducer.translations,
    flowSource: state.prefillReducer.flowSource,
    hideUseLocationButton: state.locReducer.hideUseLocationButton,
    selectedPlace: state.locReducer.selectedPlace,
    showListAndMap: state.locReducer.showListAndMap,
    showLocationIcon: state.locReducer.showLocationIcon,
    selectedPlace: state.locReducer.selectedPlace,
});

const mapDispatchToProps = (dispatch) => ({
    setSearchLocation: (places) =>
        dispatch({
            type: 'SET_SEARCH_LOCATION',
            payload: places,
        }),
    setSearchFlag: () =>
        dispatch({
            type: 'SET_SEARCH_FLAG',
        }),
    getDealers: (dealerships) =>
        dispatch({
            type: 'GET_DEALERS',
            payload: dealerships,
        }),
    setRenderFlagFalse: () =>
        dispatch({
            type: 'SET_RENDER_FLAG_FALSE',
        }),
    hideUseLocationButton: () =>
        dispatch({
            type: 'HIDE_USE_LOCATION_BUTTON',
        }),
    showListAndMap: () =>
        dispatch({
            type: 'SHOW_LIST_AND_MAP',
        }),
    showLocationIconAction: () =>
        dispatch({
            type: 'SHOW_LOCATION_ICON_TRUE',
        }),
    setSelectedPlace: () =>
        dispatch({
            type: 'SET_SELECTED_PLACE',
        }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchBox);
