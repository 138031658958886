export const URLParamsarray = [
    ['attempt', 'attempt'],
    ['bac', 'bac'],
    ['city', 'contact:address:home:city'],
    ['country', 'country'],
    ['customerComments', 'service:additionalcomments'],
    ['displaySmsAlertOption', 'displaySmsAlertOption'],
    ['editAppointmentRecordLocator', 'editAppointmentRecordLocator'],
    ['email', 'contact:email'],
    ['engine', 'vehicle:buy:engine'],
    ['firstName', 'contact:name:first'],
    ['flowSource', 'flowSource'],
    ['fuelType', 'vehicle:buy:fuel'],
    ['gcin', 'contact:gcin'],
    ['gpmsProfileId', 'contact:profileid'],
    ['hideAddToCalendar', 'hideAddToCalendar'],
    ['hideReturn', 'vhideReturn'],
    ['langCd', 'langCd'],
    ['lastName', 'contact:name:last'],
    ['locale', 'locale'],
    ['make', 'vehicle:buy:make'],
    ['miles', 'vehicle:buy:odometer:mi'],
    ['model', 'vehicle:buy:model'],
    ['nationalId', 'contact:nationalid'],
    ['origin', 'origin'],
    ['plus', 'vehicle:buy:plus'],
    ['phoneCell', 'contact:phone:cellphone'],
    ['phoneDay', 'contact:phone:phone:day'],
    ['phoneEvening', 'contact:phone:phone:evening'],
    ['postalCode', 'contact:address:home:postalcode'],
    ['programmaticallyDetectFlow', 'programmaticallyDetectFlow'],
    ['regionCode', 'contact:address:home:regioncode'],
    ['registrationMonth', 'vehicle:buy:regmonth'],
    ['registrationYear', 'vehicle:buy:regyear'],
    ['rego', 'vehicle:buy:registration'],
    ['serviceLineItems', 'service:lineitems'],
    ['serviceNotes', 'service:description'],
    ['street', 'contact:address:home:street'],
    ['theme', 'theme'],
    ['ultimateOrigin', 'origin'],
    ['urlPath', 'urlPath'],
    ['view', 'view'],
    ['vin', 'vehicle:buy:vin'],
    ['year', 'vehicle:buy:year'],
];
