import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
    setFlowSource,
    setFromReviewFlag,
    setHideContactInfoFlag,
    setModifyFlag,
    setShowCancelButtonsFlag,
} from '../../Redux/ActionCreator';
import { formatDateLong } from '../../shared/Utils/TimeLocalization';
import {
    editAppointmentAxios,
    getCalendarInvite,
    setModifyEmpty,
    submitAppointment,
} from '../../shared/BackendCalls/SharedBackendGets';
import appointmentObject from '../../shared/Utils/POST/AppointmentObject';
import AnalyticsService, { setPageNameForAnalytics } from '../../shared/Utils/analytics.service';

const constants = require('../../shared/Constants/SharedConstants');
const flowConstants = require('../../shared/RoutingFlow/FlowComponentsConstants');

class AppointmentDateAndTime extends React.Component {
    setModifyTrue = () => {
        this.props.setModifyFlag('true');
    };

    setShowCancelButtonsTrue = () => {
        AnalyticsService().trackAppointmentSubmissionPageAndEvent('cancel');
        setPageNameForAnalytics('analyticsPageName', 'cancelAppointment');
        this.props.setShowCancelButtonsFlag(true);
    };

    setHideContactInfo = () => {
        this.props.setHideContactInfoFlag('true');
    };

    setFromReview = () => {
        this.props.setFromReviewFlag('true');
    };

    editAppointment = () => {
        setPageNameForAnalytics('analyticsPageName', 'edit');
        this.setModifyTrue();
    };

    ics = () => {
        const apptObject = appointmentObject();

        getCalendarInvite(this.props.vCountry, apptObject.bac, this.props.record);
    };

    isMyBrandOrigin() {
        const origin = this.props.ultimateOrigin;
        let isMyBrand;
        const MY_BRAND = 'OCAPP';

        if (origin && origin.indexOf(MY_BRAND) > -1) {
            isMyBrand = true;
        } else {
            isMyBrand = false;
        }
        return isMyBrand;
    }

    consent = () => {
        const showPrivacyDetails = this.props.countryOptions.SHOW_PRIVACY_DETAILS;
        const showPrivacyDetailsModal = this.props.countryOptions.SHOW_PRIVACY_DETAILS_MODAL;
        if (showPrivacyDetails === 'true' && showPrivacyDetailsModal === 'true') {
            if (this.props.vDeploymentMode === 'REQUEST_FORM') {
                return (
                    <div className="view-privacy-policy">
                        <span
                            className="contact-disclaimer-label"
                            dangerouslySetInnerHTML={{
                                __html: `${this.props.vTranslations.CONSENT_PART_ONE_SUBIMIT_REQUEST} ${this.props.vTranslations.CONSENT_LINK} ${this.props.vTranslations.CONSENT_PART_TWO}`,
                            }}
                        />
                    </div>
                );
            }
            return (
                <div className="view-privacy-policy">
                    <span
                        className="contact-disclaimer-label"
                        dangerouslySetInnerHTML={{
                            __html: `${this.props.vTranslations.CONSENT_PART_ONE} ${this.props.vTranslations.CONSENT_LINK} ${this.props.vTranslations.CONSENT_PART_TWO}`,
                        }}
                    />
                </div>
            );
        }
        if (showPrivacyDetails === 'true' && showPrivacyDetailsModal === 'false') {
            if (this.props.vDeploymentMode === 'REQUEST_FORM') {
                return (
                    <span
                        className="contact-disclaimer-label"
                        dangerouslySetInnerHTML={{
                            __html:
                                this.props.vTranslations.CONTACT_CONSENT === undefined
                                    ? `${this.props.vTranslations.CONSENT_PART_ONE_SUBIMIT_REQUEST} ${this.props.vTranslations.CONSENT_LINK} ${this.props.vTranslations.CONSENT_PART_TWO}`
                                    : this.props.vTranslations.CONTACT_CONSENT,
                        }}
                    />
                );
            }
            return (
                <span
                    className="contact-disclaimer-label"
                    dangerouslySetInnerHTML={{
                        __html:
                            this.props.vTranslations.CONTACT_CONSENT === undefined
                                ? `${this.props.vTranslations.CONSENT_PART_ONE} ${this.props.vTranslations.CONSENT_LINK} ${this.props.vTranslations.CONSENT_PART_TWO}`
                                : this.props.vTranslations.CONTACT_CONSENT,
                    }}
                />
            );
        }
        return null;
    };

    render() {
        let showCalendarButton = false;
        let submitAppointmentButton;
        if (
            this.isMyBrandOrigin() ||
            this.props.countryOptions.ADD_TO_CALENDAR_MOBILE_APP !== 'false' ||
            this.props.dealershipSummary.displayCalendarButton
        ) {
            showCalendarButton = true;
        }

        if (this.props.modifyFlag === true) {
            submitAppointmentButton = (
                <Link
                    id="btnSubmit"
                    to={{ pathname: constants.CONFIRMATION_LINK }}
                    onClick={(e) => {
                        setModifyEmpty();
                    }}
                    className="main-button button primary-button"
                    data-dtm="submit appointment top"
                >
                    {this.props.footerButtonText}
                </Link>
            );
        } else {
            submitAppointmentButton = (
                <Link
                    id="btnSubmit"
                    to={this.props.footerButtonLink}
                    onClick={(e) => {
                        submitAppointment();
                    }}
                    data-dtm="submit appointment top"
                    className="main-button button primary-button"
                >
                    {this.props.footerButtonText}
                </Link>
            );
        }

        return (
            <div className="review-confirm-appointment-date-time-section paper">
                {this.props.showSpinner ? <CircularProgress /> : null}
                <div
                    className={`review-confirm-appointment-date-time bold${this.props.showSpinner ? ' invisible' : ''}`}
                >
                    {formatDateLong(this.props.selectedDate)}
                </div>
                {this.props.headerNavigation === constants.REVIEW && this.props.country === 'US' && (
                    <div className="submit-review">
                        <div>{this.consent()}</div>
                        <div className="submit-appointment-button">{submitAppointmentButton}</div>
                    </div>
                )}
                {this.props.headerNavigation === constants.CONFIRMATION ? (
                    !this.props.deleteAppointmentFlag ? (
                        !this.props.pastAppointment ? (
                            this.props.dealerSummary.deploymentMode === 'REQUEST_FORM' ? null : (
                                <div className="review-confirm-appointment-edit-cancel-link-container">
                                    {showCalendarButton ? (
                                        <>
                                            {!this.props.origin.includes('OCAPP') && (
                                                <button
                                                    id="btnAddToCal"
                                                    onClick={(e) => {
                                                        if (this.props.showSpinner) {
                                                            e.preventDefault();
                                                        }
                                                        this.ics();
                                                    }}
                                                    className={`stat-button-link confirmation-appointment-link link${
                                                        this.props.showSpinner ? ' invisible' : ''
                                                    }`}
                                                    data-dtm="appointment details"
                                                >
                                                    {this.props.footerButtonText}
                                                </button>
                                            )}
                                        </>
                                    ) : null}
                                    <Link
                                        id="btnEditAppt"
                                        data-dtm="view appointment"
                                        className={`stat-button-link review-confirm-appointment-edit-cancel-link${
                                            this.props.showSpinner ? ' invisible' : ''
                                        }`}
                                        onClick={() => {
                                            this.setHideContactInfo();
                                            this.editAppointment();
                                        }}
                                        to={{
                                            pathname: this.props.vTranslations.APPOINTMENT_LINK,
                                        }}
                                    >
                                        {this.props.vTranslations.EDIT_APPOINTMENT}
                                    </Link>
                                    <button
                                        data-dtm="view appointment"
                                        className={`stat-button-link confirmation-appointment-link link${
                                            this.props.showSpinner ? ' invisible' : ''
                                        }`}
                                        onClick={this.setShowCancelButtonsTrue}
                                    >
                                        {this.props.vTranslations.CANCEL_APPOINTMENT}
                                    </button>
                                </div>
                            )
                        ) : null
                    ) : null
                ) : null}
                {this.props.deleteAppointmentFlag ? (
                    <div className="review-confirm-appointment-date-time-cancel">
                        <div className="icon alert-caution-red" /> {this.props.vTranslations.CANCEL_SUCCESS}
                    </div>
                ) : null}
                {this.props.pastAppointment ? (
                    <div className="review-confirm-appointment-date-time-cancel">
                        {this.props.vTranslations.VIEW_PAST_APPT}
                    </div>
                ) : null}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    dealerSummary: state.prefillReducer.dealerSummaryObject.dealershipSummary,
    dealershipSummary: state.prefillReducer.dealerSummaryObject,
    deleteAppointmentFlag: state.confirmationReducer.deleteAppointmentFlag,
    flowSource: state.prefillReducer.flowSource,
    headerNavigation: state.headReducer.headerNavigation,
    modifyFlag: state.appointmentReducer.modifyFlag,
    pastAppointment: state.prefillReducer.editAppointmentObject.pastAppointment,
    recordLocator: state.reviewReducer.recordLocator,
    selectedDate: state.appointmentReducer.selectedDate,
    showSpinner: state.ossAppGlobals.showSpinner,
    vTranslations: state.countryOptionsReducer.translations,
    ultimateOrigin: state.prefillReducer.ultimateOrigin,
    countryOptions: state.countryOptionsReducer.countryOptions,
    origin: state.prefillReducer.origin,
    footerButtonText: state.headReducer.footerButtonText,
    record: state.reviewReducer.recordLocator
        ? state.reviewReducer.recordLocator
        : state.prefillReducer.editAppointmentRecordLocator,
    vCountry: state.prefillReducer.country,
    footerButtonLink: state.headReducer.footerButtonLink,
    country: state.prefillReducer.country,
    vDeploymentMode: state.prefillReducer.dealerSummaryObject.dealershipSummary.deploymentMode,
});

export default connect(mapStateToProps, {
    setFlowSource,
    setFromReviewFlag,
    setHideContactInfoFlag,
    setModifyFlag,
    setShowCancelButtonsFlag,
    setModifyEmpty,
    submitAppointment,
})(AppointmentDateAndTime);
withRouter(AppointmentDateAndTime);
