import {batch, connect} from 'react-redux';
import { Redirect } from 'react-router-dom';
import dayjs from 'dayjs';
import React from 'react';
import $ from 'jquery';
import {
    getAppointments,
    getNextAvailable,
    getAdvisorsTransportOptions,
    getPPDAvailability,
} from '../../shared/BackendCalls/SharedBackendGets';

import { formatDateLong } from '../../shared/Utils/TimeLocalization';
import {
    setAppointmentDate,
    setConciergePhoneNumber,
    setCustomerEmail,
    setCustomerFirstName,
    setCustomerLastName,
    setCustomerPhoneNumber, setPickupAddress,
    setShowTimesFlag,
    setStartDate,
    setTransportation,
    setTransportationCallComplete,
} from '../../Redux/ActionCreator';
import Contact from './Contact/Contact';
import Day from './Dates/Day';
import Month from './Dates/Month';
import Details from './Details/Details';
import store from '../../Redux/Store';
import Time from './Dates/Time';
import {
    AuthenticatedFlow,
    AuthenticatedOCPlus,
    DealerFlowUs,
    EditAppointmentFlow,
} from '../../shared/RoutingFlow/FlowComponentsConstants';
import AnalyticsService from '../../shared/Utils/analytics.service';
import {adobeAnalytics} from '../../shared/Utils/Utils';
import { getPhoneNum } from './Dealership/DealershipPhone';

import DealerShipInformation from './Dealership/DealerShipInformation';
import {MOBILE_SERVICE, PREMIUM_CONCIERGE, PREMIUM_CONCIERGE_WITH_LOANER} from "./AppointmentConstants";
import CircularProgress from "@material-ui/core/CircularProgress";
const customParseFormat = require('dayjs/plugin/customParseFormat');
const appointmentConstants = require('../Appointment/AppointmentConstants');

dayjs.extend(customParseFormat);

class Appointment extends React.Component {
    constructor(props) {
        super(props);
        window.scrollTo(0, 0);



        if (
            this.props.flowSource === AuthenticatedFlow ||
            this.props.flowSource === DealerFlowUs ||
            this.props.flowSource === AuthenticatedOCPlus
        ) {
            this.setCustomerInfo(
                this.props.customerEmail,
                this.props.customerFirstName,
                this.props.customerLastName,
                this.props.customerPhoneNumber
            );
        }

        if((this.props.transportation.code === MOBILE_SERVICE ||
            this.props.transportation.code === PREMIUM_CONCIERGE ||
            this.props.transportation.code === PREMIUM_CONCIERGE_WITH_LOANER) && (this.props.flowSource !== EditAppointmentFlow) && (!this.props.pickupAddress)){
            batch(()=>{
                store.dispatch(setTransportation('false'));
                store.dispatch(setPickupAddress('empty'));
            })

            }

        if (this.props.dealerSummary) {

            if (
                this.props.transportation.code === appointmentConstants.PREMIUM_CONCIERGE ||
                this.props.editAppt.transportationOption === appointmentConstants.PREMIUM_CONCIERGE
            ) {
                //First PP&D availability call to get first available date for label
                getPPDAvailability(this.props.dealerSummary.bac, null, dayjs(), 'ppdFirstAvail');
            } else {
                getNextAvailable(this.props.dealerSummary.bac);
            }
            getAdvisorsTransportOptions();
        }

        this.state = {
            isEditAppointmentFlow: this.props.flowSource === EditAppointmentFlow,
        };
    }

    componentDidUpdate() {
        this.props.availabilityErrorFlag ? this.props.history.push({ pathname: '/Error' }) : '';
    }

    componentDidMount() {
        // Initialize the Analytics Functionality if Appropriate
        if (AnalyticsService().loggingEnabled) {
            // adobeAnalytics((this.props.flowSource === EditAppointmentFlow ?  'edit appointment' : 'appointment'))

            // Append the Analytics Object to the DOM
            $('body').append(adobeAnalytics('form-step'));
            console.log('Analytics Added for Page to : Appointment using JQuery');
        }
    }


    // Owner Center can pass phone numbers in multiple fields. This function find which one has data.
    findPrepopulatedPhoneNumber(cell, day, evening) {
        let phoneNumber;

        if (cell) {
            phoneNumber = cell;
        } else if (day) {
            phoneNumber = day;
        } else if (evening) {
            phoneNumber = evening;
        } else {
            phoneNumber = cell;
        }

        return phoneNumber;
    }

    setCustomerInfo(email, firstName, lastName, phoneNumber) {
        if (!email) {
            this.props.setCustomerEmail(this.props.email);
        }

        if (!firstName) {
            this.props.setCustomerFirstName(this.props.firstName);
        }

        if (!lastName) {
            this.props.setCustomerLastName(this.props.lastName);
        }

        if (!phoneNumber) {
            const phone = this.findPrepopulatedPhoneNumber(
                this.props.phoneCell,
                this.props.phoneDay,
                this.props.phoneEvening
            );
            this.props.setCustomerPhoneNumber(phone);
            this.props.setConciergePhoneNumber(phone);
        }
    }

    /**
     * @desc Store first available data as selected date/time and reset other fields
     */
    selectFirstAvailable() {
        store.dispatch(setAppointmentDate(this.props.firstAvailDate));
        store.dispatch(setShowTimesFlag('true'));
        store.dispatch(setStartDate(this.props.firstAvailDate));

        getAppointments(this.props.firstAvailDate);
    }

    render() {
        return (
            <>
                {this.props.networkErrorFlag && <Redirect to="/Error" />}
                <div className="appointment-main-container">
                    <div className="appointment-main-header headline2">
                        {this.state.isEditAppointmentFlow
                            ? this.props.translations.MODIFY_APPOINTMENT
                            : this.props.translations.APP_OPTIONS}
                    </div>
                    <div className="appointment-headline-border headline-border" />
                    <DealerShipInformation />
                    <Details customerPhoneNumber={this.props.customerPhoneNumber} />
                    {
                        this.props.transportation.code && (this.props.transportation.code === MOBILE_SERVICE ||
                            this.props.transportation.code === PREMIUM_CONCIERGE_WITH_LOANER ||
                            this.props.transportation.code === PREMIUM_CONCIERGE ) && !this.props.pickupAddress ? null :

                            this.props.optionCallLoading ? <CircularProgress /> :  <>
                                <div className="appointment-header headline2">
                                {this.state.isEditAppointmentFlow
                                    ? this.props.translations.MODIFY_APP_DT
                                    : this.props.translations.APP_DT}
                            </div>
                                <div className="appointment-headline-border headline-border apt-headline2" />
                                {(this.props.availabilityErrorFlag && !this.props.countryOptions.HIDE_FIRST_AVAILABLE === 'true') ||
                                !this.props.firstAvailDate ? (
                                    (this.props.transportation.code === 'PREMIUM_CONCIERGE' ||
                                        this.props.transportation.code === 'CADILLAC_PREMIUM_CONCIERGE') && (
                                        <>
                                            <div>{this.props.translations.PPD_NO_AVAIL}</div>
                                        </>
                                    )
                                ) : (
                                    <div className="appointment-first-avail-container">
                                        {this.props.translations.FIRST_AVAILABLE} {''}
                                        {this.props.firstAvailDate === 'null' ? (
                                            <>
                                                {this.props.translations.INVALID_DATE}{' '}
                                                {
                                                    <a
                                                        className="dealership-phone call-dealership"
                                                        href={`tel:${getPhoneNum(this.props.dealerSummary, this.props.vCountry)}`}
                                                    >
                                                        {this.props.translations.CALL}
                                                    </a>
                                                }
                                            </>
                                        ) : (
                                            <>{formatDateLong(this.props.firstAvailDate)}</>
                                        )}
                                    </div>
                                )}
                                <Month />
                                <Day />
                                <Time />
                            </>




                    }

                    <Contact customerPhoneNumber={this.props.customerPhoneNumber} />
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    availabilityErrorFlag: state.appointmentReducer.availabilityErrorFlag,
    countryOptions: state.countryOptionsReducer.countryOptions,
    customerEmail: state.appointmentReducer.customerEmail,
    customerFirstName: state.appointmentReducer.customerFirstName,
    customerLastName: state.appointmentReducer.customerLastName,
    customerPhoneNumber: state.appointmentReducer.customerPhoneNumber,
    dealerSummary: state.prefillReducer.dealerSummaryObject.dealershipSummary,
    email: state.prefillReducer.email,
    firstAvailDate: state.appointmentReducer.firstAvailDate,
    firstName: state.prefillReducer.firstName,
    flowSource: state.prefillReducer.flowSource,
    lastName: state.prefillReducer.lastName,
    networkErrorFlag: state.appointmentReducer.networkErrorFlag,
    phoneCell: state.prefillReducer.phoneCell,
    phoneDay: state.prefillReducer.phoneDay,
    phoneEvening: state.prefillReducer.phoneEvening,
    startDate: state.appointmentReducer.startDate,
    translations: state.countryOptionsReducer.translations,
    transportation: state.appointmentReducer.transportation,
    editAppt: state.prefillReducer.editAppointmentObject,
    vCountry: state.prefillReducer.country,
    pickupAddress:state.appointmentReducer.pickupAddress,
    transportationCallComplete:state.appointmentReducer.transportationCallComplete,
    optionCallLoading: state.appointmentReducer.optionCallLoading
});

export default connect(mapStateToProps, {
    setAppointmentDate,
    setConciergePhoneNumber,
    setCustomerEmail,
    setCustomerFirstName,
    setCustomerLastName,
    setCustomerPhoneNumber,
    setShowTimesFlag,
    setStartDate,
})(Appointment);
