import {
    SET_ANALYTICS_PAGE_NAME,
    SET_HEADER_FOOTER,
    SET_PREFILL_HEADER,
    SET_TRANSLATIONS,
    SET_VEHICLE_SEARCH_BY_LICENSE_RESP,
} from './ActionTypes';
import findNextFlow from '../shared/RoutingFlow/RoutingFlow';

const initialState = {
    backButtonLink: '',
    headerNavigation: '',
    headerTitle: '',
    locationName: '',
    prefill: '',
    footerButtonLink: '',
    footerButtonText: '',
    translations: '',
    analyticsPageName:''
};

const headerReducer = (state = initialState, action) => {
    if (action.payload) {
        const constants = require('../shared/Constants/SharedConstants');

        let backButtonLink = '';
        let headerNavigation = '';
        let headerTitle = '';
        let locationName = '';
        let footerButtonLink = '';
        let footerButtonText = '';
        let urlHead = '';

        switch (action.type) {
            case SET_VEHICLE_SEARCH_BY_LICENSE_RESP: {
                if (Object.keys(action.payload).length > 0) {
                    if (action.payload.data[0].vin !== 'Error') {
                        state.backButtonLink = constants.VEHICLE; // Change back button to vehicle page when on search by license results page
                    }
                }
                return state;
            }
            case SET_PREFILL_HEADER: {
                state.prefill = action.payload;
                return state;
            }
            case SET_TRANSLATIONS: {
                state.translations = action.payload;
                return state;
            }
            case SET_ANALYTICS_PAGE_NAME: {
                state.analyticsPageName = action.payload;
                return state;
            }

            case SET_HEADER_FOOTER: {
                // headerTitle = action.payload;
                urlHead = state.translations.URL_HEAD;
                const flow = state.prefill.flowSource;
                // let currentPage;//Use for translated page name if necessary, will have to update RoutingFlow with translations
                switch (action.payload) {
                    case urlHead + state.translations.SERVICES_LINK: // We are in services page
                        headerNavigation = constants.TITLE_SERVICES;
                        headerTitle = state.translations.TITLE_SERVICES;
                        footerButtonText = state.translations.CONTINUE;
                        backButtonLink = state.translations[findNextFlow(flow, 'OssLandingPage', 'Back')];

                        // Use findNextFlow(flow, page, direction) to find the lookup key for the translation of the link
                        footerButtonLink = state.translations[findNextFlow(flow, 'OssLandingPage', 'Forward')];
                        break;
                    case urlHead + state.translations.DEALERSHIP_LOCATOR_LINK:
                        headerNavigation = constants.DEALERSHIP_LOC;
                        headerTitle = state.translations.DEALERSHIP_LOC;
                        footerButtonText = state.translations.CONTINUE;
                        // footerButtonLink = state.translations['APPOINTMENT_LINK'],
                        footerButtonLink = state.translations[findNextFlow(flow, 'Locator', 'Forward')];
                        backButtonLink = state.translations[findNextFlow(flow, 'Locator', 'Back')];
                        break;
                    case urlHead + state.translations.APPOINTMENT_LINK:
                        headerNavigation = constants.APPOINTMENT;
                        headerTitle = state.translations.APPOINTMENT;
                        footerButtonText = state.translations.CONTINUE;
                        footerButtonLink = state.translations[findNextFlow(flow, 'Appointment', 'Forward')];
                        backButtonLink = state.translations[findNextFlow(flow, 'Appointment', 'Back')];
                        break;
                    case urlHead + state.translations.REVIEW_LINK:
                        headerNavigation = constants.REVIEW;
                        headerTitle = state.translations.REVIEW;
                        if (state.prefill.dealerSummaryObject.dealershipSummary.deploymentMode === 'REQUEST_FORM') {
                            footerButtonText = state.translations.SUBMIT_REQUEST;
                        } else {
                            footerButtonText = state.translations.SUBMIT_APPOINTMENT;
                        }
                        footerButtonLink = state.translations[findNextFlow(flow, 'Review', 'Forward')];
                        backButtonLink = state.translations[findNextFlow(flow, 'Review', 'Back')];
                        break;
                    case urlHead + state.translations.CONFIRMATION_LINK:
                        headerNavigation = constants.CONFIRMATION;
                        headerTitle = state.translations.CONFIRMATION;
                        locationName = state.translations.CONFIRMATION;
                        backButtonLink = state.translations[findNextFlow(flow, 'Confirmation', 'Back')];
                        footerButtonText = state.translations.ADD_TO_CALENDAR;
                        break;
                    case urlHead + state.translations.ERROR_LINK:
                        headerTitle = state.translations.TITLE_ERROR;
                        break;
                    case urlHead + state.translations.VEHICLE_LINK: // We are in Select Vehicle page
                        headerNavigation = constants.SELECT_VEHICLE;
                        headerTitle = state.translations.SELECT_VEHICLE;
                        backButtonLink = state.translations[findNextFlow(flow, 'Vehicle', 'Back')];
                        footerButtonText = state.translations.CONTINUE;
                        footerButtonLink = state.translations[findNextFlow(flow, 'Vehicle', 'Forward')];
                        break;
                    case urlHead + state.translations.LANDING_LINK:
                        headerNavigation = constants.LANDING;
                        break;
                    case urlHead + state.translations.SIGN_IN_LINK:
                        headerNavigation = constants.SIGN_IN;
                        break;
                    case urlHead + state.translations.MY_GARAGE_LINK:
                        headerNavigation = constants.SELECT_VEHICLE;
                        headerTitle = state.translations.SELECT_VEHICLE;
                    default:
                        headerTitle = '';
                }
                return {
                    ...state,
                    footerButtonText,
                    footerButtonLink,
                    backButtonLink,
                    headerNavigation,
                    headerTitle,
                    locationName,
                };
            }
            default:
                return state;
        }
    } else {
        return state;
    }
};

export default headerReducer;
