import { connect } from 'react-redux';
import dayjs from 'dayjs';
import React from 'react';
import { getAppointments } from '../../../shared/BackendCalls/SharedBackendGets';
import { setShowTimesFlag, setStartDate } from '../../../Redux/ActionCreator';
import store from '../../../Redux/Store';

const isSameOrAfter = require('dayjs/plugin/isSameOrAfter');

dayjs.extend(isSameOrAfter);

class Month extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            maxLookaheadDate: this.getMaxLookaheadDate(
                this.props.countryOptions.MAXIMUM_APPOINTMENT_LOOKAHEAD,
                this.props.numDaysToDisplay
            ),
        };
    }

    /**
     * @desc Set start date
     * @param {dayjs object} date
     */
    setDate(date) {
        store.dispatch(setShowTimesFlag('false'));
        store.dispatch(setStartDate(date));
        getAppointments(date);
    }

    /**
     * @desc Find the maximum possible date that could be selected
     * @param {string} maxLookaheadDays
     * @param {int} numDaysToDisplay
     * @return dayJS object with maximum possible date
     */
    getMaxLookaheadDate(maxLookaheadDays, numDaysToDisplay) {
        const maxLookaheadInt = parseInt(maxLookaheadDays);

        const maxLookaheadDate = dayjs().add(maxLookaheadInt, 'day').subtract(numDaysToDisplay, 'day');

        return maxLookaheadDate;
    }

    /**
     * @desc Find the last day of dates being displayed
     * @param {dayjs object} startDate
     * @param {int} numDaysToDisplay
     * @returns {dayjs object} representing the last date of dates displayed
     */
    getEndDate(startDate, numDaysToDisplay) {
        const endDate = startDate.add(numDaysToDisplay - 1, 'day');

        return endDate;
    }

    /**
     * @desc Display first dates of previous month
     * @param {dayjs object} startDate
     */
    setPrevMonth(startDate) {
        const prevMonth = dayjs(startDate).subtract(1, 'month');
        const firstOfMonth = prevMonth.date(1);

        this.setState({ isNextButtonEnabled: true });

        if (dayjs().isSameOrAfter(firstOfMonth, 'date')) {
            this.setDate(dayjs());
        } else {
            this.setDate(firstOfMonth);
        }
    }

    /**
     * @desc Display first dates of next month
     * @param {dayjs object} startDate
     * @param {dayjs object} maxLookaheadDate
     * @param {int} numDaysToDisplay
     */
    setNextMonth(startDate, maxLookaheadDate, numDaysToDisplay) {
        const nextMonth = dayjs(startDate).add(1, 'month');
        const firstOfMonth = nextMonth.date(1);

        if (firstOfMonth.isSameOrAfter(dayjs(maxLookaheadDate).subtract(this.props.numDaysToDisplay, 'day'), 'date')) {
            this.setDate(maxLookaheadDate.subtract(numDaysToDisplay - 1, 'day'));
        } else {
            this.setDate(firstOfMonth);
        }
    }

    /**
     * @desc Returns boolean representing if next month button should be
     *  enabled
     * @param {*} startDate
     * @param {*} maxLookaheadDate
     * @param {*} numDaysToDisplay
     * @returns {boolean}
     */
    isNextButtonEnabled(startDate, maxLookaheadDate, numDaysToDisplay) {
        let isEnabled;
        const endDate = this.getEndDate(startDate, numDaysToDisplay);

        if (endDate.isSame(maxLookaheadDate, 'month')) {
            isEnabled = false;
        } else {
            isEnabled = true;
        }

        return isEnabled;
    }

    render() {
        const endMonth = this.getEndDate(this.props.startDate, this.props.numDaysToDisplay).format('MMMM YYYY');
        const startMonth = dayjs(this.props.startDate).format('MMMM YYYY');

        return (
            <div className="appointment-month-container">
                <div
                    id="btnLastMonth"
                    className={`prev-btn icon arrow-left${
                        dayjs().isSame(this.props.startDate, 'month') ? '-disabled disabled' : ''
                    }`}
                    onClick={() => this.setPrevMonth(this.props.startDate)}
                />
                <div className="appointment-month bold">
                    {
                        // Add hyphenated month if startingDate and endingDate are in different months
                        startMonth + (startMonth === endMonth ? '' : ` - ${endMonth}`)
                    }
                </div>
                <div
                    id="btnNextMonth"
                    className={`next-btn icon arrow-right${
                        this.isNextButtonEnabled(
                            this.props.startDate,
                            this.state.maxLookaheadDate,
                            this.props.numDaysToDisplay
                        )
                            ? ''
                            : '-disabled disabled'
                    }`}
                    onClick={() =>
                        this.setNextMonth(
                            this.props.startDate,
                            this.state.maxLookaheadDate,
                            this.props.numDaysToDisplay
                        )
                    }
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    countryOptions: state.countryOptionsReducer.countryOptions,
    numDaysToDisplay: state.appointmentReducer.numDaysToDisplay,
    startDate: state.appointmentReducer.startDate,
});

export default connect(mapStateToProps, {
    setShowTimesFlag,
    setStartDate,
})(Month);
