import {useLDClient} from "launchdarkly-react-client-sdk";
import {useEffect} from "react";
import {useSelector} from "react-redux";


const LDContextUpdate = ()=>{
    const ldClient = useLDClient();
    const {country,bac } = useSelector((state) => state.prefillReducer);

    useEffect(()=>{
        if(country && bac){
            const newMultiContext = {
                kind: 'multi',
                user: {
                    key: 'app-user-46679',
                    country:country
                },
                dealership: {
                    key: 'app-dealership-46679',
                    bac: bac
                }
            }
            if(ldClient){
                ldClient.identify(newMultiContext, null, function() {
                    console.log("New context's flags available");
                });
            }

        }
    },[]);
    return <></>
}

export default LDContextUpdate