import {
    SET_ACT_COMP,
    SET_APPCONFIG,
    SET_BRANCHDEALER,
    SET_DEALERSUMMARY,
    SET_FIRST_NAME,
    SET_FLOW_SOURCE,
    SET_LAST_NAME,
    SET_LOCALE,
    SET_MOCKED_TRANSLATIONS,
    SET_PREFILL,
    SET_PREFILL_MILEAGE,
    SET_PREFILL_MOCKED,
    SET_PREFILLEDIT,
    SET_REWARDS_POINTS,
    SET_BRANDSITE_ORIGIN,
    SET_MY_REWARDS_POINTS_INDICATOR,
    SET_MY_GARAGE_VIN,
    SET_MY_ACCOUNT_LOGIN,
    SET_PROFILE_ID,
    SET_EV_CERTIFIED,
    SET_CITY,
    SET_POSTAL_CODE,
    SET_REGION_CODE,
    SET_EMAIL,
    SET_STREET,
} from './ActionTypes';
import { URLParamsarray } from '../shared/Constants/urlParamNames';
import { ossDetermineFlow } from '../shared/RoutingFlow/FlowComponents';
import validateFlow from '../shared/RoutingFlow/FlowValidations';

const initialState = {
    actioncom: '',
    bac: '',
    backupTheme: '',
    branchDealer: false,
    brand: '',
    brandsiteOrigin: false,
    city: '',
    country: '',
    customerComments: '',
    dealerSummaryObject: { dealershipSummary: { address: { postalZone: '' } } },
    displaySmsAlertOption: false,
    editAppointmentFlagComp: false,
    editAppointmentObject: [],
    editAppointmentRecordLocator: '',
    email: '',
    engine: '',
    errorMessage: '',
    failRequiredParams: false,
    firstName: '',
    flowSource: '',
    fuelType: '',
    gcin: '',
    gpmsProfileId: '',
    hideAddToCalendar: false,
    hideReturn: '',
    isMyAccountLogin: 'false',
    langCd: '',
    lastName: '',
    licensePlate: '',
    locale: '',
    locator: false,
    make: '',
    makeCode: '',
    metricsSource: '',
    miles: '',
    model: '',
    nationalId: '',
    origin: '',
    pathname: '',
    phoneCell: '',
    phoneDay: '',
    phoneEvening: '',
    postalCode: '',
    programmaticallyDetectFlow: false,
    regionCode: '',
    registration: '',
    rego: '',
    rewardsPoints: '',
    sellingSource: '',
    serviceLineItems: '',
    serviceNotes: '',
    street: '',
    theme: '',
    themeMake: '',
    translations: '',
    ultimateOrigin: '',
    units: true,
    urlParamString: '',
    urlPath: '',
    useMyRewardsIndicator: '',
    view: '',
    vin: '',
    year: '',
    plus: '',
    certified: true,
    url: '',
    code: '',
    isOCPLusRequired:'false',
};

const constants = require('../shared/RoutingFlow/FlowComponentsConstants');
const sharedConstants = require('../shared/Constants/SharedConstants');
const localizationConstants = require('../shared/Constants/LocalizationConstants');

const prefillReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PREFILL: {
            return Object.assign({}, state, {
                newState: setPrefill(state, action),
            });
        }
        case SET_PREFILL_MOCKED: {
            return Object.assign({}, state, {
                newState: setPrefillMocked(state, action),
            });
        }
        case SET_APPCONFIG: {
            return Object.assign({}, state, {
                newState: setAppConfig(state, action),
            });
        }
        case SET_MOCKED_TRANSLATIONS: {
            return Object.assign({}, state, {
                newState: setMockedTranslations(state, action),
            });
        }
        case SET_REWARDS_POINTS: {
            return Object.assign({}, state, {
                newState: setRewardsPoints(state, action),
            });
        }
        case SET_PREFILL_MILEAGE: {
            return Object.assign({}, state, {
                newState: setPrefillMileage(state, action),
            });
        }
        case SET_BRANCHDEALER: {
            return Object.assign({}, state, {
                newState: setBranchDealer(state, action),
            });
        }
        case SET_PREFILLEDIT: {
            return Object.assign({}, state, {
                newState: setPrefillFromEditAppointment(state, action),
            });
        }
        case SET_ACT_COMP: {
            return Object.assign({}, state, {
                newState: setActComplete(state, action),
            });
        }
        case SET_LOCALE: {
            return Object.assign({}, state, {
                newState: setLocale(state, action),
            });
        }
        case SET_DEALERSUMMARY: {
            return Object.assign({}, state, {
                newState: setDealerSummary(state, action),
            });
        }
        case SET_FIRST_NAME: {
            if (action.payload) {
                return Object.assign({}, state, {
                    new_state: (state.firstName = action.payload),
                });
            }
        }
        case SET_LAST_NAME: {
            if (action.payload) {
                return Object.assign({}, state, {
                    new_state: (state.lastName = action.payload),
                });
            }
        }
        case SET_CITY: {
            if (action.payload) {
                return Object.assign({}, state, {
                    new_state: (state.city = action.payload),
                });
            }
        }
        case SET_POSTAL_CODE: {
            if (action.payload) {
                return Object.assign({}, state, {
                    new_state: (state.postalCode = action.payload),
                });
            }
        }
        case SET_REGION_CODE: {
            if (action.payload) {
                return Object.assign({}, state, {
                    new_state: (state.regionCode = action.payload),
                });
            }
        }
        case SET_STREET: {
            if (action.payload) {
                return Object.assign({}, state, {
                    new_state: (state.street = action.payload),
                });
            }
        }
        case SET_EMAIL: {
            if (action.payload) {
                return Object.assign({}, state, {
                    new_state: (state.email = action.payload),
                });
            }
        }
        case SET_FLOW_SOURCE: {
            if (action.payload) {
                return Object.assign({}, state, {
                    new_state: (state.flowSource = action.payload),
                });
            }
        }
        case SET_BRANDSITE_ORIGIN: {
            return Object.assign({}, state, {
                newState: setBrandsiteOrigin(state, action),
            });
        }
        case SET_MY_REWARDS_POINTS_INDICATOR: {
            let useMyRewardsIndicator = action.payload;
            return { ...state, useMyRewardsIndicator };
        }
        case SET_MY_GARAGE_VIN: {
            return { ...state, vin: action.payload };
        }
        case SET_MY_ACCOUNT_LOGIN: {
            return Object.assign({}, state, {
                newState: setMyAccountLogin(state, action),
            });
        }
        case SET_PROFILE_ID: {
            return { ...state, gpmsProfileId: action.payload };
        }
        case SET_EV_CERTIFIED: {
            let { certified, url, code } = action.payload;

            return {
                ...state,
                certified,
                url,
                code,
            };
        }
        default:
            return state;
    }
};

const setPrefill = (state, action) => {
    state.actioncom = sharedConstants.PREFILL;

    if (Object.keys(action.payload).length > 0) {
        state.pathname = action.payload.pathname;

        let searchLocation = action.payload.search;

        if (window.locationurl) {
            searchLocation = '?' + window.locationurl;
        }

        state.urlParamString = searchLocation;

        let urlParamsItems = URLParamsarray;

        for (let i = 0; i < urlParamsItems.length; i++) {
            let item = urlParamsItems[i];
            let params = _.get(action.payload.queryArray, item[1]);

            if (params) {
                //if more than one origin, set the second one as ultimateOrigin
                if (Array.isArray(params)) {
                    if (item[0] === 'origin') {
                        state[item[0]] = params[0];
                        params = undefined;
                    } else if (item[0] === 'ultimateOrigin') {
                        state[item[0]] = params[1];
                        params = undefined;
                    } else {
                        state[item[0]] = params;
                        params = undefined;
                    }
                } else {
                    state[item[0]] = params;
                    params = undefined;
                }
            }
        }
        if (state.origin === '') {
            state.origin = 'CFUI';
        }
        if (state.ultimateOrigin === '') {
            state.ultimateOrigin = 'CFUI';
        }

        //Remove 1 from beginning of phone numbers if phone number is larger than 10
        if (state.phoneDay && state.phoneDay.length > 10) {
            state.phoneDay = state.phoneDay.replace(/\s/g, '');

            if (state.phoneDay.charAt(0) === '+') {
                state.phoneDay = state.phoneDay.substring(1);
                state.phoneDay = state.phoneDay.replace(/\s/g, '');
            }
            if (state.phoneDay.charAt(0) === '1') {
                state.phoneDay = state.phoneDay.substring(1);
                state.phoneDay = state.phoneDay.replace(/\s/g, '');
            }
            if (state.phoneDay.charAt(1) === '1') {
                state.phoneDay = state.phoneDay.substring(2);
                state.phoneDay = state.phoneDay.replace(/\s/g, '');
            }
        }
        if (state.phoneCell && state.phoneCell.length > 10) {
            state.phoneCell = state.phoneCell.replace(/\s/g, '');

            if (state.phoneCell.charAt(0) === '+') {
                state.phoneCell = state.phoneCell.substring(1);
                state.phoneCell = state.phoneCell.replace(/\s/g, '');
            }
            if (state.phoneCell.charAt(0) === '1') {
                state.phoneCell = state.phoneCell.substring(1);
                state.phoneCell = state.phoneCell.replace(/\s/g, '');
            }
            if (state.phoneCell.charAt(1) === '1') {
                state.phoneCell = state.phoneCell.substring(2);
                state.phoneCell = state.phoneCell.replace(/\s/g, '');
            }
        }
        if (state.phoneEvening && state.phoneEvening > 10) {
            state.phoneEvening = state.phoneEvening.replace(/\s/g, '');

            if (state.phoneEvening.charAt(0) === '+') {
                state.phoneEvening = state.phoneEvening.substring(1);
                state.phoneEvening = state.phoneEvening.replace(/\s/g, '');
            }
            if (state.phoneEvening.charAt(0) === '1') {
                state.phoneEvening = state.phoneEvening.substring(1);
                state.phoneEvening = state.phoneEvening.replace(/\s/g, '');
            }
            if (state.phoneEvening.charAt(1) === '1') {
                state.phoneEvening = state.phoneEvening.substring(2);
                state.phoneEvening = state.phoneEvening.replace(/\s/g, '');
            }
        }

        const pathArray = state.pathname ? state.pathname.split('/') : [];
        //Next Set the BAC and Country, and Mileage if they did not come from URL params
        if (!state.bac && pathArray.length >= 6 && pathArray[3] !== 'b') {
            if (pathArray[3] === 'dealership') {
                state.bac = pathArray[4];
            } else {
                state.bac = pathArray[5];
            }
        }

        if (!state.country && pathArray[2] === 'o' && pathArray[3] === 'd') {
            state.country = pathArray[4];
        }
        if (!state.country && pathArray[2] === 'o' && pathArray[3] === 'b') {
            state.locator = true;
            state.country = pathArray[4];

            if (pathArray[5]) {
                state.theme = pathArray[5];
            }
        }

        if (!state.miles) {
            if (
                action.payload.previousRequest &&
                typeof action.payload.previousRequest.vehicle !== 'undefined' &&
                typeof action.payload.previousRequest.vehicle.mileage !== 'undefined'
            ) {
                state.miles = action.payload.previousRequest.vehicle.mileage;
            } else {
                state.miles = '0';
            }
        }

        //Set the URL Path, need this for flows:
        state.urlPath = action.payload.pathname;

        //Call function to set theme
        state.backupTheme = action.payload.backupTheme;
        setCSSThemeMake(state);

        //If langCd is defined set that as the locale
        if (state.langCd) {
            state.locale = state.langCd;
        }

        state.flowSource = ossDetermineFlow(
            state.vin,
            state.serviceLineItems,
            state.origin,
            state.pathname,
            state.branchDealer,
            state.bac,
            state.locator,
            state.make,
            state.year,
            state.model,
            state.isMyAccountLogin,
            state.isOCPLusRequired
        );

        switch (state.flowSource) {
            case constants.BrandFlow:
                break;
            case constants.TireFinderFlow:
                break;
            case constants.EditAppointmentFlow:
                //Retrieve the Appointment Record Locator/BAC from URL
                state.editAppointmentRecordLocator = pathArray[6];
                state.country = localizationConstants.US;

                if (action.payload.locale) {
                    state.langCd = action.payload.locale.locale.tag;
                }
                break;
            case constants.AuthenticatedFlow:
                //Verify Minimum Required Params
                //Next we need to check that the minimum required parameters are sent in
                if (
                    !state.firstName ||
                    !state.lastName ||
                    !state.street ||
                    !state.city ||
                    !state.regionCode ||
                    !state.postalCode
                ) {
                    state.failRequiredParams = true;
                }
                break;
            case constants.OssLocatorFlow:
                break;
            case constants.DealerFlowUs:
                break;
            case constants.DealerFlow:
                break;
            default:
            //Send to error page, condition may never happen?
        }

        state.brand = action.payload.brand;
        state.brandsiteOrigin = action.payload.brandsiteOrigin;
        state.customerComments = action.payload.customerComments;
        state.makeCode = action.payload.makeCode;
        state.nationalId = action.payload.nationalId;
        state.rego = action.payload.rego;
        state.sellingSource = action.payload.sellingsource;
        state.serviceNotes = action.payload.serviceNotes;
    }
    return state;
};

const setPrefillMocked = (state, action) => {
    state.actioncom = sharedConstants.PREFILL;

    if (Object.keys(action.payload).length > 0) {
        state.urlParamString =
            '?country=US&bac=118653&locale=&origin=TESTPAGE&vehicle:buy:vin=1G1AL55F577342141&vehicle:buy:year=2014&vehicle:buy:make=Chevrolet&vehicle:buy:model=Corvette&vehicle:buy:engine=SUPER FAST!&vehicle:buy:odometer:mi=45000&contact:name:first=FirstName&contact:name:last=LastName&contact:address:home:street=123 Main St.&contact:address:home:city=Atlanta&contact:address:home:regioncode=GA&contact:address:home:postalcode=30009&contact:email=myemail@gm.com&contact:phone:phone:day=123-456-7890&contact:phone:cellphone=234-567-8901&contact:phone:phone:evening=345-678-9012&service:additionalcomments=Customer Comments';

        if (Object.keys(action.payload).length > 0) {
            let urlParamsItems = URLParamsarray;
            for (let i = 0; i < urlParamsItems.length; i++) {
                let item = urlParamsItems[i];
                state[item[0]] = action.payload[item[0]];
            }
        }
        //Remove 1 from beginning of phone numbers if phone number is larger than 10
        if (state.phoneDay && state.phoneDay.length > 10) {
            if (state.phoneDay.charAt(0) === '+') {
                state.phoneDay = state.phoneDay.substring(1);
            }
            if (state.phoneDay.charAt(0) === '1') {
                state.phoneDay = state.phoneDay.substring(1);
            }
        }
        if (state.phoneCell && state.phoneCell.length > 10) {
            if (state.phoneCell.charAt(0) === '+') {
                state.phoneCell = state.phoneCell.substring(1);
            }
            if (state.phoneCell.charAt(0) === '1') {
                state.phoneCell = state.phoneCell.substring(1);
            }
        }
        if (state.phoneEvening && state.phoneEvening > 10) {
            if (state.phoneEvening.charAt(0) === '+') {
                state.phoneEvening = state.phoneEvening.substring(1);
            }
            if (state.phoneEvening.charAt(0) === '1') {
                state.phoneEvening = state.phoneEvening.substring(1);
            }
        }
    }

    //Call function to set theme
    setCSSThemeMake(state);

    if (state.programmaticallyDetectFlow) {
        state.flowSource = ossDetermineFlow(
            state.vin,
            state.serviceLineItems,
            state.origin,
            state.urlPath,
            state.branchDealer,
            state.bac,
            state.locator,
            state.make,
            state.year,
            state.model,
            state.isMyAccountLogin,
            state.isOCPLusRequired
        );
    }

    state.errorMessage = validateFlow(state);

    switch (state.flowSource) {
        case constants.EditAppointmentFlow:
            //Retrieve the Appointment Record Locator if not defined directly
            if (!state.editAppointmentRecordLocator) {
                let pathArray = state.urlPath.split('/');
                state.editAppointmentRecordLocator = pathArray[6];
            }

        default:
        //Send to error page, condition may never happen?
    }

    return state;
};

const setAppConfig = (state, action) => {
    if (Object.keys(action.payload).length > 0) {
        //Next extract the GCIN so we can set that as we need for the rewards call
        if (
            action.payload.previousRequest &&
            typeof action.payload.previousRequest.customer !== 'undefined' &&
            typeof action.payload.previousRequest.customer.gcin !== 'undefined'
        ) {
            state.gcin = action.payload.previousRequest.customer.gcin;
        }
    }
    return state;
};

const setMockedTranslations = (state, action) => {
    if (Object.keys(action.payload).length > 0) {
        state.translations = action.payload;

        //Hardcode the Metrics Source for Mocked
        state.metricsSource = 'DEALER';
    }
    return state;
};

const setRewardsPoints = (state, action) => {
    if (action.payload > 0) {
        state.rewardsPoints = action.payload;
    }
    return state;
};

const setPrefillMileage = (state, action) => {
    if (action.payload > 0) {
        state.miles = action.payload;
    }
    return state;
};

const setBranchDealer = (state, action) => {
    if (action.payload > 0) {
        state.branchDealer = action.payload;
        if (window.location.pathname.includes('DevelopEntryParams') || window.location.pathname.includes('/oss/o/d/')) {
            state.flowSource = ossDetermineFlow(
                state.vin,
                state.serviceLineItems,
                state.origin,
                state.urlPath,
                state.branchDealer,
                state.bac,
                state.locator,
                state.make,
                state.year,
                state.model,
                state.isMyAccountLogin,
                state.isOCPLusRequired
            );
        }
    }
    return state;
};

const setMyAccountLogin = (state, action) => {
    if (action.payload) {
        state.isMyAccountLogin = action.payload['MY_ACCOUNT_LOGIN_ENABLED']

        //Determine if this is a Owner Center Plus Scenario
        state.isOCPLusRequired = action.payload['VEHICLE_REQUIREPLUS_ORIGINS'].includes(
            state.origin
        );

        state.flowSource = ossDetermineFlow(
            state.vin,
            state.serviceLineItems,
            state.origin,
            state.urlPath,
            state.branchDealer,
            state.bac,
            state.locator,
            state.make,
            state.year,
            state.model,
            state.isMyAccountLogin,
            state.isOCPLusRequired
        );
    }
    return state;
};

const setDealerSummary = (state, action) => {
    if (Object.keys(action.payload).length > 0) {
        state.dealerSummaryObject = action.payload;
        state.actioncom = sharedConstants.SUMMBAC;

        if (window.location.pathname.includes('DevelopEntryParams') || window.location.pathname.includes('/oss/o/d/')) {
            if (action.payload.dealershipSummary.childSummaries.length > 0) {
                state.branchDealer = true;
                state.flowSource = ossDetermineFlow(
                    state.vin,
                    state.serviceLineItems,
                    state.origin,
                    state.urlPath,
                    state.branchDealer,
                    state.bac,
                    state.locator,
                    state.make,
                    state.year,
                    state.model,
                    state.isMyAccountLogin,
                    state.isOCPLusRequired
                );
            }
        }
    }
    return state;
};

const setPrefillFromEditAppointment = (state, action) => {
    if (Object.keys(action.payload).length > 0) {
        state.editAppointmentObject = action.payload;
        state.country = action.payload.countryCode;
        state.bac = action.payload.bac;
        state.locale = action.payload.customerLocale;
        state.gcin = action.payload.customer.gcin;
        state.year = action.payload.vehicle.vehicle.year;
        state.vin = action.payload.vehicle.vin;
        state.make = action.payload.vehicle.vehicle.make.make;
        state.model = action.payload.vehicle.vehicle.model.model;
        state.miles = action.payload.vehicle.mileage;
        state.engine = action.payload.vehicle.vehicle.plus ? action.payload.vehicle.vehicle.plus.plus : '';
        state.licensePlate = action.payload.vehicle.licensePlate;
        state.registration = action.payload.vehicle.registrationId;
        state.useMyRewardsIndicator = action.payload.useMyRewardsIndicator;

        //Do a reverse loop and reconstruct the URL to have available if the user hits new appointment.
        let urlParamsItems = URLParamsarray;
        state.urlParamString = '?';
        let place = '';

        for (let i = 0; i < urlParamsItems.length; i++) {
            let item = urlParamsItems[i];
            if (state[item[0]]) {
                state.urlParamString = state.urlParamString + place + item[1] + '=' + state[item[0]];
                place = '&';
            }
        }
    }
    return state;
};

const setActComplete = (state, action) => {
    if (action.payload) {
        state.actioncom = action.payload;
    }
    return state;
};

const setLocale = (state, action) => {
    if (Object.keys(action.payload).length > 0) {
        state.locale = action.payload;
    }
    return state;
};

const setBrandsiteOrigin = (state, action) => {
    state.brandsiteOrigin = action.payload;
    return state;
};

//Helpers start here:
export const setCSSThemeMake = (state) => {
    //Try to grab backup theme if we didn't get one in URL
    if (!state.theme) {
        state.theme = state.backupTheme;
    }

    //Run Validations on Specific Flow
    //If we have the make or a theme call the CSS Renderer Here:
    if (state.theme) {
        switch (state.theme) {
            case sharedConstants.BU: {
                state.themeMake = sharedConstants.Buick;
                break;
            }
            case sharedConstants.CA: {
                state.themeMake = sharedConstants.Cadillac;
                break;
            }
            case sharedConstants.CH: {
                state.themeMake = sharedConstants.Chevrolet;
                break;
            }
            case sharedConstants.GM: {
                state.themeMake = sharedConstants.GMC;
                break;
            }
            case sharedConstants.b_buick: {
                state.themeMake = sharedConstants.Buick;
                break;
            }
            case sharedConstants.b_cadillac: {
                state.themeMake = sharedConstants.Cadillac;
                break;
            }
            case sharedConstants.b_chevrolet: {
                state.themeMake = sharedConstants.Chevrolet;
                break;
            }
            case sharedConstants.b_gmc: {
                state.themeMake = sharedConstants.GMC;
                break;
            }
            default:
                break;
        }
    }
    console.log('The theme is' + state.themeMake);
};

export default prefillReducer;
