import {useSelector} from "react-redux";
import * as React from 'react';
import {setMobileServicePlusAcknowledgeModel, showMobileServicePlusAcknowledgeModel} from "../../Redux/ActionCreator";
import store from "../../Redux/Store";
import {Typography} from "@material-ui/core";
import {useFlags} from "launchdarkly-react-client-sdk";
import {checkVinEligibility} from "../../shared/BackendCalls/SharedBackendGets";
import {useEffect} from "react";
import SharedBasicModal from "../../shared/Modal/SharedBasicModal";





const ServiceAcknowledge = ()=>{
    const {showAcknowledgeModel}  = useSelector((state) => state.mobileServiceReducer);
    const {translations} = useSelector((state) => state.countryOptionsReducer);


    const translationsObject = [translations.MOBILE_SERVICE_ACK_PARA_1 || "MOBILE_SERVICE_ACK_PARA_1",
                                translations.MOBILE_SERVICE_ACK_PARA_2 || "MOBILE_SERVICE_ACK_PARA_2",
                                translations.MOBILE_SERVICE_ACK_PARA_3 || "MOBILE_SERVICE_ACK_PARA_3"]


    return <>
        <SharedBasicModal show={showAcknowledgeModel}
                          title={translations.MOBILE_SERVICE_ACK_TITLE}
                          onClose={()=>{store.dispatch(setMobileServicePlusAcknowledgeModel(false))}}
                          primaryButton={translations.MOBILE_SERVICE_ACK_BUTTON_ACK}
                          bodyAsText={translationsObject.length && translationsObject.map((bodyItem)=>(<Typography paragraph key={bodyItem}>{bodyItem}</Typography>))}
                          handleClick={()=>{store.dispatch(setMobileServicePlusAcknowledgeModel(false))}}/>

   </>

}

export default ServiceAcknowledge